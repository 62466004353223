import * as React from "react";
import { FormattedMessage } from "react-intl";
import Button from "reactstrap/lib/Button";

interface DetailToggleButtonProps {
	onClick: () => void;
	detailsVisible: boolean;
	pricing?: boolean;
}

/**
 * DetailToggleButton - To be used when toggling between showing and hiding details.
 *
 * @param onClick - Handle the click, usually by toggling a "details are open" state.
 * @param detailsVisible - Current toggle state
 * @param pricing - Whether the lable should refer specifically to pricing details
 */
export const DetailToggleButton: React.FunctionComponent<DetailToggleButtonProps> = ({ onClick, detailsVisible, pricing }) => (
	<Button className="pl-0" color="link" size="sm" onClick={onClick}>
		{detailsVisible
			? pricing ? <FormattedMessage id={"lbl_HidePricingDetails"} /> : <FormattedMessage id={"lbl_HideDetails"} /> 
			: pricing ? <FormattedMessage id={"lbl_SeePricingDetails"} /> : <FormattedMessage id={"lbl_SeeDetails"} />
		}
	</Button>
);
