import * as React from 'react';
import {FormattedMessage} from "react-intl";
import {Button} from "reactstrap";
import {InjectedInventoryServiceProps} from "../../hoc/inject-inventory-service";
import { RemoveItemIcon } from "../icons/icons";
import './passcode-display.css';

interface PasscodeDisplayProps {
	appliedPasscode: string;
	eventInstanceId: string;
	fetchEvents: () => void;
}

export class PasscodeDisplay extends React.Component<PasscodeDisplayProps & InjectedInventoryServiceProps> {
	
	public render() {
		const {appliedPasscode} = this.props;
		return (
			<div className="pts-passcode-display d-inline-block mb-2 py-0 px-2 small">
				<span className="text-muted mr-1">
					<FormattedMessage id="lbl_Passcode"/>: 
				</span>
				<span className="text-info">
					{appliedPasscode}
				</span>
				<Button color="link" onClick={this.clearPasscode}>
					<RemoveItemIcon />
				</Button>
			</div>
		);
	}
	
	private clearPasscode = () => {
		const {eventInstanceId, fetchEvents, inventoryService} = this.props;
		
		// remove the passcode , reset the eventDescriptor, and refetch the events
		window.PublicTicketApp.passcode = '';
		
		inventoryService.expireCache();
		inventoryService.fetchEventDescriptor(eventInstanceId);
		fetchEvents();
	}
	
}
