import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {AnyAction} from 'redux';
import {EventInstanceSaleStatus} from '../../enums/event-instance-sale-status';
import {CartService} from "../../helpers/cart-service";
import {InventoryService} from "../../helpers/inventory-service";
import {getEventRoute} from "../../helpers/routing";
import {BasicStringKeyedMap} from '../../models/basic-map';
import {LevelDescriptor} from '../../models/event-descriptor/level-descriptor';
import {CountdownTimer} from "../countdown-timer";
import {DetailToggleButton} from "../detail-toggle-button";
import {EmptyMiniCartMessage} from "../empty-mini-cart-message";
import {HTMLContent} from "../html-content/html-content";
import {MiniCartItemTotal} from "../mini-cart/mini-cart-item-total";
import {PanelNav} from "../panel-nav";
import {
	PasscodeDisplayWithInjections as PasscodeDisplay,
	PasscodeFormWithInjections as PasscodeForm
} from "../passcode/wrapped-components";
import {CartItemQuantityPickerWithInjectedIntl as CartItemQuantityPicker} from './ga-item-quantity-selector';
import {getGAQuantityInputsTotalPrice, QuantityBasedCartFormProps} from "./ga-item-selection";


export interface GACartProps extends QuantityBasedCartFormProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	clearAllMessages: () => void;
	fetchEvents: () => void;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

interface GACartState {
	showPricingDetails: boolean;
}

/**
 * A list of allocations in the GA form.
 */
export class GASeatForm extends React.Component<GACartProps, GACartState> {
	constructor(props: GACartProps) {
		super(props);
		this.state = {showPricingDetails: true}
	}

	public previous = () => {
		this.props.history.push(getEventRoute(this.props.eventDescriptor.teId));
	}
	
	public togglePricingDetails = () => {
		this.setState({showPricingDetails: !this.state.showPricingDetails});
	}

	public render() {

		const { 
			blockingActions,
			cart,
			cartTimeRemaining,
			clearAllMessages,
			config: {includeFeesInPrice, portalSeatSelectionMessage}, 
			currencyCode, 
			deleteFromCart, 
			eventDescriptor, 
			fetchEvents,
			quantityInputs, 
			handleNext,
			handleQuantityChange, 
			intl,
			validatePasscode
		} = this.props;
		const cartService = new CartService(cart);
		const itemTotal = cartService.getItemTotalPrice(eventDescriptor.id, includeFeesInPrice) + getGAQuantityInputsTotalPrice(Object.values(quantityInputs), includeFeesInPrice);
		const eiCartSize = cartService.getNumberOfItemsInCart(eventDescriptor.id) + Object.values(quantityInputs).reduce((prev, input) => prev + Number(input.inputQty), 0);
		const isBusy = Object.values(quantityInputs).some(gaInput => gaInput.isBusy);

		const gaItems: JSX.Element[] = [];

		let nonPWYWPriceLevelExists = false;

		eventDescriptor.allocList.forEach(allocation => {
			const pricelevels = InventoryService.getLevelsByAllocationId(eventDescriptor, allocation.id);
			if (pricelevels.length <= 0) {
				return; // no need to render anything if there are no allocations found
			}

			let allocationItems;

			if (allocation.hasRetQty) {
				if(!nonPWYWPriceLevelExists) {  
					nonPWYWPriceLevelExists = pricelevels.some((pl) => !pl.pwyw);  
				}

				allocationItems = pricelevels.map((level: LevelDescriptor) => (
					<div key={level.id} className="mr-3 mb-3">
						<CartItemQuantityPicker
							currencyCode={currencyCode}
							quantityInput={quantityInputs[level.id]}
							deleteFromCart={deleteFromCart}
							handleQuantityChange={handleQuantityChange}
							includeFeesInPrice={includeFeesInPrice}
						 	showPricingDetails={this.state.showPricingDetails}
						/>
					</div>
				));
			} else {
				allocationItems = <p className="text-info"><FormattedMessage id="lbl_SoldOut"/></p>;
			}

			gaItems.push(
				<div key={allocation.id} className="mb-5">
					<p className="m-0 font-weight-bold">{allocation.name}</p>
					<p className="mb-2">
						<small className="text-info">{allocation.note}</small>
					</p>
					{allocationItems}
				</div>
			);
		});

		return (
			<div className="ga-cart">
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				{eventDescriptor.isPasscodeEligible && (
					<PasscodeForm
						blockingActions={blockingActions}
						clearAllMessages={clearAllMessages}
						eventInstanceId={eventDescriptor.id}
						fetchEvents={fetchEvents}
						validatePasscode={validatePasscode}
					/>
				)}
				{!!eventDescriptor.appliedPasscode && (
					<PasscodeDisplay
						appliedPasscode={eventDescriptor.appliedPasscode}
						eventInstanceId={eventDescriptor.id}
						fetchEvents={fetchEvents}
					/>
				)}
				
				{eventDescriptor.saleStatus === EventInstanceSaleStatus.OS && eventDescriptor.hasRetQty &&  (
					<>
						{!!portalSeatSelectionMessage && (
							<p className="mb-2">
								<HTMLContent rawHtml={portalSeatSelectionMessage}/>
							</p>
						)}
						
						{gaItems}
						
						{(includeFeesInPrice && nonPWYWPriceLevelExists) && 
							<DetailToggleButton onClick={this.togglePricingDetails} detailsVisible={this.state.showPricingDetails} pricing={true}/>
						}

						{eiCartSize > 0
							? <MiniCartItemTotal currencyCode={currencyCode} itemTotal={itemTotal}/>
							: <EmptyMiniCartMessage/>
						}

						<div className="mt-3">
							<PanelNav
								next={{handleClick: handleNext, label: intl.formatMessage({id: "lbl_Next"}), isDisabled: !eiCartSize || isBusy}}
								back={{handleClick: this.previous, label: intl.formatMessage({id: "lbl_Back"})}}
							/>
						</div>
					</>
				)}
			</div>
		);
	}

}
