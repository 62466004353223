import {WrappedComponentProps} from "react-intl";
import {Route, Switch} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {Paths} from "../../enums/paths";
import {InventoryService} from "../../helpers/inventory-service";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {SeatDescriptor} from "../../models/event-descriptor/seat-descriptor";
import {SeatMap} from "../seat-map/seat-map";
import {SectionMap} from "../section-map/section-map";

interface FulfillmentVenueMapProps extends RouteComponentProps<any>, WrappedComponentProps {
	cart: Cart;
	fulfillmentEventDescriptor: EventDescriptor;
	existingSeats: BasicStringKeyedMap<CartItem>;
	pendingSeats: BasicStringKeyedMap<string>;
	stagedSeats: BasicStringKeyedMap<CartItem>
	numberOfSeatsRequired: number;
	handleSeatClick: (seat: SeatDescriptor) => void;
	isCYO: boolean;
}

export const FulfillmentVenueMap = (props: FulfillmentVenueMapProps) => {
	const {fulfillmentEventDescriptor, existingSeats, pendingSeats, stagedSeats, numberOfSeatsRequired, handleSeatClick, history, isCYO, match, intl} = props;
	
	return (
		<div>
			{!!fulfillmentEventDescriptor && (
				<Switch>
					<Route path={[Paths.FULFILLMENT_VENUE, Paths.FULFILLMENT_INSTANCE]} exact={true} render={() => {
						return (
							<SectionMap eventDescriptor={fulfillmentEventDescriptor} fulfillment={true} isCYO={isCYO} history={history} match={match} />
						)}
					} />

					<Route path={[Paths.FULFILLMENT_SECTION, Paths.FULFILLMENT_INSTANCE_SECTION]} exact={true} render={(routeProps) => {
						return (
							<div>
								<div id="SeatLegend" className="mb-2">
									<div className="d-flex align-items-start">
										<div className="w-100 text-center">
											<span className="seat-map seat seat-unavailable" style={{display: "inline-block", width: 12, height: 12, marginRight: 10}}/>
											{intl.formatMessage({id: "lbl_SectionOrGroupNotAvailable"})}
										</div>
										<div className="w-100 text-center">
											<span className="seat-map seat seat-selected" style={{display: "inline-block", width: 12, height: 12, marginRight: 10}}/>
											{intl.formatMessage({id: "lbl_YourSeats"})}
										</div>
									</div>
								</div>

								<div className="text-center font-weight-bold mb-3">
									{intl.formatMessage({id: "lbl_SelectNumberOfSeats"}, {numSeatsRequired: numberOfSeatsRequired})}
								</div>
								
								<SeatMap
									sectionOrGroupId={routeProps.match.params.sectionId}
									eventDescriptor={fulfillmentEventDescriptor}
									onSeatClick={handleSeatClick}
									pyosCartItemMap={existingSeats}
									allocMap={InventoryService.getAllocationsMappedById(fulfillmentEventDescriptor)}
									pendingSeatRequests={pendingSeats}
									stagedSeatRequests={stagedSeats}
									{...props}
								/>
							</div>
						)}
					} />
				</Switch>
			)}
		</div>
	)
}