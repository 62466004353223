import {AnyAction} from "redux";
import {Analytics} from "../models/analytics";
import {Cart} from "../models/cart";
import {EventDescriptorCache} from "../models/event-descriptor-cache/event-descriptor-cache";
import {Portal} from "../models/portal/portal";
import {PublicTicketApp} from "../models/public-ticket-app/public-ticket-app";
import {analytics} from "./analytics";
import {cart} from "./cart";
import {eventDescriptorCache} from "./event-descriptor-cache";
import {portal} from "./portal";
import {ptApp} from "./public-ticket-app";


///
/// Interfaces
///

export interface RootState {
	analytics: Analytics;
	cart: Cart;
	eventDescriptorCache: EventDescriptorCache;
	portal: Portal;
	ptApp: PublicTicketApp;
}

///
/// State
///

export const initialState = {
	analytics: new Analytics(),
	cart: new Cart(),
	eventDescriptorCache: new EventDescriptorCache(),
	portal: new Portal(),
	ptApp: new PublicTicketApp()
};

///
/// Reducer
///

function rootReducer(state: RootState = initialState, action: AnyAction) {
	return {
		analytics: analytics(state.analytics, action),
		cart: cart(state.cart, action),
		eventDescriptorCache: eventDescriptorCache(state.eventDescriptorCache, action),
		portal: portal(state.portal, action),
		ptApp: ptApp(state.ptApp, action)
	};
}
export default rootReducer;