import * as React from 'react';
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Paths} from "../../enums/paths";
import {SimpleHTMLContent} from "../html-content/simple-html-content";

interface ErrorMessageProps {
	htmlMessage?: string;
	message?: string;
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({htmlMessage, message}) => {
	return (
		<div>
			<p>{!!htmlMessage ? <SimpleHTMLContent rawHtml={htmlMessage}/> : message}</p>
			<p>
				<Link to={Paths.ROOT}>
					<FormattedMessage id="msg_click_here_to_go_home" />
				</Link>
			</p>
		</div>
	);
};
