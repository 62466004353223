import * as React from "react";
import {FormattedMessage, WrappedComponentProps} from "react-intl";
import {CartService} from "../../helpers/cart-service";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {SubmitResult} from "../../models/public-ticket-app/submit-result";
import {FacebookShareButton} from "../social-media/facebook-share-button";
import {GoogleAddToCalendarButton} from "../social-media/google-add-to-calendar-button";
import {PrintTicketsButton} from "../social-media/print-tickets";
import {TwitterShareButton} from "../social-media/twitter-share-button";
import {OrderInfo} from "./order-info";
import {MobileTicketButton} from "../social-media/mobile-ticket-button";
import {getMobileTicketPath} from "../../helpers/routing";
import {RouterProps} from "react-router";

interface CompletePanelProps extends WrappedComponentProps, RouterProps {
	config: PublicTicketAppConfig;
	submitResult: SubmitResult | null;
}

export class CompletePanel extends React.Component<CompletePanelProps> {
	public render() {
		const {config, history, intl, submitResult} = this.props;

		if (!submitResult) {
			return null;
		}

		const {
			cart,
			firstRetailItem,
			facebookShareURL,
			twitterShareURL,
			printAtHomeTicketURL,
			mobileTicketToken,
			googleAddToCalendarURL,
		} = submitResult;

		const retailQuantity = new CartService(cart).getTotalRetailQuantity();
		const socialSharingHeading = config.socialSharingHeading || intl.formatMessage({id: 'lbl_SpreadTheWord'});
		const socialSharesExist = facebookShareURL || twitterShareURL;

		const orderNumber = cart.name || '';
		return (
			<div>
				<div className="mb-5">
					<OrderInfo 
						currencyCode={config.currencyCode}
						orderNumber={orderNumber}
						orderTotal={cart.orderTotal}
						retailQuantity={retailQuantity}
					/>

					<p>
						<FormattedMessage id="msg_you_will_receive_confirmation_email"/>
					</p>

					{
						!!printAtHomeTicketURL && (
							<PrintTicketsButton url={printAtHomeTicketURL} intl={intl} />
						)
					}

					{
						!!mobileTicketToken && (
							<MobileTicketButton path={getMobileTicketPath(cart.id, mobileTicketToken)} history={history} intl={intl} />
						)
					}

					{
						!!googleAddToCalendarURL && (
							<GoogleAddToCalendarButton url={googleAddToCalendarURL} intl={intl}/>
						)
					}
				</div>

				{
					socialSharesExist && (
						<div>
							<strong>{socialSharingHeading}</strong>

							{/* First event instance will automatically be chosen as the one to share.*/}
							{
								!!facebookShareURL && (
									<FacebookShareButton
										eventInstance={firstRetailItem}
										facebookAppId={config.fbAppId}
										intl={intl}
										url={facebookShareURL}
									/>
								)
							}

							{
								!!twitterShareURL && (
									<TwitterShareButton
										eventInstance={firstRetailItem}
										intl={intl}
										twitterHandle={config.twitterHandle}
										url={twitterShareURL}
									/>
								)
							}

						</div>
					)
				}

			</div>
		);
	}
}
