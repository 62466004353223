import {ActionTypes} from "../enums/action-types";
import {RootState} from "../reducers";

declare let window: any;

//Actions we don't want to display in the console for some reason
const filteredActions = [
	ActionTypes.PTS_UPDATE_CART_TIME_REMAINING  //too noisy
];

export const logger = (store: any) => (next: any) => (action: any) => {
	const showLog = !filteredActions.includes(action.type);
	
	if (showLog){
		console.log("dispatching", action);	// eslint-disable-line no-console
	}
	
	const result = next(action);
	const nextState: RootState = store.getState();
	
	if (showLog) {
		console.log("next state", nextState);	// eslint-disable-line no-console
	}
	if (!!window.PublicTicketApp) {
		// PMGR-9121 - Copy the values of the blockingActions and nonBlockingActions props into a global so it can be accessed from automated test context
		window.PublicTicketApp.asyncActions = Object.assign({}, nextState.ptApp.blockingActions, nextState.ptApp.nonBlockingActions);
	}
	return result;
};

