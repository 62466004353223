import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Media} from "reactstrap";
import {TicketableEventTypes} from "../../enums/ticketable-event-types";
import {TicketableEvent} from "../../models/ticketable-events/ticketable-event";
import {HTMLContent} from "../html-content/html-content";

interface EventItemProps {
	detailPath: string;
	ticketableEvent: TicketableEvent;
}

export const EventItem: React.FunctionComponent<EventItemProps> = (props) => {
	const {detailPath, ticketableEvent} = props;
	let imagePath : string | null = null;
	let altText : string | undefined = ticketableEvent.name;
	if (ticketableEvent.smallImagePath) {
		imagePath = ticketableEvent.smallImagePath;
		altText = ticketableEvent.smallImageAltText || altText;
	} else if (ticketableEvent.largeImagePath) {
		imagePath = ticketableEvent.largeImagePath;
		altText = ticketableEvent.largeImageAltText || altText;
	}

	return (
		<Media  tag="li" className="mb-5">
			{!!imagePath && (
				<Media left={true}>
					<Link to={detailPath}>
						<Media
							object={true}
							src={window.PublicTicketApp.assetPath + imagePath} alt={altText}
							style={{height: '150px', width: '150px', objectFit: 'cover'}}
							className="mr-4 d-none d-md-block"
						/>
					</Link>
				</Media>
			)}
			<Media body={true}>
				<Media heading={true} className="pts-event-item-header">
					<Link to={detailPath}>
						{ticketableEvent.name}
					</Link>
				</Media>
				<div>
					{ticketableEvent.description && <HTMLContent rawHtml={ticketableEvent.description} />}
					<Link to={detailPath} className="link__te-details btn btn-primary btn-sm">
						<FormattedMessage id={ticketableEvent.type === TicketableEventTypes.MEMBERSHIP ? "pmgr_lbl_Buy" : "pmgr_lbl_LearnMore"}/>
					</Link>
				</div>
			</Media>
		</Media>
	);
};
