import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import { Route } from 'react-router-dom';
import { Nav } from 'reactstrap';
import {Paths} from "../../enums/paths";
import {Cart} from "../../models/cart";
import {LinkItem, LinkStates} from "../../models/link-item";
import CheckoutNavItem from "./checkout-nav-item";



interface CheckoutNavProps {
	links: LinkItem[];
	currentPath: string;
	visitedPages: string[];
}

interface CheckoutNavWithRouteProps {
	cart: Cart;
	links: LinkItem[];
	currentPath: string;
}
interface CheckoutNavWithRouteState {
	visitedPages: string[];
}

/**
 * Navigation component to be displayed during the checkout stage.
 * Take a config, which is basically a list of links,
 * and the current path, which ultimately comes down from React Router.
 */
export const CheckoutNav: React.FC<CheckoutNavProps> = ((props) => {
	const {links, currentPath, visitedPages} = props;
	
	const commonBSClasses = "d-flex flex-row justify-content-center align-items-center bg-light";
	const currentItem = links.find(link => link.path === currentPath);

	/**
	 * The `extraSmallScreenFallback` component is visible only on "Extra small" screens,
	 * which is anything under 576px.
	 * The regular `nav` is too wide to be functional on small screens.
	 * But we can't just hide it altogether.
	 * We need to show the user which stage of the checkout process they're on.
	 * This "fallback" component is meant to do that. It's basically a label.
	 * Worth noting: It's not interactive, so we lose that functionality on small screens. 
	 */
	const extraSmallScreenFallback = !!currentItem && (
		<div className="d-sm-none">
			<div className={commonBSClasses}>
				<span className="small text-uppercase">
					<FormattedMessage id={currentItem.label} />
				</span>
			</div>
		</div>
	);
	
	links.forEach(link => (
		link.linkState = getLinkState(link.path, currentPath, visitedPages)
	));
	
	const nav = (
		<div className="d-none d-sm-block bg-light">
			<Nav navbar={true} className={commonBSClasses}>
				{
					links.map(link => (
						<CheckoutNavItem
							key={link.path}
							link={link}
						/>
					))
				}
			</Nav>
		</div>
	);
	return (
		<div>
			{extraSmallScreenFallback}
			{nav}
		</div>
	);
});

/**
 * Checks the linkPath against the current path and visitedPages to figure out what state the link should be in
 *
 * @linkPath: path that is compared to currentPath & visitedPages
 * @currentPath: currentPath - path of the current page.
 * @visitedPages: array of pages that have already been visited while in the current checkout session.
 * 
 * @return LinkStates
 */
export const getLinkState = (linkPath: string, currentPath: string, visitedPages: string[]): LinkStates => {
	
	let lState: LinkStates = LinkStates.DISABLED;
	
	if (linkPath === currentPath) {
		lState = LinkStates.CURRENT;
	} else {
		visitedPages.forEach((vPage: string) => {
			if (vPage === linkPath) {
				lState = LinkStates.ACTIVE;
			}
		});
	}
	return lState;
};

/**
 * Gives the Checkout nav Router superpowers.
 */

export class CheckoutNavWithRoute extends React.Component<CheckoutNavWithRouteProps, CheckoutNavWithRouteState> {
	public readonly state: CheckoutNavWithRouteState = {
		visitedPages: []
	};
	
	public componentDidUpdate() {
		const {cart} = this.props;
		
		const oldPages: string[] = this.state.visitedPages;
		const newPages: string[] = [];
		
		const pageFound: boolean = oldPages.find((vPage: string) => {
			return vPage === this.props.currentPath;
		}) !== undefined;
		
		// Add existing pages back in after passing validation
		oldPages.forEach((vPage) => {
			if (this.addPageLink(cart, vPage)){
				newPages.push(vPage);
			}
		});
		
		// Add current page if it passes validation
		if (!pageFound && this.addPageLink(cart, this.props.currentPath)){
			newPages.push(this.props.currentPath);
		}
		
		if (JSON.stringify(oldPages) !== JSON.stringify(newPages)){
			this.setState({visitedPages: newPages});
		}
	}
	
	public render() {
		
		return (
			<Route component={(propsFromRoute: any) => {
				const currentPath = propsFromRoute.location.pathname;
				return (
					<CheckoutNav
						links={this.props.links}
						currentPath={currentPath}
						visitedPages={this.state.visitedPages}
					/>
				);
			}}/>
		);
	}
	
	private addPageLink = (cart: Cart, currentPage: string): boolean => {
		// special validation for payment link
		if (currentPage === Paths.CART__PAYMENT || currentPage === Paths.PORTAL__PENDING_RENEWAL__PAYMENT){
			
			if (!cart.validated || (cart.validationErrors && cart.validationErrors.length > 0)){
				return false;
			}
		}
		return true;
	}
}
