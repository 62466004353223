declare var window: any;

//todo: consider creating an initialize once interface
export class TwitterSDK {

	/**
	 * Loads Twitter SDK if it has not been loaded already.
	 * Calling this after it has already been loaded, is a noop.
	 */
	public load() {
		window.twttr = ((d, s, id) => {
			const fjs = d.getElementsByTagName(s)[0];
			const t = window.twttr || {};
			if (d.getElementById(id)) { return t; }
			const js = d.createElement(s) as HTMLScriptElement;
			js.id = id;
			js.src = "https://platform.twitter.com/widgets.js";
			js.async = true;

			if (fjs.parentNode) {
				fjs.parentNode.insertBefore(js, fjs);
			}

			t._e = [];
			t.ready = (f: any) => {
				t._e.push(f);
			};

			return t;
		})(document, "script", "twitter-wjs");
	}
}