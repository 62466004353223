import {History} from "history";
import * as React from "react";
import {Redirect} from "react-router";
import {InventoryService} from "../../helpers/inventory-service";
import {
	getSectionGroupRoute,
	getSectionRoute,
	getSubFulfillmentInstanceSectionRoute,
	getSubFulfillmentSectionRoute
} from "../../helpers/routing";
import {shouldSkipSectionMap, venueHasDetails} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {GroupDescriptor} from "../../models/event-descriptor/group-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import {HTMLContent} from "../html-content/html-content";
import {Message} from "../message";
import {TicketableEventDetailLink} from "../ticketable-event-detail-link";
import {VenueLink} from "../venue-link";
import {Section} from "./section";
import {SectionGroup} from "./section-group";
import "./section-map.css";

interface SectionMapProps {
	eventDescriptor: EventDescriptor;
	history?: History;
	match: any;
	fulfillment?: boolean;
	isCYO?: boolean;

	// when provided, overrides the normal History page navigation when a section group is selected and instead simply
	// provides the section Id to the callback function and allows it to handle the selection as needed
	handleSectionSelection?: (sectionId: string) => void;
}

export const SectionMap: React.FunctionComponent<SectionMapProps> = (props: SectionMapProps) => {
	const {eventDescriptor, handleSectionSelection, history, match, fulfillment, isCYO} = props;
	const {singleSectionOrGroupId, id, teId, venue, eiDetail, noSaleMsg, hasRetQty} = eventDescriptor;
	
	if(!history && !handleSectionSelection) {
		// If we don't have either, then there's no way to navigate
		return null;
	}
	
	// Need two different lists. One wrapped in the react object for display and one to pass into SectionLegend
	let sectionList: SectionDescriptor[] = [];
	const reactSectionList: Array<React.ReactElement<Section>> = [];

	const reactGroupList: Array<React.ReactElement<SectionGroup>> = [];
	
	const sectionMapStyle: React.CSSProperties = {
		"height": eventDescriptor.height,
		"width": eventDescriptor.width,
		"position": "relative"
	};
	
	// Return a Redirect if there is a single Section or Section Group and we are skipping section selection
	if (!!singleSectionOrGroupId && shouldSkipSectionMap(eventDescriptor)) {
		let seatMapPath;
		if(fulfillment) {
			const {eventInstanceId, allocationId, venueId, fulfillmentInstanceId} = match.params;
			seatMapPath = isCYO 
				? getSubFulfillmentInstanceSectionRoute(eventInstanceId, allocationId, fulfillmentInstanceId, singleSectionOrGroupId)
				: getSubFulfillmentSectionRoute(eventInstanceId, allocationId, venueId, singleSectionOrGroupId);
		} else {
			const sectionsMappedById: BasicStringKeyedMap<SectionDescriptor> = InventoryService.getSectionsMappedById(eventDescriptor);
			seatMapPath = singleSectionOrGroupId in sectionsMappedById
				? getSectionRoute(eventDescriptor.id, singleSectionOrGroupId)
				: getSectionGroupRoute(eventDescriptor.id, singleSectionOrGroupId);	// We'll assume it's a Section Group if we don't find it in the Sections map
		}
		return <Redirect to={seatMapPath} />
	}
	
	InventoryService.getUngroupedSections(eventDescriptor).forEach((section: SectionDescriptor) => {
			sectionList.push(section);
			reactSectionList.push(
				<Section
					key={section.id}
					section={section}
					eventDescriptor={eventDescriptor} 
					handleSectionSelection={handleSectionSelection}
					history={history}
					fulfillment={fulfillment}
					isCYO={isCYO}	
					match={match}
				/>
			);
		}
	);

	eventDescriptor.groupList.forEach((group: GroupDescriptor) => {
			sectionList = InventoryService.getSectionsByGroupId(eventDescriptor, group.id);
			reactGroupList.push(
				<SectionGroup
					key={group.id}
					group={group}
					sectionList={sectionList} 
					eventDescriptor={eventDescriptor}
					handleSectionSelection={handleSectionSelection}
					history={history}
					fulfillment={fulfillment}
					isCYO={isCYO}
					match={match}
					highlightOnSelected={eventDescriptor.groupList.length > 1}
				/>
			);
		}
	);
	return (
		<div>
			{!fulfillment && (
				<div className="mb-4">
					<TicketableEventDetailLink teId={teId} />
					{!!venue && <VenueLink venueName={venue.name} eventInstanceId={id} showDetailsLink={venueHasDetails(venue)} />}
				</div>
			)}
			
			{!!eiDetail && !fulfillment && (
				<div className="mb-4">
					<HTMLContent rawHtml={eiDetail} />
				</div>
			)}

			{ hasRetQty || fulfillment
					? <div id="SectionMap" className="bg-light justify-content-center d-flex p-5">
						<div style={sectionMapStyle}>
							{reactSectionList}
							{reactGroupList}
						</div>
					</div>
					: ( !!noSaleMsg
							? <Message htmlMessage={noSaleMsg} />
							: <Message intlId="msg_item_not_available_for_purchase" /> )
			}

		</div>
	);
};
