import * as React from "react";
import {FormattedMessage, FormattedNumber, IntlShape} from "react-intl";
import {Badge, Col, Row} from "reactstrap";
import {TicketOrderStatus} from "../../enums/ticket-order-status";

interface PendingRenewalHeaderProps {
	currencyCode: string;
	intl: IntlShape;
	orderNumber: string;
	packageCount: number;
	status: TicketOrderStatus;
	total: number;
}

export const PendingRenewalHeader: React.FunctionComponent<PendingRenewalHeaderProps> = (props) => {
	const {currencyCode, intl, orderNumber, packageCount, status, total} = props;
	return (
		<div>
			<Row className="border-bottom">
				<Col>
					<p className="pb-1 m-0 text-uppercase text-muted small">
						{orderNumber}
					</p>
				</Col>
				<Col className="text-right">
					<Badge color="info">{status}</Badge>
				</Col>
			</Row>
			<Row>
				<Col>
					<p>
						<FormattedMessage
							id="msg_subscription_with_qty"
							values={{
								qty: packageCount,
								subscription: intl.formatMessage({id: 'pmgr_term_Subscription'}).toLowerCase(),
								subscriptions: intl.formatMessage({id: 'pmgr_term_Subscriptions'}).toLowerCase()
							}}
						/>
					</p>
				</Col>
				<Col className="text-right">
					<h3 className="font-weight-light">
						<FormattedNumber
							value={total}
							style="currency"
							currency={currencyCode}
							currencyDisplay="symbol"
						/>
					</h3>
				</Col>
			</Row>
		</div>
	);
};
