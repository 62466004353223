import * as React from "react";
import {venueHasDetails} from "../../helpers/utilities";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {VenueSeatingChart} from "../add-to-cart/venue/venue-seating-chart";
import {HTMLContent} from "../html-content/html-content";
import {Message} from "../message";
import {TicketableEventDetailLink} from "../ticketable-event-detail-link";
import {VenueLink} from "../venue-link";
import {QuantityBasedEventInfo} from "./ga-item-selection";

interface GAEventInfoProps extends QuantityBasedEventInfo {
	config: PublicTicketAppConfig;
}

export const GAEventInfo: React.FunctionComponent<GAEventInfoProps> = (props) => {

	const {
		eventDescriptor: {id, teId, venue, eiDetail, noSaleMsg, hasRetQty }
	} = props;

	return (
		<div>
			<div className="mb-4">
				<TicketableEventDetailLink teId={teId} />
				{!!venue && <VenueLink venueName={venue.name} eventInstanceId={id} showDetailsLink={venueHasDetails(venue)} />}
			</div>
			{!!eiDetail && (
				<div className="mb-4">
					<HTMLContent rawHtml={eiDetail} />
				</div>
			)}
			{!!venue && <VenueSeatingChart eventInstanceId={id} venue={venue} />}

			{!hasRetQty && (
					!!noSaleMsg
						? <Message htmlMessage={noSaleMsg} />
						: <Message intlId="msg_item_not_available_for_purchase" />
			)}
		</div>
	);
};
