import {Component} from "react";
import {IntlShape} from "react-intl";
import {PrintIcon} from "../icons/icons";
import {ShareButton} from "./share-button";

interface PrintTicketsButtonProps {
	url: string;
	intl: IntlShape;
}

export class PrintTicketsButton extends Component<PrintTicketsButtonProps> {
	public navigate = () => {
		const {url} = this.props;

		if (!url) {
			return;
		}

		window.open(`${url}`);
	}
	
	public render() {
		const {intl} = this.props;

		return <ShareButton
			icon={PrintIcon}
			context={intl.formatMessage({id: 'lbl_PrintYourTickets'})}
			onClick={this.navigate}/>;
	}
}
