import * as React from "react";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {EventMain} from "../event-main";

interface MembershipInfoProps {
	eventDescriptor: EventDescriptor;
}

export const MembershipInfo: React.FunctionComponent<MembershipInfoProps> = (props) => {
	const {eventDescriptor: {
		teLargeImagePath,
		teLargeImageAltText,
		teName,
		teDetail
	}} = props;
	return <EventMain eventName={teName} eventDetail={teDetail} largeImagePath={teLargeImagePath} largeImageAltText={teLargeImageAltText}/>;
};
