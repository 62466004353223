import {IntlShape} from "react-intl";
import {Cart} from "../models/cart";
import {GroupDescriptor} from "../models/event-descriptor/group-descriptor";
import {SectionDescriptor} from "../models/event-descriptor/section-descriptor";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {calculateSuggestedDonation} from "./utilities";

export interface PriceInfo {
	minPrice: number;
	maxPrice?: number;
}

/**
 * Returns the legend string to be displayed on the section map. This will include the section or group name,
 * plus price range information, if available.
 * @param sectionOrGroup a SectionDescriptor or GroupDescriptor
 * @param intl the ReactIntl instance
 * @param currencyCode the currency code
 */
export const getLegendForSectionOrGroup = (sectionOrGroup: SectionDescriptor | GroupDescriptor, intl: IntlShape, currencyCode: string): string => {
	// Only display the price level string if the minPrice prop is less than its default value of 9999999
	if (sectionOrGroup.minPrice < 9999999) {
		return getNameWithPriceString(intl, currencyCode, sectionOrGroup.name, {minPrice: sectionOrGroup.minPrice, maxPrice: sectionOrGroup.maxPrice});
	}
	// If no active levels, then just return the Section or Section Group name with the "Not available" string
	return `${sectionOrGroup.name} (${intl.formatMessage({id: "lbl_SectionOrGroupNotAvailable"})})`;
};

/**
 * Returns a string containing the specified name, along with the price string below. The default message format will
 * return a string that looks like this: "Orchestra ($30.00 - $50.00)"
 * @param intl
 * @param currencyCode
 * @param name
 * @param priceInfo
 */
export const getNameWithPriceString = (intl: IntlShape, currencyCode: string, name: string, priceInfo: PriceInfo) => {
	return intl.formatMessage({id: "lbl_NameWithPrice"}, {name, priceString: getPriceString(intl, currencyCode, priceInfo)});
};

export const getPriceString = (intl: IntlShape, currencyCode: string, priceInfo: PriceInfo) => {
	return getPriceStringWithRangeFormat(intl, currencyCode, priceInfo, 'lbl_PriceRange');
};
/**
 * Returns a localized price string that will be either a single price (i.e., "$50.00") or a price range string
 * (i.e., "$30.00 - $50.00").
 * @param intl
 * @param currencyCode
 * @param priceInfo A PriceInfo object that specifies the minPrice and optional maxPrice
 * @param format The localized message id to display price ranges as
 */
export const getPriceStringWithRangeFormat = (intl: IntlShape, currencyCode: string, priceInfo: PriceInfo, format: string) => {
	const {minPrice, maxPrice} = priceInfo;
	const priceString: string = intl.formatNumber(minPrice, {style: "currency", currency: currencyCode});
	let maxPriceString;
	if (!!maxPrice && maxPrice > minPrice) {
		maxPriceString = intl.formatNumber(maxPrice, {style: "currency", currency: currencyCode});
		return intl.formatMessage({id: format}, {minPriceString: priceString, maxPriceString});
	}
	return priceString;
};
/**
 * Returns properly formatted donation help text
 * @param {Cart} cart
 * @param {PublicTicketAppConfig} config
 * @param {ReactIntl.InjectedIntl} intl
 * @returns {string}
 */
export const getSuggestedDonationHelpText = (cart: Cart, config: PublicTicketAppConfig, intl: IntlShape): string => {
	let helpText: string = '';
	const suggestedDonation = calculateSuggestedDonation(cart, config);
	if (!!suggestedDonation) {
		helpText = intl.formatMessage({id: "lbl_SuggestedDonation"}, 
			{donationString: intl.formatNumber(suggestedDonation, {style: "currency", currency: config.currencyCode, currencyDisplay: "symbol"})});
	}
	return helpText;
};
