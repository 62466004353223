import {History} from "history";
import * as React from "react";
import {CSSProperties} from "react";
import {
	getSectionGroupRoute,
	getSubFulfillmentInstanceSectionRoute,
	getSubFulfillmentSectionRoute
} from "../../helpers/routing";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {GroupDescriptor} from "../../models/event-descriptor/group-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import {Section} from "./section";

interface SectionGroupProps {
	group: GroupDescriptor;
	sectionList: SectionDescriptor[];
	eventDescriptor: EventDescriptor;
	history?: History;
	match: any;
	fulfillment?: boolean;
	isCYO?: boolean;
	highlightOnSelected: boolean;
	handleSectionSelection?: (sectionId: string) => void;
}

interface SectionGroupState {
	className: string;
	style: CSSProperties;
}

export class SectionGroup extends React.Component<SectionGroupProps, SectionGroupState> {
	
	public readonly state: SectionGroupState = {
		className: "section-group",
		style: {
			"position": "absolute",
			"left": this.props.group.left,
			"top": this.props.group.top,
			"height": this.props.group.gbh,
			"width": this.props.group.gbw
		}
	};
	
	constructor(props: SectionGroupProps) {
		super(props);
		
		this.highlightElement = this.highlightElement.bind(this);
		this.deHighlightElement = this.deHighlightElement.bind(this);
		this.navigate = this.navigate.bind(this);
	}

	public render() {
		const {sectionList, ...other} = this.props;

		const sectionsInGroup = sectionList.map((section) => {
			return (<Section key={section.id} section={section} {...other}/>);
		}, this);

		return (
			<div className={this.state.className} style={this.state.style} onMouseOver={this.highlightElement} onMouseOut={this.deHighlightElement} onClick={this.navigate} >
				{sectionsInGroup}
			</div>
		);
	}

	public highlightElement() {
		const {highlightOnSelected} = this.props;
		const style = {...this.state.style, cursor: "pointer"};
		const className = highlightOnSelected ? "section-group selected" : "section-group";
		this.setState({...this.state, className, style});
	}

	public deHighlightElement() {
		const style = {...this.state.style, cursor: "auto"};
		this.setState({...this.state, className: "section-group", style});
	}

	// Navigates to the seat selection page for the selected section group
	public navigate(evt: React.MouseEvent<HTMLElement>) {
		const {
			handleSectionSelection,
			history,
			group,
			eventDescriptor,
			fulfillment,
			isCYO,
			match
		} = this.props;
		
		if(!!handleSectionSelection) {
			handleSectionSelection(group.id);
		} else if (!!history) {
			const {eventInstanceId, allocationId, venueId, fulfillmentInstanceId} = match.params;
			fulfillment
				? isCYO
					? history.push(getSubFulfillmentInstanceSectionRoute(eventInstanceId, allocationId, fulfillmentInstanceId, group.id))
					: history.push(getSubFulfillmentSectionRoute(eventInstanceId, allocationId, venueId, group.id))
				: history.push(getSectionGroupRoute(eventDescriptor.id, group.id));
		}

		evt.preventDefault();
	}
}
