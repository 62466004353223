import {IntlShape} from "react-intl";
import {getTicketFees, ITEM_FEE_PROPS} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {CartItem} from "../../models/cart-item";
import {FeeDescriptor} from "../../models/fee-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {Ticket} from "../../models/ticket";
import {EventItemProps} from "./ticket-order";

/**
 * Helper function to group non-subscription tickets by EI. Public static so it can be accessed from unit test context
 */
export const getEventItems = (cartItems: CartItem[] = [], itemFeeData: FeeDescriptor[] = [], config: PublicTicketAppConfig, intl: IntlShape): EventItemProps[] => {
	if (cartItems.length < 1) {
		return [];
	}
	// Generate a map of event instances to tickets
	const ticketsByEI: BasicStringKeyedMap<EventItemProps> = cartItems.reduce((accumulator: BasicStringKeyedMap<EventItemProps>, cartItem: CartItem) => {
		// Avoid adding subscription fulfillment items to the accumulator. Fulfillment items have a non-blank value for the stoiId property
		if (!cartItem.stoiId) {
			if (!accumulator[cartItem.eiId]) {
				accumulator[cartItem.eiId] = {
					id: cartItem.eiId,
					eiName: cartItem.eiName,
					teName: cartItem.teName,
					ticketType: cartItem.ticketType,
					tickets: Array<Ticket>()
				};
			}
			const ticket: Ticket = {
				cartItem,
				fees: getTicketFees(cartItem, ITEM_FEE_PROPS, itemFeeData, config, intl)
			};
			accumulator[cartItem.eiId].tickets.push(ticket);
		}
		return accumulator;
	}, {});
	
	return Object.keys(ticketsByEI).map(eiId => {
		return {
			id: eiId,
			eiName: ticketsByEI[eiId].eiName,
			teName: ticketsByEI[eiId].teName,
			ticketType: ticketsByEI[eiId].ticketType,
			tickets: ticketsByEI[eiId].tickets
		};
	});
};

