import * as React from "react";
import { BasicStringKeyedMap } from "../basic-map";
import {ButtonProps} from "./button-props";

export const enum ModalTypes {
	// A generic action dialog in which the header, body and ButtonProps are provided
	GENERIC = "GENERIC",
	// A specialized action dialog with fixed content that clears the cart cookie and dispatches ensureCart so we start a fresh one
	EXPIRED_CART = "EXPIRED_CART",
	// A specialized action dialog with fixed content that dispatches an ensureCart action to reload the cart
	STALE_CART = "STALE_CART",
	// A specialized action dialog that clears the cart cookie and dispatches ensureCart so we start a fresh one
	COMPLETED_CART = "COMPLETED_CART",
	// A catch-all action dialog that informs the user of an unknown error, and clears the cart cookie
	UNKNOWN_CART = "UNKNOWN_CART"
}

export class ModalProps {
	public buttonProps: ButtonProps[];
	public bodyContent: React.ReactNode;
	public headerContent: React.ReactNode;
	public isOpen: boolean;
	public onClose: () => any;
	public type: ModalTypes = ModalTypes.GENERIC;
	public args: BasicStringKeyedMap<any>;
	
	constructor(buttonProps: ButtonProps[] = new Array<ButtonProps>(), bodyContent: React.ReactNode = null, headerContent: React.ReactNode = null, isOpen: boolean = false, onClose: () => any = () => {}) {
		this.buttonProps = buttonProps;
		this.bodyContent = bodyContent;
		this.headerContent = headerContent;
		this.isOpen = isOpen;
		this.onClose = onClose;
	}
}