import {BasicStringKeyedMap} from "../basic-map";
import {Benefit} from "./benefit";
import {PortalDonation} from "./portal-donation";
import {PortalOrder} from "./portal-order";
import {PortalOrderDetails} from "./portal-order-details";

export class Portal {
	public pendingRenewals: PortalOrder[] = [];
	public orders: PortalOrder[] = [];
	public donations: PortalDonation[] = [];
	public renewableBenefits: Benefit[] = [];
	public pendingRenewal: PortalOrderDetails = new PortalOrderDetails();
	public portalOrderCache: BasicStringKeyedMap<PortalOrderDetails> = {};    // Cache of portal orders with details, that have been fetched from the server
}