import {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, WrappedComponentProps} from "react-intl";
import {Card, Col, Collapse, Row} from "reactstrap";
import Button from "reactstrap/lib/Button";
import {SeatingTypes} from "../../enums/seating-types";
import {InventoryService} from "../../helpers/inventory-service";
import {layout} from "../../hoc/layout";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {SubscriptionEventDescriptor} from "../../models/event-descriptor/subscription-event-descriptor";
import {
	SubscriptionPriceLevelLinkDescriptor
} from "../../models/event-descriptor/subscription-price-level-link-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {SeatMapPreview} from "../seat-map/seat-map-preview";
import {ToggleButton} from "../toggle-button/toggle-button";
import {CYOMiniCart} from "./cyo-mini-cart";
import './cyo-performance-selection.css';
import {SubscriptionFormElements} from "./subscription-form-elements";

interface CYOPerformanceSelectionProps extends WrappedComponentProps {
	allocationName: string;
	config: PublicTicketAppConfig;
	disableNext: boolean;
	handleAddToCart: () => void;
	handleCancel: () => void;
	includeFeesInPrice: boolean;
	onPerformanceSelection: (spll: SubscriptionPriceLevelLinkDescriptor) => void;
	priceLevelQuantityMap: BasicStringKeyedMap<string>;
	selectedCYOSpllMap: BasicStringKeyedMap<SubscriptionPriceLevelLinkDescriptor[]> | {};
	subsEventDescriptor: SubscriptionEventDescriptor;
}

// Note: Since validateCYOSubscriptionPriceLevels ensures that all of the Subs PLs share common fulfillment TAs, we can
// use the SPLLs from the first Subscription price level to build the list of fulfillment events/instances for selection.
// The onPerformanceSelection callback function takes care of recording down the SPLLs for ALL of the subscription price
// levels based on the option selected here.
export const CYOPerformanceSelection = (props: CYOPerformanceSelectionProps) => {
	const {config, priceLevelQuantityMap, selectedCYOSpllMap, subsEventDescriptor} = props;
	const [expandAll, setExpandAll] = useState(false);
	const subscriptionPriceLevelsMappedById: BasicStringKeyedMap<LevelDescriptor> = InventoryService.getLevelsMappedById(subsEventDescriptor);
	
	// List of currently selected subscription price level links
	const selectedSpllIds = useMemo(() => {
		// Since our list of options is created from the SPLLs for the *first* sub price level, use that again here here when creating the selected id list
		return Object.values(selectedCYOSpllMap)[0].reduce((prev: string[], spll: SubscriptionPriceLevelLinkDescriptor) => {
			prev.push(spll.id);
			return prev;
		},[])
	},[selectedCYOSpllMap]);
	
	// Map selected quantity by CYO price level name for QuantityTypeSummary heading
	const levelSummary = useMemo(() => {
		const _levelSummary: BasicStringKeyedMap<number> = {};
		Object.keys(priceLevelQuantityMap).forEach(levelId => {
			const quantity = Number(priceLevelQuantityMap[levelId]);
			if (quantity > 0) {
				const level = subscriptionPriceLevelsMappedById[levelId];
				_levelSummary[level.name] = quantity;
			}
		});
		return _levelSummary;
	},[priceLevelQuantityMap, subscriptionPriceLevelsMappedById]);
	
	// Create a map of subscription price level links by instance and then by event in order to display a list of options
	const spllsMappedByInstanceAndEvent = useMemo(() => {
		const _spllsMappedByInstanceAndEvent: Record<string,any> = {};
		
		// As noted at the top, we can use the SPLLs from the first selected PL to create our list of options
		const firstSelectedPL = Object.keys(priceLevelQuantityMap).find((priceLevelId: string) => Number(priceLevelQuantityMap[priceLevelId]) > 0);
		const spllList = subsEventDescriptor.subscriptionPriceLevelLinks.filter(spll => spll.subscriptionPriceLevelId === firstSelectedPL);
		
		const eventIds = new Set();
		spllList.forEach((spll: SubscriptionPriceLevelLinkDescriptor) => eventIds.add(spll.ticketableEventId));
		eventIds.forEach((eventId: string) => {
			const spplsForEvent = spllList.filter((sppl: SubscriptionPriceLevelLinkDescriptor) => sppl.ticketableEventId === eventId);
			_spllsMappedByInstanceAndEvent[eventId] = spplsForEvent.reduce((prev: Record<string,SubscriptionPriceLevelLinkDescriptor[]>, spll) => {
					let spllsForEI = prev[spll.eventInstanceId];
					if (!Array.isArray(spllsForEI)) {
						spllsForEI = [];
						prev[spll.eventInstanceId] = spllsForEI;
					}
					spllsForEI.push(spll);
					return prev;
				}, {});
		});
		return _spllsMappedByInstanceAndEvent;
	}, [priceLevelQuantityMap, subsEventDescriptor.subscriptionPriceLevelLinks]);
	
	const toggleExpandAll = useCallback(() => {
		setExpandAll(prevState => !prevState)
	},[]);

	const CYOLayout = useMemo(() => {
		return layout<typeof CYOPerformanceOptions, typeof CYOMiniCart>(
			{Main: CYOPerformanceOptions, Panel: CYOMiniCart},
			null,
			null,
			{primary: subsEventDescriptor.teName, secondary: subsEventDescriptor.name}
		);
	}, [subsEventDescriptor.name, subsEventDescriptor.teName]);
	
	return (
		<CYOLayout
			currencyCode={config.currencyCode}
			expandAll={expandAll}
			levelSummary={levelSummary}
			numberOfSelections={subsEventDescriptor.numberOfSubscriberSelections!}
			selectedSpllIds={selectedSpllIds}
			spllsMappedByInstanceAndEvent={spllsMappedByInstanceAndEvent}
			subscriptionPriceLevelsMappedById={subscriptionPriceLevelsMappedById}
			handleExpandAll={toggleExpandAll}
			pyosAble={subsEventDescriptor.fulfillmentVenueIds.length > 0}
			{...props}
		/>
	)
}

interface CYOPerformanceOptionsProps extends WrappedComponentProps {
	config: PublicTicketAppConfig;
	expandAll: boolean;
	onPerformanceSelection: (spll: SubscriptionPriceLevelLinkDescriptor) => void;
	selectedSpllIds: string[];
	spllsMappedByInstanceAndEvent: BasicStringKeyedMap<BasicStringKeyedMap<SubscriptionPriceLevelLinkDescriptor[]>>;
	subsEventDescriptor: SubscriptionEventDescriptor;
	handleExpandAll: () => void;
}

const CYOPerformanceOptions = (props: CYOPerformanceOptionsProps) => {
	const {
		config,
		expandAll,
		intl,
		onPerformanceSelection,
		selectedSpllIds,
		spllsMappedByInstanceAndEvent,
		subsEventDescriptor,
		handleExpandAll
	} = props;
	
	return (
		<>
			<SubscriptionFormElements portalSeatSelectionMessage={config.portalSeatSelectionMessage} subsEventDescriptor={subsEventDescriptor}/>
			<div className="d-flex justify-content-between border-bottom py-2">
				<span className="font-weight-bold">
					{intl.formatMessage(
						{id: "lbl_SelectNumberOfEvents"},
						{numEventsSelected: selectedSpllIds.length, numEventsRequired: subsEventDescriptor.numberOfSubscriberSelections})
					}
				</span>
				<Button className="pl-0 text-uppercase small" color="link" size="sm" onClick={handleExpandAll}>
					<FormattedMessage id={expandAll ? "lbl_CollapseAll" : "lbl_ExpandAll"} />
				</Button>
			</div>
			{Object.keys(spllsMappedByInstanceAndEvent).map(teId => {
				return (
					<CYOEventGroup
						key={teId}
						intl={intl}
						selectedSpllIds={selectedSpllIds}
						pyosEnabled={subsEventDescriptor.fulfillmentVenueIds.length > 0}
						handleSelection={(spll: SubscriptionPriceLevelLinkDescriptor) => onPerformanceSelection(spll)}
						spllsMappedByInstance={spllsMappedByInstanceAndEvent[teId]}
						expandAll={expandAll}
					/>
				)
			})}
			<div className="mb-3"/>
		</>
	)
}

interface CYOEventGroupProps extends WrappedComponentProps {
	expandAll: boolean;
	handleSelection: (spll: SubscriptionPriceLevelLinkDescriptor) => void;
	pyosEnabled: boolean;
	selectedSpllIds: string[];
	spllsMappedByInstance: BasicStringKeyedMap<SubscriptionPriceLevelLinkDescriptor[]>;
}

const CYOEventGroup = (props: CYOEventGroupProps) => {
	const {expandAll, spllsMappedByInstance} = props;
	const [isOpen, setIsOpen] = useState(false);
	
	// If any of the instances in this event have more than one price option, then we want to display *all* of it's
	// instances in the expanded version
	const expandedViewRequired = Object.values(spllsMappedByInstance).some((spllList: SubscriptionPriceLevelLinkDescriptor[]) => spllList.length > 1);
	
	// When expandAll gets toggled, update the local isOpen state to match
	useEffect(() => {
		setIsOpen(expandAll);
	},[expandAll]);
	
	return (
		<div className="border-bottom py-3">
			<div className="d-flex justify-content-between align-items-center" onClick={()=>setIsOpen(prevState => !prevState)}>
				<div className="text-primary font-weight-bold">{(Object.values(spllsMappedByInstance))[0][0].ticketableEventName}</div>
				<ToggleButton isOpen={isOpen} />
			</div>
			<Collapse isOpen={isOpen} className="mt-2">
				{Object.keys(spllsMappedByInstance).map((eiId: string) => {
					return (
						<CYOInstanceGroup
							key={eiId}
							spllsForInstance={spllsMappedByInstance[eiId]}
							expandedViewRequired={expandedViewRequired}
							{...props}
						/>
					)	
				})}
			</Collapse>
		</div>
	);
}

interface CYOInstanceGroupProps extends WrappedComponentProps {
	expandedViewRequired: boolean;
	handleSelection: (spll: SubscriptionPriceLevelLinkDescriptor) => void;
	pyosEnabled: boolean;
	spllsForInstance: SubscriptionPriceLevelLinkDescriptor[];
	selectedSpllIds: string[];
}

const CYOInstanceGroup = (props: CYOInstanceGroupProps) => {
	const {expandedViewRequired, handleSelection, pyosEnabled, spllsForInstance, selectedSpllIds} = props;
	const [isSeatMapOpen, setIsSeatMapOpen] = useState(false);
	const [instanceToPreview, setInstanceToPreview] = useState<string>('');
	
	
	// If any of the allocations for this event instance have more than one price option, then we need to include
	// the price level name in the option label in order to differentiate
	const displayPriceLevelName = useMemo(() => {
		if(!expandedViewRequired) {
			return false;
		}
		const allocationMap = spllsForInstance.reduce((prev: BasicStringKeyedMap<number>, spll: SubscriptionPriceLevelLinkDescriptor) => {
			prev[spll.allocationId] = !!prev[spll.allocationId] ? prev[spll.allocationId] + 1 : 1;
			return prev;
		},{});
		return Object.values(allocationMap).some((count: number) => count > 1);
	},[expandedViewRequired, spllsForInstance]);
	
	const openSeatMapPreview = useCallback((eventInstanceId: string) => {
		setInstanceToPreview(eventInstanceId);
		setIsSeatMapOpen(true);
	},[]);
	
	const closeSeatMapPreview = useCallback(() => {
		setIsSeatMapOpen(false);
	},[]);
	
	return (
		<>
			{expandedViewRequired && <div className="mb-2">{spllsForInstance[0].eventInstanceName}</div>}
			{spllsForInstance.map((spll: SubscriptionPriceLevelLinkDescriptor) => {
				// Styling for selected options
				const selectedClass = selectedSpllIds.includes(spll.id) ? " border-success cyo-performance-selected" : "";
				
				const label = expandedViewRequired
					? displayPriceLevelName ? `${spll.allocationName} - ${spll.ticketPriceLevelName}` : spll.allocationName
					: spll.eventInstanceName;

				const handleOpenSeatMap = (evt: React.MouseEvent<HTMLButtonElement>) => {
					evt.stopPropagation();
					openSeatMapPreview(spll.eventInstanceId);
				}
				
				const seatMapLink = spll.seatingType === SeatingTypes.PYOS
					? (
						<Button className="pl-0 small" color="link" size="sm" onClick={handleOpenSeatMap}>
							<FormattedMessage key={spll.eventInstanceId} id={"lbl_PreviewSeatMap"} />
						</Button>
					) : <FormattedMessage id={"lbl_GA"} />;
				
				return(
					<div key={spll.id} className={expandedViewRequired ? "ml-3" : ""}>
						<Card body className={"pts-ticket-item py-2 mb-2 rounded" + selectedClass} onClick={()=>handleSelection(spll)}>
							<Row>
								<Col>
									{label}
								</Col>
								<Col xs="auto">
									{pyosEnabled && seatMapLink}
								</Col>
							</Row>
						</Card>
					</div>
				)
			})}

			{pyosEnabled && isSeatMapOpen && 
				<SeatMapPreview closeSeatMap={closeSeatMapPreview} instanceIdToPreview={instanceToPreview} {...props} />
			}
		</>
	)
}