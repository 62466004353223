import {FunctionComponent} from "react";
import {CaretIcon} from "../icons/icons";
import './toggle-button.css';

interface ToggleButtonProps {
    isOpen: boolean;
}

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({isOpen}) => (
	<div className={`caret ${isOpen && 'rotate'}`}>
    	<CaretIcon size={12}/>
	</div>
);
