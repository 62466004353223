import * as React from "react";
import {useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";

interface EventFilterProps {
	categories: BasicStringKeyedMap<boolean>;
	config: PublicTicketAppConfig;
	handleClick: (category: string, event: any) => void;
}

/**
 * UI component that displays selectable filters
 */
export const EventFilter: React.FunctionComponent<EventFilterProps> = (props) => {
		const {categories, handleClick} = props;

		const [focusedIndex, setFocusedIndex] = useState<Number | null>(null);

		const categoryCheckboxes = useMemo(() => {
			return Object.keys(categories).map((category, categoryIndex) => {
				const selected = categories[category];
				return (
					<label key={category}
						className={
							`btn btn-sm mr-2 mb-1 ${selected ? "active btn-primary" : "btn-outline-primary"} ${categoryIndex === focusedIndex && "focus"}`
						}
					>
						<input
							type="checkbox"
							value={category}
							onChange={(e) => handleClick(category, e)}
							onFocus={(e)=> setFocusedIndex(parseInt(e?.target?.dataset?.index!))}
							onBlur={()=> setFocusedIndex(null)}
							checked={selected}
							data-index={categoryIndex}
						/>
						{category}
					</label>
				);
			});
		}, [categories, handleClick, focusedIndex]);

		return !!categoryCheckboxes && categoryCheckboxes.length > 0
			? (
				<div className="btn-group-toggle mb-3">
					<p className="m-0 pts-event-filters-message">
						<FormattedMessage id="lbl_Filters"/>
					</p>
					{categoryCheckboxes}
				</div>
			 )
			: null;
};