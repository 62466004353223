import * as React from "react";
import {CSSProperties, useMemo, useState} from "react";
import {WrappedComponentProps, injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Tooltip} from "react-tooltip";
import {InventoryService} from "../../helpers/inventory-service";
import {getPriceString, getPriceStringWithRangeFormat} from "../../helpers/localization";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {RowDescriptor} from "../../models/event-descriptor/row-descriptor";
import {SeatDescriptor} from "../../models/event-descriptor/seat-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import {SeatAttributes} from "./seat-attributes";

declare var window: any;

interface SeatProps extends WrappedComponentProps{
	seat: SeatDescriptor;
	row: RowDescriptor;
	section: SectionDescriptor;
	allocation: AllocationDescriptor;
	eventDescriptor: EventDescriptor;
	seatAttributes: SeatAttributes;
	cartItem?: any;
	selected: boolean;
	pending: boolean;
	staged: boolean;
	onSeatClick: (seat: SeatDescriptor) => void;
}

export const Seat = (props: SeatProps) => {
	const {
		seat,
		row,
		section,
		allocation,
		eventDescriptor,
		intl,
		seatAttributes,
		selected,
		onSeatClick,
		pending,
		staged,
	} = props;
	
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const currencyCode = useSelector((state: any): string => state.ptApp.config.currencyCode);
	
	const handleSeatClick = (evt: React.MouseEvent<HTMLElement>) => {
		evt.preventDefault();
		onSeatClick(seat);
		setTooltipOpen(!(selected || staged));
	}

	const seatStyle: CSSProperties = Object.assign(
		// Set the position attributes
		{
			position: "absolute",
			top: seat.top,
			left: seat.left,
			boxSizing: "content-box",
			padding: 0
		},
		// Merge in the size attributes (may be an empty object)
		seatAttributes.seatSize
	);

	let seatClass: string = "seat";

	if (seat.avail && !pending) {
		// Set the background color on available seats
		seatStyle.backgroundColor = (allocation.color) ? allocation.color : "#aaa";
		// Add seat note background image if necessary
		if (seat.snImage) {
			seatStyle.backgroundImage = `url('${window.PublicTicketApp.sitePrefix}${eventDescriptor.layoutStylesUrl}/${seat.snImage}')`;
			seatStyle.backgroundRepeat = "no-repeat";
		}
		// Add the seat note name as a class if available
		if (seat.snName) { seatClass += ' ' + seat.snName; }
	} else {
		// If the seat is not available, decorate it with either the "selected" class and style, or the "unavailable" class and style
		if (selected) {
			seatClass += " seat-selected";
			Object.assign(seatStyle, seatAttributes.seatSelectedImage);
		} else {
			seatClass += " seat-unavailable";
			Object.assign(seatStyle, seatAttributes.seatUnavailableImage);
		}
	}

	if (pending) {
		// Add the "pending" class and pending seat style if necessary
		seatClass += " seat-pending";
		Object.assign(seatStyle, seatAttributes.seatPendingImage);
	}
	else if (staged) {
		seatClass += " seat-staged";
		Object.assign(seatStyle, seatAttributes.seatSelectedImage);
	}

	const seatInfo:{tooltip:string, ariaLabel:string} = useMemo(() => {
		if(!allocation) {
			return {tooltip: '', ariaLabel: ''};
		}
		
		let priceString = '';
		let ariaPriceString = '';
		if(!!allocation.minPrice) {		// don't set pricing info if allocation has no price data (which is the case when fulfilling CYO subs)
			const priceInfo = {maxPrice: allocation.maxPrice, minPrice: allocation.minPrice};
			priceString = ' | ' + getPriceString(intl,currencyCode,priceInfo);
			ariaPriceString = ' ' + getPriceStringWithRangeFormat(intl,currencyCode,priceInfo,'lbl_PriceRangeFromTo');

			// if there is only one price level in the allocation and it's PWYW, add "min" to the end
			const priceLevels: LevelDescriptor[] = InventoryService.getLevelsByAllocationId(eventDescriptor, allocation.id);
			if(priceLevels.length === 1 && priceLevels[0].pwyw) {
				const min = intl.formatMessage({id: "lbl_MinimumAmount"});
				priceString += ' ' + min;
				ariaPriceString += ' ' + min;
			}
		}

		const seatRowName = `${intl.formatMessage({id: "lbl_Seat"})} ${row.name}:${seat.name}`
		return {
			tooltip: seatRowName + priceString,
			ariaLabel: seatRowName + ariaPriceString
		};
	},[allocation, currencyCode, eventDescriptor, intl, row.name, seat.name])

	const seatDisabled = !seat.avail && !selected;
	
	return (
		<>
			<button
				id={seat.id}
				className={seatClass}
				style={seatStyle}
				aria-label={seatInfo.ariaLabel}
				data-seat={seat.name}
				data-row={row.name}
				data-sec={section.name}
				data-alloc={allocation ? allocation.name : null}
				data-testid={seat.id}
				data-tooltip-id={seat.id}
				onClick={handleSeatClick}
				type="button"
				disabled={seatDisabled}
			/>
			{!seatDisabled && 
				<Tooltip
					id={seat.id}
					style={{zIndex: 100, transition: 'opacity 0s'}}
					closeOnEsc={true}
					delayShow={0}
					delayHide={0}
					isOpen={tooltipOpen}
					opacity={1}
					setIsOpen={(open:boolean)=>setTooltipOpen(open)}
				>
					{seatInfo.tooltip}
				</Tooltip>
			}
		</>
	);
}
export default injectIntl(Seat);
