import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Button, NavItem, NavLink} from "reactstrap";
import {Paths} from "../../enums/paths";

interface NavLinkForInternalOrExternalProps {
	// when provided this will render a link to an external path
	externalUrl?: string;
	// id to look up the internationalized string
	formattedMessageId: string;
	// when provided it will render a link to react-routable path
	internalUrl?: Paths | string;
	// any function that should run when the link is clicked
	onClick?: () => void;
}

/**
 * Component that renders a single link to either an internal route, or external page. This is useful because app routing needs to be handled differently from external site links.
 * Specify the `internalUrl` prop to create a link used within the app router.
 * Specify the `externalUrl` instead to create a link to an external site.
 *
 * @param props the props available within this component
 * @constructor
 */
export const NavLinkForInternalOrExternal: React.FunctionComponent<NavLinkForInternalOrExternalProps> = (props) => {

	const {externalUrl, formattedMessageId, internalUrl, onClick} = props;

	if (!externalUrl && !internalUrl && !onClick) {
		return null;
	}

	const linkButton = (
		<Button color="link">
			<FormattedMessage id={formattedMessageId}/>
		</Button>
	);

	return (
		<NavItem className="ml-auto mr-1">
			{
				!!internalUrl
					? <NavLink onClick={onClick} tag={Link} to={internalUrl}>{linkButton}</NavLink>
					: <NavLink onClick={onClick} href={externalUrl}>{linkButton}</NavLink>
			}
		</NavItem>
	);
};