export const enum TicketOrderStatus {
	CONFIRMATION_EXCEPTION = "Confirmation Exception",
	COMPLETE = "Complete",
	DRAFT = "Draft",
	PENDING_RENEWAL = "Pending Renewal",
	TO_BE_QUALIFIED = "To Be Qualified",
	DELETED = 'Deleted',
	PAYMENT_EXCEPTION = 'Payment Exception',
	RESERVATION = 'Reservation',
	PARTIALLY_PAID = 'Partially Paid',
	FULLY_REFUNDED = 'Fully Refunded',
	DID_NOT_RENEW = 'Did Not Renew',
	CHARGEBACK_PENDING = 'Chargeback Pending'
}