import {Component} from "react";
import {IntlShape} from "react-intl";
import {CalendarIcon} from "../icons/icons";
import {ShareButton} from "./share-button";

interface GoogleAddToCalendarButtonProps {
	url: string; // the absolute HTTP/HTTPS URL executes adding event to google calendar
	intl: IntlShape;
}

export class GoogleAddToCalendarButton extends Component<GoogleAddToCalendarButtonProps> {

	public addToCalendar = () => {
		const {url} = this.props;

		if (!url) {
			return;
		}

		window.open(`${url}`);
	}

	public render() {
		const {intl} = this.props;

		return <ShareButton
			icon={CalendarIcon}
			context={intl.formatMessage({id: 'lbl_AddToCalendar'})}
			onClick={this.addToCalendar}/>;
	}
}