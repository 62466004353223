import * as React from "react";
import {WrappedComponentProps} from "react-intl";
import {Media} from "reactstrap";
import {getEventRoute} from "../../helpers/routing";
import {compareEvents} from "../../helpers/utilities";
import {TicketableEvent} from "../../models/ticketable-events/ticketable-event";
import {Message} from "../message";
import {EventItem} from "./event-item";
import {GhostEvents} from "./ghost-event";

interface EventListProps extends WrappedComponentProps {
	eventList: TicketableEvent[] | null;
}

export const EventList: React.FunctionComponent<EventListProps> = (props) => {
	const {eventList, intl} = props;

	if (!eventList) {
		return <GhostEvents />;
	} else if (eventList.length < 1) {
		return <Message intlId="msg_no_events_found" values={{events: intl.formatMessage({id: "pmgr_term_Events"}).toLowerCase()}} />;
	}

	return (
		<Media list={true} className="p-0">
			{
				eventList
					.sort(compareEvents)
					.map(te => <EventItem key={te.id} detailPath={getEventRoute(te.id)} ticketableEvent={te}/>)
			}
		</Media>
	);
};