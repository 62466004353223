import {History} from "history";
import _ from "lodash";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {IntlShape} from "react-intl";
import {RouteComponentProps} from "react-router";
import {Col, Row} from "reactstrap";
import {AnyAction} from "redux";
import {ActionTypes} from "../../enums/action-types";
import {Paths} from "../../enums/paths";
import {SubscriptionTypes} from "../../enums/subscription-types";
import {getSubFulfillmentRoute} from "../../helpers/routing";
import {layout} from "../../hoc/layout";
import {usePrevious} from "../../hooks";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {SubscriptionEventDescriptor} from "../../models/event-descriptor/subscription-event-descriptor";
import {
	SubscriptionPriceLevelLinkDescriptor
} from "../../models/event-descriptor/subscription-price-level-link-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {PanelNav} from "../panel-nav";
import {Stages} from "./stages";
import {SubscriptionForm} from "./subscription-form";
import {CYOPerformanceSelection} from "./cyo-performance-selection";
import {InventoryService} from "../../helpers/inventory-service";

interface SubscriptionProps extends RouteComponentProps<any>{
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	eventDescriptor: SubscriptionEventDescriptor;
	fetchEvents: () => void;
	history: History;
	insertSubscriptionCartItems: (cartId: string, cartItems: CartItem[], selectedTicketPLsBySubscriptionPLMap: BasicStringKeyedMap<string[]> | null, nonBlocking?: boolean) => Promise<any>;
	intl: IntlShape;
	validateCYOSubscriptionPriceLevels: (subscriptionPriceLevelIds: string[]) => Promise<any>;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

/**
 * Component that provides user with options to choose their own subscriptions.
 */
export const Subscription = (props: SubscriptionProps) => {
	const {
		cart,
		cartTimeRemaining,
		clearAllMessages,
		config,
		eventDescriptor,
		history,
		insertSubscriptionCartItems,
		intl,
		validateCYOSubscriptionPriceLevels
	} = props;
	
	const [selectedAllocationId, setSelectedAllocationId] = useState<string>('');
	const [priceLevelQuantityMap, setPriceLevelQuantityMap] = useState<BasicStringKeyedMap<string>>({}); 
	const [selectedCYOSpllMap, setSelectedCYOSpllMap] = useState<BasicStringKeyedMap<SubscriptionPriceLevelLinkDescriptor[]>>({});
	const [stage, setStage] = useState<Stages>(Stages.PickingAllocation);
	const [disableNext, setDisableNext] = useState<boolean>(true);
	
	const prevLevelList = usePrevious(eventDescriptor.levelList);
	const prevSelectedAllocationId = usePrevious(selectedAllocationId);

	useEffect(() => {
		if (eventDescriptor.allocList.length === 1) {
			// If there's only a single Subscription TA, pre-select it and bypass the allocation selection
			setSelectedAllocationId(eventDescriptor.allocList[0].id);
			setStage(Stages.PickingPriceLevelQuantity);
		}
		// dependency array is purposely left empty since we only want to perform this action one time when the component first loads
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {
		if (!!selectedAllocationId) {
			// PMGR-10224 We need to perform a deep comparison here against the previous value of the level list because React only does
			// a referential equality check on items in the dependency array. This means each and every time we re-fetch the event descriptor,
			// this useEffect hook is going to run, but we only want to re-initialize the values if there has been an actual change to the price
			// level options in the event descriptor.
			if(selectedAllocationId !== prevSelectedAllocationId || !_.isEqual(prevLevelList, eventDescriptor.levelList)) {
				// initialize the quantities of each price level to 0
				setPriceLevelQuantityMap(
					eventDescriptor.levelList.reduce((prevPriceLevelQuantityMap: Record<string,string>, priceLevel) => {
						if (priceLevel.allocId === selectedAllocationId) {
							prevPriceLevelQuantityMap[priceLevel.id] = "0";
						}
						return prevPriceLevelQuantityMap;
					}, {})
				);
			}
		}
	}, [eventDescriptor.levelList, prevSelectedAllocationId, prevLevelList, selectedAllocationId, stage]);
	
	const SubscriptionLayout = useMemo(() => {
		return layout<typeof SubscriptionForm>(
			{Main: SubscriptionForm},
			null,
			null,
			{primary: eventDescriptor.teName, secondary: eventDescriptor.name}
		);
	}, [eventDescriptor.name, eventDescriptor.teName]);
	
	const handleQuantityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPriceLevelQuantityMap(prevState => {
			return {...prevState, [event.target.name]: event.target.value}
		});
	}, []);
	
	const selectedSubPriceLevelIds = useMemo(() => {
		return Object.keys(priceLevelQuantityMap).filter((levelId: string) => Number(priceLevelQuantityMap[levelId]) > 0)
	},[priceLevelQuantityMap]);
	
	const handleCYOPerformanceSelection = useCallback((selectedSpll: SubscriptionPriceLevelLinkDescriptor) => {
		setSelectedCYOSpllMap((prevState: BasicStringKeyedMap<SubscriptionPriceLevelLinkDescriptor[]>) => {
			const _selectedCYOSpllMap = {...prevState};
			
			const singlePriceLevel = selectedSubPriceLevelIds.length === 1;
			
			const selectedSpllIds = _selectedCYOSpllMap[selectedSubPriceLevelIds[0]] || [];
			if (selectedSpllIds.some(spll => spll.id === selectedSpll.id)) {
				if(singlePriceLevel){
					_selectedCYOSpllMap[selectedSubPriceLevelIds[0]] =
						_selectedCYOSpllMap[selectedSubPriceLevelIds[0]].filter(spll => spll.id !== selectedSpll.id);
				} else {
					selectedSubPriceLevelIds.forEach((levelId: string) => {
						_selectedCYOSpllMap[levelId] =
							_selectedCYOSpllMap[levelId].filter(spll => spll.allocationId !== selectedSpll.allocationId);
					});
				}
			} else if (selectedSpllIds.length === eventDescriptor.numberOfSubscriberSelections) {
				// if trying to add a new selection and the required number has already been met, don't update the map
				return _selectedCYOSpllMap;
			} else {
				if(singlePriceLevel) {
					let spllList = _selectedCYOSpllMap[selectedSubPriceLevelIds[0]];
					if(!Array.isArray(spllList)) {
						spllList = [];
						_selectedCYOSpllMap[selectedSubPriceLevelIds[0]] = spllList;
					}
					spllList.push(selectedSpll);
				}
				else {
					selectedSubPriceLevelIds.forEach((levelId: string) => {
						let spllList = selectedCYOSpllMap[levelId];
						if(!Array.isArray(spllList)) {
							spllList = [];
							_selectedCYOSpllMap[levelId] = spllList;
						}
						const spplToAdd = eventDescriptor.subscriptionPriceLevelLinks.find((spll: SubscriptionPriceLevelLinkDescriptor) => {
							return spll.subscriptionPriceLevelId === levelId && spll.allocationId === selectedSpll.allocationId;
						});
						if(!!spplToAdd) {
							spllList.push(spplToAdd);
						}
					});
				}
			}
			return _selectedCYOSpllMap;
		});
	},[eventDescriptor.numberOfSubscriberSelections, eventDescriptor.subscriptionPriceLevelLinks, selectedCYOSpllMap, selectedSubPriceLevelIds]);
	
	const handleAddToCart = useCallback(() => {
		// Disable the next button and clear any error messages first
		setDisableNext(true);
		clearAllMessages();

		const subscriptionCartItems: CartItem[] = Object.keys(priceLevelQuantityMap).reduce((prev: CartItem[], priceLevelId: string) => {
			const quantityStr = priceLevelQuantityMap[priceLevelId];
			if (!!quantityStr) {
				// Generate the required number of CartItems at the selected price level
				for (let i: number = 0; i < Number(quantityStr); i++) {
					const cartItem = new CartItem();
					cartItem.toId = cart.id;
					cartItem.levelId = priceLevelId;
					cartItem.qty = 1;
					cartItem.passcode = eventDescriptor.appliedPasscode;
					prev.push(cartItem);
				}
			}
			return prev;
		}, []);

		// For choose your own subscriptions, we need to provide a map of the user's performance selections
		const isCYO = eventDescriptor.subscriptionType === SubscriptionTypes.CHOOSE_YOUR_OWN;
		const selectedTicketPLsBySubscriptionPLMap: BasicStringKeyedMap<string[]> = {};
		if(isCYO) {
			Object.keys(selectedCYOSpllMap).forEach((subLevelId: string) => {
				selectedTicketPLsBySubscriptionPLMap[subLevelId] =
					selectedCYOSpllMap[subLevelId].reduce((prev: string[], spll: SubscriptionPriceLevelLinkDescriptor) => {
						prev.push(spll.ticketPriceLevelId);
						return prev;
					},[]);
			});
		}

		insertSubscriptionCartItems(cart.cartId, subscriptionCartItems, isCYO ? selectedTicketPLsBySubscriptionPLMap : null, false)
			.then((response: any) => {
				if (response.type === ActionTypes.API_SUCCESS) {
					if(eventDescriptor.fulfillmentVenueIds.length > 0) {
						// Enter into subscription fulfillment
						history.push(getSubFulfillmentRoute(eventDescriptor.id, selectedAllocationId));	
					}
					else {
						// Redirect to the discount page
						history.push(Paths.CART);
					}
				} else {
					// re-enable the next button in case the user wants to try again
					setDisableNext(false);
				}
			});
	}, [
		cart.cartId, cart.id, clearAllMessages, eventDescriptor.appliedPasscode, eventDescriptor.fulfillmentVenueIds.length, eventDescriptor.id,
		eventDescriptor.subscriptionType, history, insertSubscriptionCartItems, priceLevelQuantityMap, selectedAllocationId, selectedCYOSpllMap]);

	useEffect(() => {
		// determines whether the "Next" button should be disabled or not given the current page and conditions
		switch(stage) {
			case Stages.PickingAllocation:
				setDisableNext(!selectedAllocationId);
				break;
			case Stages.PickingPriceLevelQuantity:
				setDisableNext(selectedSubPriceLevelIds.length === 0);
				break;
			case Stages.PickingCYOPerformances:
				setDisableNext(!(Object.values(selectedCYOSpllMap)[0].length === eventDescriptor.numberOfSubscriberSelections));
				break;
		}
	},[eventDescriptor.numberOfSubscriberSelections, selectedAllocationId, selectedCYOSpllMap, selectedSubPriceLevelIds, selectedSubPriceLevelIds.length, stage]);
	
	const handleNext = useCallback(() => {
		switch (stage) {
			case Stages.PickingAllocation:
				setStage(Stages.PickingPriceLevelQuantity);
				return;
				
			case Stages.PickingPriceLevelQuantity:
				if (eventDescriptor.subscriptionType === SubscriptionTypes.CHOOSE_YOUR_OWN) {
					setDisableNext(true);
					clearAllMessages();
					validateCYOSubscriptionPriceLevels(selectedSubPriceLevelIds)
						.then((result) => {
							if (result.type === ActionTypes.API_SUCCESS) {
								// initialize selection map and navigate to the performance selection page
								setSelectedCYOSpllMap(selectedSubPriceLevelIds.reduce((prev: Record<string,SubscriptionPriceLevelLinkDescriptor[]>, levelId: string) => {
									prev[levelId] = [];
									return prev;
								}, {}));
								setStage(Stages.PickingCYOPerformances);
							} else {
								setDisableNext(false);
							}
						})
				}
				else {
					handleAddToCart();
				}
				return;
		}
	}, [clearAllMessages, eventDescriptor.subscriptionType, handleAddToCart, selectedSubPriceLevelIds, stage, validateCYOSubscriptionPriceLevels]);
	
	const handleCYOCancel = useCallback(() => {
		setStage(Stages.PickingPriceLevelQuantity);
	},[])
	
	return (
		<>
			{stage === Stages.PickingCYOPerformances
				? (
					<CYOPerformanceSelection
						allocationName={InventoryService.getLevelsMappedByAllocationId(eventDescriptor)[selectedAllocationId][0].allocName}
						config={config}
						handleAddToCart={handleAddToCart}
						handleCancel={handleCYOCancel}
						includeFeesInPrice={config.includeFeesInPrice}
						disableNext={disableNext}
						onPerformanceSelection={handleCYOPerformanceSelection}
						priceLevelQuantityMap={priceLevelQuantityMap}
						selectedCYOSpllMap={selectedCYOSpllMap}
						subsEventDescriptor={eventDescriptor}
						intl={intl}
					/>
				) : (
					<SubscriptionLayout
						cartTimeRemaining={cartTimeRemaining}
						config={config}
						eventDescriptor={eventDescriptor}
						intl={intl}
						onAllocationChange={(allocationId: string) => setSelectedAllocationId(allocationId)}
						onQuantityChange={handleQuantityChange}
						priceLevelQuantityMap={priceLevelQuantityMap}
						selectedAllocationId={selectedAllocationId}
						stage={stage}
						subsEventDescriptor={eventDescriptor}
					/>
				)
			}
			<Row>
				<Col md={{size: 8, offset: 2}} className="mb-3">
					{stage === Stages.PickingAllocation && (
						<PanelNav
							next={{handleClick: handleNext, label: intl.formatMessage({id: "lbl_Next"}), isDisabled: disableNext}}
						/>
					)}
					{stage === Stages.PickingPriceLevelQuantity && (
						<PanelNav
							back={{handleClick: () => setStage(Stages.PickingAllocation), label: intl.formatMessage({id: "lbl_Back"})}}
							next={{handleClick: handleNext, label: intl.formatMessage({id: "lbl_Next"}), isDisabled: disableNext}}
						/>
					)}
				</Col>
			</Row>
		</>
	);
}
