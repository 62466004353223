import * as React from "react";
import {useContext} from "react";
import {ConfigContext} from "../../containers/public-ticket-app";
import {defaultOptions, sanitizeContent} from "../../helpers/sanitize-content";
import "./styles.css";

///
/// Interfaces
///

interface HTMLContentProps {
	rawHtml: string;
}

///
/// Component
///


export const HTMLContent: React.FunctionComponent<HTMLContentProps> = (props) => {
	const {rawHtml} = props;

	const config = useContext(ConfigContext);

	let sanitizeOptions;

	if (config.canInjectCode) {
		// little trick to immutably create object without 'allowedIframeHostnames' property and storing it in 'everythingButIframeHosts'
		const {allowedIframeHostnames, ...everythingButIframeHosts} = defaultOptions;

		sanitizeOptions = everythingButIframeHosts;
	}

	return (
		<div className="pts-custom-content"
			 dangerouslySetInnerHTML={{__html: sanitizeContent({rawHtml, options: sanitizeOptions})}}
		/>
	);
};