import {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, FormattedNumber, IntlShape} from "react-intl";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Col, Collapse, Row} from "reactstrap";
import {AnyAction} from "redux";
import {PublicTicketAppActions} from "../../actions/public-ticket-app-actions";
import {Severities} from "../../enums/severities";
import {SubscriptionTypes} from "../../enums/subscription-types";
import {getSubFulfillmentInstanceRoute, getSubFulfillmentVenueRoute} from "../../helpers/routing";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {FeeDescriptor} from "../../models/fee-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {SubscriptionFeeDisplay} from "../../models/subscription/subscription-fee-display";
import {
	InstanceGroup,
	SubscriptionTicketDisplay,
	UNSPECIFIED_VENUE_ID,
	VenueGroup
} from "../../models/subscription/subscription-ticket-display";
import {DetailToggleButton} from "../detail-toggle-button";
import {HTMLContent} from "../html-content/html-content";
import {FieldGroupErrorIcon} from "../icons/icons";
import {ItemHeader} from "./item-header";
import {TicketItemRow} from "./ticket-item-row";
import {SeatingTypes} from "../../enums/seating-types";

interface SubscriptionItemProps {
	allowEdit?: boolean;
	allowFulfillment?: boolean;
	allowNavigation?: boolean;
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	config: PublicTicketAppConfig;
	deleteCartItems?: (cartId: string, cartItems: CartItem[], modstamp?: number | null) => Promise<any>;
	detailsExpanded: boolean;
	intl: IntlShape;
	isBusy?: boolean;
	itemFeeData: FeeDescriptor[];
	subscription: SubscriptionTicketDisplay;
}

/**
 * Displays a subscription item in the TicketOrder.
 * It displays a list of the performances in the subscription, the
 * seats chosen along with price level, as well as fees and
 * any discounts applied.
 */
export const SubscriptionItem = (props: SubscriptionItemProps) => {
	const {
		allowEdit = false,
		allowFulfillment = false,
		allowNavigation = false,
		blockingActions,
		cart, 
		config: {
			currencyCode,
			includeFeesInPrice
		},
		deleteCartItems, 
		detailsExpanded,
		intl,
		itemFeeData,
		subscription: {
			allocId,
			allocName,
			customCartText,
			discountCodes,
			eiId,
			eiName,
			fees,
			feeSubtotal,
			instanceGroups,
			quantity,
			quantityBreakdown,
			subscriptionType,
			teName,
			ticketAmount,
			ticketSubtotal,
			totalAmount,
			venueGroups
		},
	} = props;
	
	const [isOpen, setIsOpen] = useState(detailsExpanded);
	const [alertShown, setAlertShown] = useState(false);
	const isBusy = Object.keys(blockingActions).length > 0;
	
	const dispatch = useDispatch();
	useEffect(() => {
		// If fulfillment is allowed, and any of the venueGroups or instanceGroups are not fulfilled, automatically expand the details
		if (allowFulfillment
				&& (Object.values(venueGroups).some(venueGroup => !venueGroup.isFulfilled())
					|| Object.values(instanceGroups).some(instanceGroup => !instanceGroup.isFulfilled()))) {
			setIsOpen(true);
			if (!alertShown) {
				dispatch(PublicTicketAppActions.showAlert({alertId: "msg_subscription_seat_selections_incomplete", alertSeverity: Severities.ERROR}));
				setAlertShown(true);
			}
		}
	}, [alertShown, allowFulfillment, dispatch, instanceGroups, subscriptionType, venueGroups]);
	
	const {priceForDisplay, feesForDisplay} = useMemo(() => {
		const _itemFeeTranslation: BasicStringKeyedMap<string> = new BasicStringKeyedMap<string>();
		itemFeeData.forEach((ifd: FeeDescriptor) => {
			_itemFeeTranslation[ifd.prop] = ifd.label;
		});
		_itemFeeTranslation['disTotal'] = intl.formatMessage({id: "lbl_DiscountTotal"});

		const _priceForDisplay = <FormattedNumber value={ticketAmount} style="currency" currency={currencyCode}/>;

		const _feesForDisplay: JSX.Element[] = Array<JSX.Element>();
		let totalFees: number = 0;
		if (!!fees) {
			Object.keys(fees).forEach((feeKey: string) => {
				if (!!fees[feeKey].amount) {
					totalFees += fees[feeKey].amount;
					const fee: SubscriptionFeeDisplay = fees[feeKey];
					_feesForDisplay.push(
						<TicketItemRow key={feeKey} userDefinedLabel={_itemFeeTranslation[fee.name]} priceInfo={{minPrice: fee.amount}} currencyCode={currencyCode} intl={intl}/>
					);
				}
			});
		}
		return {priceForDisplay: _priceForDisplay, feesForDisplay: _feesForDisplay, totalFees};
	}, [currencyCode, fees, intl, itemFeeData, ticketAmount]);
	
	const handleRemoveCartItems = useCallback((allocId: string) => {
		if (allowEdit && deleteCartItems) {
			const cartItemsToRemove: CartItem[] = cart.cartItems.filter(ci => ci.allocId === allocId);
			if (!!cartItemsToRemove && cartItemsToRemove.length > 0) {
				deleteCartItems(cart.cartId, cartItemsToRemove, cart.modstamp);
			}
		}
	}, [allowEdit, cart.cartId, cart.cartItems, cart.modstamp, deleteCartItems]);
	
	const ticketRollupDetails: JSX.Element = (
		<ItemHeader
			allocationName={allocName}
			ticketableEventName={teName}
			eventInstanceId={eiId}
			eventInstanceName={eiName}
			discountsApplied={discountCodes ? Object.keys(discountCodes) : []}
			quantity={quantity}
			quantityBreakdown={quantityBreakdown}
			allowNavigation={allowNavigation}
			itemTotal={totalAmount}
			itemSubtotal={ticketSubtotal}
			feeSubtotal={feeSubtotal}
			currencyCode={currencyCode}
			breakoutFees={includeFeesInPrice}
		/>
	);
	
	const removeThisSubscriptionElement: JSX.Element | null = allowEdit && !isBusy ? (
		<Button size="sm" color="link" className="py-0 pr-0 mb-1" onClick={() => handleRemoveCartItems(allocId)}>
			<FormattedMessage id={"lbl_Remove"} />
		</Button>
	) : null;
	
	const venues: JSX.Element[] = new Array<JSX.Element>();
	const instances: JSX.Element[] = new Array<JSX.Element>();
	if (subscriptionType === SubscriptionTypes.FIXED) {
		Object.values(venueGroups).forEach(venueGroup => (
			venues.push(
				<VenueGroupDisplay
					key={venueGroup.venueId}
					allowFulfillment={allowFulfillment}
					intl={intl}
					subscriptionAllocId={allocId}
					subscriptionInstanceId={eiId}
					subscriptionQuantity={quantity}
					venueGroup={venueGroup}
				/>
			)
		));
	} else if(subscriptionType === SubscriptionTypes.CHOOSE_YOUR_OWN) {
		Object.values(instanceGroups).forEach((instanceGroup: InstanceGroup) => {
			instances.push(
				<InstanceGroupDisplay
					key={instanceGroup.eiId}
					allowFulfillment={allowFulfillment}
					intl={intl}
					subscriptionAllocId={allocId}
					subscriptionInstanceId={eiId}
					instanceGroup={instanceGroup}
				/>
			)
		});
	}
	
	const priceDisplayElement: JSX.Element = (
		<div className="m-0 mt-2 mb-1">
			<span className="mr-2">{intl.formatMessage({id: "lbl_Price"}) + ':'}</span>
			<span className="small">
				{priceForDisplay}
			</span>
		</div>
	);
	
	const feesDisplayElement: JSX.Element | null = (!feesForDisplay || feesForDisplay.length > 0) ? (
		<div className="m-0 mt-1 mb-1">
			<div>{intl.formatMessage({id: "lbl_Fees"}) + ':'}</div>
			<div className="ml-3">
				{feesForDisplay}
			</div>
		</div>
	) : null;
	
	const ticketOrderDisplayElement: JSX.Element = (
		<div className="mb-3">
			{venues}
			{instances}
			<Row>
				<Col>{priceDisplayElement}</Col>
			</Row>
			<Row>
				<Col>{feesDisplayElement}</Col>
			</Row>
		</div>
	);
	
	return (
		<div className="eventItem border-bottom mb-3">
			<Row>
				<Col sm="9">
					{ticketRollupDetails}
				</Col>
				<Col>
					{removeThisSubscriptionElement}
				</Col>
			</Row>
			
			<Row>
				<Col>
					{!!customCartText && <HTMLContent rawHtml={customCartText} />}
				</Col>
			</Row>
			
			{!detailsExpanded && (
				<Row>
					<Col>
						<DetailToggleButton onClick={() => setIsOpen(!isOpen)} detailsVisible={isOpen} />
					</Col>
				</Row>
			)}
			
			<Row>
				<Col>
					<Collapse isOpen={isOpen}>
						{ticketOrderDisplayElement}
					</Collapse>
				</Col>
			</Row>
		</div>
	);
}

interface VenueGroupDisplayProps {
	allowFulfillment?: boolean;
	intl: IntlShape;
	subscriptionAllocId: string;
	subscriptionInstanceId: string;
	subscriptionQuantity: number;
	venueGroup: VenueGroup;
}

// For Fixed Subscriptions, displays all fulfillment instances and seat assignments (or GA quantity) for a single fulfillment venue 
export const VenueGroupDisplay = (props: VenueGroupDisplayProps) => {
	const {allowFulfillment = false, intl, subscriptionAllocId, subscriptionInstanceId, subscriptionQuantity, venueGroup} = props;
	
	let seatAssignments: string[] = new Array<string>();
	let pyosVenueDisplay: JSX.Element | undefined;
	if (Object.values(venueGroup.pyosInstanceGroups).length > 0) {
		seatAssignments = venueGroup.getSeatAssignments();
		pyosVenueDisplay = (
			<Row className="mb-4">
				<Col>
					<VenueInstanceDisplay venueName={venueGroup.venueName} instances={Object.values(venueGroup.pyosInstanceGroups)} intl={intl} />
					<SeatAssignmentDisplay
						allowFulfillment={allowFulfillment}
						intl={intl}
						isFulfilled={venueGroup.isFulfilled()}
						editSeatsRoute={getSubFulfillmentVenueRoute(subscriptionInstanceId, subscriptionAllocId, venueGroup.venueId)}
						seatAssignments={seatAssignments}
					/>
				</Col>
			</Row>
		);
	}
	
	let gaVenueDisplay: JSX.Element | undefined;
	if (Object.values(venueGroup.gaInstanceGroups).length > 0) {
		const venueName = venueGroup.venueId === UNSPECIFIED_VENUE_ID
			? venueGroup.venueName
			: `${venueGroup.venueName} (${intl.formatMessage({id: "lbl_GeneralAdmission"})})`
		
		gaVenueDisplay = (
			<Row className="mb-4">
				<Col>
					<VenueInstanceDisplay venueName={venueName} instances={Object.values(venueGroup.gaInstanceGroups)} intl={intl}/>
					<div className="ga-quantity ml-3 mb-2">
						{`${subscriptionQuantity}X ${intl.formatMessage({id: "lbl_GeneralAdmission"})}`}
					</div>
				</Col>
			</Row>
		);
	}
	
	return (
		<>
			{pyosVenueDisplay}
			{gaVenueDisplay}
		</>
	);
};

interface InstanceGroupDisplayProps {
	allowFulfillment?: boolean;
	intl: IntlShape;
	subscriptionAllocId: string;
	subscriptionInstanceId: string;
	instanceGroup: InstanceGroup;
}

// For Choose Your Own Subscriptions, displays a single fulfillment instance with it's seat assignments (or GA quantity)
export const InstanceGroupDisplay = (props: InstanceGroupDisplayProps) => {
	const {allowFulfillment = false, intl, subscriptionAllocId, subscriptionInstanceId, instanceGroup} = props;
	
	return (
		<Row className="mb-4">
			<Col>
				<InstanceDisplay instance={instanceGroup} intl={intl} />
				{instanceGroup.seatingType === SeatingTypes.PYOS
					? (
						<SeatAssignmentDisplay
							allowFulfillment={allowFulfillment}
							intl={intl}
							isFulfilled={instanceGroup.isFulfilled()}
							editSeatsRoute={getSubFulfillmentInstanceRoute(subscriptionInstanceId, subscriptionAllocId, instanceGroup.eiId)}
							seatAssignments={Array.from(instanceGroup.seatAssignments).sort()}
						/>
					) : (
						<div className="ga-quantity ml-3 mb-2">
							{`${Object.keys(instanceGroup.sbsls).length}X ${intl.formatMessage({id: "lbl_GeneralAdmission"})}`}
						</div>
					)}
			</Col>
		</Row>
	);
};

interface VenueInstanceDisplayProps {
	venueName: string;
	instances: InstanceGroup[];
	liveStreamURLs?: string[];
	intl: IntlShape;
}

export const VenueInstanceDisplay = (props: VenueInstanceDisplayProps) => {
	const {venueName, instances, intl} = props;
	
	return (
		<div>
			<h5 className="mb-1 ml-3">{venueName}</h5>
			<div className="pts-event-instance pt-1 pb-1">
				{instances.map((instance: InstanceGroup) => {
					return <InstanceDisplay key={instance.eiId} instance={instance} intl={intl} />
				})}
			</div>	
		</div>
	)
}

interface InstanceDisplayProps {
	instance: InstanceGroup;
	intl: IntlShape;
}

const InstanceDisplay = (props: InstanceDisplayProps) => {
	const {instance, intl} = props;
	
	return (
		<div className="mb-2 ml-3">
			<div className="small font-weight-bold">{instance.teName}</div>
			<div className="small">{instance.eiName}</div>
			{instance.getLivestreamUrls().map((livestreamUrl: string) => (
				<div key={livestreamUrl} className="pb-1">
					<a href={livestreamUrl} className="btn btn-primary btn-sm" target="_blank" rel="noreferrer">
						{intl.formatMessage({id: "lbl_ClickToView"})}
					</a>
				</div>
			))}
		</div>
	);
}

interface SeatAssignmentDisplayProps {
	allowFulfillment: boolean;
	intl: IntlShape;
	isFulfilled: boolean;
	editSeatsRoute: string;
	seatAssignments: string[];
}

// Displays a list of subscription fulfillment seat assignments and a way for the user to jump back into the fulfillment wizard to edit
// or add to their seat assignments
const SeatAssignmentDisplay = (props: SeatAssignmentDisplayProps) => {
	const {allowFulfillment, intl, isFulfilled, editSeatsRoute, seatAssignments} = props;
	return (
		<>
			<Row>
				{seatAssignments.length > 0 && (
					<Col xs="9" className="ml-3 mb-2">
						{seatAssignments.map(seatAssignment => (
							/* The "seat-assignment" class is simply a marker class to allow them to be found easily in Enzyme tests */
							<div key={seatAssignment} className="seat-assignment">{seatAssignment}</div>
						))}
					</Col>
				)}
				{allowFulfillment && isFulfilled && (
					<Col className="align-self-center">
						<Link className="btn btn-light mt-1" to={editSeatsRoute}>
							{intl.formatMessage({id: "lbl_Edit"})}
						</Link>
					</Col>
				)}
			</Row>
			{allowFulfillment && !isFulfilled && (
				<Row>
					<Col className="ml-3">
						<div className="text-danger">
							<FieldGroupErrorIcon/>
							<span className="pl-1">{intl.formatMessage({id: "msg_missing_selections"})}</span>
						</div>
						<Link className="btn btn-primary w-100 mt-1" to={editSeatsRoute}>
							{intl.formatMessage({id: "lbl_SelectSeats"})}
						</Link>
					</Col>
				</Row>
			)}
		</>
	)
}