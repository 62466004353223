import * as React from "react";
import {GiftCardApplyForm, GiftCardApplyFormProps} from "./gift-card-apply-form";
import {GiftCardInputForm, GiftCardInputFormProps} from "./gift-card-input-form";
import {NoPaymentRequired, NoPaymentRequiredProps} from "./no-payment-required";
import {PaymentInputForm, PaymentInputFormProps} from "./payment-input-form";

///
/// Interfaces
///

interface PaymentFormProps extends PaymentInputFormProps, NoPaymentRequiredProps, GiftCardInputFormProps, GiftCardApplyFormProps {}

interface PaymentFormState {
	showGiftCardInput: boolean;
	showGiftCardApply: boolean;
}


///
/// Component
///

export class PaymentForm extends React.Component<PaymentFormProps> {
	public readonly state: PaymentFormState = {
		showGiftCardInput: false,
		showGiftCardApply: false
	};
	
	public render() {
		const {
			blockingActions,
			cart,
			cartTimeRemaining,
			fetchGiftCardBalance,
			fetchGiftCardBalanceByPaymentMethodId,
			clearAllMessages,
			config,
			intl,
			userProfile,
			saveProps,
			showAlert,
			submitCart,
			history,
			prevPagePath
		} = this.props;

		if (cart.orderTotal === 0) {
			// when no amount is due
			return (
				<NoPaymentRequired
					history={history}
					intl={intl}
					blockingActions={blockingActions}
					submitCart={submitCart}
				/>
			);
		}
		if (this.state.showGiftCardInput) {
			return (
				<GiftCardInputForm
					blockingActions={blockingActions}
					cart={cart}
					clearAllMessages={clearAllMessages}
					config={config}
					fetchGiftCardBalance={fetchGiftCardBalance}
					fetchGiftCardBalanceByPaymentMethodId={fetchGiftCardBalanceByPaymentMethodId}
					history={history}
					intl={intl}
					onGiftCardInputCancel={this.onGiftCardInputCancel}
					saveProps={saveProps}
					showAlert={showAlert}
					showGiftCardApply={this.showGiftCardApply}
					userProfile={userProfile}
					prevPagePath={prevPagePath}
				/>
			);
		}
		
		if (this.state.showGiftCardApply) {
			return (
				<GiftCardApplyForm
					blockingActions={blockingActions}
					cart={cart}
					clearAllMessages={clearAllMessages}
					config={config}
					hideGiftCardApply={this.hideGiftCardApply}
					history={history}
					intl={intl}
					onGiftCardApplyCancel={this.onGiftCardApplyCancel}
					showAlert={showAlert}
					submitCart={submitCart}
					saveProps={saveProps}
				/>
			);
		}
		return (
			<PaymentInputForm
				blockingActions={blockingActions}
				cart={cart}
				cartTimeRemaining={cartTimeRemaining}
				clearAllMessages={clearAllMessages}
				config={config}
				history={history}
				intl={intl}
				saveProps={saveProps}
				showAlert={showAlert}
				showGiftCardInput={this.showGiftCardInput}
				submitCart={submitCart}
				userProfile={userProfile}
				prevPagePath={prevPagePath}
			/>
		);
	}

	public componentDidMount() {
		// When coming to this page make sure the gift card stuff is wiped out so they have to fill in the info and 'Check Balance' again
		this.clearGiftCardFields();
	}
	
	public componentWillUnmount() {
		// When leaving this component, clear the "validated" prop on the cart so we're forced to go back though one of the checkout forms to get validated again
		this.props.saveProps({validated: false});
	}


	
	
	private hideGiftCardApply = () => {
		this.setState({showGiftCardApply: false});
	}
	
	private showGiftCardApply = () => {
		this.setState({showGiftCardInput: false, showGiftCardApply: true});
	}
	
	private showGiftCardInput = () => {
		this.setState({showGiftCardInput: true});
	}
	
	private onGiftCardInputCancel = () => {
		this.clearGiftCardFields();
		this.setState({showGiftCardInput: false});
	}
	
	private onGiftCardApplyCancel = () => {
		// "Cancel" was clicked on the GC Apply screen. Clear the GC fields and close the window
		this.clearGiftCardFields();
		this.setState({showGiftCardApply: false});
	}
	
	private clearGiftCardFields = () => {
		this.props.saveProps({gcNumber: '', gcHash: '', gcBalance:null, selectedGiftCardPM: null});
	}
}
