import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {Label} from 'reactstrap';
import {BasicStringKeyedMap} from "../../models/basic-map";
import {FieldGroupErrorIcon} from "../icons/icons";

interface FormElementLabelProps {
	label?: string;
	intlLabelId?: string;
	values?: BasicStringKeyedMap<any>; // Values to be interpolated into the formatted message per React Intl convention.
	invalid?: boolean;
	required?: boolean;   // true when required text should be added to the label
	forInput? : string;
}

/**
 * Displays a label for form inputs.
 * Takes either a plain text label or an intl id.
 * If both are given, it displays the internationalized label.
 */
export const FormElementLabel: React.FunctionComponent<FormElementLabelProps> = (props) => {

	const {label, intlLabelId, values, invalid, required, forInput} = props;

	const labelContent = intlLabelId
		? <FormattedMessage id={intlLabelId} values={values} />
		: <span>{label}</span>;

	return (
		<Label className="font-weight-bold" for={forInput}>
			{invalid && <span className="mr-1"><FieldGroupErrorIcon /></span>}
			{labelContent}
			{required && (
				<span>
					<span className="text-danger pl-1">*</span>
					<span className="sr-only">
						<FormattedMessage id="lbl_Required" />
					</span>
				</span>
			)}
		</Label>
	);
};
