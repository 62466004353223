import {History} from "history";
import Cookies from "js-cookie";
import {AnyAction, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {ActionTypes} from "../enums/action-types";
import {Paths} from "../enums/paths";
import {Severities} from "../enums/severities";
import {TicketOrderStatus} from "../enums/ticket-order-status";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {SubmitResult} from "../models/public-ticket-app/submit-result";
import {VfRemoteResponse} from "../models/vf-remote-response";
import {VfRemoteSuccessResponse} from "../models/vf-remote-success-response";
import {RootState} from "../reducers";
import {ApiActions, getNextSequenceNumber} from "./api-actions";
import {PublicTicketAppActions} from "./public-ticket-app-actions";

///
/// Interfaces
///


export class CartActions {

	///
	/// Action Creators
	///

	public static saveProps = (props: BasicStringKeyedMap<any>): AnyAction => {
		return {type: ActionTypes.CART_SAVE_PROPS, requestId: getNextSequenceNumber(), props};
	}
	
	///
	/// Thunk Creators
	///
	public static ensureCart = (createIfNecessary = false, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, any, AnyAction> => {
		return (dispatch): Promise<any> => {
			const cartId = Cookies.get("cartId") || "";
			return dispatch(ApiActions.ensureCart(cartId, createIfNecessary, nonBlocking))
				.then((response: VfRemoteResponse) => {

					if (response.type === ActionTypes.API_SUCCESS) {
						// casting to success response since we know it is at this point
						const successResponse =  response as  VfRemoteSuccessResponse<Cart>;

						if (successResponse.data != null) {
							const cart: Cart = successResponse.data;

							if (cart.cartId) {
								Cookies.set("cartId", cart.cartId);
							}
						}
					}
					return response;
				});
		};
	}
	
	public static submitCart = (isPendingRenewal: boolean, history: History): ThunkAction<Promise<any>, RootState, void, AnyAction>  => {
		return (dispatch, getState: () => RootState) => {
			// Before submitting, save the state of the transient submit information that aren't returned
			// in the Cart payload so we can re-populate the payment form when necessary.
			const cart = isPendingRenewal ? getState().portal.pendingRenewal.cart : getState().cart;
			
			//TODO: while working on PMGR-8886 we realized this submitInfo is probably no longer needed because of 
			// how the reducers.cart.SUBMIT_CART is handling the state but it's too late in Yapok to mess with this.
			// Let's take a look the next time we're in here.
			const submitInfo = {
				paymentToken: cart.paymentToken,
				bluefinCardInfo: cart.bluefinCardInfo,
				nameOnCard: cart.nameOnCard,
				ccNumber: cart.ccNumber,
				ccType: cart.ccType,
				ccCvv2: cart.ccCvv2,
				ccExpMonth: cart.ccExpMonth,
				ccExpYear: cart.ccExpYear,
				selectedPM: cart.selectedPM,
				gcNumber: cart.gcNumber,
				gcHash: cart.gcHash,
				gcBalance: cart.gcBalance
			};

			return dispatch(ApiActions.submitCart(isPendingRenewal))
				.then((response: VfRemoteResponse) => {
					if (response.type === ActionTypes.API_SUCCESS) {
						const successResponse = response as VfRemoteSuccessResponse<SubmitResult>;

						// submitOrder will return a SubmitResult object.
						// This needs to be further examined to check for things like credit card declines, vs. timeout errors.
						const submitResult: SubmitResult = successResponse.data;

						if (submitResult.status) {
							CartActions.loadCompletePage(dispatch, submitResult, history);
						} else {
							// Something went wrong. Our course of action varies,
							// depending on whether the order has ended up in one of the exceptional statuses or not
							if (submitResult.cart.orderStatus === TicketOrderStatus.PAYMENT_EXCEPTION || submitResult.cart.orderStatus === TicketOrderStatus.CONFIRMATION_EXCEPTION) {
								// In the case of an unrecoverable error, navigate to the complete page, because there's nothing else the buyer can do
								CartActions.loadCompletePage(dispatch, submitResult, history);
							} else {
								// Credit card declines will NOT result in PEX or CEX. In this case, we want to
								// re-apply the transient submit information to the order so the fields on the
								// checkout form get re-populated.
								
								if (submitResult.cart.orderStatus === TicketOrderStatus.PARTIALLY_PAID){
									// PMGR-8886 - These should be cleared out after a CC failure so we don't try to reprocess the gc a 2nd time
									// We should only get into this Partial Payment scenario when the GC was charged first and the CC was declined.
									// Any timeout, GC or CC, will put the order in PEx which is caught above and a GC decline won't get this far.
									submitInfo.gcBalance = 0;
									submitInfo.gcHash = '';
									submitInfo.gcNumber = '';
								}
								dispatch(CartActions.saveProps(submitInfo));
							}
							if (!!submitResult.messages && submitResult.messages.length > 0) {
								// Display an alert
								const firstMessage: ApplicationMessage = submitResult.messages[0];
								dispatch(PublicTicketAppActions.showAlert({
									alertId: firstMessage.msgId,
									alertArgs: firstMessage.msgArgs,
									alertBody: firstMessage.msg,
									alertSeverity: Severities.ERROR
								}));
							}
						}
					}
					return response;
				});
		};
	}
	
	private static loadCompletePage(dispatch: Dispatch<AnyAction>, sr: SubmitResult, history:History): void {
		// copy the cart to the completedOrder state in ptApp
		// also set the printAtHomeTicketURL state in ptApp if appropriate
		dispatch(PublicTicketAppActions.setCompletedState(sr));
		
		// clear out the cart state
		dispatch(CartActions.clearCart());
		
		if (history.location.pathname.startsWith(Paths.CART)){
			// The cart cookie is only used for Draft orders so we should only clear it if the order the user completed was a Draft order
			Cookies.set("cartId", '');
		}
		
		// redirect to the 'complete' page
		history.push(Paths.COMPLETE);
		
	}
	
	private static clearCart(): AnyAction {
		return {type: ActionTypes.CART_CLEAR};
	}
}