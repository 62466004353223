import * as React from "react";
import {IntlShape} from "react-intl";
import {RouteComponentProps} from "react-router";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {PortalOrderDetails} from "../../models/portal/portal-order-details";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {MainContentHeader} from "../main-content-header";
import {Message} from "../message";
import {TicketOrderWithIntl} from "../ticket-order/ticket-order";

interface PortalOrderDetailProps extends RouteComponentProps<any> {
	config: PublicTicketAppConfig;
	fetchPortalOrderDetails: (ticketOrderId: string) => Promise<any>;
	portalOrderCache: BasicStringKeyedMap<PortalOrderDetails>;
	pageView: (title: string, url: string) => void;
	intl: IntlShape;
}

// todo: for PMGR-7842: fetch fee labels instead of hardcoding them
export class OrderDetail extends React.Component<PortalOrderDetailProps> {

	public componentDidMount() {
		const {fetchPortalOrderDetails, intl, match, pageView,  portalOrderCache} = this.props;

		pageView(intl.formatMessage({id: "lbl_title_portal_order"}), window.location.href);

		if (!(match.params.ticketOrderId in portalOrderCache)) {
			fetchPortalOrderDetails(match.params.ticketOrderId);
		}
	}

	public render() {
		const {config, match, portalOrderCache} = this.props;
		const portalOrderDetails = portalOrderCache[match.params.ticketOrderId];
		const portalOrderExists = !!portalOrderDetails;
		return (
			<div>
				<MainContentHeader intlId="lbl_OrderDetail" />
				{
					portalOrderExists
						? <TicketOrderWithIntl
							allowEdit={false}
							blockingActions={{}}
							cart={portalOrderDetails.cart}
							config={config}
							detailsExpanded={true}
							itemFeeData={portalOrderDetails.itemFeeData}
							orderFeeData={portalOrderDetails.orderFeeData}
						/>
						: <Message intlId="msg_no_such_order_was_found" />
				}
			</div>
		);
	}
}
