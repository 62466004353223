import {SeatingTypes} from "../enums/seating-types";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {CartItem} from "../models/cart-item";
import {SubscriptionBuyerSelectionLinkDescriptor} from "../models/subscription-buyer-selection-link-descriptor";

export class CartService {
	private cart: Cart;
	
	constructor(cart: Cart) {
		this.cart = cart;
	}

	/**
	 * Returns the subtotal sum of the cart items that belong to the specified event instance
	 * 
	 * @param eventInstanceId the id of the event instance whose items should be counted
	 * @return the total price
	 */
	public getItemTotalPrice = (eventInstanceId: string, includeFees: boolean): number => {
		return this.cart.cartItems
			.filter(cartItem => cartItem.eiId === eventInstanceId)
			.reduce((prevTotal, cartItem) => prevTotal + Number(includeFees ? cartItem.itemTotal : cartItem.subtotal), 0);
	}
	
	/**
	 * Returns the total number of items that belong to the event instance
	 *
	 * @param eventInstanceId the id of the event instance whose items should be counted
	 * @return the total number of items
	 */
	public getNumberOfItemsInCart = (eventInstanceId: string): number => {
		return this.cart.cartItems.filter(cartItem => cartItem.eiId === eventInstanceId).length;
	}
	
	/**
	 * Returns the total quantity of retail (non-fulfillment) items in the cart
	 * @return the total quantity of retail (non-fulfillment) items in the cart
	 */
	public getTotalRetailQuantity = (): number => {
		return this.cart.cartItems.reduce((prev, cartItem) => {
			if (CartItem.isRetailItem(cartItem)) {
				prev += cartItem.qty;
			}
			return prev;
		}, 0);
	}
	
	/**
	 * Returns a boolean indicating whether or not PYOS-able Subscriptions have been been completely PYOS-ed. :)
	 * In English, for each "Subscription" CartItem it finds that is for a PYOS-enabled Subscription EI, it
	 * verifies that each associated SBSL has the right quantity of fulfillment CartItems.
	 * @return true if there is at least one PYOS-able Subscription CartItem having fewer than the requisite
	 * quantity of PYOS fulfillment CartItems, or false if all 
	 */
	public hasUnfulfilledPYOSSubscriptions = (pyosEnabledSubscriptionEventDescriptors: BasicStringKeyedMap<string>): boolean => {
		// First, find and identify any "Subscription" CartItems for PYOS-enabled Subs EIs
		const pyosSubsItems = this.cart.cartItems.filter(cartItem => cartItem.eiId in pyosEnabledSubscriptionEventDescriptors);
		if (pyosSubsItems.length === 0) {
			return false;
		}
		return !!pyosSubsItems.find(pyosSubsItem => this.hasUnfulfilledPYOSSBSLs(pyosSubsItem));
	}
	
	/**
	 * Returns a boolean indicating whether or not the passed in CartItems is for a PYOS-enabled Subscription,
	 * and if so, if it has the correct quantity of fulfillment items required to be considered fulfilled. 
	 * @param cartItem a CartItem 
	 * @return true if the quantity of fulfillment CartItems matches the quantity of the SBSL
	 */
	private hasUnfulfilledPYOSSBSLs = (cartItem: CartItem): boolean => {
		// Create a local helper function to identify if the passed in SBSL is completely filled
		const hasUnfilledPYOSSBSL = (sbsl: SubscriptionBuyerSelectionLinkDescriptor): boolean => {
			if (sbsl.seatingType !== SeatingTypes.PYOS) {
				// If the SBSL is not for a PYOS performance, it won't be fulfilled
				return false;
			}
			// Accumulate the quantity of fufillment items for this SBSL
			const fulfillmentItemQuantity = this.cart.cartItems.reduce((prev, cartItem) => {
				if (cartItem.sbslId === sbsl.id) {
					prev += cartItem.qty;
				}
				return prev;
			}, 0);
			// Return true if the quantity of fulfillment items does not match SBSL.qty
			return fulfillmentItemQuantity !== sbsl.qty;
		}
		
		return !!cartItem.sbsls!.find(sbsl => hasUnfilledPYOSSBSL(sbsl));
	}
}