import * as React from 'react';
import {Button} from 'reactstrap';

interface ButtonProps {
	label: string;
	handleClick: () => any;
	isDisabled?: boolean;
}

interface PanelNavProps {
	next?: ButtonProps;
	back?: ButtonProps;
}

/**
 * The navigation that appears at the bottom of
 * the panel in the checkout flow.
 */
export const PanelNav: React.FC<PanelNavProps> = ({next, back}) => (
	<div className="d-flex w-100">
		{!!back && (
			<Button
				color="light"
				className="w-100 nav-back"
				onClick={back.handleClick}
				disabled={back.isDisabled}
			>{back.label}</Button>
		)}
		{!!next && (
			<Button
				color="primary"
				className="w-100"
				onClick={next.handleClick}
				disabled={next.isDisabled}
			>{next.label}</Button>
		)}
	</div>
);
