import * as hash from "object-hash";
import * as React from "react";
import {FormattedMessage, WrappedComponentProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Alert} from "reactstrap";
import {ContactUsMessageInterceptor} from "../../components/application-messages/contact-us-message-interceptor";
import {setScrollPosition} from "../../helpers/utilities";
import {ApplicationMessage} from "../../models/application-message";
import {Cart} from "../../models/cart";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {RootState} from "../../reducers";


/**
 * All properties available within this component
 */
interface ApplicationAlertProps extends ApplicationAlertPropsExcludingInjectedProps, ApplicationAlertStateToProps, WrappedComponentProps {}

/**
 * All properties that should be defined when using the component exported with injections
 */
interface ApplicationAlertPropsExcludingInjectedProps {
	message: ApplicationMessage;
	onClose: ()=> any;
}

interface ApplicationAlertStateToProps {
	config: PublicTicketAppConfig;
	cart: Cart;
}

interface ApplicationAlertState {
	isVisible: boolean;
}

// This assumes a singleton message.  
// It's needed for messages where we need to control the state of the isOpen and toggle which UncontrolledAlert does not give us.
// Eventually we should combine this with application-messages but we're in the 11th hour and don't want to mess with it.
// see PMGR-8489
export class ApplicationAlert extends React.Component<ApplicationAlertProps, ApplicationAlertState> {
	public readonly state: ApplicationAlertState = {
		isVisible: true
	};
	
	public componentDidMount() {
		// Scroll to the top to bring the alerts into view
		setScrollPosition(0);
	}
	
	public render() {
		const {config, cart, intl, message} = this.props;
		const isOpen = this.state.isVisible && !!message;
		const msgKey = hash.sha1(message);
		const interceptedMessage = new ContactUsMessageInterceptor(config, cart, intl).intercept(new Map([[msgKey, message]])).get(msgKey)!;
		let severityColor: string = 'light';
		switch (interceptedMessage.severity) {
			case 'SUCCESS': severityColor = 'success'; break;
			case 'INFO': severityColor = 'info'; break;
			case 'WARNING': severityColor = 'warning'; break;
			case 'ERROR': severityColor = 'danger'; break;
		}

		return (
			<Alert color={severityColor} isOpen={isOpen} toggle={this.onDismiss} fade={false}>
				{!!interceptedMessage.msgId ? (
					<div>
						<FormattedMessage id={interceptedMessage.msgId} values={interceptedMessage.msgArgs}/>
						{!!interceptedMessage.msgArgs && 'delimitedString' in interceptedMessage.msgArgs && (
							<ul>
								{interceptedMessage.msgArgs.delimitedString.split('|').map((teName: string, index:number) => <li key={index}>{teName}</li>)}
							</ul>
						)}
					</div>
				) : (
					<div>{interceptedMessage.msg}</div>
				)}
			</Alert>
		);
	}
	
	private onDismiss = () => {
		this.setState({isVisible: false });
		this.props.onClose();
	}
}

const mapStateToProps = (state: RootState): ApplicationAlertStateToProps => {
	return {
		config: state.ptApp.config,
		cart: state.cart,
	};
};

export default connect(mapStateToProps, {})(injectIntl(ApplicationAlert));
