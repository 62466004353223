import {Severities} from "../enums/severities";
import {BasicStringKeyedMap} from "./basic-map";

/**
 * Representation of Apex's ApplicationMessage class
 */
export class ApplicationMessage {
	public severity?: Severities;
	public msgId: string | undefined;
	public msgArgs: BasicStringKeyedMap<any>;
	public msg: React.ReactNode | undefined;

	constructor(severity: Severities = Severities.INFO, msgId: string | undefined, msgArgs: BasicStringKeyedMap<any> | undefined, msg: React.ReactNode | undefined) {
		this.severity = severity;
		this.msgId = msgId;
		this.msgArgs = msgArgs || {};
		this.msg = msg;
	}
}