import {Paths} from "../enums/paths";
import {SeatingTypes} from "../enums/seating-types";
import {CartItem} from "../models/cart-item";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {formatNavigationPath, isPendingRenewal, removeEnd, showDiscountPage, showDonationPage} from "./utilities";

export const getRouteToItem = (cartItem: CartItem): string => {
	if (cartItem.seatingType === SeatingTypes.GA) {
		return getInstanceRoute(cartItem.eiId);
	} else if (cartItem.seatingType === SeatingTypes.PYOS && !!cartItem.sectionId) {
		return getSectionRoute(cartItem.eiId, cartItem.sectionId);
	} else {
		return getInstanceRoute(cartItem.eiId);
	}
};

export const getInstanceRoute = (eventInstanceId: string): string => {
	return Paths.INSTANCE.replace(":eventInstanceId", eventInstanceId);
};

export const getSectionRoute = (eventInstanceId: string, sectionId: string): string => {
	return Paths.INSTANCE__SECTION.replace(":eventInstanceId", eventInstanceId).replace(":sectionId", sectionId);
};

export const getSectionGroupRoute = (eventInstanceId: string, sectionGroupId: string): string => {
	return Paths.INSTANCE__SECTION_GROUP.replace(":eventInstanceId", eventInstanceId).replace(":sectionGroupId", sectionGroupId);
};

export const getSubFulfillmentRoute = (eventInstanceId: string, allocationId: string) : string => {
	return Paths.FULFILLMENT.replace(":eventInstanceId", eventInstanceId).replace(":allocationId", allocationId);
}

export const getSubFulfillmentVenueRoute = (eventInstanceId: string, allocationId: string, venueId: string) : string => {
	return Paths.FULFILLMENT_VENUE
		.replace(":eventInstanceId", eventInstanceId)
		.replace(":allocationId", allocationId)
		.replace(":venueId", venueId);
}

export const getSubFulfillmentSectionRoute = (eventInstanceId: string, allocationId: string, venueId: string, sectionId: string): string => {
	return Paths.FULFILLMENT_SECTION
		.replace(":eventInstanceId", eventInstanceId)
		.replace(":allocationId", allocationId)
		.replace(":venueId", venueId)
		.replace(":sectionId", sectionId);
};

export const getSubFulfillmentInstanceRoute = (subInstanceId: string, allocationId: string, fulfillmentInstanceId: string) : string => {
	return Paths.FULFILLMENT_INSTANCE
		.replace(":eventInstanceId", subInstanceId)
		.replace(":allocationId", allocationId)
		.replace(":fulfillmentInstanceId", fulfillmentInstanceId);
}

export const getSubFulfillmentInstanceSectionRoute = (subInstanceId: string, allocationId: string, fulfillmentInstanceId: string, sectionId: string): string => {
	return Paths.FULFILLMENT_INSTANCE_SECTION
		.replace(":eventInstanceId", subInstanceId)
		.replace(":allocationId", allocationId)
		.replace(":fulfillmentInstanceId", fulfillmentInstanceId)
		.replace(":sectionId", sectionId);
};

export const getVenueRoute = (eventInstanceId: string): string => {
	return Paths.INSTANCE__VENUE.replace(":eventInstanceId", eventInstanceId);
};

export const getEventRoute = (eventId: string): string => {
	return Paths.EVENT.replace(":ticketableEventId", eventId);
};

export const getMobileTicketPath = (ticketOrderId: string, token: string) => {
	return Paths.MOBILE_TICKETS.replace(":ticketOrderId", ticketOrderId).replace(":token", token);
}

export const isCheckoutRoute = (pathname: string): boolean  => {
	return pathname.includes(Paths.CART) || isPendingRenewal(pathname);
};

export const getPreviousPath = (currentPath: string, config: PublicTicketAppConfig, toId: string): string =>{
	const thePath = toId ? currentPath.replace(toId,':ticketOrderId') : currentPath;
	switch (thePath) {
		case Paths.CART__DONATION:
			return Paths.CART__DELIVERY;
		case Paths.CART__DISCOUNT:
			return showDonationPage(config) ? Paths.CART__DONATION : Paths.CART__DELIVERY;
		case Paths.CART__CONTACT:
			// we could jump both the discount and donation pages hear depending on the settings
			if (showDiscountPage(config)){
				return Paths.CART__DISCOUNT;
			} else {
				return showDonationPage(config) ? Paths.CART__DONATION : Paths.CART__DELIVERY;
			}
		case Paths.CART__PAYMENT:
			return Paths.CART__CONTACT;
		
		case Paths.PORTAL__PENDING_RENEWAL__DONATION:
			return formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DELIVERY,toId);
		case Paths.PORTAL__PENDING_RENEWAL__DISCOUNT:
			return showDonationPage(config)
				? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DONATION ,toId)
				: formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DELIVERY,toId);
		case Paths.PORTAL__PENDING_RENEWAL__CONTACT:
			if (showDiscountPage(config)){
				return formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DISCOUNT,toId);
			} else {
				return showDonationPage(config)
					? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DONATION ,toId)
					: formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DELIVERY,toId);
			}
		case Paths.PORTAL__PENDING_RENEWAL__PAYMENT:
			return formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__CONTACT,toId);
	}
	return '';
};

export const getNextPath = (currentPath: string, config: PublicTicketAppConfig, toId: string): string =>{
	const thePath = toId ? currentPath.replace(toId,':ticketOrderId') : currentPath;
	switch (thePath) {
		case Paths.CART__DELIVERY:
		case Paths.CART:
			// we could jump both the donation and discount pages hear depending on the settings
			if (showDonationPage(config)){
				return Paths.CART__DONATION;
			} else {
				return (showDiscountPage(config)) ? Paths.CART__DISCOUNT : Paths.CART__CONTACT;
			}
		case Paths.CART__DONATION:
			return showDiscountPage(config) ? Paths.CART__DISCOUNT : Paths.CART__CONTACT;
		case Paths.CART__DISCOUNT:
			return Paths.CART__CONTACT;
		case Paths.CART__CONTACT:
			return Paths.CART__PAYMENT;
		
		case Paths.PORTAL__PENDING_RENEWAL:
		case Paths.PORTAL__PENDING_RENEWAL__DELIVERY:
			if (showDonationPage(config)){
				return formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DONATION,toId);
			} else {
				return showDiscountPage(config)
					? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DISCOUNT,toId)
					: formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__CONTACT, toId);
			}
		case Paths.PORTAL__PENDING_RENEWAL__DONATION:
			return showDiscountPage(config)
				? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DISCOUNT,toId)
				: formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__CONTACT,toId);
		case Paths.PORTAL__PENDING_RENEWAL__DISCOUNT:
			return formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__CONTACT,toId);
		case Paths.PORTAL__PENDING_RENEWAL__CONTACT:
			return formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__PAYMENT,toId);
	}
	return '';
};

export interface NormalizedEventsUrl {
	eventsUrl: string;
	isInternal: boolean;
}

/**
 * This method evaluates the eventsUrl param and returns a NormalizedEventsUrl object (or null) based on the following rules:
 * if eventsUrl = "nolink" return null
 * else if eventsUrl is an absolute URL (not Site relative) eventsUrl is used directly, and isInternal is false
 * else eventsUrl is set to the application root route, and isInternal is true
 *
 * @param eventsUrl the raw value of the "Events URL" setting
 */
export const getNormalizedEventsUrl = (eventsUrl: string | undefined): NormalizedEventsUrl | null => {
	let normalizedEventsUrl = null;
	if (eventsUrl !== 'nolink') {
		// The eventsUrl prop is the old "All Events URL". Remove the trailing "/" if present to make sure that comparing
		// "/ticket/" with "/ticket" doesn't falsely cause us to treat this as an external URL.
		if (!!eventsUrl && (removeEnd(eventsUrl, "/") !== removeEnd(window.PublicTicketApp.sitePrefix, "/"))) {
			// If not a Site relative URL then it is an external URL
			normalizedEventsUrl = {eventsUrl, isInternal: false};
		} else {
			// If blank, or not "nolink", then link to the application root route
			normalizedEventsUrl = {eventsUrl: Paths.ROOT, isInternal: true};
		}
	}
	return normalizedEventsUrl;
};

