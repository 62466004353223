import * as React from "react";
import {Media} from "reactstrap";

/**
 * GhostEvents - A list of placeholder events.
 */
export const GhostEvents: React.FunctionComponent = () => <>{[1,2,3].map(value => <GhostEvent key={`ghost-${value}`}/>)}</>;

const GhostImage = () => (
	<div
		style={{height: '150px', width: '150px', objectFit: 'cover'}}
		className="pts-ghost-image mr-4 d-none d-md-block"
	/>
);

const GhostText = ({shorty=false}) => (
	<div
		className="pts-ghost-text mb-2"
		style={{height: '1.5rem', width: `${shorty ? 75 : 100}%`}}
	/>
);

/**
 * GhostEvent - Placeholder for an event item in the EventList
 */
const GhostEvent: React.FunctionComponent = () => (
	<Media tag="li" className="pts-ghost-event mb-5">
		<Media left={true}>
			<GhostImage />
		</Media>
		<Media body={true}>
			<Media heading={true}>
				<GhostText />
				<GhostText />
				<GhostText />
				<GhostText shorty={true} />
			</Media>
		</Media>
	</Media>
);

const GhostDay = () => (
	<div
		className="pts-ghost-image mr-2 d-none d-md-block h-100"
		style={{flexGrow: 1, flexShrink: 1, minWidth: '10px'}}
	/>
);

const GhostWeek = () => (
	<div
		className="mb-2 d-flex"
		style={{height: '80px'}}
	>
		<GhostDay />
		<GhostDay />
		<GhostDay />
		<GhostDay />
		<GhostDay />
		<GhostDay />
		<GhostDay />
	</div>
);

export const GhostCalendar = () => (
	<div
		className=""
	>
		<GhostWeek />
		<GhostWeek />
		<GhostWeek />
		<GhostWeek />
	</div>
);
