import {FC} from "react";
import {FormattedNumber} from 'react-intl';
import { DiscountList } from "./discount-list";

///
/// Interfaces
///

interface TicketItemDiscountRowProps
{
	value: number;
	currencyCode: string;
	discountName: string;
}

///
/// Component
///

/**
 * Displays an applied discount in a single row in the expanded TicketItem
 */
export const TicketItemDiscountRow: FC<TicketItemDiscountRowProps> = (props) =>
{
	const {
		value,
		currencyCode,
		discountName,
	} = props;

	return (
		<div className="small mb-1">
			<DiscountList discountsApplied={[discountName || '']} />
			<span className="ml-1 mr-1">-</span>
			<span>
				<FormattedNumber
					value={value}
					style="currency"
					currency={currencyCode}
					currencyDisplay="symbol"
				/>
			</span>
		</div>
	);
};
