import {useCallback, useEffect, useMemo, useState} from "react";
import {WrappedComponentProps} from "react-intl";
import {RouteComponentProps} from "react-router-dom";
import {Button, Col, Row} from "reactstrap";
import {Paths} from "../../enums/paths";
import {getSubFulfillmentInstanceRoute, getSubFulfillmentVenueRoute} from "../../helpers/routing";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {InstanceGroup} from "../../models/subscription/subscription-ticket-display";
import {EventInstance} from "../../models/ticketable-events/event-instance";
import {HTMLContent} from "../html-content/html-content";
import {QuantityTypeSummary} from "../quantity-type-summary";
import {VenueInstanceDisplay} from "../ticket-order/subscription-item";
import {SubscriptionFulfillmentGroup} from "../../models/subscription-fulfillment-group";

interface FulfillmentLandingProps extends RouteComponentProps<any>, WrappedComponentProps {
	cart: Cart;
	fulfillmentGroups: SubscriptionFulfillmentGroup[];
	instanceDetail?: string;
}

export const FulfillmentLanding = (props: FulfillmentLandingProps) => {
	const {cart: {cartItems}, instanceDetail, fulfillmentGroups, match, intl, history: {push}} = props;
	const [displayComponent, setDisplayComponent] = useState(false);
	
	// If we're fulfilling a CYO sub, skip this landing page and head straight into fulfilling the first event instance
	useEffect(() => {
		// wait until the fulfillment groups have been computed
		if(fulfillmentGroups.length) {
			const cartItem = cartItems.find((ci: CartItem) => ci.allocId === match.params.allocationId);
			if(!!cartItem && CartItem.isChooseYourOwnSubscription(cartItem)) {
				const firstGroup = fulfillmentGroups.find((group: SubscriptionFulfillmentGroup) => {
					return !group.isGeneralAdmission && group.seatAssignments.length !== group.numberOfSelections;
				});
				if(!!firstGroup) {
					push(getSubFulfillmentInstanceRoute(cartItem.eiId, match.params.allocationId, firstGroup.id));
				} else {
					// if we've somehow gotten here without any PYOS events to fulfill, let's escape to the cart
					push(Paths.CART);
				}
			} else {
				// display the component once we've got the fulfillment groups and have confirmed we're not navigating away
				setDisplayComponent(true);
			}
		}
	},[cartItems, fulfillmentGroups, match.params.allocationId, push]);
	
	const {allocationName, priceLevelQuantities, totalQuantity} = useMemo(() => {
		let _allocationName = '';
		const _priceLevelQuantities: BasicStringKeyedMap<number> = {};
		let _totalQuantity = 0;

		// Retrieve the cart items for just the currently selected subscription instance and allocation
		const selectedCartItems = cartItems.filter((ci: CartItem) => ci.allocId === match.params.allocationId);
		if (selectedCartItems.length > 0) {
			_allocationName = selectedCartItems[0].allocName;
			_totalQuantity = selectedCartItems.length;

			selectedCartItems.forEach((ci: CartItem) => {
				// Initialize or increase the Price Level counter for this item
				if (!(ci.levelName in _priceLevelQuantities)) {
					_priceLevelQuantities[ci.levelName] = 0;
				}
				_priceLevelQuantities[ci.levelName]++;
			});
		}

		return {allocationName: _allocationName, priceLevelQuantities: _priceLevelQuantities, totalQuantity: _totalQuantity};
	}, [cartItems, match.params.allocationId]);

	const handleSelectSeats = useCallback(() => {
		// Navigate to the fulfillment page for the first PYOS venue in the subscription
		const pyosVenue = fulfillmentGroups.find((group: SubscriptionFulfillmentGroup) => !group.isGeneralAdmission && !!group.venueId);
		
		!!pyosVenue?.venueId
			? push(getSubFulfillmentVenueRoute(match.params.eventInstanceId, match.params.allocationId, pyosVenue.venueId))
			: push(Paths.CART);
	}, [push, fulfillmentGroups, match.params]);
	
	if(!displayComponent) {
		return null;
	}
	
	return (
		<div>
			{!!instanceDetail && (
				<div className="mb-4">
					<HTMLContent rawHtml={instanceDetail} />
				</div>
			)}
			<hr/>
			<QuantityTypeSummary priceLevelQuantityMap={priceLevelQuantities} allocationName={allocationName} intl={intl} rowClassName={"ml-3"}/>
			<hr className="mb-3" />
			<Row>
				<Col md={true}>
					{fulfillmentGroups.map((venueEventGroup: SubscriptionFulfillmentGroup) => {
						return (
							<Row className="mb-4 ml-1" key={venueEventGroup.id} data-testid="venue-group">
								<DisplayVenueEventGroup fulfillmentGroup={venueEventGroup} totalQuantity={totalQuantity} intl={intl} />
							</Row>
						)})
					}
				</Col>
				<Col md={true}>
					<Button color="primary" className="w-100" onClick={handleSelectSeats} disabled={fulfillmentGroups.length === 0}>
						{intl.formatMessage({id: "lbl_SelectSeats"})}
					</Button>
				</Col>
			</Row>
		</div>
	);
}

interface displayVenueEventGroupProps extends WrappedComponentProps{
	fulfillmentGroup: SubscriptionFulfillmentGroup,
	totalQuantity: number;
}

export const DisplayVenueEventGroup = (props: displayVenueEventGroupProps) => {
	const {fulfillmentGroup, totalQuantity, intl} = props;

	const instances = fulfillmentGroup.instances.map((instance: EventInstance) => new InstanceGroup(undefined, instance));
	const fullfillmentStatus = fulfillmentGroup.isGeneralAdmission
		? intl.formatMessage({id: "lbl_GeneralAdmission"})
		: fulfillmentGroup.seatAssignments.length !== totalQuantity ? intl.formatMessage({id: "lbl_NeedsSeatSelection"}) : '';

	return(
		<div className="w-100">
			<VenueInstanceDisplay venueName={fulfillmentGroup.venueName} instances={instances} intl={intl} />
			<div className="font-italic mb-1 ml-4">{fullfillmentStatus}</div>
			{fulfillmentGroup.seatAssignments.map((seat: string) => <div key={seat} className="ml-3">{seat}</div>)}
		</div>
	)
}
