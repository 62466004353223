export const enum ButtonColors {
	PRIMARY = "primary",
	SECONDARY = "secondary",
	SUCCESS = "success",
	INFO = "info",
	WARNING = "warning",
	DANGER = "danger",
	LINK = "link"
}

export class ButtonProps {
	public color: ButtonColors = ButtonColors.PRIMARY;
	public label: string | JSX.Element;
	public autoFocus: boolean;
	public onClick: () => any;
	constructor(color: ButtonColors, label: string | JSX.Element, autoFocus: boolean = false, onClick: () => any = () => {}) {
		this.color = color;
		this.label = label;
		this.autoFocus = autoFocus;
		this.onClick = onClick;
	}
}

