import * as React from "react";
import {TicketableEvent} from "../../models/ticketable-events/ticketable-event";
import {EventMain} from "../event-main";

interface EventHeroProps {
	event?: TicketableEvent;
}

export const EventHero: React.FunctionComponent<EventHeroProps> = (props) => {
	const {event} = props;
	if (!event) {
		return null;
	}
	return <EventMain eventName={event.name} eventDetail={event.detail} largeImagePath={event.largeImagePath} largeImageAltText={event.largeImageAltText}/>;
};
