import * as moment from "moment/min/moment-with-locales";
import * as hash from "object-hash";
import {AnyAction} from "redux";
import {OperationResult} from "../api/operation-result";
import {ActionTypes} from "../enums/action-types";
import {ApiActionNames} from "../enums/api-action-names";
import {ExceptionClasses} from "../enums/exception-classes";
import {TicketOrderStatus} from "../enums/ticket-order-status";
import {formatApplicationMessages} from "../helpers/utilities";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {CartItem} from "../models/cart-item";
import {ModalProps, ModalTypes} from "../models/public-ticket-app/modal-props";
import {PublicTicketApp} from "../models/public-ticket-app/public-ticket-app";
import {UserProfile} from "../models/public-ticket-app/user-profile";

export function ptApp(state: PublicTicketApp = new PublicTicketApp(), action: AnyAction): PublicTicketApp {
	switch (action.type) {
		case ActionTypes.PTS_SHOW_ALERT: {
			const {appMessages} = state;
			appMessages.set(hash.sha1(action.alertMessage), action.alertMessage);
			return {...state, appMessages};
		}
		case ActionTypes.PTS_CLEAR_ALL_MESSAGES: {
			return {...state, appMessages: new Map()};
		}
		case ActionTypes.PTS_CLEAR_MESSAGE: {
			const {appMessages} = state;
			appMessages.delete(action.messageKey);
			return {...state, appMessages};
		}
		case ActionTypes.PTS_SHOW_MODAL_ACTION: {
			return {...state, modalProps: action.modalProps};
		}
		case ActionTypes.PTS_HIDE_MODAL_ACTION: {
			return {...state, modalProps: new ModalProps()};
		}
		case ActionTypes.PTS_SHOW_LOGIN_FORM: {
			return {...state, showLoginForm: true};
		}
		case ActionTypes.PTS_HIDE_LOGIN_FORM: {
			return {...state, showLoginForm: false};
		}
		case ActionTypes.PTS_SHOW_RESET_PASSWORD_FORM: {
			return {...state, showResetPasswordForm: true};
		}
		case ActionTypes.PTS_HIDE_RESET_PASSWORD_FORM: {
			return {...state, showResetPasswordForm: false};
		}
		case ActionTypes.PTS_SET_SELECTED_EVENT: {
			return {...state, selectedEvent: action.selectedEvent};
		}
		case ActionTypes.PTS_SET_DEFAULT_CALENDAR_DATE: {
			return {...state, defaultCalendarDate: action.newDate};
		}
		case ActionTypes.PTS_SET_COMPLETED_STATE: {
			return {...state, submitResult: action.submitResult};
		}
		case ActionTypes.PTS_CLEAR_COMPLETED_STATE: {
			return {...state, submitResult: null};
		}
		case ActionTypes.PTS_UPDATE_CART_TIME_REMAINING: {
			if (!action.cartExpiration){
				return {...state, cartTimeRemaining: undefined};
			}
			// find the difference between now and the cart expiration
			const diff: number = moment(action.cartExpiration).diff(moment(Date.now()) - state.cartTimeDrift);
			return {...state, cartTimeRemaining: diff};
		}
		case ActionTypes.API_REQUEST: {
			const newState = {...state};
			// Add the action to the appropriate collection of blocking or non-blocking actions
			if (action.nonBlocking) {
				newState.nonBlockingActions[action.requestId] = action;
			} else {
				newState.blockingActions[action.requestId] = action;
			}
			switch (action.actionName) {
				case ApiActionNames.INSERT_CART_ITEMS:
				case ApiActionNames.INSERT_SUBSCRIPTION_CART_ITEMS:
				case ApiActionNames.DELETE_CART_ITEMS:
				case ApiActionNames.UPDATE_CART_ITEMS:
				case ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS: {
					newState.pendingItemIds = addPendingItemIds(action.params.cartItems, newState.pendingItemIds);
					newState.pendingSeatRequests = addPendingSeatRequests(action.params.cartItems, newState.pendingSeatRequests);
					break;
				}
				case ApiActionNames.FETCH_EVENT:
				case ApiActionNames.FETCH_EVENTS:
					newState.fetchingEvents = true;
					break;
				default:
					break;
			}
			newState.prevRequestIds[action.actionName] = action.requestId;
			return newState;
		}
		case ActionTypes.API_SUCCESS: {
			const newState = {...state};
			delete newState.blockingActions[action.requestId];
			delete newState.nonBlockingActions[action.requestId];
			switch (action.actionName) {
				case ApiActionNames.FETCH_GLOBAL_CONFIG:
					if (currentResponseMatchesActionName(action, newState)) {
						newState.config = action.data;
						newState.cartTimeDrift = moment(Date.now()).diff(action.data.currentDateTime);
					}
					break;
				case ApiActionNames.FETCH_EVENT:
					if (currentResponseMatchesActionName(action, newState)) {
						newState.selectedEvent = action.data;
						newState.fetchingEvents = false;
					}
					break;
				case ApiActionNames.FETCH_EVENTS:
					if (currentResponseMatchesActionName(action, newState)) {
						newState.eventList = action.data;
						newState.fetchingEvents = false;
					}
					break;
				case ApiActionNames.FETCH_EVENT_DESC:
					if (currentResponseMatchesActionName(action, newState)) {
						newState.selectedEI = action.data;
					}
					break;
				case ApiActionNames.FETCH_ITEM_FEE_DATA:
					if (currentResponseMatchesActionName(action, newState)) {
						newState.itemFeeData = action.data;
					}
					break;
				case ApiActionNames.FETCH_ORDER_FEE_DATA:
					if (currentResponseMatchesActionName(action, newState)){
						newState.orderFeeData = action.data;
					}
					break;
				// Deal with cart items that are no longer pending
				case ApiActionNames.INSERT_CART_ITEMS:
				case ApiActionNames.INSERT_SUBSCRIPTION_CART_ITEMS:
				case ApiActionNames.DELETE_CART_ITEMS:
				case ApiActionNames.UPDATE_CART_ITEMS:
				case ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS:
					newState.pendingItemIds = removePendingItemIds(action.params.cartItems, newState.pendingItemIds);
					newState.pendingSeatRequests = removePendingSeatRequests(action.params.cartItems, newState.pendingSeatRequests);
					break;
				case ApiActionNames.UPDATE_USER_PROFILE:
					const resultResponse = action.data as OperationResult<UserProfile>;
					const user = resultResponse.data;
					// only set state if there were no errors updating user profile
					if (resultResponse.applicationMessages == null || resultResponse.applicationMessages.length <= 0) {
						newState.config = { ...newState.config, userProfile: user};
					}
					break;
				default:
					break;
			}
			return newState;
		}
		case ActionTypes.API_FAILURE:
			const newState = {...state};
			// Remove the action from the blocking or non-blocking actions collections
			delete newState.blockingActions[action.requestId];
			delete newState.nonBlockingActions[action.requestId];
			// Deal with cart items that are no longer pending
			switch (action.actionName) {
				case ApiActionNames.FETCH_EVENT:
				case ApiActionNames.FETCH_EVENTS:
					newState.fetchingEvents = false;
					break;
				case ApiActionNames.INSERT_CART_ITEMS:
				case ApiActionNames.INSERT_SUBSCRIPTION_CART_ITEMS:
				case ApiActionNames.DELETE_CART_ITEMS:
				case ApiActionNames.UPDATE_CART_ITEMS:
				case ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS:
					newState.pendingItemIds = removePendingItemIds(action.params.cartItems, newState.pendingItemIds);
					newState.pendingSeatRequests = removePendingSeatRequests(action.params.cartItems, newState.pendingSeatRequests);
					break;
				default:
					break;
			}
			
			// The "silent" flag will prevent the application from displaying an error message.
			// This is done in cases where we're going to redirect to another page on error.
			// We don't want to flash an error dialog up on the screen right before redirecting, because that's annoying.
			if (!action.silent) {
				// Concatenate messages onto the ApplicationMessage array
				const partialState: Partial<PublicTicketApp> = processApiErrors(action);
				if (!!partialState.appMessages && partialState.appMessages.size > 0) {
					partialState.appMessages.forEach((v: ApplicationMessage, k: string) => newState.appMessages.set(k, v));
				}
				// Set the modalProps property if returned
				if (!!partialState.modalProps) {
					newState.modalProps = partialState.modalProps;
				}
			}
			return newState;
		default:
			return state;
	}
}

///
/// Helper Methods
///

/**
 * Returns a partial PublicTicketApp object with the appMessages and modalProps (optional) properties
 * @param action the action object containing the errors array
 * @returns Partial<PublicTicketApp>
 */
export const processApiErrors = (action: AnyAction): Partial<PublicTicketApp> => {
	const partialState: Partial<PublicTicketApp> = {
		appMessages: new Map<string, ApplicationMessage>()
	};
	
	action.errors.forEach((error: BasicStringKeyedMap<any>) => {
		
		switch (error.exceptionClass) {
			case ExceptionClasses.TO_MODIFIED:
			case ExceptionClasses.TO_NOT_FOUND:
			case ExceptionClasses.TO_FORBIDDEN: {
				let modalType: ModalTypes = ModalTypes.GENERIC;
				let args: BasicStringKeyedMap<any> = new BasicStringKeyedMap<any>();

				if (error.exceptionClass.includes(ExceptionClasses.TO_MODIFIED)) {
					// PMGR-8193 - Errors with the "TicketOrderModifiedException" class signify the stale cart error.
					// In this case, rather than just rendering an alert, we want to display a modal dialog with a "Reload Cart" button.
					modalType = ModalTypes.STALE_CART;
				} else if (error.exceptionClass.includes(ExceptionClasses.TO_NOT_FOUND)) {
					// PMGR-8217 - Errors with the "TicketOrderNotFoundException" class likely signify an expired/deleted cart.
					// In this case, display a modal dialog with a "New Cart" button.
					modalType = ModalTypes.EXPIRED_CART;
				} else if (error.exceptionClass.includes(ExceptionClasses.TO_FORBIDDEN)) {
					const targetStatuses = [TicketOrderStatus.COMPLETE, TicketOrderStatus.TO_BE_QUALIFIED];

					// If the error's message ID is not the expected one, do not construct the modal.
					if (error.message.msgId !== 'msg_disallowed_status') {
						break;
					}

					args = { 'orderName' : error.messages[0].msgArgs['orderName'] };
					if (targetStatuses.includes(error.messages[0].msgArgs['orderStatus'])) {
						// PMGR-10086 - Errors with the "TicketOrderForbiddenException" class, the proper message ID, and an expected status signify a completed cart.
						modalType = ModalTypes.COMPLETED_CART;
					} else {
						// PMGR-10086 - Other errors signify a cart in some unknown error state.
						modalType = ModalTypes.UNKNOWN_CART;
					}
				}

				const modalProps = new ModalProps();
				modalProps.isOpen = true;
				modalProps.type = modalType;
				modalProps.args = args;
				partialState.modalProps = modalProps;

				return;
			}
		}
		
		// Convert other errors into ApplicationMessage objects
		formatApplicationMessages(error).forEach((message:ApplicationMessage) => {
			if (!!partialState.appMessages) {
				partialState.appMessages.set(hash.sha1(message), message);
			}
		});
		
	});
	return partialState;
};

const currentResponseMatchesActionName = (action: AnyAction, newState: any): boolean => {
	return action.requestId === newState.prevRequestIds[action.actionName];
};

/**
 * Returns a new copy of the pendingItemIds object with the ids of the passed in cart items added
 *
 * @param {CartItem[]} cartItems
 * @param pendingItemIds map of pending item ids
 * @returns {any}
 */
const addPendingItemIds = (cartItems: CartItem[], pendingItemIds: BasicStringKeyedMap<string>): BasicStringKeyedMap<string> => {
	const newPendingItemIds = {...pendingItemIds};
	cartItems.forEach((cartItem) => {
		if (cartItem.id) {
			newPendingItemIds[cartItem.id] = cartItem.id;
		}
	});
	return newPendingItemIds;
};

/**
 * Returns a new copy of the pendingSeatRequests object after adding the ids of any seat instances or ticket price levels associated with the passed in cart items
 * @param cartItems
 * @param pendingSeatRequests
 * @returns {{}}
 */
const addPendingSeatRequests = (cartItems: CartItem[], pendingSeatRequests: BasicStringKeyedMap<string>): BasicStringKeyedMap<string> => {
	const newPendingSeatRequests = {...pendingSeatRequests};
	cartItems.forEach((cartItem: any) => {
		newPendingSeatRequests[cartItem.levelId] = cartItem.levelId;
		if (cartItem.seatId) {
			newPendingSeatRequests[cartItem.seatId] = cartItem.seatId;
		}
	});
	return newPendingSeatRequests;
};

/**
 * Returns a new copy of pendingItemIds with the ids of the passed in cart items removed
 * @param {CartItem[]} cartItems
 * @param pendingItemIds
 * @returns {{}}
 */
const removePendingItemIds = (cartItems: CartItem[], pendingItemIds: BasicStringKeyedMap<string>): BasicStringKeyedMap<string> =>{
	const newPendingItemIds = {...pendingItemIds};
	cartItems.forEach((cartItem) => {
		delete newPendingItemIds[cartItem.id];
	});
	return newPendingItemIds;
};

/**
 * Returns a new copy of the pendingSeatRequests object after removing the ids of any seat instances or ticket price levels associated with the passed in cart items
 * @param cartItems
 * @param pendingSeatRequests
 * @returns {{}}
 */
const removePendingSeatRequests = (cartItems: CartItem[], pendingSeatRequests: BasicStringKeyedMap<string>): BasicStringKeyedMap<string> => {
	const newPendingSeatRequests = {...pendingSeatRequests};
	cartItems.forEach((cartItem: any) => {
		delete newPendingSeatRequests[cartItem.levelId];
		delete newPendingSeatRequests[cartItem.seatId];
	});
	return newPendingSeatRequests;
};
