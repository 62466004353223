import * as React from "react";
import { FormattedMessage } from "react-intl";

interface DiscountListProps {
	discountsApplied: string[];
}

/**
 * DiscountsList - A list of the discounts applied. 
 * May be used for discounts applied to an order or to a single item in an order.
 * 
 * @param discountsApplied - Array of the discounts applied, by user-friendly name.
 */
export const DiscountList: React.FC<DiscountListProps> = ({ discountsApplied }) => (
	<span>
		<span className="mr-1">
			<FormattedMessage id="lbl_plural_DiscountApplied" values={{ discountCount: discountsApplied.length }} />
		</span>
		<span className="text-info text-uppercase">{discountsApplied.join(', ')}</span>
	</span>
);