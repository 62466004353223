import {ActionTypes} from "../enums/action-types";
import {ApiActionNames} from "../enums/api-action-names";
import {TicketOrderStatus} from "../enums/ticket-order-status";
import {Cart} from "../models/cart";
import {CartItem} from "../models/cart-item";
import {EventDescriptor} from "../models/event-descriptor/event-descriptor";
import {RootState} from "../reducers";

export const analyticsMiddleware = (store: any) => (next: any) => (action: any) => {
	const state: RootState = store.getState();
	const {analytics, ptApp:{config:{currencyCode}}} = state;
	switch (action.type) {
		
		case ActionTypes.ANALYTICS_APP_MOUNT: {
			gtmPush({"event": "appStarted"});
			break;
		}
		
		case ActionTypes.ANALYTICS_PAGE_VIEW: {
			const {title, url} = action;
			// If the title or the url have changed, then set the document title and fire the "pageView" trigger
			if (title !== analytics.title || url !== analytics.url) {
				document.title = title;
				// send page view to Google Tag Manager
				gtmPush({"event": "pageView", "url": url});
				// send page view to Google Analytics
				ga4SendEvent('page_view', {	page_title: title, page_location: url });

				// if an Event Instance page is being viewed, then also send a view item event to Google Analytics
				const urlParams = url.split('/');
				const instanceIndex = urlParams.findIndex((param: string) => param === 'instances');
				if(instanceIndex > -1) {
					const eiId = urlParams[instanceIndex + 1];
					const eventDescriptor = state.eventDescriptorCache.descriptors[eiId]?.descriptor;
					if(!!eventDescriptor) {
						ga4SendEvent('view_item', getGA4ViewItemPayload(eventDescriptor));
					}
				}
			}
			break;
		}

		// Sends event to Google Analytics when items are added or removed from the cart
		case ActionTypes.API_SUCCESS: {
			if ([ApiActionNames.INSERT_CART_ITEMS,ApiActionNames.INSERT_SUBSCRIPTION_CART_ITEMS,ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS].includes(action.actionName)) {
				const existingItemIds = state.cart.cartItems.map((cartItem: CartItem) => cartItem.id);
				const addedItems = action.data.cartItems.filter((cartItem: CartItem) => !existingItemIds.includes(cartItem.id));
				ga4SendEvent('add_to_cart', getGA4AddRemoveItemPayload(addedItems, currencyCode));
			} else if (action.actionName === ApiActionNames.DELETE_CART_ITEMS) {
				const deletedItemIds = action.params.cartItems.map((cartItem: CartItem) => cartItem.id);
				const deletedItems = state.cart.cartItems.filter((cartItem: CartItem) => deletedItemIds.includes(cartItem.id));
				ga4SendEvent('remove_from_cart', getGA4AddRemoveItemPayload(deletedItems, currencyCode));
			}
			break;
		}
		
		// This is a hack that allows you to "simulate" a purchase by trying to apply a discount code of "ANALYTICS-PURCHASE".
		// It will result in an API failure because there is no such discount code.
		case ActionTypes.API_FAILURE: {
			if (action.actionName === ApiActionNames.APPLY_DISCOUNT && action.params.discountCode === "ANALYTICS-PURCHASE") {
				const {cart} = state;
				// Send purchase event to Google Analytics
				ga4SendEvent("purchase", getGA4PurchasePayload(cart, currencyCode));
				// Push the GTM purchase data onto the data layer and trigger a pageView so it gets tracked since this hack
				// doesn't take the user to a new page like an actual purchase does
				gtmPush(getGTMPurchasePayload(cart));
				gtmPush({"event": "pageView", "url": action.url});
				// This fires the FB Pixel "Purchase" event
				fbqTrack("Purchase", getFBPurchasePayload(cart, currencyCode));
			}
			break;
		}
		
		// For a completed order, send analytics purchase events to Google Analytics, GTM, and Facebook
		case ActionTypes.PTS_SET_COMPLETED_STATE: {
			const {submitResult} = action;
			const {cart} = submitResult;
			
			// Check the orderStatus to make sure it is one of the values that indicates a known successful purchase.
			// This excludes PEX, since we don't know whether it was successful or not. Include CEX, because money changed hands.
			if (!!cart && (cart.orderStatus === TicketOrderStatus.COMPLETE || cart.orderStatus === TicketOrderStatus.TO_BE_QUALIFIED || cart.orderStatus === TicketOrderStatus.CONFIRMATION_EXCEPTION)) {
				// Send purchase event to Google Analytics
				ga4SendEvent("purchase", getGA4PurchasePayload(cart, currencyCode));
				// Push the GTM purchase data onto the data layer. It won't actually get tracked until the next "pageview" event fires
				gtmPush(getGTMPurchasePayload(cart));
				// Fire FB Pixel "Purchase" event
				fbqTrack("Purchase", getFBPurchasePayload(cart, currencyCode));
			}
			break;
		}
	}
	return next(action);
};

/**
 * Builds the payload for sending 'purchase' events to Google Analytics
 * @param cart the cart that was purchased
 * @param currencyCode
 */
const getGA4PurchasePayload = (cart: Cart, currencyCode: string = "USD"): object => {
	return {
		"transaction_id": cart.id,
		"affiliation": `${window.PublicTicketApp.orgName} - Public Ticket Site`,
		"value": cart.orderTotal,
		"tax": cart.salesTaxTotal,
		"shipping": cart.deliveryFee,
		"currency": currencyCode,
		"items": constructGA4ItemArray(cart.cartItems, currencyCode)
	}
}

/**
 * Builds the payload for sending either an 'add_to_cart' or a 'remove_from_cart' event to Google Analytics
 * @param cartItems the cart items being added or removed from the cart
 * @param currencyCode
 */
const getGA4AddRemoveItemPayload = (cartItems: CartItem[], currencyCode: string = "USD"): object => {
	return {
		"currency": currencyCode,
		"value": cartItems.reduce((total: number, cartItem: CartItem) => total + cartItem.itemTotal, 0),
		"items": constructGA4ItemArray(cartItems, currencyCode)
	}
}

/**
 * Builds the payload for sending a 'view_item' event to Google Analytics
 * @param eventDescriptor the event descriptor for the event instance being viewed
 */
const getGA4ViewItemPayload = (eventDescriptor: EventDescriptor) => {
	return {
		"items": [
			{
				"item_id": eventDescriptor.id,
				"item_name": `${eventDescriptor.teName} - ${eventDescriptor.name}`,
				"item_category": eventDescriptor.teType
			}
		]
	}
}

/**
 * Translates an array of cart items into to an array of Google Analytics items
 * @param cartItems
 * @param currencyCode
 */
const constructGA4ItemArray = (cartItems: CartItem[], currencyCode: string = "USD") => {
	return cartItems.map((cartItem: CartItem) => {
		return {
			"item_id": cartItem.eiId,
			"item_name": `${cartItem.teName} - ${cartItem.eiName}`,
			"coupon": cartItem.disName,
			"currency": currencyCode,
			"discount": cartItem.disAmt,
			"item_category": cartItem.ticketType,
			"item_variant": `${cartItem.allocName} - ${cartItem.levelName}`,
			"price": cartItem.itemTotal,
			"quantity": cartItem.qty
		};
	})
}

/**
 * This builds and returns the GTM Enhanced Ecommerce purchase payload.
 * References:
 * https://developers.google.com/tag-manager/enhanced-ecommerce
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
 * @param cart
 *
 * @return the ecommerce purchase payload object
 */
const getGTMPurchasePayload = (cart: Cart): object => {
	// Build the "products" array
	const products = cart.cartItems.map((cartItem: CartItem) => {
		return {
			"name": `${cartItem.teName} - ${cartItem.eiName}`,
			"id": cartItem.eiId,
			"price": cartItem.itemTotal,
			"category": cartItem.ticketType,
			"variant": `${cartItem.allocName} - ${cartItem.levelName}`,
			"quantity": cartItem.qty,
			"coupon": cartItem.disName
		};
	});
	return {
		"event": "purchase",
		"ecommerce": {
			"purchase": {
				"actionField": {
					"id": cart.name,
					"affiliation": `${window.PublicTicketApp.orgName} - Public Ticket Site`,
					"revenue": cart.orderTotal,
					"tax": cart.salesTaxTotal,
					"shipping": cart.deliveryFee
				},
				"products": products
			}
		}
	};
};

/**
 * Builds the purchase payload for the Facebook Pixel
 * @param cart
 * @param currencyCode
 */
const getFBPurchasePayload = (cart: Cart, currencyCode: string = "USD"): object => {
	// Build the "contents" array
	const contents = cart.cartItems.map((cartItem: CartItem) => {
		return {
			"id": cartItem.eiId,
			// Make sure we report the unit price
			"item_price": cartItem.itemTotal / cartItem.qty,
			"quantity": cartItem.qty
		};
	});
	return {
		"value": cart.orderTotal,
		"currency": currencyCode,
		"contents": contents,
		"content_type": "product"
	};
};

/**
 * Sends the specified event and payload to Google Analytics if the gtag object is defined
 * @param eventType currently supported events: purchase, add_to_cart, remove_from_cart
 * @param payload
 */
const ga4SendEvent = (eventType: string, payload: any):void => {
	if(!!window.gtag) {
		window.gtag('event', eventType, payload);
	}
}

/**
 * Pushes the specified payload to the Google Tag Manager data layer if defined
 * @param payload
 */
const gtmPush = (payload: any): void => {
	if (!!window.gtmDataLayer) {
		window.gtmDataLayer.push(payload);
	}
};

/**
 * Fires the specified Facebook tracking event with the specified payload the fbq object is defined
 * @param event
 * @param payload
 */
const fbqTrack = (event: string, payload: any): void => {
	if (!!window.fbq) {
		window.fbq("track", event, payload);
	}
};