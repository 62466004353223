import Cookies from "js-cookie";
import * as React from "react";
import {FormattedMessage, WrappedComponentProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Route, Switch} from "react-router";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {AnyAction} from "redux";
import {ApiActions} from "../actions/api-actions";
import {CartActions} from "../actions/cart-actions";
import {PublicTicketAppActions} from "../actions/public-ticket-app-actions";
import {Paths} from "../enums/paths";
import {ButtonProps} from "../models/public-ticket-app/button-props";
import {ModalProps, ModalTypes} from "../models/public-ticket-app/modal-props";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {RootState} from "../reducers";
import {ThunkDispatch} from "redux-thunk";

interface ModalActionProps extends ModalActionDispatchToProps, ModalStateToProps, WrappedComponentProps {}

interface ModalActionDispatchToProps {
	ensureCart: (createIfNecessary?: boolean) => Promise<any>;
	fetchPendingRenewal: (ticketOrderId: string) => Promise<any>;
	hideModal: () => void;
}

interface ModalStateToProps {
	modalProps: ModalProps;
	config: PublicTicketAppConfig;
}

export class ModalAction extends React.Component<ModalActionProps> {
	public render() {
		const clearCartAndReload = () => {
			Cookies.remove("cartId");
			window.location.reload();
			this.onClose();
		}

		const {ensureCart, fetchPendingRenewal, intl, modalProps: {bodyContent, buttonProps, headerContent, isOpen, type, args}, config} = this.props;
		switch (type) {
			case ModalTypes.EXPIRED_CART:
			case ModalTypes.COMPLETED_CART:
			case ModalTypes.UNKNOWN_CART: {
				const onReload = clearCartAndReload;

				let messageValues: {} = {};
				let messageHeaderId: string = '';
				let messageBodyId: string = '';
				let buttonId: string = '';
				switch (type) {
					case ModalTypes.EXPIRED_CART:
						messageValues = { buttonLabel: intl.formatMessage({id: 'lbl_button_Reload'}) };
						buttonId = 'lbl_button_Reload';
						messageHeaderId = 'msg_action_heading_expired_cart';
						messageBodyId = 'msg_action_body_expired_cart';

						break;
					case ModalTypes.COMPLETED_CART:
						messageValues = {
							errorContactInfo: config.errorContactInfo || intl.formatMessage({id: 'lbl_ErrorContactInfoDefault'}),
							ticketOrderName: args['orderName']
						};
						buttonId = 'lbl_button_NewOrder';
						messageHeaderId = 'msg_action_heading_completed_cart';
						messageBodyId = 'msg_action_body_completed_cart'

						break;
					case ModalTypes.UNKNOWN_CART:
						messageValues = {
							errorContactInfo: config.errorContactInfo || intl.formatMessage({id: 'lbl_ErrorContactInfoDefault'}),
							ticketOrderName: args['orderName']
						};
						buttonId = 'lbl_button_NewOrder';
						messageHeaderId = 'msg_action_heading_unknown_cart';
						messageBodyId = 'msg_action_body_unknown_cart'

						break;
				}

				return (
					<div>
						<Modal isOpen={isOpen} className="text-dark" autoFocus={false} aria-modal={true} aria-labelledby="CartReloadLabel" aria-describedby="CartReloadDescription">
							{/*
							PMGR-8217 - Hide the "close" button on this dialog. We want to force them to click the
							Reload button so we don't get an ugly "forbidden" error if they try to add seats again.
							*/}
							<ModalHeader>
								<div id="CartReloadLabel">
									<FormattedMessage id={messageHeaderId} />
								</div>
							</ModalHeader>
							<ModalBody>
								<div id="CartReloadDescription">
									<FormattedMessage id={messageBodyId} values={messageValues}/>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button onClick={onReload} color="primary" autoFocus={true}>
									<FormattedMessage id={buttonId}/>
								</Button>
							</ModalFooter>
						</Modal>
					</div>
				);
			}
			
			case ModalTypes.STALE_CART: {
				const onReload = (ticketOrderId?: string) => {
					if (ticketOrderId) {
						fetchPendingRenewal(ticketOrderId);
					} else {
						ensureCart();
					}
					this.onClose();
				};

				return (
					<div>
						<Modal isOpen={isOpen} className="text-dark" autoFocus={false} aria-modal={true} aria-labelledby="StaleCartLabel" aria-describedby="StaleCartDescription">
							<ModalHeader>
								<div id="StaleCartLabel">
									<FormattedMessage id="msg_action_heading_stale_cart" />
								</div>
							</ModalHeader>
							<ModalBody>
								<div id="StaleCartDescription">
									<FormattedMessage id="msg_action_body_stale_cart" values={{buttonLabel: intl.formatMessage({id: "lbl_button_ReloadCart"})}}/>
								</div>
							</ModalBody>
							<ModalFooter>

								<Switch>
									<Route path={Paths.PORTAL__PENDING_RENEWAL} render={(propsFromRoute) => {
										return (
											<Button onClick={() => onReload(propsFromRoute.match.params.ticketOrderId)} color="primary" autoFocus={true}>
												<FormattedMessage id="lbl_button_ReloadCart"/>
											</Button>
										);
									}} />

									<Route render={() => {
										return (
											<Button onClick={() => onReload()} color="primary" autoFocus={true}>
												<FormattedMessage id="lbl_button_ReloadCart"/>
											</Button>
										);
									}} />
								</Switch>
							</ModalFooter>
						</Modal>
					</div>
				);
			}
			
			default: {
				const footerButtons: JSX.Element[] = buttonProps.map((props: ButtonProps, index: number) => {
					const {color, label, onClick, autoFocus} = props;
					return <Button autoFocus={autoFocus} key={"button-" + index} color={color} onClick={() => this.buttonClick(onClick)}>{label}</Button>;
				});
				return (
					<div>
						<Modal isOpen={isOpen} toggle={this.onClose} className="text-dark" keyboard={true} aria-modal="true" aria-labelledby="ModalActionLabel" aria-describedby="ModalActionDescription" autoFocus={false}>
							<ModalHeader toggle={this.onClose}>
								<div id="ModalActionLabel">{headerContent}</div>
							</ModalHeader>
							<ModalBody>
								<div id="ModalActionDescription">{bodyContent}</div>
							</ModalBody>
							{footerButtons.length > 0 && <ModalFooter>{footerButtons}</ModalFooter>}
						</Modal>
					</div>
				);
			}
		}
	}
	
	private onClose = () => {
		const {hideModal, modalProps} = this.props;
		hideModal();
		modalProps.onClose();
	}
	
	private buttonClick = (callback: () => any) => {
		this.props.hideModal();
		callback();
	}
}

function mapStateToProps(state: RootState): ModalStateToProps {
	return {
		modalProps: state.ptApp.modalProps,
		config: state.ptApp.config
	};
}

function mapDispatchToProps(dispatch: ThunkDispatch<RootState, void, AnyAction>): ModalActionDispatchToProps {
	return {
		ensureCart: (createIfNecessary = false): Promise<any> => {
			return dispatch(CartActions.ensureCart(createIfNecessary));
		},
		fetchPendingRenewal: (ticketOrderId: string): Promise<any> => {
			return dispatch(ApiActions.fetchPendingRenewal(ticketOrderId));
		},
		hideModal: (): void => {
			dispatch(PublicTicketAppActions.hideModalAction());
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModalAction));
