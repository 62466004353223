import {FC} from "react";
import {FormattedNumber} from 'react-intl';
import {Col, Row} from "reactstrap";

///
/// Interfaces
///

interface NonTicketItemProps {
	// examples: Donation, Tax, Delivery fee,
	name: string;

	// One of:
	// PatronTicket__ShippingFee__c
	// PatronTicket__DonationAmount__c
	amount: number;

	// Could be:
	// PatronTicket__DeliveryMethod__c
	// "Thank you" if donation
	description?: string;

	// used by React Intl to format currency.
	currencyCode: string;
}

/**
 * Displays non-ticket items, such as donations, delivery fees, and tax.
 */
export const NonTicketItem: FC<NonTicketItemProps> = (props) => {
	const {
		name,
		amount,
		description,
		currencyCode,
	} = props;

	const isItemValid = !name || isNaN(amount);

	if (isItemValid) {
		return null;
	}

	return (
		<div className="nonTicketItem">
			<Row>
				<Col>
					<span className="itemName mr-1">{name}</span>
					<p className="small">{description}</p>
				</Col>
				<Col className="text-right">
					<FormattedNumber
						value={amount}
						style="currency"
						currency={currencyCode}
						currencyDisplay="symbol"
					/>
				</Col>
			</Row>
		</div>
	);
};