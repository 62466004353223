import * as React from 'react';
import * as Fa from 'react-icons/fa'
import { FormattedMessage } from 'react-intl';
import './icon.css';

interface IconProps {
	className?: string;
	size?: number;
	intlId?: string;
}

function wrap(RawIconComponent: any)
{
	return class extends React.Component<IconProps> {
		
		public render()
		{
			const {className = 'text-primary', size = 30, intlId} = this.props;
			return (
				<span>
					{!!intlId && (
						<span className="sr-only">
							<FormattedMessage id={intlId} />
						</span>
					)}
					<RawIconComponent
						size={size}
						className={className}
						style={{verticalAlign: "text-bottom"}}
					/>
				</span>
			);
		}
	};
}

// Base icons
export const CalendarIcon = wrap(Fa.FaRegCalendarAlt);
export const ListIcon = wrap(Fa.FaList);
export const CaretIcon = wrap(Fa.FaChevronDown);
export const EditIcon = wrap(Fa.FaEdit);
export const CloseIcon = wrap(Fa.FaTimesCircle);
export const WarningIcon = wrap(Fa.FaExclamationCircle);
export const TwitterIcon = wrap(Fa.FaTwitter);
export const FacebookIcon = wrap(Fa.FaFacebook);
export const PrintIcon = wrap(Fa.FaPrint);
export const TicketIcon = wrap(Fa.FaTicketAlt);
const EnvelopeIcon = wrap(Fa.FaEnvelope);
const FaLockIcon = wrap(Fa.FaLock);
const FaPhoneIcon = wrap(Fa.FaPhone);
const ChevronLeftIcon = wrap(Fa.FaChevronCircleLeft);
const PlusIcon = wrap(Fa.FaPlusCircle);
const CircleNotchIcon = wrap(Fa.FaCircleNotch);
const FaExternalLinkIcon = wrap(Fa.FaExternalLinkAlt);
const FaClockIcon = wrap(Fa.FaRegClock);

// "Elaborate" icons. 
// These are for specific cases and come with a11y support and Intl baked in. (And they have nice semantic names.)
export const LoadingIcon = (props: { size?: number }) => <CircleNotchIcon className="text-info pts-icon--spin" intlId="lbl_Busy" size={props.size || 16} />;
export const AddToCartIcon = () => <PlusIcon intlId="lbl_AddToCart" size={16} />;
export const RemoveItemIcon = () => <CloseIcon className="pts-icon--remove" intlId="lbl_Remove" size={16} />;
export const CloseDialogueIcon = () => <CloseIcon className="text-light pts-icon--close-dialogue" intlId="lbl_Close" size={20} />;
export const CloseDialogueIconDark = () => <CloseIcon className="text-dark pts-icon--close-dialogue" intlId="lbl_Close" size={20} />;
export const BackIcon = () => <ChevronLeftIcon intlId="lbl_Back" size={16} />;
export const EmailIcon = () => <EnvelopeIcon intlId="lbl_Email" size={16} />;
export const PhoneIcon = () => <FaPhoneIcon intlId="lbl_Phone" size={16} />;
export const SecurePaymentIcon = () => <FaLockIcon intlId="lbl_SecurePayment" className="text-warning"/>;
export const FieldGroupErrorIcon = () => <WarningIcon intlId="lbl_Error" size={16} className="text-danger mr-1" />;
export const SmallExternalLinkIcon = () => <FaExternalLinkIcon intlId="lbl_ExternalLink" size={12} />;
export const TimerIcon = () => <FaClockIcon className="" intlId="lbl_Clock" size={16} />;

