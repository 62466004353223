import * as React from "react";
import {FormattedMessage} from "react-intl";
import {IntlShape} from "react-intl";

interface EmptyCartProps {
	intl: IntlShape;
}

export const EmptyCart: React.FunctionComponent<EmptyCartProps> = ({intl}) => {
	const linkName: string = intl.formatMessage({id: "pmgr_term_Events"});
	return (
		<div>
			<h4><FormattedMessage id={"msg_cart_empty"} values={{linkName}} /></h4>
		</div>
	);
};