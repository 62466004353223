import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {inventoryService} from "../index";
import {EventDescriptorCache} from "../models/event-descriptor-cache/event-descriptor-cache";
import {EventDescriptor} from "../models/event-descriptor/event-descriptor";

// This file contains custom React hooks

/**
 * Fetches the event descriptor for the specified Event Instance.
 *
 * @param eiIdString - This may be a single EI Id, or a comma separated string of multiple EI Ids when fetching a
 * fulfillment Event Descriptor.
 * 
 * @returns The Event Descriptor
 */
export const useEventDescriptor = (eiIdString: string) => {
	const eventDescriptorCache = useSelector((state: any): EventDescriptorCache => state.eventDescriptorCache);
	const [eventDescriptor, setEventDescriptor] = useState<EventDescriptor>();
	
	// This effect fetches the specified fulfillment event descriptor
	useEffect(() => {
		if (eiIdString.length > 0) {
			if (eiIdString in eventDescriptorCache.descriptors) {
				const _eventDescriptor = inventoryService.getEventDescriptor(eiIdString);
				if (_eventDescriptor) {
					setEventDescriptor(_eventDescriptor);
				}
			} else {
				inventoryService.fetchEventDescriptor(eiIdString, true);
			}
		}
		else {
			// If no string was passed in, clear out the event descriptor state
			setEventDescriptor(undefined);
		}
	}, [eiIdString, eventDescriptorCache.descriptors]);

	return eventDescriptor;
};

/**
 * Fetches the fulfillment event descriptor for the specified Event Instance.
 *
 * @param eiIdString - This may be a single EI Id, or a comma separated string of multiple EI Ids when fetching a
 * fulfillment Event Descriptor.
 * @param cartId - The id of the cart that contains the SBSLs being fulfilled
 * @param sbslIds - The ids of the SBSL's currently being fulfilled
 * @param modstamp - The cart's last modification timestamp
 *
 * @returns The Event Descriptor
 */
export const useFulfillmentEventDescriptor = (eiIdString: string, cartId: string, sbslIds: string[], modstamp: number | null = null) => {
	const eventDescriptorCache = useSelector((state: any): EventDescriptorCache => state.eventDescriptorCache);
	const [eventDescriptor, setEventDescriptor] = useState<EventDescriptor>();
	
	useEffect(() => {
		// Delete any existing event descriptors for this EI string to make sure we get a fresh copy of 
		// to avoid accidentally getting the wrong one from the cache (ex. we need to fetch fulfillment EDs for
		// different specified allocations, but the EI string in the cache will look the same in both cases)
		inventoryService.deleteEventDescriptor(eiIdString);
		
		// Return a cleanup function that does the same thing on unmount
		return () => inventoryService.deleteEventDescriptor(eiIdString);

	}, [eiIdString, modstamp]);
	
	// This effect fetches the specified fulfillment event descriptor
	useEffect(() => {
		if (eiIdString.length && cartId.length && sbslIds.length) {
			if (eiIdString in eventDescriptorCache.descriptors) {
				const _eventDescriptor = inventoryService.getEventDescriptor(eiIdString);
				if (_eventDescriptor) {
					setEventDescriptor(_eventDescriptor);
				}
			} else {
				inventoryService.fetchEventDescriptor(eiIdString, true, cartId, sbslIds, modstamp);
			}
		}
		else {
			// If any parameters are missing, clear out the event descriptor state
			setEventDescriptor(undefined);
		}
	}, [cartId, eiIdString, eventDescriptorCache.descriptors, modstamp, sbslIds]);

	return eventDescriptor;
};

/**
 * Tracks previous state of a value.
 * Source: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * @param value - Props, state, or any other calculated value.
 *
 * @returns Value from the previous render.
 */
export const usePrevious = <T>(value: T): T | undefined => {
	const ref = useRef<T>();
	
	useEffect(() => {
		ref.current = value;
	});
	
	return ref.current;
}