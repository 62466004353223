import * as React from 'react';
import {FormattedMessage} from "react-intl";
import posed from 'react-pose';

export const enum MessageTypes {
	CHECKING_BALANCE = "checkingBal",
	SUBMITTING = "submitting",
	WAITING = "waiting"
}

// The animation behavior is defined here.
const AnimatedContent = posed.div({
	closed: { opacity: 0, delay: 200 },
	open: { opacity: 1 },
});

const messageContent = (type?: MessageTypes) => {
	let msgElement;
	switch (type) {
		case MessageTypes.CHECKING_BALANCE:
			msgElement = <FormattedMessage id="lbl_CheckingYourBalance"/>;
			break;
		case MessageTypes.SUBMITTING:
			msgElement = <FormattedMessage id="lbl_SubmittingYourOrder"/>;
			break;
		case MessageTypes.WAITING:
		default:
			msgElement = <FormattedMessage id="lbl_Waiting"/>;
	}
	return (
		<p className="bg-info p-1 text-center">
			<small>
				{msgElement}
			</small>
		</p>
	);
};

interface WaitingMessageProps {
	isOpen: boolean;
	type?: MessageTypes;
}

/**
 * WaitingMessage - A message to be displayed when app is waiting on an action to complete.
 * 
 * @param isOpen - Set to true to display the message.
 * @param type - The type of waiting message to display.
 */
export const WaitingMessage: React.FunctionComponent<WaitingMessageProps> = (props) => (
	<AnimatedContent className="w-100" pose={props.isOpen ? 'open' : 'closed'}>
		{messageContent(props.type)}
	</AnimatedContent>
);
