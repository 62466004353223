import * as React from "react";
import {sanitizeContent} from "../../helpers/sanitize-content";

interface RawHTMLContentProps {
	rawHtml: string;
	inline?: boolean;
}

/**
 * Renders raw HTML, wrapped in either a span or a div, depending on the value of the "inline" property
 * @param rawHtml The raw HTML to be rendered. The passed in markup will be sanitized according to strict rules.
 * @param inline If true, the raw HTML will be rendered as the inner HTML of a span, otherwise a div.
 */
export const SimpleHTMLContent: React.FunctionComponent<RawHTMLContentProps> = ({rawHtml, inline = true}) => {
	const sanitizedHtml = sanitizeContent({rawHtml});
	// Returns unescaped HTML, wrapped in either a block element (div) or an inline element (span), depending on the value of the inline field
	return inline ? <span dangerouslySetInnerHTML={{__html: sanitizedHtml}}/> : <div dangerouslySetInnerHTML={{__html: sanitizedHtml}}/>;
};

