/**
 * Holds the values that a <Link>, Href, or button might need to work
 */

export const enum LinkStates {
	ACTIVE = "active",
	DISABLED = "disabled",
	CURRENT = "current",
}

export class LinkItem {
	public label: string;
	public path: string;
	public handleClick?: () => void;
	public linkState?: LinkStates;
}