import {History} from "history";
import * as React from "react";
import {WrappedComponentProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AnalyticsActions} from "../../actions/analytics-actions";
import {ApiActions} from "../../actions/api-actions";
import {CartActions} from "../../actions/cart-actions";
import {PublicTicketAppActions} from "../../actions/public-ticket-app-actions";
import {BuyerInfoForm} from "../../components/buyer-info-form";
import {DeliveryForm} from "../../components/delivery-form";
import {DiscountForm} from "../../components/discount-form";
import {DonationForm} from "../../components/donation-form";
import {DonationMessage} from "../../components/donation-message";
import {PaymentForm} from "../../components/payment/payment-form";
import {TicketOrderPropsExcludingInjectedProps, TicketOrderWithIntl} from "../../components/ticket-order/ticket-order";
import {Paths} from "../../enums/paths";
import {Severities} from "../../enums/severities";
import {getNextPath, getPreviousPath} from "../../helpers/routing";
import {getCartPageTitle} from "../../helpers/utilities";
import {Layout, layout} from "../../hoc/layout";
import {Analytics} from "../../models/analytics";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart as CartModel} from "../../models/cart";
import {Portal} from "../../models/portal/portal";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {RootState} from "../../reducers";

///
/// Interfaces
///

/**
 * All properties available within this component
 */
interface PortalPendingRenewalProps extends PortalPendingRenewalPropsExcludingInjectedProps, PortalPendingRenewalDispatchToProps, PortalPendingRenewalStateToProps, WrappedComponentProps, RouteComponentProps<any>{}

/**
 * All properties that should be defined when using the component exported with injections.
 */
interface PortalPendingRenewalPropsExcludingInjectedProps {}

interface PortalPendingRenewalDispatchToProps {
	applyDiscount: (cartId: string, discountCode: string, modstamp?: number | null) => Promise<any>;
	clearAllMessages: () => void;
	fetchGiftCardBalance: (gcNumber: string, captchaToken: string) => Promise<any>;
	fetchGiftCardBalanceByPaymentMethodId: (paymentMethodId: string) => Promise<any>;
	fetchItemFeeData: (cartId: string) => Promise<any>;
	fetchOrderFeeData: (cartId: string) => Promise<any>;
	fetchPendingRenewal: (ticketOrderId: string) => Promise<any>;
	onCustomFieldChange: (customFields: BasicStringKeyedMap<any>) => void;
	pageView: (title: string, url: string) => void;
	saveProps: (props: BasicStringKeyedMap<any>) => void;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
	submitCart: (history: History) => any;
	updateCart: () => Promise<any>;
}

interface PortalPendingRenewalStateToProps {
	analytics: Analytics;
	cart: CartModel;
	config: PublicTicketAppConfig;
	portal: Portal;
	blockingActions: BasicStringKeyedMap<AnyAction>;
}

///
/// Component
///

export class PendingRenewal extends React.Component<PortalPendingRenewalProps> {

	private readonly Delivery: Layout<typeof TicketOrderWithIntl, DeliveryForm>;
	private readonly Donation: Layout<typeof DonationMessage, DonationForm>;
	private readonly Discount: Layout<typeof TicketOrderWithIntl, DiscountForm>;
	private readonly Contact: Layout<typeof TicketOrderWithIntl, BuyerInfoForm>;
	private readonly Payment: Layout<typeof TicketOrderWithIntl, typeof PaymentForm>;

	constructor(props: PortalPendingRenewalProps) {
		super(props);

		const {formatMessage} = this.props.intl;

		// Need to create the layout components in the constructor, and not in the render method, because of this problem:
		// https://labs.chiedo.com/blog/always-define-components-outside-react-render-method/

		const mainHeading = formatMessage({id: "lbl_YourOrder"});
		const screenPrimaryHeading = {primary: formatMessage({id: "lbl_Checkout"})};

		this.Delivery = layout(
			{Main: TicketOrderWithIntl, Panel: DeliveryForm},
			mainHeading,
			formatMessage({id: "lbl_Delivery"}),
			screenPrimaryHeading
		);

		this.Donation = layout(
			{Main: DonationMessage, Panel: DonationForm},
			props.config.donationCTAHeader,
			formatMessage({id: "lbl_Donation"}),
			screenPrimaryHeading
		);

		this.Discount = layout(
			{Main: TicketOrderWithIntl, Panel: DiscountForm},
			mainHeading,
			formatMessage({id: "lbl_Discounts"}),
			screenPrimaryHeading
		);

		this.Contact = layout(
			{Main: TicketOrderWithIntl, Panel: BuyerInfoForm},
			mainHeading,
			formatMessage({id: "lbl_Contact"}),
			screenPrimaryHeading,
			{largePanel: true}
		);

		this.Payment = layout(
			{Main: TicketOrderWithIntl, Panel: PaymentForm},
			mainHeading,
			formatMessage({id: "lbl_Payment"}),
			screenPrimaryHeading
		);
	}

	public componentDidMount() {
		const {pageView, portal, match} = this.props;
		// The only time we will need to fetch the Pending Renewal is when mounting, and we only mount once when the /portal/pendingRenewal/:ticketOrderId route is matched.
		const ticketOrderId = match.params.ticketOrderId;
		if (!!ticketOrderId && ticketOrderId !== portal.pendingRenewal.cart.id) {
			this.props.fetchPendingRenewal(ticketOrderId);
		}

		pageView(this.getTitle(), window.location.href);
	}
	
	public componentDidUpdate() {
		const {analytics, pageView} = this.props;

		// Send a page view event anytime the URL changes (unless we're transitioning to the order complete page, 
		// in which case the page view will be sent from that component)
		if (analytics.url !== window.location.href && !window.location.href.endsWith('complete')) {
			pageView(this.getTitle(), window.location.href);
		}
	}

	public render() {
		const {
			applyDiscount,
			blockingActions,
			clearAllMessages,
			config,
			fetchGiftCardBalance,
			intl,
			location,
			onCustomFieldChange,
			portal,
			saveProps,
			showAlert,
			submitCart,
			updateCart
		} = this.props;
		const thePath = location.pathname;
		
		// Properties required for the TicketOrder Component used within the Layout component
		const {pendingRenewal} = portal;
		const ticketOrderProperties: TicketOrderPropsExcludingInjectedProps = {
			allowEdit: false,
			blockingActions,
			cart: pendingRenewal.cart,
			config,
			itemFeeData: pendingRenewal.itemFeeData,
			orderFeeData: pendingRenewal.orderFeeData,
		};
		
		const prevPath = getPreviousPath(thePath, config, pendingRenewal.cart.id);
		const nextPath = getNextPath(thePath, config, pendingRenewal.cart.id);
		
		return (
			<Switch>
				<Route
					path={Paths.PORTAL__PENDING_RENEWAL__DELIVERY}
					exact={true}
					render={(routeProps) =>
						<this.Delivery
							{...routeProps}
							{...ticketOrderProperties}
							clearAllMessages={clearAllMessages}
							intl={intl}
							nextPagePath={nextPath}
							saveProps={saveProps}
							showAlert={showAlert}
							updateCart={updateCart}
						/>
					}
				/>

				<Route
					path={Paths.PORTAL__PENDING_RENEWAL__DONATION}
					exact={true}
					render={(routeProps) =>
						<this.Donation
							{...routeProps}
							blockingActions={blockingActions}
							cart={ticketOrderProperties.cart}
							clearAllMessages={clearAllMessages}
							config={config}
							intl={intl}
							nextPagePath={nextPath}
							prevPagePath={prevPath}
							saveProps={saveProps}
							showAlert={showAlert}
							updateCart={updateCart}
						/>
					}
				/>

				<Route
					path={Paths.PORTAL__PENDING_RENEWAL__DISCOUNT}
					exact={true}
					render={(routeProps) =>
						<this.Discount
							{...routeProps}
							{...ticketOrderProperties}
							applyDiscount={applyDiscount}
							clearAllMessages={clearAllMessages}
							intl={intl}
							nextPagePath={nextPath}
							prevPagePath={prevPath}
							showAlert={showAlert}
						/>
					}
				/>

				<Route
					path={Paths.PORTAL__PENDING_RENEWAL__CONTACT}
					exact={true}
					render={(routeProps) =>
						<this.Contact
							{...routeProps}
							{...ticketOrderProperties}
							applyDiscount={applyDiscount}
							clearAllMessages={clearAllMessages}
							intl={intl}
							nextPagePath={nextPath}
							onCustomFieldChange={onCustomFieldChange}
							prevPagePath={prevPath}
							saveProps={saveProps}
							showAlert={showAlert}
							updateCart={updateCart}
						/>
					}
				/>

				<Route
					path={Paths.PORTAL__PENDING_RENEWAL__PAYMENT}
					exact={true}
					render={(routeProps) =>
						<this.Payment
							{...routeProps}
							{...ticketOrderProperties}
							clearAllMessages={clearAllMessages}
							fetchGiftCardBalance={fetchGiftCardBalance}
							fetchGiftCardBalanceByPaymentMethodId={this.props.fetchGiftCardBalanceByPaymentMethodId}
							intl={intl}
							userProfile={config.userProfile}
							prevPagePath={prevPath}
							saveProps={saveProps}
							showAlert={showAlert}
							submitCart={submitCart}
						/>
					}
				/>

				{/*Redirect all non-matching routes*/}
				<Redirect to={Paths.PORTAL__PENDING_RENEWAL__DELIVERY} />
			</Switch>
		);
	}

	private getTitle = () => {
		return getCartPageTitle(window.location.href, this.props.intl);
	}
}

///
/// Redux Connections
///

const mapStateToProps = (state: RootState): PortalPendingRenewalStateToProps  => {
	return {
		analytics: state.analytics,
		config: state.ptApp.config,
		portal: state.portal,
		cart: state.cart,
		blockingActions: state.ptApp.blockingActions
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, AnyAction>): PortalPendingRenewalDispatchToProps => {
	return {
		applyDiscount: (cartId: string, discountCode: string, modstamp?: number) => {
			return dispatch(ApiActions.applyDiscount(cartId, discountCode, modstamp));
		},
		clearAllMessages: () => {
			dispatch(PublicTicketAppActions.clearAllMessages());
		},
		fetchItemFeeData: (cartId: string) => {
			return dispatch(ApiActions.fetchItemFeeData(cartId));
		},
		fetchOrderFeeData: (cartId: string) => {
			return dispatch(ApiActions.fetchOrderFeeData(cartId));
		},
		fetchPendingRenewal: (ticketOrderId) => {
			return dispatch(ApiActions.fetchPendingRenewal(ticketOrderId));
		},
		pageView: (title: string, url: string): void => {
			dispatch(AnalyticsActions.pageView(title, url));
		},
		onCustomFieldChange: (customFields: BasicStringKeyedMap<any>) => {
			dispatch(CartActions.saveProps({customFields}));
		},
		saveProps: (props: BasicStringKeyedMap<any>) => {
			dispatch(CartActions.saveProps(props));
		},
		showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => {
			dispatch(PublicTicketAppActions.showAlert({alertBody, alertSeverity}));
		},
		submitCart : (history: History) => {
			return dispatch(CartActions.submitCart(true, history));
		},
		updateCart: () => {
			return dispatch(ApiActions.updateCart(true));
		},
		fetchGiftCardBalance: (gcNumber: string, captchaToken: string) => {
			return dispatch(ApiActions.fetchGiftCardBalance(gcNumber, captchaToken));
		},
		fetchGiftCardBalanceByPaymentMethodId: (paymentMethodId: string) => {
			return dispatch(ApiActions.fetchGiftCardBalanceByPaymentMethodId(paymentMethodId));
		}
	};
};

/**
 * PortalPendingRenewal component export with injections
 */
export const PortalPendingRenewalConnected = injectIntl(connect(mapStateToProps, mapDispatchToProps)(PendingRenewal));
