import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {AnyAction} from "redux";
import {EventInstanceSaleStatus} from "../../enums/event-instance-sale-status";
import {getInstanceRoute} from "../../helpers/routing";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {EventInstance} from "../../models/ticketable-events/event-instance";
import {TicketableEvent} from '../../models/ticketable-events/ticketable-event';
import {CountdownTimer} from "../countdown-timer";
import {SimpleHTMLContent} from "../html-content/simple-html-content";
import {PasscodeDisplayWithInjections as PasscodeDisplay, PasscodeFormWithInjections as PasscodeForm} from "../passcode/wrapped-components";

interface EventDetailProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	event: TicketableEvent;
	fetchEvents: () => void;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

export const EventDetail: React.FunctionComponent<EventDetailProps> = (props) => {
	const {blockingActions, cartTimeRemaining, clearAllMessages, event, fetchEvents, validatePasscode} = props;
	if (!event) {
		return null;
	}
	
	return (
		<div>
			<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
			{
				event.instances.map(ei => {
					let cta;
					let passcodeDisplay;
					if (ei.soldOut) {
						cta = <span className="text-info">{getNoSaleMessage(ei)}</span>;
					} else if (ei.saleStatus === EventInstanceSaleStatus.OS){
						cta = (
							<Link className="link__add-to-cart btn btn-primary btn-sm" to={getInstanceRoute(ei.id)}>
								<FormattedMessage id="pmgr_lbl_Buy"/>
							</Link>
						);
						
						if (!!ei.appliedPasscode){
							passcodeDisplay = 
								<PasscodeDisplay
									appliedPasscode={ei.appliedPasscode}
									eventInstanceId={ei.id}
									fetchEvents={fetchEvents}
								/>;
						}
					}
					if (ei.noSaleMessage) {
						cta = (
							<span className="text-info">
								<SimpleHTMLContent rawHtml={ei.noSaleMessage}/>
							</span>
						);
					}
					
					let passcodeCTA;
					if (ei.isPasscodeEligible){
						passcodeCTA =
							<PasscodeForm
								blockingActions={blockingActions}
								clearAllMessages={clearAllMessages}
								eventInstanceId={ei.id}
								fetchEvents={fetchEvents}
								validatePasscode={validatePasscode}
							/>;
					}
					
					return(
						<div key={ei.id} className="pts-event-instance mb-4 p-2">
							<p className="m-0 mb-2">{ei.name}</p>
							{passcodeDisplay}
							<p className="m-0">{cta}</p>
							{passcodeCTA}
						</div>
					);
				})
			}
		</div>
	);
};

const getNoSaleMessage = (eventInstance: EventInstance) => {
	return !!eventInstance && !!eventInstance.noSaleMessage
		? <SimpleHTMLContent rawHtml={eventInstance.noSaleMessage}/>
		: <FormattedMessage id="msg_item_not_available_for_purchase"/>;
};