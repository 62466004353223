import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";
import {ApiActionNames} from "../enums/api-action-names";
import {SubscriptionTypes} from "../enums/subscription-types";
import {TicketableEventTypes} from "../enums/ticketable-event-types";
import {CartItem} from "../models/cart-item";
import {EventDescriptorCache} from "../models/event-descriptor-cache/event-descriptor-cache";
import {EventDescriptor} from "../models/event-descriptor/event-descriptor";
import {SeatDescriptor} from "../models/event-descriptor/seat-descriptor";
import {SubscriptionEventDescriptor} from "../models/event-descriptor/subscription-event-descriptor";

/* eventDescriptorCache will take the following form:
{
	lastUpdated: <timestamp>,
	cacheSize: <integer>,
	descriptors: {
		[ei.Id]: {
			lastUpdated: <timestamp>,
			lastAccessed: <timestamp>,
			descriptor: {
				id: <ei.Id>,
				name: <ei.Name>,
				...
			}
		},
		[ei.Id]: {
			lastUpdated: <timestamp>,
			lastAccessed: <timestamp>,
			descriptor: {
				id: <ei.Id>,
				name: <ei.Name>,
				...
			}
		}
	},
	pendingFetches: {
		[ei.Id]: ei.Id,
		[ei.Id]: ei.Id
	},
	// This object just keeps track of the ids of the Subscription EventDescriptors that are PYOS-enabled
	pyosEnabledSubscriptionEventDescriptors {
		[ei.Id]: ei.Id,
		[ei.Id]: ei.Id,
	}
}
*/

export function eventDescriptorCache(state: EventDescriptorCache = new EventDescriptorCache(), action: AnyAction): EventDescriptorCache {
	switch (action.type) {
		case ActionTypes.API_REQUEST:
			if (action.actionName === ApiActionNames.FETCH_EVENT_DESC || action.actionName === ApiActionNames.FETCH_FULFILLMENT_EVENT_DESCRIPTOR) {
				return handleFetchEventDescriptor(state, action);
			}
			return state;
		case ActionTypes.API_SUCCESS:
			switch (action.actionName) {
				case ApiActionNames.FETCH_EVENT_DESC:
				case ApiActionNames.FETCH_FULFILLMENT_EVENT_DESCRIPTOR:
					return handleFetchEventDescriptor(state, action);
				case ApiActionNames.INSERT_CART_ITEMS:
				case ApiActionNames.INSERT_FULFILLMENT_ITEMS:
				case ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS:
				case ApiActionNames.DELETE_CART_ITEMS:
					return handleAddOrDeleteSeats(state, action);
				default:
					return state;
			}
		case ActionTypes.API_FAILURE:
			if (action.actionName === ApiActionNames.FETCH_EVENT_DESC || action.actionName === ApiActionNames.FETCH_FULFILLMENT_EVENT_DESCRIPTOR) {
				return handleFetchEventDescriptor(state, action);
			}
			return state;
		case ActionTypes.ISVC_ED_ACCESSED:
			return handleEventDescriptorAccessed(state, action);
		case ActionTypes.ISVC_DELETE_ED:
			return handleDeleteEventDescriptor(state, action);
		default:
			return state;
	}
}

// Static action handlers
function handleFetchEventDescriptor(edCache: EventDescriptorCache, action: AnyAction): EventDescriptorCache {
	switch (action.type) {
		case ActionTypes.API_REQUEST: {
			// Add the ID of the EI to the pendingFetches hash
			const newPendingFetches = {...edCache.pendingFetches};
			newPendingFetches[action.params.eiId] = action.params.eiId;
			return {...edCache, pendingFetches: newPendingFetches};
		}
		case ActionTypes.API_SUCCESS: {
			const now = new Date().getTime();
			const eventDescriptor = action.data as EventDescriptor;
			// Generate a new ED cache entry
			const newDescriptorEntry = {...edCache.descriptors[eventDescriptor.id], lastUpdated: new Date().getTime(), descriptor: eventDescriptor};
			// Add the new ED cache entry to the descriptors object and update the lastUpdated and cacheSize properties
			const newDescriptors = {...edCache.descriptors, [eventDescriptor.id]: newDescriptorEntry};
			// Remove the ID of the EI from the pendingFetches hash
			const newPendingFetches = {...edCache.pendingFetches};
			delete newPendingFetches[eventDescriptor.id];
			// If the EventDescriptor is a "Subscription" EventDescriptor that has "fulfillmentVenueIds" arrayproperty, then it is PYOS enabled
			const newPYOSEnabledSubscriptionEventDescriptors = {...edCache.pyosEnabledSubscriptionEventDescriptors};
			if (eventDescriptor.teType === TicketableEventTypes.SUBSCRIPTION) {
				const subsEventDescriptor = eventDescriptor as SubscriptionEventDescriptor;
				if ((subsEventDescriptor.subscriptionType === SubscriptionTypes.FIXED || subsEventDescriptor.subscriptionType === SubscriptionTypes.CHOOSE_YOUR_OWN)
					&& subsEventDescriptor.fulfillmentVenueIds
					&& subsEventDescriptor.fulfillmentVenueIds.length > 0) {
					newPYOSEnabledSubscriptionEventDescriptors[subsEventDescriptor.id] = subsEventDescriptor.id;
				}
			}
			// Return the updated ED cache
			return {
				...edCache, 
				lastUpdated: now, 
				cacheSize: Object.keys(newDescriptors).length, 
				descriptors: newDescriptors, 
				pendingFetches: newPendingFetches, 
				pyosEnabledSubscriptionEventDescriptors: newPYOSEnabledSubscriptionEventDescriptors
			};
		}
		case ActionTypes.API_FAILURE: {
			// Remove the ID of the EI from the pendingFetches hash
			const newPendingFetches = {...edCache.pendingFetches};
			delete newPendingFetches[action.params.eiId];
			// Return the updated ED cache
			return {...edCache, pendingFetches: newPendingFetches};
		}
		default:
			return edCache;
	}
}

function handleAddOrDeleteSeats(edCache: EventDescriptorCache, action: AnyAction): EventDescriptorCache {
	// Safeguard against being called with the wrong action
	if (action.actionName !== ApiActionNames.INSERT_CART_ITEMS
		&& action.actionName !== ApiActionNames.INSERT_FULFILLMENT_ITEMS
		&& action.actionName !== ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS
		&& action.actionName !== ApiActionNames.DELETE_CART_ITEMS) {
		return edCache;
	}
	
	// Get the CartItems from the request as an array
	let cartItems: CartItem[] = [];
	if (action.actionName === ApiActionNames.INSERT_CART_ITEMS
		|| action.actionName === ApiActionNames.INSERT_FULFILLMENT_ITEMS
		|| action.actionName === ApiActionNames.DELETE_CART_ITEMS
		|| action.actionName === ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS) {
		// The actions above have a cartItems param, representing an array of CartItem objects
		cartItems = action.params.cartItems;
	}
	
	// Group the ids of bound seat instances by the id of their parent event descriptor.
	// This will build an object with the following shape:
	// seatIdsMappedByInstanceId = { ei1: { seat1: cartItem1, seat2: cartItem2 }, ei2: { seat3: cartItem3, seat4: cartItem4 }}
	const seatIdsMappedByInstanceId: {[index: string]: any}  = {};
	cartItems.forEach((cartItem: CartItem) => {
		if (!!cartItem.seatId) {
			if (!(cartItem.eiId in seatIdsMappedByInstanceId)) {
				seatIdsMappedByInstanceId[cartItem.eiId] = {};
			}
			seatIdsMappedByInstanceId[cartItem.eiId][cartItem.seatId] = cartItem.id;
		}
	});
	
	// Now iterate over event descriptor ids
	const now = new Date().getTime();
	const newDescriptors = {...edCache.descriptors};
	Object.keys(seatIdsMappedByInstanceId).forEach((eiId: string) => {
		const seatsForThisDescriptor = seatIdsMappedByInstanceId[eiId];
		
		// PMGR-10129 - Now that the EventDescriptorCache contains both regular EventDescriptors and fulfillment EventDescriptors
		// we have to deal with the fact the EventDescriptor.id may no longer be a singular Event Instance Id. It may in fact, be a 
		// comma delimited string of multiple fulfillment Event Instance Ids. So, we need to do a "fuzzy" match between seat instance
		// eiId and EventDescriptor.id to make this work (EventDescriptor.id.includes(CartItem.eiId)).
		Object.keys(newDescriptors).forEach(edId => {
			if (edId.includes(eiId)) {
				const newDescriptorEntry = newDescriptors[edId];
				if (!!newDescriptorEntry) {
					newDescriptorEntry.descriptor.seatList = newDescriptorEntry.descriptor.seatList.map((seatInstance: SeatDescriptor) => {
						const newSeatInstance = {...seatInstance};
						if (newSeatInstance.id in seatsForThisDescriptor) {
							// Handle the insertion of regular or PYOS subscription cart items
							if (action.actionName === ApiActionNames.INSERT_CART_ITEMS
								|| action.actionName === ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS
								|| action.actionName === ApiActionNames.INSERT_FULFILLMENT_ITEMS) {
								// If adding a cart item that references a seat, mark the seat as unavailable
								newSeatInstance.avail = false;
								newSeatInstance.toiId = seatsForThisDescriptor[newSeatInstance.id];

								// The else condition deletion of cart items
							} else {
								// If deleting a cart item that references a seat, then free up the seat
								newSeatInstance.avail = true;
								delete newSeatInstance.toiId;
							}
						}
						return newSeatInstance;
					});
					newDescriptorEntry.lastUpdated = now;
					newDescriptors[eiId] = newDescriptorEntry;
				}
			}
		});
		
	});
	return {...edCache, descriptors: newDescriptors, lastUpdated: now};
}

function handleEventDescriptorAccessed(edCache: EventDescriptorCache, action: AnyAction): EventDescriptorCache {
	// Only set lastAccessed if there is an entry in the cache already
	if (!(action.instanceId in edCache.descriptors)) {
		return edCache;
	}
	const newDescriptorEntry = {...edCache.descriptors[action.instanceId], lastAccessed: new Date().getTime()};
	const newDescriptors = {...edCache.descriptors, [action.instanceId]: newDescriptorEntry};
	return {...edCache, descriptors: newDescriptors};
}

function handleDeleteEventDescriptor(edCache: EventDescriptorCache, action: any): EventDescriptorCache {
	// Remove the EventDescriptor from the cache
	const newDescriptors = {...edCache.descriptors};
	delete newDescriptors[action.instanceId];
	return {...edCache, cacheSize: Object.keys(newDescriptors).length, descriptors: newDescriptors};
}