import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BackIcon } from "../icons/icons";
import './back-link.css';

interface BackLinkProps {
	intlId: string;
	toLocation: string;
}

interface BackLinkState {
	hovering: boolean;
}

/**
 * Displays a small link to go back to somewhere you've just been.
 * To be used to return to a list when viewing a single item in a list,
 * and similar cases.
 * 
 * @param toLocation Path to link location
 * @param intlId React Intl id of the link label
 */
export class BackLink extends React.Component<BackLinkProps, BackLinkState> {
	public state = {
		hovering: false,
	};
	
	public render() {
		const {toLocation, intlId} = this.props;
		const iconClass = `pts-back-link__icon ${this.state.hovering
			? 'pts-back-link__icon--hover'
			: 'pts-back-link__icon--no-hover'}`;
		return (
			<div className="border-bottom pb-2 mb-2">
				<small>
					<Link to={toLocation}>
						<span className={iconClass}>
							<BackIcon />
						</span>
						<span
							onMouseEnter={this.handleMouseEnter}
							onMouseLeave={this.handleMouseLeave}
							className="align-middle p-1">
							<FormattedMessage id={intlId} />
						</span>
					</Link>
				</small>
			</div>
		);

	}

	private handleMouseEnter = () => {
		this.setState({ hovering: true });
	}

	private handleMouseLeave = () => {
		this.setState({ hovering: false });
	}
} 
