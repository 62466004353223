import * as React from "react";
import {ChangeEvent, KeyboardEvent} from "react";
import {Checkbox, CheckboxGroup} from 'react-checkbox-group';
import {DateTimePicker} from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css';
import {FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label} from "reactstrap";
import {InputType} from "reactstrap/lib/Input";
import {FormElementLabel} from "./form-element-label/form-element-label";

export const enum FieldGroupTypes {
	CHECKBOX = "checkbox",
	CURRENCY = "currency",
	DATE = "date",
	EMAIL = "email",
	NUMBER = "number",
	MULTIPICKLIST = "multipicklist",
	PASSWORD = "password",
	SELECT = "select",
	TEXT = "text",
	TEXTAREA = "textarea"
}

interface FieldGroupProps {
	id: string;
	name: string;
	type: FieldGroupTypes;
	value: any;

	checked?: boolean;
	currencySymbol?: string;
	disabled?: boolean;
	feedbackMessage?: string;
	help?: string;
	intlLabelId?: string;
	invalid?: boolean;
	required?: boolean;   // causes required-ness indicator to be displayed in field label
	label?: string;
	locale?: string;
	maxLength?: number;
	multiple?: boolean;
	onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
	onKeyPress?: (evt: KeyboardEvent<HTMLInputElement>) => void;
	onDateChange?: (date: Date) => void;
	onMultiselectChange?: (values: string[]) => void;
	selectionOptions?: any[] | null;
	readonly?: boolean;
	autoFocus?: boolean;
}

export class FieldGroup extends React.Component<FieldGroupProps> {

	public render() {
		const {
			id,
			type,
			label,
			intlLabelId,
			help,
			invalid,
			feedbackMessage,
			selectionOptions = null,
			onChange,
			onDateChange,
			onKeyPress,
			onMultiselectChange,
			currencySymbol,
			required,
			readonly,
			value,
			autoFocus,
			...props
		} = this.props;

		let fg;
		switch(type) {
			case FieldGroupTypes.CHECKBOX: {
				fg = (
					<FormGroup check={true}>
						<Input id={id} type="checkbox" aria-label={label} aria-required={required} onChange={onChange} onKeyPress={onKeyPress} invalid={invalid} value={value} autoFocus={autoFocus} {...props} />
						<FormElementLabel
							label={label}
							intlLabelId={intlLabelId}
							required={required}
							invalid={invalid}
							forInput={id}
						/>

						{!!help && <FormText>{help}</FormText>}
						{invalid && !!feedbackMessage && <FormFeedback>{feedbackMessage}</FormFeedback>}
					</FormGroup>
				);
				break;
			}
			case FieldGroupTypes.CURRENCY: {
				fg = (
					<FormGroup>
						<FormElementLabel
							label={label}
							intlLabelId={intlLabelId}
							required={required}
							invalid={invalid}
							forInput={id}
						/>
						<InputGroup>
							<InputGroupAddon addonType="prepend">{currencySymbol}</InputGroupAddon>
							<Input id={id} type="number" aria-label={label} aria-required={required} invalid={invalid} onChange={onChange} onKeyPress={onKeyPress} min={0} value={value} autoFocus={autoFocus} {...props} />
							{invalid && !!feedbackMessage && <FormFeedback>{feedbackMessage}</FormFeedback>}
						</InputGroup>
						{!!help && <FormText>{help}</FormText>}
					</FormGroup>
				);
				break;
			}
			case FieldGroupTypes.NUMBER: {
				fg = (
					<FormGroup>
						<FormElementLabel
							label={label}
							intlLabelId={intlLabelId}
							required={required}
							invalid={invalid}
							forInput={id}
						/>
						<InputGroup>
							<Input id={id} type="number" aria-label={label} aria-required={required} invalid={invalid} onChange={onChange} onKeyPress={onKeyPress} min={0} value={value} autoFocus={autoFocus} {...props} />
							{invalid && !!feedbackMessage && <FormFeedback>{feedbackMessage}</FormFeedback>}
						</InputGroup>
						{!!help && <FormText>{help}</FormText>}
					</FormGroup>
				);
				break;
			}
			// TODO - Make the invalid state of this component look good
			case FieldGroupTypes.DATE: {
				fg = (
					<FormGroup>
						<FormElementLabel
							label={label}
							intlLabelId={intlLabelId}
							required={required}
							invalid={invalid}
							forInput={id}
						/>
						<DateTimePicker aria-label={label} aria-required={required} time={false} onChange={onDateChange} onKeyPress={onKeyPress} value={value} autoFocus={autoFocus} {...props} />
						{!!help && <FormText>{help}</FormText>}
						{invalid && !!feedbackMessage && <FormFeedback>{feedbackMessage}</FormFeedback>}
					</FormGroup>
				);
				break;
			}
			// TODO - Make the invalid state of this component look good
			case FieldGroupTypes.MULTIPICKLIST: {
				if (!!selectionOptions) {
					const checkBoxes = selectionOptions.map((option, index) => {
						return (
							<FormGroup check={true} key={`${id}-${index}`}>
								<Label check={true}>
									<Checkbox value={option.value}/>
									{' ' + option.label}
								</Label>
							</FormGroup>
						);
					});

					fg = (
						<FormGroup tag="fieldset">
							<FormElementLabel
								label={label}
								required={required}
								invalid={invalid}
							/>
							<CheckboxGroup aria-label={label} aria-required={required} checkboxDepth={3} onChange={onMultiselectChange} value={value} autoFocus={autoFocus} {...props}>
								{checkBoxes}
							</CheckboxGroup>
							{!!help && <FormText>{help}</FormText>}
							{invalid && !!feedbackMessage && <FormFeedback>{feedbackMessage}</FormFeedback>}
						</FormGroup>
					);
				}
				break;
			}
			case FieldGroupTypes.TEXT: {
				fg = (
					<FormGroup>
						<FormElementLabel
							label={label}
							intlLabelId={intlLabelId}
							required={required}
							invalid={invalid}
						/>

						{
							readonly
								? <p> {value} </p>
								: <>
									<Input id={id} type="text" aria-label={label} aria-required={required} invalid={invalid} onChange={onChange} onKeyPress={onKeyPress} value={value} autoFocus={autoFocus} {...props} />
									{!!help && <FormText>{help}</FormText>}
									{invalid && !!feedbackMessage && <FormFeedback>{feedbackMessage}</FormFeedback>}
								</>
						}

					</FormGroup>
				);

				break;
			}
			default: {
				// we are letting typescript know that we trust the "type" variable will ONLY contain a value corresponding to InputType.
				const manuallyValidatedType = type as InputType;

				fg = (
					<FormGroup>
						<FormElementLabel
							label={label}
							intlLabelId={intlLabelId}
							required={required}
							invalid={invalid}
						/>
						<Input id={id} type={manuallyValidatedType} aria-label={label} aria-required={required} invalid={invalid} onChange={onChange} onKeyPress={onKeyPress} value={value} autoFocus={autoFocus} {...props} >
							{selectionOptions}
						</Input>
						{!!help && <FormText>{help}</FormText>}
						{invalid && !!feedbackMessage && <FormFeedback>{feedbackMessage}</FormFeedback>}
					</FormGroup>
				);
			}
		}
		
		return(
			<div key={id}>{fg}</div>
		);
	}
}