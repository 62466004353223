import {useCallback, useEffect, useMemo, useState} from "react";
import {WrappedComponentProps} from "react-intl";
import {useDispatch} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {Col, Row} from "reactstrap";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {ApiActions} from "../../actions/api-actions";
import {ActionTypes} from "../../enums/action-types";
import {Paths} from "../../enums/paths";
import {SeatingTypes} from "../../enums/seating-types";
import {getSubFulfillmentInstanceRoute, getSubFulfillmentVenueRoute} from "../../helpers/routing";
import {createSeatAssignmentStringForSeat} from "../../helpers/utilities";
import {useFulfillmentEventDescriptor} from "../../hooks";
import {inventoryService} from "../../index";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {SeatDescriptor} from "../../models/event-descriptor/seat-descriptor";
import {SubscriptionBuyerSelectionLinkDescriptor} from "../../models/subscription-buyer-selection-link-descriptor";
import {SubscriptionFulfillmentGroup} from "../../models/subscription-fulfillment-group";
import {FulfillmentStatus, SubscriptionFulfillmentSlot} from "../../models/subscription-fulfillment-slot";
import {EventInstance} from "../../models/ticketable-events/event-instance";
import {RootState} from "../../reducers";
import {FulfillmentMiniCart} from "./fulfillment-mini-cart";
import {FulfillmentVenueMap} from "./fulfillment-venue-map";
import './fulfillment.css';

interface FulfillmentSeatSelectionProps extends RouteComponentProps<any>, WrappedComponentProps {
	cart: Cart;
	fulfillmentGroups: SubscriptionFulfillmentGroup[];
	cartTimeRemaining: number;
}

export const FulfillmentSeatSelection = (props: FulfillmentSeatSelectionProps) => {
	const {cart, fulfillmentGroups, cartTimeRemaining, match, history, intl} = props;
	const {params: {allocationId, eventInstanceId, fulfillmentInstanceId, venueId, sectionId}} = match;
	const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
	
	const [fulfillmentInstanceIds, setFulfillmentInstanceIds] = useState<string>('');
	const [sbslIds, setSBSLIds] = useState<string[]>([]);
	const [fulfillmentGroup, setFulfillmentGroup] = useState<SubscriptionFulfillmentGroup>();
	const [subFulfillmentSlots, setSubFulfillmentSlots] = useState<SubscriptionFulfillmentSlot[]>([]);
	
	const fulfillmentEventDescriptor = useFulfillmentEventDescriptor(fulfillmentInstanceIds, cart.cartId, sbslIds, cart.modstamp);
	
	// CYO subs are fulfilled one instance at a time and will include a fulfillmentInstanceId parameter in the path that
	// represents the instance that's currently being fulfilled. Fixed subs on the other hand are fulfilled one venue
	// group (which may contains multiple instances) at a time.
	const isCYOFulfillment = !!fulfillmentInstanceId;
	
	// Derive various states from props
	useEffect(() => {
		// Clear instance IDs to force the fulfillmentEventDescriptor to start empty while we determine the new 
		// descriptor we need to fetch (that way the user doesn't see the previous venue on the screen)
		setFulfillmentInstanceIds('');
		
		// Find the subscription items currently being fulfilled
		const currentSubItems = cart.cartItems.filter((ci: CartItem) => {
			if(ci.allocId !== allocationId) {
				return false;
			}
			if(isCYOFulfillment) {
				// for CYO subs, we need to also make sure the cart item in question has the current performance as one of its selections
				// (users can make multiple passes through the purchase flow for a given allocation while also making different performance
				// selections each time)
				return ci.sbsls?.some((sbsl: SubscriptionBuyerSelectionLinkDescriptor) => sbsl.eiId === fulfillmentInstanceId) || false;
			}
			return true; 
		});
		if(currentSubItems.length > 0){
			const selectedVenueGroup = fulfillmentGroups.find((group: SubscriptionFulfillmentGroup) => {
				return isCYOFulfillment ? group.id === fulfillmentInstanceId : !group.isGeneralAdmission && group.venueId === venueId;
			});
			
			const eiIds: string[] = [];
			if(!!selectedVenueGroup && selectedVenueGroup.instances.length > 0) {
				selectedVenueGroup.instances.forEach((ei: EventInstance) => eiIds.push(ei.id));
			}

			const _sbslIds: string[] = [];
			currentSubItems.forEach((ci: CartItem) => {
				ci.sbsls?.forEach((sbsl: SubscriptionBuyerSelectionLinkDescriptor) => {
					if(isCYOFulfillment) {
						if(sbsl.eiId === fulfillmentInstanceId && SeatingTypes.PYOS) {
							_sbslIds.push(sbsl.id);
						}
					} else if(sbsl.venueId === venueId && sbsl.seatingType === SeatingTypes.PYOS) {
						_sbslIds.push(sbsl.id);
					}
				})
			});
				
			if(_sbslIds.length && eiIds.length) {
				setFulfillmentGroup(selectedVenueGroup);
				setFulfillmentInstanceIds(eiIds.join(','));
				setSBSLIds(_sbslIds);
			}
		}

		// Determine sub fulfillment slots
		setSubFulfillmentSlots(prev => {
			// create map of existing sub fulfillment slots
			const mapSubFulfillmentSlots = prev.reduce((prev: BasicStringKeyedMap<SubscriptionFulfillmentSlot>, slot: SubscriptionFulfillmentSlot) => {
				prev[slot.subItem.id] = slot;
				return prev;
			},{});

			currentSubItems.forEach((ci: CartItem) => {
				if(!(ci.id in mapSubFulfillmentSlots)) {
					// Only add new slot to map if it doesn't already exist so we don't overwrite a current status
					const sbsls = ci.sbsls!.filter((sbsl: SubscriptionBuyerSelectionLinkDescriptor) => {
						return isCYOFulfillment
							? sbsl.eiId === fulfillmentInstanceId && sbsl.seatingType === SeatingTypes.PYOS
							: sbsl.venueId === venueId && sbsl.seatingType === SeatingTypes.PYOS
					});
					mapSubFulfillmentSlots[ci.id] = new SubscriptionFulfillmentSlot(ci, sbsls);
				}
			})

			// Find any existing fulfillment items for this fulfillment group and assign to the correct slot
			cart.cartItems.forEach((ci: CartItem) => {
				if(!!ci.stoiId && ci.stoiId in mapSubFulfillmentSlots) {
					if((!!venueId && ci.venueId === venueId) || (!!fulfillmentInstanceId && ci.eiId === fulfillmentInstanceId)) {
						const slot = mapSubFulfillmentSlots[ci.stoiId];
						slot.fulfillmentItems.push(ci);
						slot.seatAssignment = ci.seatAssign || '';
						slot.seatKey = ci.seatKey || '';
					}
				}
			});

			const _subFulfillmentSlots = Object.values(mapSubFulfillmentSlots);

			// Update the status on any slots that are fully fulfilled
			_subFulfillmentSlots.forEach((slot: SubscriptionFulfillmentSlot) => {
				if (slot.fulfillmentItems.length === slot.sbsls.length) {
					slot.fulfillmentStatus = FulfillmentStatus.FULFILLED;
				}
			});
			return _subFulfillmentSlots;
		})
		
	}, [allocationId, cart.cartItems, fulfillmentInstanceId, fulfillmentGroups, venueId, isCYOFulfillment]);
	
	const updateSubSlotBySeatKey = useCallback((seatKey: string, newStatus: FulfillmentStatus) => {
		setSubFulfillmentSlots((prev: SubscriptionFulfillmentSlot[]) => {
			const slots = [...prev];
			const slot = slots.find((slot : SubscriptionFulfillmentSlot) => slot.seatKey === seatKey);
			if(!!slot) {
				slot.fulfillmentStatus = newStatus;
				if(newStatus === FulfillmentStatus.NEEDS_SELECTION) {
					// clear our values if slot is being reset to no seat selected
					slot.seatAssignment = '';
					slot.seatKey = '';
					slot.fulfillmentItems = [];
				}
			}
			return slots;
		});
	},[])
	
	const {mapOfStagedSeats, mapOfExistingSeats, mapOfPendingRequests} = useMemo(() => {
		return subFulfillmentSlots.reduce((prev: BasicStringKeyedMap<any>, slot: SubscriptionFulfillmentSlot) => {
			if(slot.fulfillmentStatus === FulfillmentStatus.STAGED) {
				prev.mapOfStagedSeats[slot.seatKey] = seatToCartItem(slot.seatKey);
			}
			else if(slot.fulfillmentStatus === FulfillmentStatus.FULFILLED) {
				prev.mapOfExistingSeats[slot.seatKey] = seatToCartItem(slot.seatKey);
			}
			else if(slot.fulfillmentStatus === FulfillmentStatus.PENDING) {
				prev.mapOfPendingRequests[slot.seatKey] = seatToCartItem(slot.seatKey);
			}
			return prev;
		}, {mapOfStagedSeats: {}, mapOfExistingSeats: {}, mapOfPendingRequests: {}});
	},[subFulfillmentSlots]);
	
	const handleRemoveSeat = useCallback((seatKey: string) => {
		if(seatKey in mapOfStagedSeats) {
			updateSubSlotBySeatKey(seatKey, FulfillmentStatus.NEEDS_SELECTION);
		}
		else if(seatKey in mapOfExistingSeats) {
			// delete all existing fulfillment items for this seat position
			const itemsToDelete = subFulfillmentSlots.find((slot: SubscriptionFulfillmentSlot) => slot.seatKey === seatKey && !!slot.sbsls)?.fulfillmentItems;
			if(itemsToDelete){
				// mark as pending while items are being deleted
				updateSubSlotBySeatKey(seatKey, FulfillmentStatus.PENDING);
				
				dispatch(ApiActions.deleteCartItems(cart.cartId, itemsToDelete))
					.then(() => {
						// remove seat from pending list once the action completes (regardless if it succeeds or fails)
						updateSubSlotBySeatKey(seatKey, FulfillmentStatus.NEEDS_SELECTION);
					})
			}
		}
	},[cart.cartId, dispatch, mapOfExistingSeats, mapOfStagedSeats, subFulfillmentSlots, updateSubSlotBySeatKey]);

	const handleSeatClick = useCallback((seat: SeatDescriptor) => {
		if(seat.id in mapOfPendingRequests) {
			// ignore clicks on seats that are in the process of being inserted or deleted from the server
			return;
		}
		else if(seat.id in mapOfStagedSeats || seat.id in mapOfExistingSeats){
			handleRemoveSeat(seat.id);
		}
		else if(seat.avail && (Object.keys(mapOfExistingSeats).length + Object.keys(mapOfStagedSeats).length < subFulfillmentSlots.length)){
			// as long as we haven't hit our seat limit, add a new request for this seat location
			const cartItem = seatToCartItem(seat.id); 

			if(!!fulfillmentEventDescriptor){
				// Create the seat assignment string on the fly for display since these items are not yet persisted
				cartItem.seatAssign = createSeatAssignmentStringForSeat(seat, fulfillmentEventDescriptor);
			}

			// Find the next available sub fulfillment slot and assign the seat request to it
			setSubFulfillmentSlots((prev: SubscriptionFulfillmentSlot[]) => {
				const slots = [...prev]
				const slot = slots.find((slot : SubscriptionFulfillmentSlot) => slot.fulfillmentStatus === FulfillmentStatus.NEEDS_SELECTION);
				if(!!slot) {
					slot.seatAssignment = cartItem.seatAssign || '';
					slot.seatKey = cartItem.seatKey || '';
					slot.fulfillmentStatus = FulfillmentStatus.STAGED;
				}
				return slots;
			});
		}
	},[mapOfPendingRequests, mapOfStagedSeats, mapOfExistingSeats, subFulfillmentSlots.length, handleRemoveSeat, fulfillmentEventDescriptor]);
	
	// Navigates to the fulfillment group that requires seat selections (or head to the cart if we're finished)
	const navigateToNextVenueOrFinish = useCallback(() => {
		// remove current ED from cache
		inventoryService.deleteEventDescriptor(fulfillmentInstanceIds);
		
		const nextGroup = fulfillmentGroups.find((group: SubscriptionFulfillmentGroup) => {
			return isCYOFulfillment
				? !group.isGeneralAdmission && group.seatAssignments.length !== group.numberOfSelections && group.id !== fulfillmentInstanceId 
				: !group.isGeneralAdmission && group.seatAssignments.length !== subFulfillmentSlots.length && group.venueId !== venueId;
		});
		
		if(!!nextGroup) {
			const nextPath = isCYOFulfillment
				? getSubFulfillmentInstanceRoute(eventInstanceId, allocationId, nextGroup.id)
				: getSubFulfillmentVenueRoute(eventInstanceId, allocationId, nextGroup.venueId!);
			
			// Reset state before navigating
			setFulfillmentInstanceIds('');
			setSubFulfillmentSlots([]);

			// Navigate to the next group
			history.push(nextPath);
			
		} else {
			// Nothing left to fulfill, head to the cart
			history.push(Paths.CART);
		}
	},[fulfillmentInstanceIds, isCYOFulfillment, fulfillmentGroups, fulfillmentInstanceId, subFulfillmentSlots.length, eventInstanceId, allocationId, venueId, history])
	
	// navigates back to the section selection page from the seat selection page
	const handleChangeSection = useCallback(() => {
		isCYOFulfillment
			? history.push(getSubFulfillmentInstanceRoute(eventInstanceId, allocationId, fulfillmentInstanceId))
			: history.push(getSubFulfillmentVenueRoute(eventInstanceId, allocationId, venueId));
	},[allocationId, eventInstanceId, fulfillmentInstanceId, history, isCYOFulfillment, venueId]);
	
	const handleConfirmSeats = useCallback(() => {
		if(Object.keys(mapOfStagedSeats).length === 0){
			// nothing to be done
			navigateToNextVenueOrFinish();
		}
		
		// Determine list of fulfillment items to insert from staged seat requests
		const fItems: CartItem[] = [];
		const stagedSubFulfillmentSlots = subFulfillmentSlots.filter((slot: SubscriptionFulfillmentSlot) => slot.fulfillmentStatus === FulfillmentStatus.STAGED);
		stagedSubFulfillmentSlots.forEach((slot: SubscriptionFulfillmentSlot) => {
			slot.sbsls.forEach((sbsl: SubscriptionBuyerSelectionLinkDescriptor) => {
				const ci = new CartItem();
				ci.toId = cart.id;
				ci.eiId = sbsl.eiId;
				ci.sbslId = sbsl.id;
				ci.soiId = sbsl.soiId;
				ci.levelId = sbsl.levelId;
				ci.seatId = slot.seatKey;
				ci.seatKey = slot.seatKey;
				ci.qty = 1;
				fItems.push(ci);
			})
		});

		// Mark all staged requests as now pending
		setSubFulfillmentSlots((prev: SubscriptionFulfillmentSlot[]) => {
			const slots = [...prev];
			slots.forEach((slot: SubscriptionFulfillmentSlot) => {
				if(slot.fulfillmentStatus === FulfillmentStatus.STAGED) {
					slot.fulfillmentStatus = FulfillmentStatus.PENDING;
				}
			});
			return slots;
		});
		
		// Insert the fulfillment items
		if(fItems.length) {
			dispatch(ApiActions.insertFulfillmentItems(cart.cartId, fItems, cart.modstamp))
				.then((response) => {
					if (response.type === ActionTypes.API_SUCCESS) {
						// We're finished with this venue, move on
						navigateToNextVenueOrFinish();
					}
					else if (response.type === ActionTypes.API_FAILURE) {
						// Refresh the event descriptor to show the user the most up to date seat availability
						inventoryService.deleteEventDescriptor(fulfillmentInstanceIds);
						
						// Clear existing seat requests and force the user to re-select
						setSubFulfillmentSlots((prev: SubscriptionFulfillmentSlot[]) => {
							const slots = [...prev]
							slots.forEach((slot: SubscriptionFulfillmentSlot) => {
								if(slot.fulfillmentStatus === FulfillmentStatus.PENDING) {
									slot.fulfillmentStatus = FulfillmentStatus.NEEDS_SELECTION;
									slot.seatKey = '';
									slot.seatAssignment = '';
								}
							})
							return slots;
						});
					}
				});
		}
	},[subFulfillmentSlots, mapOfStagedSeats, navigateToNextVenueOrFinish, cart.id, cart.cartId, cart.modstamp, dispatch, fulfillmentInstanceIds]);
	
	return (
		<div className="fulfillment">
			{!!fulfillmentGroup && (
				<Row>
					<Col md="8" className="mb-4">
						<h4 className="ml-3">{fulfillmentGroup.venueName}</h4>
						<hr/>
						<div id="VenueMapInstances">
							{fulfillmentGroup.instances.map((instance: EventInstance) => {
								return (
									<div key={instance.id} className="mb-2 ml-3">
										<div className="small font-weight-bold">{instance.eventName}</div>
										<div className="small text-secondary">{instance.name}</div>
									</div>
								)
							})}
							<hr/>
						</div>
						
						{/* only show the map once the descriptor has been updated */
						!!fulfillmentEventDescriptor && fulfillmentEventDescriptor.id === fulfillmentInstanceIds && (
							<FulfillmentVenueMap
								fulfillmentEventDescriptor={fulfillmentEventDescriptor}
								existingSeats={mapOfExistingSeats}
								pendingSeats={mapOfPendingRequests}
								stagedSeats={mapOfStagedSeats}
								numberOfSeatsRequired={subFulfillmentSlots.length}
								handleSeatClick={handleSeatClick}
								isCYO={isCYOFulfillment}
								{...props}
							/>
						)}
					</Col>
					<Col md={true}>
						<FulfillmentMiniCart
							fulfillmentGroup={fulfillmentGroup}
							subFulfillmentSlots={subFulfillmentSlots}
							//only provide a function if we're currently on the seat selection page and this isn't a single section venue
							handleChangeSection={!!sectionId && !fulfillmentEventDescriptor?.singleSectionOrGroupId ? handleChangeSection : undefined} 
							handleConfirmSeats={handleConfirmSeats}
							handleRemoveSeat={handleRemoveSeat}
							cartTimeRemaining={cartTimeRemaining}
							intl={intl}
						/>
					</Col>
				</Row>
			)}
		</div>
	);
}

const seatToCartItem = (seatId: string) => {
	const cartItem = new CartItem();
	cartItem.seatId = seatId;
	cartItem.seatKey = seatId;
	return cartItem;
}