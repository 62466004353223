import * as React from 'react';
import {AnyAction} from "redux";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {compareEvents} from '../../helpers/utilities';
import {EventItem} from '../event-list/event-item';
import {getEventRoute} from '../../helpers/routing';
import {GhostEvents} from "../event-list/ghost-event";
import {WrappedComponentProps, injectIntl} from 'react-intl';
import {Media} from 'reactstrap';
import {Message} from "../message";
import {PublicTicketAppConfig} from '../../models/public-ticket-app/public-ticket-app-config';
import SubscriptionItem from './subscription-item';
import {SubscriptionViews} from '../../enums/subscription-views';
import {TicketableEvent} from '../../models/ticketable-events/ticketable-event';

interface SubscriptionListProps extends WrappedComponentProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	clearAllMessages: () => void;
	fetchEvents: () => void;
	// The pre-filtered list of TicketableEvent objects that only includes those of Type = Subscription
	subscriptionEvents: TicketableEvent[] | null;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
	config: PublicTicketAppConfig;
}

export const SubscriptionList: React.FunctionComponent<SubscriptionListProps> = (props) => {
	const {
		blockingActions,
		clearAllMessages,
		fetchEvents,
		intl,
		subscriptionEvents,
		validatePasscode,
		config: {subscriptionView}
	} = props;

	if (!subscriptionEvents) {
		return <GhostEvents />;
	}
	if (subscriptionEvents.length < 1) {
		return <Message intlId="msg_no_subscriptions_found" values={{subscriptions: intl.formatMessage({id: "pmgr_term_Subscriptions"}).toLowerCase()}} />;
	}

	let outputElements: JSX.Element[];

	if (subscriptionView === SubscriptionViews.CONDENSED) {
		outputElements =
			subscriptionEvents
			.sort(compareEvents)
			.map(te => <EventItem key={te.id} detailPath={getEventRoute(te.id)} ticketableEvent={te}/>);
	} else {
		outputElements = 
			subscriptionEvents
			.sort(compareEvents)
			.map((te) => {
				const imagePath = te.smallImagePath || te.largeImagePath;
				const formattedImageSrc = imagePath && window.PublicTicketApp.assetPath + imagePath;
				return (
					<SubscriptionItem
						key={te.id}
						blockingActions={blockingActions}
						clearAllMessages={clearAllMessages}
						fetchEvents={fetchEvents}
						formattedImageSrc={formattedImageSrc}
						te={te}
						validatePasscode={validatePasscode}
					/>
				);
			});
	}

	return (
		<Media list={true} className="p-0">
			{ outputElements }
		</Media>
	);
};

export default injectIntl(SubscriptionList);
