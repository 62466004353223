import * as React from 'react';
import {Omit} from "react-router";
import {InventoryService} from "../helpers/inventory-service";
import {inventoryService} from "../index";

///
/// Interfaces
///

export interface InjectedInventoryServiceProps {
	inventoryService: InventoryService;
}

///
/// Component
///

/**
 * Injects an instance of inventory service into the component
 *
 * @param Component to be injected with inventory service
 * @returns passed in Component with injected inventoryService
 */
export const injectInventoryService = <OriginalProps extends InjectedInventoryServiceProps>(Component: React.ComponentType<OriginalProps>):
	React.ComponentType<Omit<OriginalProps, keyof InjectedInventoryServiceProps>> => {
	// @ts-ignore - Ignoring a horrible Typescript error here that I can't figure out. Good luck to anyone that decides to remove this ts-ignore statement :)
	return class WrapperComponent extends React.Component<OriginalProps> {
		 public render() {
			return <Component {...this.props} inventoryService={inventoryService}/>;
		}
	};
};