export const enum ApiActionNames {
    APPLY_DISCOUNT = "vfApplyDiscount",
    DELETE_CART_ITEMS = "vfDeleteCartItems",
    ENSURE_CART = "vfEnsureCart",
    FETCH_GLOBAL_CONFIG = "vfFetchGlobalConfig",
    FETCH_LOCALIZED_MESSAGES = "vfFetchLocalizedMessages",
    FETCH_EVENTS = "vfFetchEvents",
    FETCH_EVENT = "vfFetchEvent",
    FETCH_EVENT_DESC = "vfFetchEventDescriptor",
    FETCH_ITEM_FEE_DATA = "vfFetchItemFeeData",
    FETCH_ORDER_FEE_DATA = "vfFetchOrderFeeData",
    FETCH_GIFT_CARD_BALANCE = "vfFetchGiftCardBalance",
    FETCH_GIFT_CARD_BALANCE_BY_PAYMENT_METHOD_ID = "vfFetchGiftCardBalanceByPaymentMethodId",
	FETCH_SUBCRIPTION_FULFILLMENT_GROUPS = "vfFetchSubscriptionFulfillmentGroups",
	FETCH_FULFILLMENT_EVENT_DESCRIPTOR = "vfFetchFulfillmentEventDescriptor",
	FETCH_PREVIEW_FULFILLMENT_EVENT_DESCRIPTOR = "vfFetchPreviewFulfillmentEventDescriptor",
    FETCH_MOBILE_TICKET_CART = "vfFetchMobileTicketCart",
    FETCH_APPLE_PASS = "vfFetchApplePass",
    FETCH_GOOGLE_PASS_URL = "vfFetchGooglePassURL",
    INSERT_CART_ITEMS = "vfInsertCartItems",
    INSERT_PYOS_SUBSCRIPTION_CART_ITEMS = "vfInsertPYOSSubscriptionCartItems",
    INSERT_SUBSCRIPTION_CART_ITEMS = "vfInsertSubscriptionCartItems",
	INSERT_FULFILLMENT_ITEMS = "vfInsertFulfillmentItems",
    SUBMIT_CART = "vfSubmitCart",
    UPDATE_CART = "vfUpdateCart",
    UPDATE_CART_ITEMS = "vfUpdateCartItems",
    VALIDATE_PASSCODE = "vfValidatePasscode",
	VALIDATE_CYO_SUBSCRIPTION_PRICE_LEVELS = "vfValidateCYOSubscriptionPriceLevels",
	
    //Portal specific
    LOG_IN = "vfLogin",
    FORGOT_PASSWORD = "vfForgotPassword",
    CHANGE_PASSWORD = "vfChangePassword",
    FETCH_PORTAL_ORDERS = "vfFetchPortalOrders",
    FETCH_PORTAL_DONATIONS = "vfFetchPortalDonations",
    FETCH_RENEWABLE_BENEFITS = "vfFetchRenewableBenefits",
    FETCH_PORTAL_ORDER_DETAILS = "vfFetchPortalOrderDetails",
    FETCH_PENDING_RENEWAL = "vfFetchPendingRenewal",
    UPDATE_USER_PROFILE = "vfUpdateUserProfile",
    DO_NOT_RENEW = "vfDoNotRenew",
    SUBMIT_CONTACT_REQUEST = "vfSubmitContactRequest"
}