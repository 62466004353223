import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Badge, Button, Col, Row} from "reactstrap";
import {Paths} from "../../enums/paths";
import {Benefit as BenefitModel} from "../../models/portal/benefit";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";

interface PendingRenewalHeaderProps {
	benefit: BenefitModel;
	config: PublicTicketAppConfig;
}

export const Benefit: React.FunctionComponent<PendingRenewalHeaderProps> = ({
	benefit: {
		benefitLevelName,
		effectiveStatus,
		expirationDate,
		id,
	},
	config
}) => (
		<div className="mb-5">
			<Row className="mb-1">
				<Col>
					<h3>
						{benefitLevelName}
					</h3>
				</Col>
				<Col className="text-right">
					<Badge color="info">{effectiveStatus}</Badge>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className="pb-1 m-0 small">
						<span className="mr-1"><FormattedMessage id="lbl_BenefitExpDate" /></span>
						<span>{expirationDate}</span>
					</p>
				</Col>
				<Col className="text-right">
					<Link to={Paths.PORTAL__RENEWABLE_BENEFIT__CONTACT_REQUEST.replace(":benefitId", id)} className="mr-2">
						<Button size="sm" color="link">
							<FormattedMessage id="lbl_ContactMe" />
						</Button>
					</Link>

					{
						(!config.membershipsUrl || config.membershipsUrl === 'nolink')
							? <Link to={`${Paths.MEMBERSHIPS}?benefitId=${id}`} className="mr-0">
								<Button size="sm" color="primary">
									<FormattedMessage id="lbl_button_Renew" />
								</Button>
							</Link>
							: <a href={config.membershipsUrl} className="mr-0">
								<Button size="sm" color="primary">
									<FormattedMessage id="lbl_button_Renew" />
								</Button>
							</a>
					}

				</Col>
			</Row>
		</div>
	);
