import * as React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {Link} from "react-router-dom";
import {getInstanceRoute} from "../../helpers/routing";
import { DiscountList } from "./discount-list";

interface ItemHeaderProps
{
	// PatronTicket__TicketableEvent__c Name
	ticketableEventName: string;
	// PatronTicket__EventInstance__c Id
	eventInstanceId: string;
	// PatronTicket__EventInstance__c Name
	eventInstanceName?: string;
	// For single ticket events, quantity is the number of tickets added to cart for this event instance.
	// For subscriptions, the quantity is the number of subscriptions.
	quantity: number;
	quantityBreakdown?: string[];
	// De-duped map of Discount Names applied to this group of tickets
	discountsApplied: string[];
	// TicketAllocation__c Name
	allocationName?: string;
	// adds link back to EI so user can change selections
	allowNavigation: boolean;
	// The total price of this item (e.g. event, subscription)
	itemTotal: number;
	itemSubtotal: number;
	feeSubtotal: number;
	currencyCode: string;
	breakoutFees: boolean;
}

/**
 * Displays header for an item in the TicketOrder
 */
export const ItemHeader: React.FunctionComponent<ItemHeaderProps> = ({
	ticketableEventName,
	eventInstanceId,
	eventInstanceName,
	quantity,
	quantityBreakdown,
	discountsApplied,
	allocationName,
	allowNavigation,
	itemTotal,
	itemSubtotal,
	feeSubtotal,
	currencyCode,
	breakoutFees
}) =>
{
	const allocationElem: JSX.Element = !!allocationName ? <span>{'x ' + allocationName}</span> : <></>;

	let quantityBreakdownElem: JSX.Element = <></>;
	if (!!quantityBreakdown) {
		quantityBreakdownElem = 
			<div className="ml-4">
				{quantityBreakdown.map((bd) => {
					return <div key={bd}>{bd}</div>
				})}
			</div>;
	}

	const ticketCountLabel = (
		<span className="mr-1">
			<FormattedMessage id="lbl_Quantity" values={{quantity}} />
			{allocationElem}
			{quantityBreakdownElem}
		</span>
	);
	const teName = (allowNavigation) ? <Link to={getInstanceRoute(eventInstanceId)}>{ticketableEventName}</Link> : ticketableEventName;

	return (
		<div>
			<h5 className="m-0">
				{teName}
			</h5>
			{!!eventInstanceName && <p className="m-0 text-uppercase">{eventInstanceName}</p>}
			<p className="m-0">
				<span>
					<FormattedMessage id={"lbl_ItemTotal"} />
				</span>
				<span className="mr-1">:</span>
				<span className="itemTotal">
					<FormattedNumber
						value={itemTotal}
						style="currency"
						currency={currencyCode.toString()}
						currencyDisplay="symbol"
					/>
				</span>
			</p>
			<div className="m-0 small">
				{ticketCountLabel}
				{(discountsApplied.length > 0) && <DiscountList discountsApplied={discountsApplied} />}
			</div>
			{breakoutFees && feeSubtotal > 0 && (
				<>
					<p className="m-0 small">
						<FormattedMessage id={"lbl_ItemSubtotal"}/>
						<span className="mr-1">:</span>
						<FormattedNumber
							value={itemSubtotal}
							style="currency"
							currency={currencyCode.toString()}
							currencyDisplay="symbol"
						/>
					</p>
					<p className="m-0 small">
						<FormattedMessage id={"lbl_ItemFeeSubtotal"}/>
						<span className="mr-1">:</span>
						<FormattedNumber
							value={feeSubtotal}
							style="currency"
							currency={currencyCode.toString()}
							currencyDisplay="symbol"
						/>
					</p>
				</>
			)}
		</div>
	);
};
