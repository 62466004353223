import {AnyAction} from "redux";
import {ApplicationMessage} from "../application-message";
import {BasicStringKeyedMap} from "../basic-map";
import {EventDescriptor} from "../event-descriptor/event-descriptor";
import {FeeDescriptor} from "../fee-descriptor";
import {TicketableEvent} from "../ticketable-events/ticketable-event";
import {ModalProps} from "./modal-props";
import {PublicTicketAppConfig} from "./public-ticket-app-config";
import {SubmitResult} from "./submit-result";

export class PublicTicketApp {
	public appMessages: Map<string, ApplicationMessage> = new Map();  // Map of ApplicationMessage objects, keyed by a hash of the object itself
	public blockingActions: BasicStringKeyedMap<AnyAction> = {};
	public cartTimeDrift: number; // difference between browser date/time and server date/time
	public cartTimeRemaining?: number; // number of seconds until the cart expires
	public config: PublicTicketAppConfig = new PublicTicketAppConfig();
	public defaultCalendarDate?: string;
	public eventList: TicketableEvent[] | null = null; // will be null when event list has not been fetched yet.
	public fetchingCart: boolean = false;
	public fetchingEvents: boolean = false;
	public itemFeeData: FeeDescriptor[] = [];
	public modalProps: ModalProps = new ModalProps();
	public nonBlockingActions: BasicStringKeyedMap<AnyAction> = {};
	public orderFeeData: FeeDescriptor[] = [];
	public pendingItemIds: BasicStringKeyedMap<string> = {};
	public pendingSeatRequests: BasicStringKeyedMap<string> = {};
	public prevRequestIds: BasicStringKeyedMap<string> = {};
	public selectedEI?: EventDescriptor | null;
	public selectedEvent: TicketableEvent | null = null;
	public showLoginForm: boolean = false;
	public showResetPasswordForm: boolean = false;
	public submitResult: SubmitResult | null = null;
}
