import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";
import {Analytics} from "../models/analytics";

export const analytics = (state = new Analytics(), action: AnyAction): Analytics => {
	switch (action.type) {
		case ActionTypes.ANALYTICS_PAGE_VIEW:
			return Object.assign(state, {title: action.title, url: action.url});
		default:
			return state;
	}
};
