import * as React from "react";
import {FormattedMessage, IntlShape} from "react-intl";
import {RouterProps} from "react-router";
import {Button, Col, Collapse, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, Row} from "reactstrap";
import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";
import {DeliveryMethods} from "../enums/delivery-methods";
import {Severities} from "../enums/severities";
import {TicketableEventTypes} from "../enums/ticketable-event-types";
import {CartService} from "../helpers/cart-service";
import {formatValidationErrors, handleApplicationErrors} from "../helpers/checkout-form-utils";
import {SO_TYPE} from "../helpers/constants";
import {getSuggestedDonationHelpText} from "../helpers/localization";
import {canEditBuyerInfo, isPendingRenewal, isPortalUser, scrubbedValue, showDonationField} from "../helpers/utilities";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {PicklistEntryDescriptor} from "../models/public-ticket-app/picklist-entry-descriptor";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {SCPLOptions} from "../models/public-ticket-app/scpl-options";
import {CountdownTimer} from "./countdown-timer";
import {FieldGroup, FieldGroupTypes} from "./field-group";
import {FormElementLabel} from "./form-element-label/form-element-label";
import {PanelNav} from "./panel-nav";
import {RequiredFieldLegend} from "./required-field-legend";
import {StateField} from "./state-field";
import {WaitingMessage} from "./waiting-message";
import {HTMLContent} from "./html-content/html-content";
import {Label} from 'reactstrap';

interface BuyerInfoFormProps extends RouterProps {
	applyDiscount: (cartId: string, discountCode: string, modstamp?: number) => Promise<any>;
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	intl: IntlShape;
	nextPagePath: string;
	onCustomFieldChange: (fieldValues: BasicStringKeyedMap<any>) => void;
	prevPagePath: string;
	pyosEnabledSubscriptionEventDescriptors?: BasicStringKeyedMap<string>;
	saveProps: (props: any) => void;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
	updateCart: () => Promise<any>;
}

interface BuyerInfoFormState {
	discountCode: string;
	donationAmt: string;
	emailConfirm: string;
	errors: BasicStringKeyedMap<string>;
	fieldLabels: BasicStringKeyedMap<string>;
	useDifferentAddressForShipping: boolean;
}

export class BuyerInfoForm extends React.Component<BuyerInfoFormProps, BuyerInfoFormState> {
	public readonly state: BuyerInfoFormState = {
		discountCode: "",
		donationAmt: "",
		emailConfirm: this.props.cart.email || "",
		errors: {},
		fieldLabels: {
			salutation: this.props.intl.formatMessage({ id: "ticketorder__c.salutation__c" }),
			firstName: this.props.intl.formatMessage({ id: "ticketorder__c.firstname__c" }),
			lastName: this.props.intl.formatMessage({ id: "ticketorder__c.lastname__c" }),
			pronouns: this.props.intl.formatMessage({ id: "ticketorder__c.pronouns__c" }),
			address: this.props.intl.formatMessage({ id: "ticketorder__c.streetaddress__c" }),
			city: this.props.intl.formatMessage({ id: "ticketorder__c.city__c" }),
			state: this.props.intl.formatMessage({ id: "ticketorder__c.state__c" }),
			postalCode: this.props.intl.formatMessage({ id: "ticketorder__c.postalcode__c" }),
			country: this.props.intl.formatMessage({ id: "ticketorder__c.country__c" }),
			phone: this.props.intl.formatMessage({ id: "ticketorder__c.phone__c" }),
			email: this.props.intl.formatMessage({ id: "ticketorder__c.email__c" }),
			emailConfirm: this.props.intl.formatMessage({ id: "pmgr_lbl_ConfirmEmail" }),
			// Override the standard Comment field label to just "Comments" *unless* it's already been overridden via translation workbench
			comments: this.props.intl.formatMessage({ id: "ticketorder__c.comments__c" }) === "Buyer's Comments"
				? this.props.intl.formatMessage({ id: "lbl_Comments" }) 
				: this.props.intl.formatMessage({ id: "ticketorder__c.comments__c" }),
			optIn: this.props.intl.formatMessage({ id: "ticketorder__c.emailoptin__c" }),
			orderSource: this.props.intl.formatMessage({ id: "ticketorder__c.ordersource__c" }),
			accessibilityInformation: this.props.intl.formatMessage({ id: "pmgr_lbl_Accessibility_Information" }),
			accessRequirements: this.props.intl.formatMessage({ id: "ticketorder__c.accessrequirements__c" }),
			seatUpgradeRequested: this.props.intl.formatMessage({ id: "lbl_SeatUpgradeRequested" }),
			shippingSalutation: this.props.intl.formatMessage({ id: "ticketorder__c.shippingsalutation__c" }),
			shippingFirstName: this.props.intl.formatMessage({ id: "ticketorder__c.shippingfirstname__c" }),
			shippingLastName: this.props.intl.formatMessage({ id: "ticketorder__c.shippinglastname__c" }),
			shippingAddress: this.props.intl.formatMessage({ id: "ticketorder__c.shippingstreetaddress__c" }),
			shippingCity: this.props.intl.formatMessage({ id: "ticketorder__c.shippingcity__c" }),
			shippingState: this.props.intl.formatMessage({ id: "ticketorder__c.shippingstate__c" }),
			shippingPostalCode: this.props.intl.formatMessage({ id: "ticketorder__c.shippingpostalcode__c" }),
			shippingCountry: this.props.intl.formatMessage({ id: "ticketorder__c.shippingcountry__c" }),
		},
		useDifferentAddressForShipping: false,
	};
	
	// Map of field API names to corresponding cart props
	private readonly fieldMap: BasicStringKeyedMap<string> = {
		salutation__c: "salutation",
		firstname__c: "firstName",
		lastname__c: "lastName",
		pronouns__c: 'pronouns',
		email__c: "email",
		phone__c: "phone",
		streetaddress__c: "address",
		city__c: "city",
		state__c: "state",
		postalcode__c: "postalCode",
		country__c: "country",
		comments__c: "comments",
		emailoptin__c: "emailOptIn",
		ordersource__c: "orderSource",
		shippingfirstname__c: "shippingFirstName",
		shippinglastname__c: "shippingLastName",
		shippingstreetaddress__c: "shippingAddress",
		shippingcity__c: "shippingCity",
		shippingstate__c: "shippingState",
		shippingpostalcode__c: "shippingPostalCode",
		shippingcountry__c: "shippingCountry",
		donationamount__c: "donationAmt",
	};
	
	private readonly salutationOptions: JSX.Element[];
	private readonly orderSourceOptions: JSX.Element[];
	
	// state and country picklist feature
	private readonly scplCountryOptions: JSX.Element[];

	// used for the top row if form field, which render dynamically based on settings
	private readonly topRowColumnWidth: string;

	constructor(props: BuyerInfoFormProps) {
		super(props);
		const {config} = props;
		
		this.salutationOptions = config.salutations.map((salutation: PicklistEntryDescriptor) => {
			return <option key={salutation.value} value={salutation.value}>{salutation.label}</option>;
		});
		
		// Construct country picklists when the SF state and country picklist feature is enabled in the org
		if (config.isSCPLEnabled && !!config.scplOptions) {
			this.scplCountryOptions = config.scplOptions.map((scplOption: SCPLOptions) => {
				return <option key={scplOption.country} value={scplOption.country}>{scplOption.country}</option>
			});
		}

		this.orderSourceOptions = config.orderSources.map((orderSource) => {
			return <option key={orderSource.value} value={orderSource.value}>{orderSource.label}</option>;
		});

		this.topRowColumnWidth = (
			12 / (2 + (config.arePronounsEnabled ? 1 : 0) + (config.isSalutationDisabled ? 0 : 1))
		).toString();
	}

	public componentDidMount() {
		const {cart, config, saveProps} = this.props;
		// Convert the Cart donationAmt property to a string and save to local state
		this.setState({donationAmt: !!cart.donationAmt ? cart.donationAmt.toFixed(2) : ""});
		
		// if SCPL is enabled and no country is already selected, set to the default (first) option
		if(config.isSCPLEnabled) {
			if (!cart.country) {
				saveProps({'country': config.scplOptions[0].country});
			}
			if (!cart.shippingCountry && cart.delMethod === DeliveryMethods.SHIP) {
				saveProps({'shippingCountry': config.scplOptions[0].country});
			}
		}
	}
	
	public render() {
		const { blockingActions, cart, cartTimeRemaining, config, intl, pyosEnabledSubscriptionEventDescriptors } = this.props;
		const { discountCode, donationAmt, emailConfirm, errors, useDifferentAddressForShipping } = this.state;
		const isBusy = Object.keys(blockingActions).length > 0;
		const customInputs: JSX.Element[] = this.configureCustomFields();
		const isDisabled = !canEditBuyerInfo(cart)
		
		// PMGR-10131 - Determine if there are any unfilled or partially filled PYOS Subs and disable the "Next" button if necessary
		let hasUnfilledSubs = false;
		if (!!pyosEnabledSubscriptionEventDescriptors) {
			hasUnfilledSubs = (new CartService(cart)).hasUnfulfilledPYOSSubscriptions(pyosEnabledSubscriptionEventDescriptors);
		}
		
		let suggestedDonationHelpText = '';
		if (showDonationField(config)) {
			suggestedDonationHelpText = getSuggestedDonationHelpText(cart, config, intl);
		}

		const commentLabel = (!!cart.orderType && cart.orderType.includes(TicketableEventTypes.SUBSCRIPTION) && !!config.subscriptionCommentsLabel)
			? config.subscriptionCommentsLabel
			: this.state.fieldLabels.comments;

		return (
			<div style={{ minHeight: '300px' }} className="d-flex flex-column">
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				<RequiredFieldLegend />
				<Row>
					{ !config.isSalutationDisabled &&
						<Col md={this.topRowColumnWidth}>

							<FieldGroup
								id="salutation"
								name="salutation"
								data-testid="salutation"
								type={FieldGroupTypes.SELECT}
								label={this.state.fieldLabels.salutation}
								value={cart.salutation || ""}
								selectionOptions={this.salutationOptions}
								onChange={this.handleChange}
								invalid={!!errors.salutation}
								disabled={isDisabled}
							/>
						</Col>
					}
					<Col md={this.topRowColumnWidth}>
						<FieldGroup
							id="firstName"
							name="firstName"
							type={FieldGroupTypes.TEXT}
							label={this.state.fieldLabels.firstName}
							value={cart.firstName || ""}
							onChange={this.handleChange}
							invalid={!!errors.firstName}
							feedbackMessage={errors.firstName}
							required={true}
							disabled={isDisabled}
						/>
					</Col>
					<Col md={this.topRowColumnWidth}>
						<FieldGroup
							id="lastName"
							name="lastName"
							type={FieldGroupTypes.TEXT}
							label={this.state.fieldLabels.lastName}
							value={cart.lastName || ""}
							onChange={this.handleChange}
							invalid={!!errors.lastName}
							feedbackMessage={errors.lastName}
							required={true}
							disabled={isDisabled}
						/>
					</Col>
					{ config.arePronounsEnabled &&
						<Col md={this.topRowColumnWidth}>
							<FieldGroup
								id="pronouns"
								name="pronouns"
								data-testid="pronouns"
								type={FieldGroupTypes.TEXT}
								label={this.state.fieldLabels.pronouns}
								value={cart.pronouns || ""}
								onChange={this.handleChange}
								invalid={!!errors.pronouns}
								feedbackMessage={errors.pronouns}
								required={false}
								disabled={isDisabled}
							/>
						</Col>
					}
				</Row>

				<Row>
					<Col>
						<FieldGroup
							id="address"
							name="address"
							type={FieldGroupTypes.TEXTAREA}
							label={this.state.fieldLabels.address}
							value={cart.address || ""}
							onChange={this.handleChange}
							invalid={!!errors.address}
							feedbackMessage={errors.address}
							required={true}
							disabled={isDisabled}
						/>
					</Col>
				</Row>

				<Row>
					<Col md="4">
						<FieldGroup
							id="city"
							name="city"
							type={FieldGroupTypes.TEXT}
							label={this.state.fieldLabels.city}
							value={cart.city || ""}
							onChange={this.handleChange}
							invalid={!!errors.city}
							feedbackMessage={errors.city}
							required={true}
							disabled={isDisabled}
						/>
					</Col>
					<Col md="4">
						<StateField
							id="state"
							name="state"
							label={this.state.fieldLabels.state}
							value={cart.state || ""}
							onChange={this.handleStateFieldChange}
							invalid={!!errors.state}
							feedbackMessage={errors.state}
							required={true}
							disabled={isDisabled}
							isSCPLEnabled={config.isSCPLEnabled}
							scplOptions={config.scplOptions}
							stateCodes={config.stateCodes}
							selectedCountry={cart.country}
							intl={intl}
						/>
					</Col>
					<Col md="4">
						<FieldGroup
							id="postalCode"
							name="postalCode"
							type={FieldGroupTypes.TEXT}
							label={this.state.fieldLabels.postalCode}
							value={cart.postalCode || ""}
							onChange={this.handleChange}
							invalid={!!errors.postalCode}
							feedbackMessage={errors.postalCode}
							required={true}
							disabled={isDisabled}
						/>
					</Col>
				</Row>

				<Row>
					<Col md="6">
						{config.isSCPLEnabled ? (
							<FieldGroup
								id="country"
								name="country"
								type={FieldGroupTypes.SELECT}
								label={this.state.fieldLabels.country}
								value={cart.country || ""}
								selectionOptions={this.scplCountryOptions}
								onChange={this.handleChange}
								invalid={!!errors.country}
								required={true}
								disabled={isDisabled}
							/>
						) : (
							<FieldGroup
								id="country"
								name="country"
								type={FieldGroupTypes.TEXT}
								label={this.state.fieldLabels.country}
								value={cart.country || ""}
								onChange={this.handleChange}
								invalid={!!errors.country}
								disabled={isDisabled}
							/>
						)}
					</Col>
					<Col md="6">
						<FieldGroup
							id="phone"
							name="phone"
							type={FieldGroupTypes.TEXT}
							label={this.state.fieldLabels.phone}
							value={cart.phone || ""}
							onChange={this.handleChange}
							invalid={!!errors.phone}
							feedbackMessage={errors.phone}
							required={true}
							disabled={isDisabled}
						/>
					</Col>
				</Row>

				<Row>
					<Col md="6">
						<FieldGroup
							id="email"
							name="email"
							type={FieldGroupTypes.EMAIL}
							label={this.state.fieldLabels.email}
							value={cart.email || ""}
							onChange={this.handleChange}
							invalid={!!errors.email}
							feedbackMessage={errors.email}
							required={true}
							disabled={isDisabled}
						/>
					</Col>
					<Col md="6">
						<FieldGroup
							id="emailConfirm"
							name="emailConfirm"
							type={FieldGroupTypes.EMAIL}
							label={this.state.fieldLabels.emailConfirm}
							value={emailConfirm}
							onChange={this.handleChange}
							invalid={!!errors.emailConfirm}
							feedbackMessage={errors.emailConfirm}
							required={true}
							disabled={isDisabled}
						/>
					</Col>
				</Row>

				<Row>
					<Col md="6">
						<FieldGroup
							id="optIn"
							name="optIn"
							type={FieldGroupTypes.CHECKBOX}
							label={this.state.fieldLabels.optIn}
							value={cart.optIn || false}
							checked={cart.optIn}
							onChange={this.handleChange}
							disabled={isDisabled}
						/>
					</Col>
					<Col md="6">
						{!!config.orderSourceLabel && !window.PublicTicketApp.orderSource && !isPortalUser(config.userProfile) && (
							<FieldGroup
								id="orderSource"
								name="orderSource"
								type={FieldGroupTypes.SELECT}
								label={!!config.orderSourceLabel ? config.orderSourceLabel : this.state.fieldLabels.orderSource}
								value={cart.orderSource || ""}
								selectionOptions={this.orderSourceOptions}
								onChange={this.handleChange}
								invalid={!!errors.orderSource}
								feedbackMessage={errors.orderSource}
								required={true}
								disabled={isDisabled}
							/>
						)}
					</Col>
				</Row>

				{config.isAccessRequirementsEnabled && (
				 	<>
				 	{!!config.accessibilityInformation && 
						<Row>
							<Col>
								<div className="mb-2">
									<Label className="font-weight-bold">
										{this.state.fieldLabels.accessibilityInformation}
									</Label>
									<HTMLContent rawHtml={config.accessibilityInformation || ''}/>
								</div>
							</Col>
						</Row>
					}
					<Row>
						<Col>
							<FieldGroup
							id="accessRequirements"
							name="accessRequirements"
							type={FieldGroupTypes.MULTIPICKLIST}
							label={this.state.fieldLabels.accessRequirements}
							multiple={true}
							value={cart.accessRequirements?.split(';') || []}
							selectionOptions={config.accessRequirements}
							onMultiselectChange={(values) => this.handleMultiSelectChange('accessRequirements', values)}
							required={false}
							invalid={!!errors.accessRequirements}
							disabled={isDisabled}
							/>
						</Col>
					</Row>
					</>
				)}

				<Row>
					<Col>
						<FieldGroup
							id="comments"
							name="comments"
							type={FieldGroupTypes.TEXTAREA}
							label={commentLabel}
							value={cart.comments || ""}
							onChange={this.handleChange}
							disabled={isDisabled}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						{isPendingRenewal('', cart) &&
							<FieldGroup
								id="seatUpgradeRequested"
								name="seatUpgradeRequested"
								type={FieldGroupTypes.CHECKBOX}
								label={this.state.fieldLabels.seatUpgradeRequested}
								value={cart.seatUpgradeRequested || false}
								checked={cart.seatUpgradeRequested}
								onChange={this.handleChange}
								disabled={isDisabled}
							/>
						}
					</Col>
				</Row>
				
				{(config.usesDiscountCodes && config.disableDiscountPage) && (
					<Row>
						<Col>
							<FormGroup>
								<FormElementLabel intlLabelId={'lbl_EnterYourDiscountCode'}/>
								<InputGroup>
									<Input
										type="text"
										id="discountCode"
										name="discountCode"
										aria-label={intl.formatMessage({id: "lbl_DiscountCode"})}
										value={discountCode}
										onChange={this.handleChange}
										invalid={!!errors.discountCode}
										disabled={isDisabled}
									/>
									<InputGroupAddon addonType="append">
										<Button onClick={this.handleApplyDiscount} disabled={isBusy || isDisabled}>
											<FormattedMessage id="lbl_Apply" />
										</Button>
									</InputGroupAddon>
									{!!errors.discountCode && <FormFeedback>{errors.discountCode}</FormFeedback>}
								</InputGroup>
							</FormGroup>
						</Col>
					</Row>
				)}
				
				<Row>
					<Col>
						{showDonationField(config) &&
						<FieldGroup
							id="donationAmt"
							name="donationAmt"
							type={FieldGroupTypes.CURRENCY}
							label={config.donationLabel}
							value={donationAmt}
							onChange={this.handleChange}
							help={suggestedDonationHelpText}
							currencySymbol={config.currencySym}
							invalid={!!errors.donationAmt}
							feedbackMessage={errors.donationAmt}
							disabled={isDisabled}
						/>
						}
					</Col>
				</Row>

				{/* Custom Fields */}
				{customInputs.length > 0 && (
					<div>
						<legend><FormattedMessage id="pmgr_lbl_OtherInformation" /></legend>
						{
							customInputs.map((input, i) => (
								<Row key={`custom-input-${i}`}>
									<Col>
										{input}
									</Col>
								</Row>
							))
						}
					</div>)
				}

				{/* Shipping Fields */}
				{
					cart.delMethod === DeliveryMethods.SHIP && (
						<fieldset>
							{/* TODO: This should match 'Other Information.  Leaving for Emma to decide. */}
							<legend><FormattedMessage id="lbl_ShippingInformation" /></legend>
							<div className="mb-3">
								<FieldGroup
									id="useDifferentAddressForShipping"
									name="useDifferentAddressForShipping"
									type={FieldGroupTypes.CHECKBOX}
									value={useDifferentAddressForShipping}
									onChange={this.handleToggleShippingFields}
									label={intl.formatMessage({id: "lbl_ShipToDifferentAddress"})}
									disabled={isDisabled}
								/>
							</div>
							<Collapse isOpen={useDifferentAddressForShipping}>
							
								<Row>
									<Col md="4">
										<FieldGroup
											id="shippingSalutation"
											name="shippingSalutation"
											type={FieldGroupTypes.SELECT}
											label={this.state.fieldLabels.shippingSalutation}
											value={cart.shippingSalutation || ""}
											selectionOptions={this.salutationOptions}
											onChange={this.handleChange}
											invalid={!!errors.shippingSalutation}
											feedbackMessage={errors.shippingSalutation}
											disabled={isDisabled}
										/>
									</Col>
									<Col md="4">
										<FieldGroup
											id="shippingFirstName"
											name="shippingFirstName"
											type={FieldGroupTypes.TEXT}
											label={this.state.fieldLabels.shippingFirstName}
											value={cart.shippingFirstName || ""}
											onChange={this.handleChange}
											invalid={!!errors.shippingFirstName}
											feedbackMessage={errors.shippingFirstName}
											required={true}
											disabled={isDisabled}
										/>
									</Col>
									<Col md="4">
										<FieldGroup
											id="shippingLastName"
											name="shippingLastName"
											type={FieldGroupTypes.TEXT}
											label={this.state.fieldLabels.shippingLastName}
											value={cart.shippingLastName || ""}
											onChange={this.handleChange}
											invalid={!!errors.shippingLastName}
											feedbackMessage={errors.shippingLastName}
											required={true}
											disabled={isDisabled}
										/>
									</Col>
								</Row>

								<Row>
									<Col>
										<FieldGroup
											id="shippingAddress"
											name="shippingAddress"
											type={FieldGroupTypes.TEXTAREA}
											label={this.state.fieldLabels.shippingAddress}
											value={cart.shippingAddress || ""}
											onChange={this.handleChange}
											invalid={!!errors.shippingAddress}
											feedbackMessage={errors.shippingAddress}
											required={true}
											disabled={isDisabled}
										/>
									</Col>
								</Row>

								<Row>
									<Col md="6">
										<FieldGroup
											id="shippingCity"
											name="shippingCity"
											type={FieldGroupTypes.TEXT}
											label={this.state.fieldLabels.shippingCity}
											value={cart.shippingCity || ""}
											onChange={this.handleChange}
											invalid={!!errors.shippingCity}
											feedbackMessage={errors.shippingCity}
											required={true}
											disabled={isDisabled}
										/>
									</Col>
									<Col md="6">
										<StateField
											id="shippingState"
											name="shippingState"
											label={this.state.fieldLabels.shippingState}
											value={cart.shippingState || ""}
											onChange={this.handleStateFieldChange}
											invalid={!!errors.shippingState}
											feedbackMessage={errors.shippingState}
											required={true}
											disabled={isDisabled}
											isSCPLEnabled={config.isSCPLEnabled}
											scplOptions={config.scplOptions}
											stateCodes={config.stateCodes}
											selectedCountry={cart.shippingCountry}
											intl={intl}
										/>
									</Col>
								</Row>

								<Row>
									<Col md="6">
										<FieldGroup
											id="shippingPostalCode"
											name="shippingPostalCode"
											type={FieldGroupTypes.TEXT}
											label={this.state.fieldLabels.shippingPostalCode}
											value={cart.shippingPostalCode || ""}
											onChange={this.handleChange}
											invalid={!!errors.shippingPostalCode}
											feedbackMessage={errors.shippingPostalCode}
											required={true}
											disabled={isDisabled}
										/>
									</Col>
									<Col md="6">
										{config.isSCPLEnabled ? (
											<FieldGroup
												id="shippingCountry"
												name="shippingCountry"
												type={FieldGroupTypes.SELECT}
												label={this.state.fieldLabels.shippingCountry}
												value={cart.shippingCountry || ""}
												selectionOptions={this.scplCountryOptions}
												onChange={this.handleChange}
												invalid={!!errors.shippingCountry}
												required={true}
												disabled={isDisabled}
											/>
										) : (
											<FieldGroup
												id="shippingCountry"
												name="shippingCountry"
												type={FieldGroupTypes.TEXT}
												label={this.state.fieldLabels.shippingCountry}
												value={cart.shippingCountry || ""}
												onChange={this.handleChange}
												invalid={!!errors.shippingCountry}
												disabled={isDisabled}
											/>
										)}
									</Col>
								</Row>
							</Collapse>
						</fieldset>
					)
				}

				<div className="mt-auto">
					<WaitingMessage isOpen={isBusy} />
					<PanelNav
						next={{ label: intl.formatMessage({ id: "lbl_Next" }), handleClick: this.handleSubmit, isDisabled: (isBusy || hasUnfilledSubs) }}
						back={{ label: intl.formatMessage({ id: "lbl_Back" }), handleClick: this.back }}
					/>
				</div>
			</div>
		);
	}
	
	private handleToggleShippingFields = () => {
		this.setState({useDifferentAddressForShipping: !this.state.useDifferentAddressForShipping});
	}
	
	/**
	 * Returns an object with the shipping fields populated with the values from their equivalent billing fields
	 */
	private copyBillingToShipping = (): Partial<Cart> => {
		const {cart} = this.props;
		return{
			shippingSalutation: cart.salutation,
			shippingFirstName: cart.firstName,
			shippingLastName: cart.lastName,
			shippingAddress: cart.address,
			shippingCity: cart.city,
			shippingState: cart.state,
			shippingPostalCode: cart.postalCode,
			shippingCountry: cart.country
		};
	}
	
	private handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		switch (evt.target.name) {
			// discountCode, donationAmt and emailConfirm are saved directly to component state
			case "discountCode":
				this.setState({discountCode: evt.target.value});
				break;
			case "donationAmt":
				this.setState({donationAmt: evt.target.value});
				break;
			case "emailConfirm":
				this.setState({emailConfirm: evt.target.value});
				break;
			default:
				// Other props are saved to the cart state
				this.props.saveProps({[evt.target.name]: scrubbedValue(evt)});
		}
	}
	
	private handleMultiSelectChange = (name: string, values: string[]) => {
		this.props.saveProps({[name]: values.join(';')});
	}
	
	private handleStateFieldChange = (fieldName: string, fieldValue: string) => {
		this.props.saveProps({[fieldName] : fieldValue});
	}
	
	private handleApplyDiscount = () => {
		const {applyDiscount, cart, clearAllMessages, intl, showAlert} = this.props;
		const {discountCode} = this.state;
		
		// Clear error messages at the start of the submit operation
		clearAllMessages();
		
		// Make sure the discount code value is not blank
		if (!this.state.discountCode) {
			const errors = {discountCode: intl.formatMessage({id: "msg_discount_cannot_be_blank"})};
			this.setState({errors});
			return;
		}
		
		applyDiscount(cart.cartId, discountCode, cart.modstamp)
			.then(result => {
				if (!!result.data && 'numberOfItemsDiscounted' in result.data) {
					const discountCount = result.data.numberOfItemsDiscounted;
					if (discountCount > 0) {
						showAlert(intl.formatMessage({id: "msg_discount_applied_to_items"}, {discountCode, discountCount}), Severities.SUCCESS);
					} else {
						showAlert(intl.formatMessage({id: "msg_discount_not_applicable"}, {discountCode}), Severities.WARNING);
					}
				}
			});
	}
	
	private handleSubmit = () => {
		const {cart, clearAllMessages, history, intl, saveProps, showAlert, updateCart} = this.props;
		const {donationAmt, useDifferentAddressForShipping} = this.state;
		
		// Clear error messages at the start of the submit operation
		clearAllMessages();
		
		if (!canEditBuyerInfo(cart)) {
			return history.push(this.props.nextPagePath);
		}
		
		// Perform any client-side validation
		if (!this.validate()) {
			return;
		}

		// If the delivery method is "Ship" and we are NOT using a different shipping address,
		// then copy the billing address fields to the shipping address fields before submitting
		let cartProps: Partial<Cart> = {};
		if (cart.delMethod === DeliveryMethods.SHIP && !useDifferentAddressForShipping) {
			cartProps = this.copyBillingToShipping();
		}
		
		// Convert the state property into a number before saving it to the cart
		if (!!donationAmt) {
			cartProps.donationAmt = Number(Number(donationAmt).toFixed(2));
		}
		
		saveProps(cartProps);
		updateCart()
			.then((result: any) => {
				switch (result.type) {
					case ActionTypes.API_SUCCESS: {
						const updatedCart: Cart = result.data;
						const filteredValidationErrors = this.getFilteredValidationErrors(updatedCart.validationErrors || []);
						if (filteredValidationErrors.length > 0) {
							const errors = handleApplicationErrors(this.getFilteredValidationErrors(filteredValidationErrors), this.fieldMap, intl);
							if (Object.keys(errors).length > 0) {
								showAlert(formatValidationErrors(errors), Severities.ERROR);
							}
							this.setState({errors});
						} else {
							// If successfully updated with no validation errors, navigate to the next page
							history.push(this.props.nextPagePath);
						}
						break;
					}
					case ActionTypes.API_FAILURE: {
						if (!!result.errors && result.errors.length > 0) {
							const errors: BasicStringKeyedMap<string> = {};
							result.errors.forEach((err: any) => {
								if (!!err.messages && err.messages.length > 0) {
									Object.assign(errors, {...handleApplicationErrors(this.getFilteredValidationErrors(err.messages), this.fieldMap, intl)});
								}
							});
							this.setState({errors});
						}
						break;
					}
				}
			});
	}
	
	/**
	 * Performs conditional filtering of the cart.validationErrors collection prior to passing it off to handleApplicationErrors.
	 * At this time, the only filtering is to filter out "shipping" field validation errors when the shipping fields
	 * are not actually visible on the form.
	 */
	private getFilteredValidationErrors = (validationErrors: ApplicationMessage[]): ApplicationMessage[] => {
		const {cart} = this.props;
		const {useDifferentAddressForShipping} = this.state;
		let filteredValidationErrors: ApplicationMessage[] = validationErrors;
		
		// If DM is ship, but we're not shipping to a different address, then we don't want to display
		// "field is required" errors for the hidden "shipping" fields, so remove them from the validationErrors
		// collection before passing them to handleApplicationErrors.
		if (cart.delMethod === DeliveryMethods.SHIP && !useDifferentAddressForShipping) {
			filteredValidationErrors = validationErrors.filter(validationError => {
				const fieldName: string = (!!validationError.msgArgs && !!validationError.msgArgs.fieldName) ? validationError.msgArgs.fieldName : "";
				return !fieldName.startsWith("shipping");
			});
		}
		return filteredValidationErrors;
	}
	
	private validate = () => {
		const {cart, intl, showAlert} = this.props;
		const {donationAmt, emailConfirm} = this.state;
		const errors: BasicStringKeyedMap<string> = {};
		
		if (!!donationAmt && (isNaN(Number(donationAmt)) || (Number(donationAmt) < 0))) {
			errors.donationAmt = intl.formatMessage({id: "msg_amount_is_invalid"});
		}

		if (!!cart.email && !emailConfirm) {
			errors.emailConfirm = intl.formatMessage({ id: "msg_is_required" }, {fieldLabel: this.state.fieldLabels.emailConfirm});
		}

		if (!!cart.email && !!emailConfirm && emailConfirm !== cart.email) {
			errors.emailConfirm = intl.formatMessage({ id: "msg_email_mismatch" });
		}
		
		const isValid = Object.keys(errors).length === 0;
		if (!isValid) {
			showAlert(formatValidationErrors(errors), Severities.ERROR);
		}
		this.setState({errors});
		return isValid;
	}

	private back = () => {
		this.props.history.push(this.props.prevPagePath);
	}

	private onCustomCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const customFields = { ...this.props.cart.customFields };
		customFields[evt.target.name] = '' + evt.target.checked;
		this.props.onCustomFieldChange(customFields);
	}

	private onCustomDateFieldChange = (fieldName: string, date: Date) => {
		const customFields = { ...this.props.cart.customFields };
		customFields[fieldName] = date ? '' + date.valueOf() : null;
		this.props.onCustomFieldChange(customFields);
	}

	private onCustomFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const customFields = { ...this.props.cart.customFields };
		customFields[evt.target.name] = scrubbedValue(evt);
		this.props.onCustomFieldChange(customFields);
	}

	private onCustomMultiPicklistFieldChange = (fieldName: string, values: string[]) => {
		const customFields = { ...this.props.cart.customFields };
		customFields[fieldName] = values.join(';');
		this.props.onCustomFieldChange(customFields);
	}

	private configureCustomFields = () => {
		const { cart, config } = this.props;
		const { errors } = this.state;
		const { customFields, currencySym } = config;
		const customInputs: JSX.Element[] = [];
		const isDisabled = !canEditBuyerInfo(cart);
		if (!!customFields) {
			customFields.forEach((field) => {
				let input;
				switch (field.displayType) {
					case SO_TYPE.DATE: {
						const dateValue = cart.customFields[field.name] ? new Date(Number(cart.customFields[field.name])) : null;
						input = (
							<FieldGroup
								id={field.name}
								name={field.name}
								type={FieldGroupTypes.DATE}
								label={field.label}
								locale={window.PublicTicketApp.locale}
								value={dateValue}
								onDateChange={(date: Date) => this.onCustomDateFieldChange(field.name, date)}
								required={field.required}
								invalid={!!errors[field.name.toLocaleLowerCase()]}
								disabled={isDisabled}
							/>
						);
						break;
					}
					case SO_TYPE.BOOLEAN: {
						input = (
							<FieldGroup
								id={field.name}
								name={field.name}
								type={FieldGroupTypes.CHECKBOX}
								label={field.label}
								value={cart.customFields[field.name]}
								checked={cart.customFields[field.name] === 'true'}
								onChange={this.onCustomCheckboxChange}
								required={field.required}
								invalid={!!errors[field.name.toLocaleLowerCase()]}
								disabled={!field.createable || isDisabled}
							/>
						);
						break;
					}
					case SO_TYPE.PERCENT:
					case SO_TYPE.DOUBLE: {
						input = (
							<FieldGroup
								id={field.name}
								name={field.name}
								type={FieldGroupTypes.NUMBER}
								label={field.label}
								value={cart.customFields[field.name] || ""}
								onChange={this.onCustomFieldChange}
								required={field.required}
								invalid={!!errors[field.name.toLocaleLowerCase()]}
								disabled={isDisabled}
							/>
						);
						break;
					}
					case SO_TYPE.CURRENCY:
						input = (
							<FieldGroup
								id={field.name}
								name={field.name}
								type={FieldGroupTypes.CURRENCY}
								label={field.label}
								value={cart.customFields[field.name] || ""}
								onChange={this.onCustomFieldChange}
								currencySymbol={currencySym}
								required={field.required}
								invalid={!!errors[field.name.toLocaleLowerCase()]}
								disabled={isDisabled}
							/>
						);
						break;
					case SO_TYPE.STRING:
					case SO_TYPE.PHONE:
					case SO_TYPE.URL: {
						input = (
							<FieldGroup
								id={field.name}
								name={field.name}
								type={FieldGroupTypes.TEXT}
								readonly={!field.createable}
								label={field.label}
								value={cart.customFields[field.name] || ""}
								onChange={this.onCustomFieldChange}
								required={field.required}
								invalid={!!errors[field.name.toLocaleLowerCase()]}
								disabled={isDisabled}
							/>
						);
						break;
					}
					case SO_TYPE.EMAIL: {
						input = (
							<FieldGroup
								id={field.name}
								name={field.name}
								type={FieldGroupTypes.EMAIL}
								label={field.label}
								value={cart.customFields[field.name] || ""}
								onChange={this.onCustomFieldChange}
								required={field.required}
								invalid={!!errors[field.name.toLocaleLowerCase()]}
								disabled={isDisabled}
							/>
						);
						break;
					}
					case SO_TYPE.PICKLIST: {
						if (!!field.pickListOptions) {
							const selectionOptions = field.pickListOptions.map((option) => {
								return <option key={option.value} value={option.value}>{option.label}</option>;
							});
							input = (
								<FieldGroup
									id={field.name}
									name={field.name}
									type={FieldGroupTypes.SELECT}
									label={field.label}
									value={cart.customFields[field.name] || ""}
									selectionOptions={selectionOptions}
									onChange={this.onCustomFieldChange}
									required={field.required}
									invalid={!!errors[field.name.toLocaleLowerCase()]}
									disabled={isDisabled}
								/>
							);
						}
						break;
					}
					case SO_TYPE.MULTIPICKLIST: {
						const selectedItems = (cart.customFields[field.name]) ? cart.customFields[field.name].split(';') : [];
						input = (
							<FieldGroup
								id={field.name}
								name={field.name}
								type={FieldGroupTypes.MULTIPICKLIST}
								label={field.label}
								multiple={true}
								value={selectedItems}
								selectionOptions={field.pickListOptions}
								onMultiselectChange={(values) => this.onCustomMultiPicklistFieldChange(field.name, values)}
								required={field.required}
								invalid={!!errors[field.name.toLocaleLowerCase()]}
								disabled={isDisabled}
							/>
						);
						break;
					}
					case SO_TYPE.TEXTAREA: {
						input = (
							<FieldGroup
								id={field.name}
								name={field.name}
								type={FieldGroupTypes.TEXTAREA}
								label={field.label}
								value={cart.customFields[field.name] || ""}
								onChange={this.onCustomFieldChange}
								required={field.required}
								invalid={!!errors[field.name.toLocaleLowerCase()]}
								disabled={isDisabled}
							/>
						);
						break;
					}
					default:
						input = null;  //don't show it if it's not supported
				}
				customInputs.push(
					<div key={field.name}>
						{input}
					</div>
				);
			});
		}
		return customInputs;
	}
}
