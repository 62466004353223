import Cookies from "js-cookie";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from 'react-router-dom';
import {Badge, Button, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem} from 'reactstrap';
import Collapse from "reactstrap/lib/Collapse";
import {Paths} from "../../enums/paths";
import {CartService} from "../../helpers/cart-service";
import {getNormalizedEventsUrl, NormalizedEventsUrl} from "../../helpers/routing";
import {hasDarkModeHeader, isPortalUser} from "../../helpers/utilities";
import {Cart} from "../../models/cart";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {CountdownTimer} from "../countdown-timer";
import './header.css';
import {NavLinkForInternalOrExternal} from "./nav-link-for-internal-or-external";
import {UserWelcome} from "./user-welcome";

interface HeaderProps {
	cart: Cart;
	cartTimeRemaining?: number;
	config: PublicTicketAppConfig;
	headerImagePath?: string;
	mobileHeaderImagePath?: string;
	logoOnly?: boolean;
	logoURL?: string;
	onClickLogin: () => void;
	orgName: string;
}
interface HeaderState {
	readonly isToggleOpen: boolean;
}
/**
 * Header - The general header for the PTS app.
 */
export class Header extends React.Component<HeaderProps, HeaderState> {
	
	public state: HeaderState = {
		isToggleOpen: false,
	};
	
	public handleToggleClick = () => {
		this.setState({isToggleOpen: !this.state.isToggleOpen});
	}
	
	public render() {
		const {
			onClickLogin,
			config: {
				portalEnabled,
				userProfile,
				eventsUrl,
				membershipsUrl,
				subscriptionsUrl,
				themeProps,
			},
			cart,
			cartTimeRemaining,
			headerImagePath,
			mobileHeaderImagePath,
			logoOnly,
			logoURL,
			orgName,
		} = this.props;
		
		const orderQuantity = new CartService(cart).getTotalRetailQuantity();
		
		const logo = !!headerImagePath && (
			<span>
				{/* Constrain size on "extra small" and "small" screens */}
				<img
					className="d-md-none"
					src={window.PublicTicketApp.sitePrefix + (!!mobileHeaderImagePath ? mobileHeaderImagePath : headerImagePath)}
					alt={orgName}
					height={30}
				/>
				{/* Do not constrain on screens "medium" and larger. */}
				<img
					className="d-none d-md-inline"
					src={window.PublicTicketApp.sitePrefix + headerImagePath}
					alt={orgName}
					style={{ maxHeight: '200px' }}
				/>
			</span>
		);

		const cartLink = (
			<NavItem className="ml-auto mr-2">
				<Link className="nav-link" to={Paths.CART}>
					<Button color="primary" outline={true}>
						<span className="mr-1">
							<FormattedMessage id="lbl_nav_Cart" />
						</span>
						<span className="mr-3">
							<Badge color="primary" pill={true}>{orderQuantity}</Badge>
						</span>
						<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={false} />
					</Button>
				</Link>
			</NavItem>
		);
		
		const loginLink = (
			<NavLinkForInternalOrExternal
				formattedMessageId='lbl_LogIn'
				onClick={onClickLogin}
			/>
		);

		const userWelcome = !!userProfile && (
			<UserWelcome
				userName={userProfile.firstName}
				handleLogoutClick={this.handleLogoutClick}
			/>
		);
		
		// PMGR-8173 Determine which of the three event type links to show and where they should link to, based on the three Url properties.
		let eventsLink = null;
		const normalizedEventsUrl: NormalizedEventsUrl | null = getNormalizedEventsUrl(eventsUrl);
		if (!!normalizedEventsUrl) {
			eventsLink = normalizedEventsUrl.isInternal
				? <NavLinkForInternalOrExternal internalUrl={normalizedEventsUrl.eventsUrl} formattedMessageId="pmgr_term_Events"/>
				: <NavLinkForInternalOrExternal externalUrl={normalizedEventsUrl.eventsUrl} formattedMessageId="pmgr_term_Events"/>;
		}
		
		let membershipsLink = null;
		if (!membershipsUrl) {
			// If blank, link to the default memberships path
			membershipsLink = <NavLinkForInternalOrExternal internalUrl={Paths.MEMBERSHIPS} formattedMessageId={'pmgr_term_Memberships'}/>;
		} else if (membershipsUrl !== 'nolink') {
			// If not blank, and not 'nolink', then assume an external URL
			membershipsLink = <NavLinkForInternalOrExternal externalUrl={membershipsUrl} formattedMessageId={'pmgr_term_Memberships'}/>;
		}
		
		let subscriptionsLink = null;
		if (!subscriptionsUrl) {
			// If blank, link to the default subscriptions path
			subscriptionsLink = <NavLinkForInternalOrExternal internalUrl={Paths.SUBSCRIPTIONS} formattedMessageId={'pmgr_term_Subscriptions'}/>;
		} else if (subscriptionsUrl !== 'nolink') {
			// If not blank, and not 'nolink', then assume an external URL
			subscriptionsLink = <NavLinkForInternalOrExternal externalUrl={subscriptionsUrl} formattedMessageId={'pmgr_term_Subscriptions'}/>;
		}
		
		const portalStuff = isPortalUser(userProfile) ? userWelcome : loginLink;
		const isDark: boolean = hasDarkModeHeader(themeProps);
		
		return (
			<div className="pts-header border-bottom">
				<Navbar light={!isDark} dark={isDark} expand="md">
					<NavbarBrand href={logoURL} className="mr-auto">
						{!!logo ? logo : orgName}
					</NavbarBrand>
					<NavbarToggler onClick={this.handleToggleClick} className="mr-2" />
					{!logoOnly  &&
						<Collapse isOpen={this.state.isToggleOpen} navbar={true}>
							<Nav className="ml-auto" navbar={true}>
								{subscriptionsLink}
								{membershipsLink}
								{eventsLink}
								{!!cart.cartId && cartLink}
								{portalEnabled && portalStuff}
							</Nav>
						</Collapse>
					}
				</Navbar>
			</div>
		);
	}
	
	private handleLogoutClick = () => {
		/* 
			We're just going to clear the cart cookie and the user has to start over with an empty cart.
			This is the easiest/cleanest way to deal with this otherwise we'd have to make lots of modifications 
			to the order like removing the contact & account info, readjust all the discounts(no more member discounts),
			recalculate fees, etc.
		*/
		Cookies.remove("cartId");
	}
}
