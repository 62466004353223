import { ITEM_FEE_PROPS } from '../../helpers/utilities';
import {CartItem, CartItemFees} from '../cart-item';
import { SubscriptionTicketDisplay } from './subscription-ticket-display';

export class SubscriptionFeeDisplay {

	private static DISCOUNT_DEVELOPER_NAME: string = 'disTotal';

	public static feeNames: string[] = [...ITEM_FEE_PROPS, SubscriptionFeeDisplay.DISCOUNT_DEVELOPER_NAME];
	public name: string = '';
	public amount: number = 0;

	constructor(name: string, value: number) {
		this.name = name;
		this.amount = value;
	}

	public static get discountDeveloperName() {
		return SubscriptionFeeDisplay.DISCOUNT_DEVELOPER_NAME;
	}

	public static tallyFees = ((cartItem: CartItem, ticketDisplay: SubscriptionTicketDisplay) => {

		if (!ticketDisplay) {
			return;
		}

		SubscriptionFeeDisplay.feeNames.forEach((feeItem: keyof CartItemFees | 'disTotal') => {

			const amount = !!cartItem[feeItem] ? cartItem[feeItem] : 0;

			if (!!ticketDisplay.fees[feeItem]) {
				ticketDisplay.fees[feeItem].amount = ticketDisplay.fees[feeItem].amount + amount;
			} else {
				ticketDisplay.fees[feeItem] = new SubscriptionFeeDisplay(feeItem, amount);
			}
		});
	});
}