import {FormattedMessage, FormattedNumber} from "react-intl";

interface OrderInfoProps {
	currencyCode: string;
	orderNumber: string;
	orderTotal: number;
	retailQuantity: number;
}

export const OrderInfo = (props: OrderInfoProps)  => {
	const {currencyCode, orderNumber, orderTotal, retailQuantity} = props;
	return (
		<div className="pts-order-info p-2 m-0 mb-5">
			<p className="text-uppercase font-weight-bold border-bottom">
				<span>{orderNumber}</span>
			</p>
			<p className="m-0">
				<FormattedMessage id="msg_item_with_qty" values={{qty: retailQuantity}}/>
			</p>
			<p className="m-0 font-weight-bold d-flex justify-content-between">
				<FormattedMessage id="lbl_Total"/>
				<FormattedNumber value={orderTotal} style="currency" currency={currencyCode} currencyDisplay="symbol"/>
			</p>
		</div>
	);
};
