import {IntlShape} from "react-intl";
import {Form} from "reactstrap";
import {EventInstanceSaleStatus} from "../../enums/event-instance-sale-status";
import {InventoryService} from "../../helpers/inventory-service";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {SubscriptionEventDescriptor} from "../../models/event-descriptor/subscription-event-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {Message} from "../message";
import {AllocationSelection} from "./allocation-selection";
import {PriceLevelQuantitySelection} from "./price-level-quantity-selection";
import {Stages} from "./stages";
import {SubscriptionFormElements} from "./subscription-form-elements";

interface FixedSubscriptionFormProps {
	config: PublicTicketAppConfig;
	intl: IntlShape;
	onAllocationChange: (allocationId: string) => void;
	onQuantityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	priceLevelQuantityMap: BasicStringKeyedMap<string>;
	selectedAllocationId: string;
	stage: Stages;
	subsEventDescriptor: SubscriptionEventDescriptor;
}

export const SubscriptionForm: React.FunctionComponent<FixedSubscriptionFormProps> = (props) => {
	const {
		config,
		intl,
		onAllocationChange,
		onQuantityChange,
		priceLevelQuantityMap,
		selectedAllocationId,
		stage,
		subsEventDescriptor
	} = props;
	
	const subscriptionPriceLevelsMap: BasicStringKeyedMap<LevelDescriptor[]> = InventoryService.getLevelsMappedByAllocationId(props.subsEventDescriptor);
	
	return (
		<>
			<SubscriptionFormElements portalSeatSelectionMessage={config.portalSeatSelectionMessage} subsEventDescriptor={subsEventDescriptor}/>
			{props.subsEventDescriptor.saleStatus !== EventInstanceSaleStatus.OS
				? (!!subsEventDescriptor.noSaleMsg
					? <Message htmlMessage={subsEventDescriptor.noSaleMsg} />
					: <Message intlId="msg_item_not_available_for_purchase" />
				) : (
					<Form>
						{stage === Stages.PickingAllocation &&
							<AllocationSelection 
								allocations={subsEventDescriptor.allocList}
								intl={intl}
								onAllocationChange={onAllocationChange}
								selectedAllocationId={selectedAllocationId}
								subscriptionPriceLevelsMap={subscriptionPriceLevelsMap}
							/>
						}
						{stage === Stages.PickingPriceLevelQuantity &&
							<PriceLevelQuantitySelection
								currencyCode={config.currencyCode}
								intl={intl}
								onQuantityChange={onQuantityChange}
								priceLevelQuantityMap={priceLevelQuantityMap}
								priceLevels={subscriptionPriceLevelsMap[selectedAllocationId]}
							 	includeItemFees={config.includeFeesInPrice}
							/>
						}
					</Form>
				)
			}
		</>
	);
};
