import * as React from "react";
import {Redirect, Route, Switch} from "react-router";
import AddToCart from "../containers/add-to-cart";
import Cart from "../containers/cart";
import Complete from "../containers/complete";
import Events from "../containers/events";
import {PortalConnected} from "../containers/portal/portal";
import {Paths} from "../enums/paths";
import CaptureRouteNotFound from "./error/capture-route-not-found";
import {RouteNotFound} from "./error/route-not-found";
import Event from "./event";
import Fulfillment from "./fulfillment/fulfillment";
import MobileTicketLanding from "../containers/mobile-ticket-landing/mobile-ticket-landing";

/**
 * Root Router for public ticket app
 */
export class PublicTicketAppRoutes extends React.Component {
	public render() {
		return (
			<CaptureRouteNotFound>
				<Switch>
					{/* Routes with child routes */}
					<Route path={Paths.INSTANCE} component={AddToCart} />
					<Route path={Paths.FULFILLMENT} component={Fulfillment} />
					<Route path={Paths.CART} component={Cart}/>
					<Route path={Paths.PORTAL} component={PortalConnected}/>
					
					{/* Routes without child routes. These route should have "exact={true}" set for 404 redirect to work correctly*/}
					<Route path={Paths.EVENTS} component={Events} exact={true}/>
					<Route path={Paths.EVENT} component={Event} exact={true}/>
					<Route path={Paths.COMPLETE} component={Complete} exact={true}/>
					<Route path={Paths.SUBSCRIPTIONS} component={Events} exact={true}/>
					<Route path={Paths.MEMBERSHIPS} component={Events} exact={true}/>
					<Route path={Paths.MOBILE_TICKETS} component={MobileTicketLanding} exact={true}/> 
					<Route path={Paths.MOBILE_TICKETS_WITH_FILTER} component={MobileTicketLanding} exact={true}/>

					{/* Redirects */}
					<Redirect from={Paths.LEGACY_EVENT_INSTANCE_DETAIL} to={Paths.INSTANCE} />
					<Redirect from={Paths.LEGACY_TICKETABLE_EVENT_DETAIL} to={Paths.EVENT} />
					<Redirect from={Paths.LEGACY_MEMBERSHIP_LIST} to={Paths.MEMBERSHIPS} />
					
					{/* Handles 404 routing for unmatched routes. Child routes must handle their own 404 routing by also containing the <RouteNotFound/> component. */}
					<RouteNotFound/>
				</Switch>
			</CaptureRouteNotFound>
		);
	}
}