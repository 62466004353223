import {VfRemoteActionMethods} from "../enums/vf-remote-action-methods";
import {CartItem} from "../models/cart-item";
import {Benefit} from "../models/portal/benefit";
import {PortalDonation} from "../models/portal/portal-donation";
import {PortalOrder} from "../models/portal/portal-order";
import {VfRemoteErrorResponse} from "../models/vf-remote-error-response";
import {VfRemoteResponse} from "../models/vf-remote-response";

/**
 * Methods here execute an http request to visual force page.
 *
 * This is the first layer between the server and client
 */
export default class VfRemote {

	public static vfFetchGlobalConfig(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_GLOBAL_CONFIG, params.draftTheme);
	}

	public static vfFetchLocalizedMessages() {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_LOCALIZED_MESSAGES);
	}

	public static vfFetchEvent(params: any) {
		const accessCode = params.accessCode || '';
		const passcode = params.passcode || '';

		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_EVENT, params.eventId, params.siteURL, accessCode, passcode);
	}

	public static vfFetchEvents(params: any) {
		const accessCode = params.accessCode || '';
		const passcode = params.passcode || '';
		
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_EVENTS, params.siteURL, accessCode, passcode);
	}

	public static vfFetchEventDescriptor(params: any) {
		const accessCode = params.accessCode || '';
		const passcode = params.passcode || '';
		
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_EVENT_DESCRIPTOR, params.eiId, accessCode, passcode);
	}

	public static vfFetchFulfillmentEventDescriptor(params: any) {
		// The eiId param will be a comma separated string containing multiple EI Ids. Split it into an array because
		// that is what the remote action accepts as a parameter.
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_FULFILLMENT_EVENT_DESCRIPTOR, params.cartId, params.modstamp, params.eiIds.split(','), params.sbslIds);
	}

	public static vfFetchPreviewFulfillmentEventDescriptor(params: any) {
		const accessCode = params.accessCode || '';
		const passcode = params.passcode || '';
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_PREVIEW_FULFILLMENT_EVENT_DESCRIPTOR, params.eiId, params.spllIds, accessCode, passcode);
	}

	public static vfFetchItemFeeData(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_ITEM_FEE_DATA, params.cartId, params.activeOnly);
	}

	public static vfFetchOrderFeeData(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_ORDER_FEE_DATA, params.cartId);
	}
	
	public static vfFetchGiftCardBalance(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_GIFT_CARD_BALANCE, params.gcNumber, params.captchaToken);
	}

	public static vfFetchGiftCardBalanceByPaymentMethodId(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_GIFT_CARD_BALANCE_BY_PAYMENT_METHOD_ID, params.paymentMethodId);
	}
	
	public static vfFetchMobileTicketCart(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_MOBILE_TICKET_CART, params.ticketOrderId, params.token, params.eiId, params.toiIds);
	}

	public static vfEnsureCart(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.ENSURE_CART, params.cartId, params.userAgent, params.orderSource, params.createIfNecessary);
	}

	public static vfUpdateCart(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.UPDATE_CART, params.cart);
	}

	public static vfSubmitCart(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.SUBMIT_ORDER, params.cart);
	}

	public static vfInsertCartItems(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.INSERT_CART_ITEMS, params.cartId, params.modstamp, params.cartItems, params.accessCode);
	}
	
	public static vfInsertPYOSSubscriptionCartItems(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS, params.cartId, params.modstamp, params.cartItems, params.accessCode);
	}
	
	public static vfInsertSubscriptionCartItems(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.INSERT_SUBSCRIPTION_CART_ITEMS, params.cartId, params.modstamp, params.cartItems, params.selectedTicketPLsBySubscriptionPLMap, params.accessCode);
	}

	public static vfInsertFulfillmentItems(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.INSERT_FULFILLMENT_ITEMS, params.cartId, params.modstamp, params.cartItems);
	}

	public static vfFetchSubscriptionFulfillmentGroups(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_SUBSCRIPTION_FULFILLMENT_GROUPS, params.cartId, params.sbslIds);
	}

	public static vfUpdateCartItems(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.UPDATE_CART_ITEMS, params.cartId, params.modstamp, params.cartItems, params.accessCode);
	}

	public static vfUpdateUserProfile(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.UPDATE_USER_PROFILE, params.user);
	}

	public static vfDeleteCartItems(params: any) {
		// Convert the cartItems array into an array of cart item ids before calling the Visualforce remote method
		const cartItemIds = params.cartItems.map((cartItem: CartItem) => cartItem.id);

		return VfRemote.vfRemote(VfRemoteActionMethods.DELETE_CART_ITEMS, params.cartId, params.modstamp, cartItemIds);
	}

	public static vfApplyDiscount(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.APPLY_DISCOUNT, params.cartId, params.discountCode, params.modstamp);
	}

	public static vfValidatePasscode(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.VALIDATE_PASSCODE, params.passcode, params.eventInstanceId);
	}
	
	public static vfValidateCYOSubscriptionPriceLevels(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.VALIDATE_CYO_SUBSCRIPTION_PRICE_LEVELS, params.subscriptionPriceLevelIds);
	}

	public static vfFetchApplePass(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_APPLE_PASS, params.toiId, params.token);
	}

	public static vfFetchGooglePassURL(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_GOOGLE_PASS_URL, params.toiId, params.token);
	}
	
	//
	// Portal Specific
	//

	public static vfLogin(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.LOGIN, params.userName, params.password, params.retURL, params.cartId);
	}
	public static vfForgotPassword(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FORGOT_PASSWORD, params.userName);
	}
	
	public static vfChangePassword(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.CHANGE_PASSWORD, params.oldPassword, params.newPassword, params.verifyPassword);
	}
	
	public static vfFetchPortalOrders(): Promise<PortalOrder> {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_PORTAL_ORDERS);
	}
	
	public static vfFetchPortalDonations(): Promise<PortalDonation> {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_PORTAL_DONATIONS);
	}
	
	public static vfFetchRenewableBenefits(): Promise<Benefit> {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_RENEWABLE_BENEFITS);
	}
	
	public static vfFetchPortalOrderDetails(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_PORTAL_ORDER_DETAILS, params.ticketOrderId);
	}
	
	public static vfFetchPendingRenewal(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.FETCH_PENDING_RENEWAL, params.ticketOrderId);
	}
	
	public static vfDoNotRenew(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.DO_NOT_RENEW, params.ticketOrderId);
	}
	
	public static vfSubmitContactRequest(params: any) {
		return VfRemote.vfRemote(VfRemoteActionMethods.SUBMIT_CONTACT_REQUEST, params.whatId, params.request);
	}

	///
	/// Private Methods
	///

	private static vfRemote(method: VfRemoteActionMethods, ...args: any[]): Promise<any> {
		return new Promise((resolve, reject) => {
			// The namespace variable is a global defined in BoxOffice.page
			const namespacePrefix = window.PublicTicketApp.namespace ? (window.PublicTicketApp.namespace + ".") : "";
			let invokeArgs: any = [namespacePrefix + "Controller_PublicTicketApp." + method];

			// If there are "extra" arguments to the VF Remote method, they will be in the args variable
			if (args.length > 0) {
				invokeArgs = invokeArgs.concat(Array.prototype.slice.call(args, 0));
			}

			invokeArgs.push((result: any, response: VfRemoteResponse) => {

					if (response.status) {
						resolve(result);
					}
					else {
						response = VfRemote.parseVFRemotingException(response as VfRemoteErrorResponse);
						reject(response);
					}
				}
			);

			invokeArgs.push({buffer: false, escape: false, timeout: 120000});
			window.Visualforce.remoting.Manager.invokeAction.apply(window.Visualforce.remoting.Manager, invokeArgs);
		});
	}

	private static parseVFRemotingException(payload: VfRemoteErrorResponse): VfRemoteErrorResponse {
		if (payload.message[0] === "{") { // smells like JSON
			const resp = JSON.parse(payload.message);
			resp.type = "exception";
			return resp;
		}
		return payload;
	}
}
