import * as React from 'react';
import {IntlShape} from 'react-intl';
import {RouterProps} from "react-router";
import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";
import {Severities} from "../enums/severities";
import {formatValidationErrors, handleApplicationErrors} from "../helpers/checkout-form-utils";
import {getSuggestedDonationHelpText} from "../helpers/localization";
import {canEditBuyerInfo} from "../helpers/utilities";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {CountdownTimer} from "./countdown-timer";
import {FieldGroup, FieldGroupTypes} from "./field-group";
import {PanelNav} from './panel-nav';
import {WaitingMessage} from './waiting-message';

/**
 * All properties that should be defined when using the component
 */
interface DonationFormProps extends RouterProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	intl: IntlShape;
	nextPagePath: string;
	prevPagePath: string;
	saveProps: (props: any) => void;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
	updateCart: () => Promise<any>;
}

interface DonationFormState {
	donationAmt: string;
	errors: BasicStringKeyedMap<string>;
	dirty: boolean;
}

export class DonationForm extends React.Component<DonationFormProps, DonationFormState> {
	public readonly state: DonationFormState = {
		donationAmt: "",
		errors: {},
		dirty: false
	};
	
	private readonly fieldMap: BasicStringKeyedMap<string> = {
		donationamount__c: "donationAmt"
	};
	
	public componentDidMount() {
		// Convert the Cart donationAmt property to a string and save to local state
		const {cart} = this.props;
		this.setState({donationAmt: !!cart.donationAmt ? cart.donationAmt.toFixed(2) : ""});
	}
	
	public render() {
		const {donationAmt, errors} = this.state;
		const {blockingActions, cart, cartTimeRemaining, config, intl} = this.props;
		const {donationLabel, currencySym} = config;

		const isBusy: boolean = Object.keys(blockingActions).length > 0;
		const helpText = getSuggestedDonationHelpText(cart, config, intl);
		
		return (
			<div style={{minHeight: '300px'}} className="d-flex flex-column">
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				<FieldGroup
					id="donationAmt"
					name="donationAmt"
					type={FieldGroupTypes.CURRENCY}
					label={donationLabel}
					value={donationAmt}
					onChange={this.handleChange}
					currencySymbol={currencySym}
					help={helpText}
					invalid={!!errors.donationAmt}
					feedbackMessage={errors.donationAmt}
					disabled={!canEditBuyerInfo(cart)}
				/>
				<div className="mt-auto">
					<WaitingMessage isOpen={isBusy} />
					<PanelNav
						next={{handleClick: this.handleSubmit, label: intl.formatMessage({id: "lbl_Next"}), isDisabled: isBusy}}
						back={{handleClick: this.back, label: intl.formatMessage({id: "lbl_Back"})}}
					/>
				</div>
			</div>
		);
	}
	
	private handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		// Maintain the donation amount in component state until we try to validate or submit
		this.setState({dirty: true, donationAmt: evt.target.value});
	}
	
	private handleSubmit = () => {
		const {dirty, donationAmt} = this.state;
		const {cart, clearAllMessages, history, intl, nextPagePath, saveProps, showAlert, updateCart} = this.props;
		
		// Clear error messages at the start of the submit operation
		clearAllMessages();
		
		if (!canEditBuyerInfo(cart)) {
			history.push(this.props.nextPagePath);
		}
		
		// Perform any client-side validation
		if (!this.validate()) {
			return;
		}
		
		// No point in updating the cart if there are no changes to donation amount
		if (dirty) {
			// Convert the state property into a number before saving it to the cart
			saveProps({donationAmt: !!donationAmt ? Number(Number(donationAmt).toFixed(2)) : null});
			updateCart()
				.then((result: any) => {
					switch (result.type) {
						case ActionTypes.API_SUCCESS: {
							const updatedCart: Cart = result.data;
							const filteredValidationErrors = this.getFilteredValidationErrors(updatedCart.validationErrors || []);
							if (filteredValidationErrors.length > 0) {
								const errors = handleApplicationErrors(filteredValidationErrors, this.fieldMap, intl);
								if (Object.keys(errors).length > 0) {
									showAlert(formatValidationErrors(errors), Severities.ERROR);
								}
								this.setState({errors});
							} else {
								// If successfully updated with no blocking validation errors, navigate to the next page
								history.push(this.props.nextPagePath);
							}
							break;
						}
						case ActionTypes.API_FAILURE: {
							if (!!result.errors && result.errors.length > 0) {
								const errors: BasicStringKeyedMap<string> = {};
								result.errors.forEach((err: any) => {
									if (!!err.messages && err.messages.length > 0) {
										Object.assign(errors, {...handleApplicationErrors(this.getFilteredValidationErrors(err.messages), this.fieldMap, intl)});
									}
								});
								this.setState({errors});
							}
							break;
						}
					}
				});
		} else {
			history.push(nextPagePath);
		}
	}
	
	/**
	 * Performs conditional filtering of the cart.validationErrors collection prior to passing it off to handleApplicationErrors.
	 * At this time, the only validation error we will show here are ones pertaining to the "donationamount__c" field.
	 */
	private getFilteredValidationErrors = (validationErrors: ApplicationMessage[]): ApplicationMessage[] => {
		return validationErrors.filter(validationError => {
			const fieldName: string = (!!validationError.msgArgs && !!validationError.msgArgs.fieldName) ? validationError.msgArgs.fieldName : "";
			return fieldName.toLowerCase() === "donationamount__c";
		});
	}
	
	private validate = () => {
		const {intl, showAlert} = this.props;
		const {donationAmt} = this.state;
		const errors: BasicStringKeyedMap<string> = {};
		if (!!donationAmt && (isNaN(Number(donationAmt)) || (Number(donationAmt) < 0))) {
			errors.donationAmt = intl.formatMessage({id: "msg_amount_is_invalid"});
		}
		const isValid = Object.keys(errors).length ===0;
		if (!isValid) {
			showAlert(formatValidationErrors(errors), Severities.ERROR);
		}
		this.setState({errors});
		return isValid;
	}
	
	private back = () => {
		this.props.history.push(this.props.prevPagePath);
	}
}
