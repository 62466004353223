import {History} from "history";
import * as React from "react";
import {CSSProperties} from "react";
import {
	getSectionRoute,
	getSubFulfillmentInstanceSectionRoute,
	getSubFulfillmentSectionRoute
} from "../../helpers/routing";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";

declare var window: any;

interface SectionProps {
	section: SectionDescriptor;
	eventDescriptor: EventDescriptor;
	history?: History;
	match: any;
	fulfillment? : boolean;
	isCYO? : boolean;
	handleSectionSelection?: (sectionId: string) => void;
}

interface SectionState {
	className: string;
	positionStyle: CSSProperties;
	imageStyle: CSSProperties;
}

export class Section extends React.Component<SectionProps, SectionState> {
	
	public readonly state: SectionState = {
		className: "section-icon",
		positionStyle: {
			position: "absolute",
			left: this.props.section.left + "px",
			top: this.props.section.top + "px"
		},
		imageStyle: {
			background: `url('${window.PublicTicketApp.sitePrefix}${this.props.eventDescriptor.layoutStylesUrl}/${this.props.section.normalImage}') no-repeat`,
			height: this.props.section.imageHeight,
			width: this.props.section.imageWidth
		}
	};
	
	constructor(props: SectionProps) {
		super(props);
		
		this.highlightElement = this.highlightElement.bind(this);
		this.deHighlightElement = this.deHighlightElement.bind(this);
		this.navigate = this.navigate.bind(this);
	}
	
	public render() {
		const {section} = this.props;
		const sectionName = section.name;
		if (sectionName === "stage") {
			return (<div className={this.state.className} style={{...this.state.positionStyle, ...this.state.imageStyle}} />);
		} else {
			return (
				<div style={this.state.positionStyle} >
					{/* Only decorate ungrouped sections on mouseover */}
					{("groupId" in section)
						? <a href="" className={this.state.className} style={this.state.imageStyle} title={sectionName}/>
						: <a href="" className={this.state.className} style={this.state.imageStyle} title={sectionName}
							onMouseOver={this.highlightElement} onMouseOut={this.deHighlightElement} onClick={this.navigate}/>
					}
				</div>
			);
		}
	}

	public highlightElement() {
		const {section, eventDescriptor} = this.props;
		this.setState({
			className: "section-icon selected",
			imageStyle: {
				...this.state.imageStyle,
				background: `url('${window.PublicTicketApp.sitePrefix}${eventDescriptor.layoutStylesUrl}/${section.selectedImage}') no-repeat`
			}
		});
	}

	public deHighlightElement() {
		const {section, eventDescriptor} = this.props;
		this.setState({
			className: "section-icon",
			imageStyle: {
				...this.state.imageStyle,
				background: `url('${window.PublicTicketApp.sitePrefix}${eventDescriptor.layoutStylesUrl}/${section.normalImage}') no-repeat`
			}
		});
	}

	// Navigates to the seat selection page for the selected section
	public navigate(evt: React.MouseEvent<HTMLElement>) {
		const {
			handleSectionSelection,
			history,
			section,
			eventDescriptor,
			fulfillment,
			isCYO,
			match
		} = this.props;
		
		if(!!handleSectionSelection) {
			handleSectionSelection(section.id);
		} else if (!!history) {
			const {eventInstanceId, allocationId, venueId, fulfillmentInstanceId} = match.params;
			fulfillment
				? isCYO
					? history.push(getSubFulfillmentInstanceSectionRoute(eventInstanceId, allocationId, fulfillmentInstanceId, section.id))
					: history.push(getSubFulfillmentSectionRoute(eventInstanceId, allocationId, venueId, section.id))
				: history.push(getSectionRoute(eventDescriptor.id, section.id));
		}
		
		evt.preventDefault();
	}
}
