import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Col, Container, Row} from "reactstrap";
import {SmallExternalLinkIcon} from "../icons/icons";

interface FooterProps {
	copyrightYear: number | string;
	dbaName?: string;
	orgName: string;
	physicalAddress?: string;
	privacyPolicyUrl?: string;
	showComplianceFooter?: boolean;
	termsAndConditions?: string;
	termsAndConditionsUrl?: string;
}

export const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
	const {
		copyrightYear,
		dbaName,
		orgName,
		physicalAddress,
		privacyPolicyUrl,
		showComplianceFooter,
		termsAndConditions,
		termsAndConditionsUrl
	} = props;
	
	// Replace line-break characters with <br/>
	const physicalAddressLines = (!!physicalAddress)
		? physicalAddress.split('\n').map((line, index) => <LineBreaker key={line} lineOfText={line}/>)
		: null;
	
	const termsAndConditionsLines = (!!termsAndConditions)
		? termsAndConditions.split('\n').map((line, index) => <LineBreaker key={line} lineOfText={line}/>)
		: null;
	
	return (
		<footer>
			<Container fluid={true} className="bg-dark text-light small pt-5 pb-2">
				<Container>
					{!!showComplianceFooter && (
						<Row className="mb-3 border-bottom no-gutters">
							{(!!dbaName || !!physicalAddress) && (
								<Col md="4" className="mb-3 pr-md-4 col-print-4">
									<h5 className="border-bottom text-uppercase"><FormattedMessage id="lbl_OrganizationInfo"/></h5>
									{!!dbaName && <p className="mb-1">{dbaName}</p>}
									{!!physicalAddressLines && (
										<address className="mb-1">
											{physicalAddressLines}
										</address>
									)}
								</Col>
							)}
							{(!!termsAndConditions || !!termsAndConditionsUrl) && (
								<Col md="4" className="mb-3 pr-md-4 col-print-4">
									<h5 className="border-bottom text-uppercase"><FormattedMessage id="lbl_TermsAndConditions"/></h5>
									{!!termsAndConditionsLines && (
										<div className="mb-1">
											{termsAndConditionsLines}
										</div>
									)}
									{!!termsAndConditionsUrl && (
										<div className="mb-1">
											<FooterLink url={termsAndConditionsUrl} intlId="lbl_ViewTermsAndConditions" />
										</div>
									)}
								</Col>
							)}
							{(!!privacyPolicyUrl) && (
								<Col md="4" className="mb-3 col-print-4">
									{!!privacyPolicyUrl && (
										<>
											<h5 className="border-bottom text-uppercase"><FormattedMessage id="lbl_PrivacyPolicy"/></h5>
											<div className="mb-3">
												<FooterLink url={privacyPolicyUrl} intlId="lbl_ViewPrivacyPolicy" />
											</div>
										</>
									)}
								</Col>
							)}
						</Row>
					)}
					{/* Copyright */}
					<Row>
						<Col>
							<p className="text-center">
								<FormattedMessage id="lbl_Copyright"/> {copyrightYear} {orgName}
							</p>
						</Col>
					</Row>
				</Container>
			</Container>
		</footer>
	);
};

interface LineBreakerProps {
	lineOfText: string;
}

export const LineBreaker: React.FunctionComponent<LineBreakerProps> = ({lineOfText}) => {
	return <>{lineOfText}<br/></>;
};

interface FooterLinkProps {
	url: string;
	intlId: string;
}

const FooterLink: React.FunctionComponent<FooterLinkProps> = ({url, intlId}) => (
	<a className="text-light" href={url} target="_blank" rel="noreferrer">
		<span>
			<FormattedMessage id={intlId} />
		</span>
		{' '}
		<SmallExternalLinkIcon />
	</a>	
);
