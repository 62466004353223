import * as React from "react";
import {FormGroup, Input, Label} from "reactstrap";

interface RadioButtonProps {
	value: string;
	name: string;
	label: string | JSX.Element;
	disabled?: boolean;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	defaultChecked?: boolean;
}
export const RadioButton = ({value, name, label, handleChange, disabled, defaultChecked}: RadioButtonProps) => (
	<FormGroup className="m-0">
		<Label>
			<Input type='radio' name={name} value={value} onChange={handleChange} disabled={disabled} defaultChecked={defaultChecked}/>
			{label}
		</Label>
	</FormGroup>
);
