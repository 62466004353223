import * as React from "react";
import {IntlShape} from "react-intl";
import {Col, Collapse, Row} from "reactstrap";
import {AnyAction} from "redux";
import {TicketableEventTypes} from "../../enums/ticketable-event-types";
import {InjectedInventoryServiceProps} from "../../hoc/inject-inventory-service";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {Ticket} from "../../models/ticket";
import {DetailToggleButton} from "../detail-toggle-button";
import {HTMLContent} from "../html-content/html-content";
import {ItemHeader} from "./item-header";
import {TicketTable} from "./ticket-table";
import {RouteComponentProps} from "react-router-dom";

interface EventItemPropsWithInjections extends EventItemProps, InjectedInventoryServiceProps, RouteComponentProps<any> { }

export interface EventItemProps {
	// contains the request ids of in-flight API actions that should block the UI
	blockingActions: BasicStringKeyedMap<AnyAction>;
	
	config: PublicTicketAppConfig;
	
	intl: IntlShape;
	
	// PatronTicket__EventInstance__c Id
	eventInstanceId: string;
	
	// PatronTicket__EventInstance__c Name
	eventInstanceName: string;

	// PatronTicket__TicketableEvent__c Name
	ticketableEventName: string;
	
	// PatronTicket__TicketableEvent__c Type
	ticketableEventType?: TicketableEventTypes;

	// an array of objects that wrap a cartItem and it's associated fees
	tickets: Ticket[];

	// displays the "Remove" and 'Edit' buttons to allow changes
	allowEdit: boolean;
	
	// displays the item name as a link allowing back to the event
	allowNavigation?: boolean;

	// a callback to dispatch an action to remove an item from the cart.
	removeItem?: (cartItemId: string) => void;

	// a callback to dispatch and action to update the price level on an item in the cart.
	changeItemPriceLevel?: (cartItemId: string, priceLevelId: string) => void;
	
	// if true, the event details will be expanded and the hide/show details toggle will be hidden
	detailsExpanded: boolean;
	
	// the currently selected EventDescriptor
	selectedEI?: EventDescriptor | null;
}

interface EventItemState {
	eventDescriptor: EventDescriptor | null;
	isOpen: boolean;
	status: 'Open' | 'Closed';
}

/**
 * Displays tickets for a single event instance
 */
export class EventItem extends React.Component<EventItemPropsWithInjections, EventItemState> {
	
	public readonly state: EventItemState = {
		eventDescriptor: null,
		isOpen: this.props.detailsExpanded, // Toggled when user clicks the toggle visibility button.
		status: this.props.detailsExpanded ? 'Open' : 'Closed' // Tracks the collapsed status, per ReactStrap Collapse convention.
	};
	
	public toggle = () => {
		const {eventInstanceId, inventoryService} = this.props;
		const {isOpen} = this.state;
		if (!isOpen) {
			// If opening the details, then trigger a fetch of the EventDescriptor so we have updated price level information
			inventoryService.fetchEventDescriptor(eventInstanceId, false);
		}
		this.setState({isOpen: !isOpen});
	}
	
	public onEntered = () => {
		this.setState({status: 'Open'});
	}

	public onExiting = () => {
		this.setState({status: 'Closed'});
	}
	
	public componentDidMount() {
		const {eventInstanceId, selectedEI} = this.props;
		if (!!selectedEI && selectedEI.id === eventInstanceId) {
			this.setState({eventDescriptor: selectedEI});
		}
	}
	
	public componentDidUpdate() {
		const {eventInstanceId, selectedEI} = this.props;
		const {eventDescriptor} = this.state;
		if (!eventDescriptor && !!selectedEI && selectedEI.id === eventInstanceId) {
			this.setState({eventDescriptor: selectedEI});
		}
	}
	
	public render() {
		const {
			allowNavigation = true,
			config,
			eventInstanceId,
			eventInstanceName,
			ticketableEventName,
			ticketableEventType,
			tickets,
			changeItemPriceLevel,
			detailsExpanded,
			...other
		} = this.props;

		const {isOpen} = this.state;

		// Elegant failure mode
		if (!tickets || tickets.length < 1) {
			return null;
		}

		// Aggregate the total price, total quantity and build a map of discounts that are applied for this event
		let eventTotal: number = 0;
		let eventQuantity: number = 0;
		let eventSubtotal: number = 0;
		let eventFeeSubtotal: number = 0;
		const discountsApplied: BasicStringKeyedMap<string> = {};
		tickets.forEach(ticket => {
			eventTotal += ticket.cartItem.itemTotal;
			eventQuantity += ticket.cartItem.qty;
			eventSubtotal += (ticket.cartItem.subtotal || 0) + (ticket.cartItem.disTotal || 0);
			eventFeeSubtotal += ticket.cartItem.itemFeeTotal || 0;
			if (!!ticket.cartItem.disName) {
				discountsApplied[ticket.cartItem.disName] = ticket.cartItem.disName;
			}
		});

		// custom cart text to show for this event
		const instanceCustomCartText = tickets[0].cartItem.customCartText;

		return (
			<div className="eventItem border-bottom mb-3"> 
				<Row>
					<Col>
						{/* PMGR-8324 - Don't display the EI name for "Membership" items */}
						<ItemHeader
							eventInstanceId={eventInstanceId}
							eventInstanceName={ticketableEventType === TicketableEventTypes.MEMBERSHIP ? undefined : eventInstanceName}
							ticketableEventName={ticketableEventName}
							discountsApplied={Object.keys(discountsApplied)}
							quantity={eventQuantity}
							allowNavigation={allowNavigation}
							itemTotal={eventTotal}
							itemSubtotal={eventSubtotal}
							feeSubtotal={eventFeeSubtotal}
							currencyCode={config.currencyCode}
							breakoutFees={config.includeFeesInPrice}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						{!!instanceCustomCartText && <HTMLContent rawHtml={instanceCustomCartText} />}
					</Col>
				</Row>

				{!detailsExpanded && (
					<Row>
						<Col>
							<DetailToggleButton onClick={this.toggle} detailsVisible={isOpen} />
						</Col>
					</Row>
				)}

				<Row>
					<Col>
						<Collapse
							isOpen={this.state.isOpen}
							onExiting={this.onExiting}
							onEntered={this.onEntered}>
							<TicketTable
								currencyCode={config.currencyCode}
								changeItemPriceLevel={changeItemPriceLevel}
								eventDescriptor={this.state.eventDescriptor}
								tickets={tickets}
								{...other}
							/>
						</Collapse>
					</Col>
				</Row>
			</div>
		);
	}
}
