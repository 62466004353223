import {Component, CSSProperties} from "react";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {CartItem} from "../../models/cart-item";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {RowDescriptor} from "../../models/event-descriptor/row-descriptor";
import {SeatDescriptor} from "../../models/event-descriptor/seat-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import {Row} from "./row";
import {SeatAttributes} from "./seat-attributes";
import {SeatMap} from "./seat-map";

interface SectionProps {
	section: SectionDescriptor;
	eventDescriptor: EventDescriptor;
	cart: any;
	allocMap: BasicStringKeyedMap<AllocationDescriptor>;
	previewMode: boolean;
	pyosCartItemMap: BasicStringKeyedMap<CartItem>;
	seatAttributes: SeatAttributes;
	onSeatClick: (seat: SeatDescriptor) => void;
}

export class Section extends Component<SectionProps> {
	
	public static getSectionLabel(section: SectionDescriptor) {
		let sectionLabelElement = null;
		if (section.nameLeft && section.nameTop) {
			let sectionLabelStyle: CSSProperties = {
				position: "absolute",
				left: section.nameLeft,
				top: section.nameTop
			};
			if (!!section.labelStyle) {
				const additionalStyles = JSON.parse(section.labelStyle);
				sectionLabelStyle = Object.assign(sectionLabelStyle, additionalStyles);
			}
			sectionLabelElement = <div id={'sectionLabel_' + section.shortName} style={sectionLabelStyle}>{section.name}</div>;
		}
		return sectionLabelElement;
	}
	
	public static getRowsInSection(section: SectionDescriptor, eventDescriptor: EventDescriptor, other: any) {
		return eventDescriptor.rowList.reduce((prev: JSX.Element[], row: RowDescriptor) => {
			if (row.sectionId === section.id) {
				prev.push(
					<Row key={"row-" + row.id}
						row={row}
						section={section}
						eventDescriptor={eventDescriptor}
						{...other}
					/>
				);
			}
			return prev;
		}, []);
	}
	
	public render() {
		const {section, eventDescriptor, previewMode, ...other} = this.props;
		const invertStageArrow = !!eventDescriptor.venue && eventDescriptor.venue.invertStageArrow;
		const rowsInSection = Section.getRowsInSection(section, eventDescriptor, other);
		const stageLocationBoxStyle = SeatMap.getStageLocationBoxStyle(section);
		return (
			<div>
				<h4 style={{textAlign: "center"}}>Selected Section: {section.name}</h4>
				<div className="map-container" style={stageLocationBoxStyle}>
					{/* When previewing the seat map, cover it with an overlay to prevent clicking and make it appear more like a preview */}
					{previewMode && <div className="seat-map-preview-overlay"/>}
					<div
						className={`stage ${SeatMap.getStageLocationImageClass(section)}${invertStageArrow ? " inverted" : ""}`}
						style={SeatMap.getStageLocationImageStyle(section)}
					/>
					<div className="section-box" style={SeatMap.getSeatLocationBoxStyle(section)}>
						<div style={{height: section.height, width: section.width}}>
							{rowsInSection}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
