import {BasicStringKeyedMap} from "../models/basic-map";

export const SO_TYPE: BasicStringKeyedMap<string> = {
	BOOLEAN: 'BOOLEAN',
	CURRENCY: 'CURRENCY',
	DATE: 'DATE',
	DOUBLE: 'DOUBLE',
	EMAIL: 'EMAIL',
	MULTIPICKLIST: 'MULTIPICKLIST',
	PERCENT: 'PERCENT',
	PICKLIST: 'PICKLIST',
	PHONE: 'PHONE',
	STRING: 'STRING',
	TEXTAREA: 'TEXTAREA',
	URL: 'URL'
};

// Different input types formatted in field-group.  Values match the '<Input type' options
export const FG_TYPE: BasicStringKeyedMap<string> = {
	CHECKBOX: 'checkbox',
	DATE: 'date',
	EMAIL: 'email',
	NUMBER: 'number',
	MULTIPICKLIST: 'multipicklist',
	PASSWORD: 'password',
	SELECT: 'select',
	TEXT: 'text',
	TEXTAREA: 'textarea'
};