// Describes both item fee and order fee fields
import {FeeAggregationTypes} from "../enums/fee_aggregation_types";

export class FeeDescriptor {
	public apiName: string;
	public aggregation: FeeAggregationTypes = FeeAggregationTypes.SEPARATE;
	public label: string;
	public prop: string;
	public value: number = 0;
	public waivable: boolean;
}
