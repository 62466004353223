import * as React from 'react';
import {FormattedMessage} from "react-intl";
import {SimpleHTMLContent} from "./html-content/simple-html-content";

interface MessageProps {
	defaultMessage?: string;
	htmlMessage?: string;
	intlId?: string;
	values?: {[key: string]: any};
}

/**
 * Displays a styled message.
 * Use when there are no items in a list
 * and any other case where a simple information message is needed.
 *
 * @param defaultMessage An optional default message to be rendered if the passed in intlId can't be resolved
 * @param htmlMessage An optional raw HTML message that can be rendered unescaped.
 * @param intlId An optional message Id corresponding to one of the messages defined in data.json.
 * @param values A values object to be passed to FormattedMessage when an intlId is provided.
 */
export const Message: React.FunctionComponent<MessageProps> = (
	{defaultMessage, htmlMessage, intlId, values}
	) => {
	return (
		<div className="bg-light text-dark p-2 text-uppercase small text-center">
			{!!htmlMessage && <span><SimpleHTMLContent rawHtml={htmlMessage}/></span>}
			{!!intlId
				? <FormattedMessage id={intlId} values={values} defaultMessage={defaultMessage}/>
				: <span>{defaultMessage}</span>
			}
		</div>
	);
};
