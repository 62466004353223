import * as React from "react";
import {FormattedMessage} from "react-intl";

interface MainContentHeaderProps {
	intlId: string;
	values?: {[key: string]: any};
}

export const MainContentHeader: React.FunctionComponent<MainContentHeaderProps> = ({intlId, values}) => (
	<div className="pts-main-content-header mb-4">
		<h1>
			<FormattedMessage id={intlId} values={values} />
		</h1>
	</div>
);
