import { FormattedMessage } from "react-intl";

/**
 * A message to be displayed when the mini cart is empty.
 * Put at the bottom of mini cart.
 */
export const EmptyMiniCartMessage = () => (
	<div className="pts-empty-cart-message text-center mb-3 py-4">
		<h3>
			<FormattedMessage id="lbl_AddItems" />
		</h3>
	</div>
);
