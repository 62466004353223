import {History} from "history";
import * as React from "react";
import {FormattedMessage, IntlShape} from "react-intl";
import {RouterProps} from "react-router";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {AnyAction} from "redux";
import {Severities} from "../../enums/severities";
import {isDraft, isPendingRenewal} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {UserProfile} from "../../models/public-ticket-app/user-profile";
import {CountdownTimer} from "../countdown-timer";
import {FieldGroup, FieldGroupTypes} from "../field-group";
import {LoadingIcon, SecurePaymentIcon} from "../icons/icons";
import {PanelNav} from "../panel-nav";
import {MessageTypes, WaitingMessage} from "../waiting-message";
import {SubmitResult} from "../../models/public-ticket-app/submit-result";

export interface PaymentInputFormProps extends RouterProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	intl: IntlShape;
	prevPagePath: string;
	saveProps: (props: any) => void;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
	submitCart: (history: History) => Promise<any>;
	userProfile: UserProfile;
}
interface PropsFromPaymentForm {
	showGiftCardInput: () => void;
}

interface PaymentInputFormState {
	errors: Partial<PaymentInputFormValues>;
	paymentErrors: string[];
	submitClicked: boolean;
	isPaymentConfirmed: boolean;
	shouldShowPaymentModal: boolean;
	isPaymentFormComplete: boolean;
}

interface PaymentInputFormValues {
	ccNumber: string;
	ccExpMonth: string;
	ccExpYear: string;
	ccCvv2: string;
	gcNumber: string;
	saveCardOnFile: string;
	selectedPM: string;
}

declare var PatronPay: any;

export class PaymentInputForm extends React.Component<PaymentInputFormProps & PropsFromPaymentForm, PaymentInputFormState> {
	public readonly state: PaymentInputFormState = {
		errors: {},
		paymentErrors: [],
		submitClicked: false,
		isPaymentConfirmed: false,
		shouldShowPaymentModal: false,
		isPaymentFormComplete: false
	};

	private readonly paymentTokenFieldId: string = "paymentTokenId";

	public componentDidMount(): void {
		document.addEventListener('PatronPay:PublicPayment:PaymentInputChange', (event: CustomEvent) => {
			if (event?.detail?.isComplete) {
				this.setState({ isPaymentFormComplete: true });
			} else {
				this.setState({ isPaymentFormComplete: false });
			}
		});
	}

	public render() {
		const { blockingActions, cart, cartTimeRemaining, config, intl, showGiftCardInput } = this.props;
		const { errors, paymentErrors, submitClicked, isPaymentConfirmed, shouldShowPaymentModal, isPaymentFormComplete } = this.state;
		const isBusy: boolean = Object.keys(blockingActions).length > 0;
		
		const storedPaymentSelectOptions = this.getStoredPaymentSelectOptions();
		const hasStoredPayments = config.userPaymentMethods && config.userPaymentMethods.length > 0;
		const hasSelectedOneOfTheStoredPayments = !!cart.selectedPM;
		
		const gcBalance = cart.gcBalance || 0;
		const formattedGCBalance = intl.formatNumber((gcBalance), {style: "currency", currency: config.currencyCode});
		
		const amtDueAfterGC = gcBalance > cart.amtDue ? 0 : cart.amtDue - gcBalance;
		const formattedAmtDueAfterGC = intl.formatNumber((amtDueAfterGC), {style: "currency", currency: config.currencyCode});

		return (
			<div className="d-flex flex-column">
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				<div className="text-center pb-3 small">
					<SecurePaymentIcon/>
					<span className="align-middle"><FormattedMessage id="lbl_SecurePayment"/></span>
				</div>
				{
					(isDraft(cart) || isPendingRenewal('', cart)) && config.giftCardsEnabled && (
						<div>
							<div className="mt-auto">
								{cart.gcNumber && cart.gcHash ? (
									<>
										<p className="mb-1">
											<FormattedMessage id="msg_gift_card_balance" values={{gcBalance:formattedGCBalance}} />
										</p>
										<p className="mb-4">
											<FormattedMessage id="msg_gift_card_balance_remaining" values={{amtDueAfterGC:formattedAmtDueAfterGC}} />
										</p>
									</>
								) : (
									<Button onClick={showGiftCardInput} className="btn-block btn-outline-primary" disabled={isPaymentConfirmed}>
										<FormattedMessage id="lbl_UseGiftCard" />
									</Button>
								)}
							</div>
						</div>
					)
				}
				
				{
					config.canUseSavedPaymentMethods && hasStoredPayments &&
					<FieldGroup
						id="selectedPM"
						name="selectedPM"
						label={intl.formatMessage({id: 'lbl_SelectCreditCardOnFile'})}
						type={FieldGroupTypes.SELECT}
						value={cart.selectedPM || ""}
						selectionOptions={storedPaymentSelectOptions}
						onChange={this.handleChange}
						invalid={!!errors.selectedPM}
						feedbackMessage={errors.selectedPM}
						required={false}
					/>
				}

				{
					!hasSelectedOneOfTheStoredPayments &&
						<>
							<Button color='primary' className='mt-4 mb-4' disabled={isPaymentConfirmed} onClick={this.openPaymentModal}>{intl.formatMessage({id: "lbl_EnterPayment"})}</Button>

							<Modal
								isOpen={shouldShowPaymentModal}
								onOpened={this.promptPayment}
								toggle={this.closePaymentModal}
								backdrop='static'
								className='text-dark'
								aria-labelledby='PaymentModalHeader'
							>
								<ModalHeader toggle={this.closePaymentModal}>
									<div id='PaymentModalHeader'>
										{intl.formatMessage({id: "lbl_EnterPayment"})}
									</div>
								</ModalHeader>
								<ModalBody>
									<ul style={{ listStyle: 'none' }}>
										{paymentErrors.map((paymentError, i) => {
											return <li key={i}>{paymentError}</li>;
										})}
									</ul>
									<div id='PaymentFrameContainer'></div>
									{config.canSavePaymentMethods &&
										<FieldGroup
											id='saveCardOnFile'
											name='saveCardOnFile'
											type={FieldGroupTypes.CHECKBOX}
											label={intl.formatMessage({id: "lbl_SaveCardForFuturePurchase"})}
											value={cart.saveCardOnFile}
											onChange={this.handleCheckboxChange}
											invalid={!!errors.saveCardOnFile}
											feedbackMessage={errors.saveCardOnFile}
											disabled={isBusy}
										/>
									}
								</ModalBody>
								<ModalFooter>
									{submitClicked && paymentErrors.length === 0 && (<LoadingIcon size={32} />)}
									<Button color='secondary' onClick={this.closePaymentModal}>{intl.formatMessage({id: "lbl_button_Cancel"})}</Button>
									<Button color='primary' onClick={this.submitPayment} disabled={submitClicked || !isPaymentFormComplete}>{intl.formatMessage({id: "lbl_CompletePayment"})}</Button>
								</ModalFooter>
							</Modal>
							{/*
								PMGR-7913 - These are non-visible elements that are used to seed the style of the Bluefin iFrame.
								At this time, only the label style is used. This is because the Bluefin iFrame "API" doesn't allow
								you to set the background color of the input fields. So, if we try to pull in the styles from the
								input element, the input fields look really crappy with dark themes that have light text, because
								you end up with light text on white background in the input field, making it difficult to see.
							*/}
							<div id='bfSample'>
								<label id='bfLabel' className='text-dark'/>
							</div>
						</>
				}

				<input type="hidden" id={this.paymentTokenFieldId}/>

				<div className="mt-auto">
					<WaitingMessage isOpen={isBusy} type={isBusy ? MessageTypes.SUBMITTING : undefined}/>
					{/* The submit button bypassing the payment modal is only shown in the event that a saved payment method has been selected. */}
					{hasSelectedOneOfTheStoredPayments && (
						<PanelNav
							next={{label: intl.formatMessage({id: "lbl_Submit"}), handleClick: this.submitPayment, isDisabled: isBusy || submitClicked}}
							back={{label: intl.formatMessage({id: "lbl_Back"}), handleClick: this.back, isDisabled: isBusy}}
						/>
					)}
					{!hasSelectedOneOfTheStoredPayments && (
						<PanelNav
							back={{label: intl.formatMessage({id: "lbl_Back"}), handleClick: this.back, isDisabled: isBusy}}
						/>
					)}
				</div>
			</div>
		);
	}
	
	/**
	 * Creates list of stored payment options available to this user
	 */
	private getStoredPaymentSelectOptions = (): JSX.Element[] => {
		const { config, intl, userProfile } = this.props;

		let storedPaymentSelectOptions: JSX.Element[] = [];

		if (userProfile != null && config.userPaymentMethods && config.userPaymentMethods.length > 0)  {
			storedPaymentSelectOptions = config.userPaymentMethods.map(paymentMethod => {

				const localizedLabel = intl.formatMessage(
					{id: 'lbl_StoredCardDetails'},
					{
						type: paymentMethod.type,
						lastFourDigits: paymentMethod.lastFourDigits,
						expirationDate: paymentMethod.expirationDate
					});

				return <option key={paymentMethod.id} value={paymentMethod.id}>{localizedLabel}</option>;
			});

			storedPaymentSelectOptions = [
				<option key='0' value=''>
					{intl.formatMessage({id: 'lbl_Select'})}
				</option>,
				...storedPaymentSelectOptions];
		}
		return storedPaymentSelectOptions;
	}

	private openPaymentModal = () => {
		this.setState({ shouldShowPaymentModal: true });
	}

	private closePaymentModal = () => {
		this.setState({ shouldShowPaymentModal: false });
	}

	private promptPayment = () => {
		const { config, cart, intl } = this.props;

		let amount = cart.amtDue;
		if (cart.gcBalance) {
			amount = cart.gcBalance > cart.amtDue ? 0 : amount - cart.gcBalance;
		}

		// If SCPL is enabled, determine the cart's country's code, if possible.
		const countryCode = config.scplOptions?.find((scplOption) => scplOption.country === cart.country)?.countryCode;

		PatronPay.PublicPayment.api.promptPayment({
			amount: amount.toFixed(2),
			domMountPointId: 'PaymentFrameContainer',
			billingDetails: {
				address: {
					country: countryCode,
					postalCode: cart.postalCode
				}
			},
			styles: {
				matchLabelStyleId: 'bfLabel',
				width: '100%',
				height: 200
			},
			bluefin: {
				shouldReinitialize: true,
				inputStyle: '2',
				layout: '3',
				expirationYear: 'double_dropdown',
				expirationYearLabel: '1',
				cvvLabel: '1',
				numberLabel: '3'
			}
		}).then((result: any) => {
			// If a payment gateway iFrame exists, set the element's title attribute (intended to improve accessibility).
			const iframe = document.querySelector('#PaymentFrameContainer iframe');
			if (iframe) {
				iframe.setAttribute('title', intl.formatMessage({id: "lbl_Payment"}));
			}
		}).catch((error: any) => {
			console.error(error); // eslint-disable-line no-console
			this.paymentErrorHandler(error.messages);
		});
	}

	private submitPayment = () => {
		const { cart, clearAllMessages, submitCart, history, saveProps } = this.props;

		clearAllMessages();
		this.clearPaymentErrorMessages();

		if (!this.validate()) {
			return;
		}

		this.setState({ submitClicked: true });

		if (!!cart.selectedPM) {
			submitCart(history)
			.then((result) => {
				if (result?.data?.messages?.length > 0) {
					this.setState({ submitClicked: false });
				} else {
					this.setState({ isPaymentConfirmed: true });
				}
			});
		} else {
			PatronPay.PublicPayment.api.confirmPayment()
			.then((result: any) => {
				saveProps({
					paymentToken: result.token,
					gcNumber: cart.gcNumber
				});

				submitCart(history)
				.then((result) => {
					const submitResult: SubmitResult = result.data;

					// Check for any next actions (such as 3DS challenge)
					if(!!submitResult.nextActionSecret) {
						PatronPay.PublicPayment.api.handleStripeNextAction(submitResult.nextActionSecret)
							.then((result: any) => {
								// Update the payment token with the confirmed Payment Intent transaction Id and submit payment again
								saveProps({ paymentToken: result.id });
								submitCart(history).then((result) => this.handleSubmitResult(result.data));
							})
							.catch((error: any) => {
								// display challenge error and allow the user to try again
								console.error(error); // eslint-disable-line no-console
								this.paymentErrorHandler(error.messages);
								this.setState({ submitClicked: false });
							});
					} else {
						this.handleSubmitResult(submitResult);
					}
				});
			}).catch((error: any) => {
				console.error(error); // eslint-disable-line no-console
				this.paymentErrorHandler(error.messages);
				this.setState({ submitClicked: false });
			});
		}
	}

	private handleSubmitResult = (submitResult: SubmitResult) => {
		this.closePaymentModal();
		if (submitResult.messages?.length > 0) {
			this.setState({ submitClicked: false, isPaymentFormComplete: false });
		} else {
			this.setState({ isPaymentConfirmed: true });
		}
	}

	private validate = () => {
		const { cart, intl } = this.props;

		if (!!cart.selectedPM) {
			// when user selects a stored payment to use, there is nothing to validate.
			this.setState({errors: {}});
			return true;
		}

		const requiredFields: string[] = [];
		const errors: BasicStringKeyedMap<string> = {};
		requiredFields.forEach((fieldName: keyof typeof cart) => {
			if (!cart[fieldName]) {
				errors[fieldName] = intl.formatMessage({id: "msg_required_field"});
			}
		});
		this.setState({errors});
		return (Object.keys(errors).length === 0);
	}

	private paymentErrorHandler = (errorMessages: string[]) => {
		this.setState({ paymentErrors: errorMessages });
	}

	private clearPaymentErrorMessages = () => {
		this.setState({ paymentErrors: [] });
	}

	private handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.props.saveProps({[evt.target.name]: evt.target.value});
	}

	private handleCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.props.saveProps({[evt.target.name]: evt.target.checked});
	}
	
	private back = () => {
		this.props.history.push(this.props.prevPagePath);
	}
}
