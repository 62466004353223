export enum ThemeNames {
	CINEMATIC = "Cinematic",
	MODERNE = "Moderne",
	ZANY = "Zany"
}

export class ThemeProps {
	public brand1: string;
	public brand2: string;
	public fontFamily: string;
	public themeName: ThemeNames;
}