import * as React from "react";
import {Col, Row} from "reactstrap";
import {venueHasDetails} from "../../helpers/utilities";
import {SubscriptionEventDescriptor} from "../../models/event-descriptor/subscription-event-descriptor";
import {HTMLContent} from "../html-content/html-content";
import {TicketableEventDetailLink} from "../ticketable-event-detail-link";
import {VenueLink} from "../venue-link";

interface SubscriptionFormElementsProps {
	portalSeatSelectionMessage?: string;
	subsEventDescriptor: SubscriptionEventDescriptor;
}

export const SubscriptionFormElements: React.FunctionComponent<SubscriptionFormElementsProps> = (props) => {
	const {portalSeatSelectionMessage, subsEventDescriptor} = props;
	const {id, teId, venue} = subsEventDescriptor;
	return (
		<>
			<div className="mb-4">
				{!!portalSeatSelectionMessage && (
					<Row>
						<Col>
							<HTMLContent rawHtml={portalSeatSelectionMessage} />
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<TicketableEventDetailLink teId={teId}/>
					</Col>
				</Row>
				{!!venue && (
					<Row>
						<Col>
							<VenueLink venueName={venue.name} eventInstanceId={id} showDetailsLink={venueHasDetails(venue)} />
						</Col>
					</Row>
				)}
			</div>
				
			{!!subsEventDescriptor.eiDetail && (
				<div className="mb-4">
					<HTMLContent rawHtml={subsEventDescriptor.eiDetail} />
				</div>
			)}
		</>
	);
};
