import {FC} from "react";
import {FormattedMessage, WrappedComponentProps} from 'react-intl';
import {getPriceString, PriceInfo} from "../../helpers/localization";

interface TicketItemRowProps extends WrappedComponentProps{
	userDefinedLabel?: string;
	intlId?: string;
	priceInfo: PriceInfo;
	currencyCode: string;
	appendedLabelIntlId?: string;
}

/**
 * Displays a single row in the expanded TicketItem
 * 
 * @param userDefinedLabel An org-defined label, as with a fee.
 * @param intlId The React Intl localization id. Use this if there is no user-defined label.
 * @param priceInfo object that includes the price of the item (or the min/max range for CYO items)
 * @param currencyCode
 * 
 */
 export const TicketItemRow: FC<TicketItemRowProps> = ({userDefinedLabel, intlId, appendedLabelIntlId, priceInfo, currencyCode, intl}) => {
	const label =
		!!userDefinedLabel ? <span>{userDefinedLabel}</span> :
		!!intlId ? <FormattedMessage id={intlId} /> :
		null;
	const appendedLabel = appendedLabelIntlId && <FormattedMessage id={appendedLabelIntlId} />
	return (
		<div data-testid="ticket-item-row" className="small mb-1">
			<span>
				{label}	
			</span>
			<span className="mr-1">
				:
			</span>
			<span>{getPriceString(intl, currencyCode, priceInfo)}</span>
			{appendedLabel && <span>
				{' '}{appendedLabel}
			</span>}
		</div>
	);
};
