import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";
import {ApiActionNames} from "../enums/api-action-names";
import {TicketOrderStatus} from "../enums/ticket-order-status";
import {Cart} from "../models/cart";
import {Portal} from "../models/portal/portal";
import {PortalOrder} from "../models/portal/portal-order";
import {PortalOrderDetails} from "../models/portal/portal-order-details";
import {getFilteredProps} from "./cart";

/**
 * The Portal Reducer
 */
export const portal = (state = new Portal(), action: AnyAction): Portal => {
	switch (action.type) {
		case ActionTypes.API_SUCCESS:
			switch (action.actionName) {
				case ApiActionNames.FETCH_PORTAL_ORDERS:
					return {
						...state,
						pendingRenewals: action.data.filter((order: PortalOrder) => order.status === TicketOrderStatus.PENDING_RENEWAL),
						orders: action.data.filter((order: PortalOrder) => order.status !== TicketOrderStatus.PENDING_RENEWAL)
					};
				case ApiActionNames.FETCH_PENDING_RENEWAL: {
					const pendingRenewal: PortalOrderDetails = action.data;
					const portalOrderCache = {...state.portalOrderCache};
					portalOrderCache[pendingRenewal.cart.id] = pendingRenewal;
					return {...state, pendingRenewal, portalOrderCache};
				}
				case ApiActionNames.FETCH_PORTAL_ORDER_DETAILS: {
					const portalOrderDetails: PortalOrderDetails = action.data;
					const portalOrderCache = {...state.portalOrderCache};
					portalOrderCache[portalOrderDetails.cart.id] = portalOrderDetails;
					return {...state, portalOrderCache};
				}
				case ApiActionNames.FETCH_PORTAL_DONATIONS:
					return {...state, donations: action.data};
				case ApiActionNames.FETCH_RENEWABLE_BENEFITS:
					return {...state, renewableBenefits: action.data};
				case ApiActionNames.UPDATE_CART:
					if (isPendingRenewalCart(action.data)) {
						// These actions should selectively apply only those fields that might have changed server-side due to recalculation
						const pendingRenewal = {...state.pendingRenewal};
						pendingRenewal.cart = {...pendingRenewal.cart, ...getFilteredProps(action.data)};
						return {...state, pendingRenewal};
					}
					return state;
				case ApiActionNames.APPLY_DISCOUNT:
					// The response to this action has a different shape than the ones above. It has both a "cart"
					// property, and an integer representing the number of items to which the discount was applied.
					// We need to extract the cart property and apply it to the store.
					if (isPendingRenewalCart(action.data.cart)) {
						const pendingRenewal = {...state.pendingRenewal};
						pendingRenewal.cart = {...pendingRenewal.cart, ...getFilteredProps(action.data.cart)};
						return {...state, pendingRenewal};
					}
					return state;
				case ApiActionNames.SUBMIT_CART:
					// The SUBMIT_CART action returns a result object which has a cart property
					if (isPendingRenewalCart(action.data.cart)) {
						const pendingRenewal = {...state.pendingRenewal};
						pendingRenewal.cart = {...pendingRenewal.cart, ...action.data.cart};
						return {...state, pendingRenewal};
					}
					return state;
				default:
					return state;
			}
		case ActionTypes.CART_SAVE_PROPS: {
			if (isPendingRenewalCart(state.pendingRenewal.cart)) {
				const pendingRenewal = {...state.pendingRenewal};
				pendingRenewal.cart = {...pendingRenewal.cart, ...action.props};
				return {...state, pendingRenewal};
			}
			return state;
		}
		case ActionTypes.CART_CLEAR:
			if (isPendingRenewalCart(state.pendingRenewal.cart)) {
				return {...state, pendingRenewal: new PortalOrderDetails()};
			}
			return state;
		default:
			return state;
	}
};

// Some of the above ActionTypes are also defined in the cart reducer.
// We need to make sure we're only updating the cart state when we're supposed to.
function isPendingRenewalCart(c: Cart) {
	return c.orderStatus === TicketOrderStatus.PENDING_RENEWAL;
}