import * as React from 'react';
import {FormattedMessage, WrappedComponentProps, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {Media} from 'reactstrap';
import {AnyAction} from "redux";
import {EventInstanceSaleStatus} from "../../enums/event-instance-sale-status";
import {getInstanceRoute} from "../../helpers/routing";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {EventInstance} from '../../models/ticketable-events/event-instance';
import {TicketableEvent} from '../../models/ticketable-events/ticketable-event';
import {HTMLContent} from '../html-content/html-content';
import {SimpleHTMLContent} from "../html-content/simple-html-content";
import {PasscodeDisplayWithInjections as PasscodeDisplay, PasscodeFormWithInjections as PasscodeForm} from "../passcode/wrapped-components";

interface SubscriptionItemProps extends WrappedComponentProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	clearAllMessages: () => void;
	fetchEvents: () => void;
	formattedImageSrc?: string;
	te: TicketableEvent;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

export const SubscriptionItem: React.FunctionComponent<SubscriptionItemProps> = (props) => {
	const {
		blockingActions,
		clearAllMessages,
		fetchEvents,
		formattedImageSrc,
		te,
		validatePasscode
	} = props;

	const thumbnailImage = (
		<Media left={true}>
			<Media
				object={true}
				src={formattedImageSrc} alt={te.name}
				style={{height: '150px', width: '150px', objectFit: 'cover'}}
				className="mr-4 d-none d-md-block"
			/>
		</Media>);
	return (
		<Media tag="li" className="mb-5">
			{formattedImageSrc && thumbnailImage}
			<Media body={true}>
				<Media heading={true}>
					{te.name}
				</Media>
				<div className="border-bottom pb-1 mb-3">
					{
						te.description && (
							<div className="mr-3">
								<HTMLContent rawHtml={te.description} />
							</div>
						)
					}
				</div>
					<>
					{
						te.instances.map((ei: EventInstance) => {
							const subscriptionLocation = getInstanceRoute(ei.id);
							const eiName = (
								<h3 className="mb-2">
									{
										ei.soldOut
											? <p className="m-0">{ei.name}</p>
											: <Link to={subscriptionLocation}>{ei.name}</Link>
									}
								</h3>);
							
							let cta;
							let passcodeDisplay;
							if (ei.soldOut) {
								cta = <p className="text-info"><SimpleHTMLContent rawHtml={ei.noSaleMessage || ''}/></p>;
							} else if (ei.saleStatus === EventInstanceSaleStatus.OS) {
								cta = (
									<Link className="btn btn-primary btn-sm" to={subscriptionLocation}>
										<FormattedMessage id="pmgr_lbl_Buy" />
									</Link>);
								
								if (!!ei.appliedPasscode) {
									passcodeDisplay = (
										<PasscodeDisplay
											appliedPasscode={ei.appliedPasscode}
											eventInstanceId={ei.id}
											fetchEvents={fetchEvents}
										/>);
								}
							}
							
							let passcodeCTA;
							if (ei.isPasscodeEligible && ei.saleStatus === EventInstanceSaleStatus.NOSY){
								passcodeCTA = (
									<PasscodeForm
										blockingActions={blockingActions}
										clearAllMessages={clearAllMessages}
										eventInstanceId={ei.id}
										fetchEvents={fetchEvents}
										validatePasscode={validatePasscode}
									/>);
							}
							
							return (
								<div key={ei.id} className="mb-5 pb-3">
									{eiName}
									{ei.detail && <HTMLContent rawHtml={ei.detail} />}
									{passcodeDisplay}
									{cta}
									{passcodeCTA}
								</div>
							);
						})
					}
					</>
			</Media>
		</Media>
	);
};
export default injectIntl(SubscriptionItem);
