import {useMemo} from "react";
import {WrappedComponentProps} from "react-intl";
import {Button, Card, Col, Row} from "reactstrap";
import {SubscriptionFulfillmentGroup} from "../../models/subscription-fulfillment-group";
import {FulfillmentStatus, SubscriptionFulfillmentSlot} from "../../models/subscription-fulfillment-slot";
import {InstanceGroup} from "../../models/subscription/subscription-ticket-display";
import {EventInstance} from "../../models/ticketable-events/event-instance";
import {CountdownTimer} from "../countdown-timer";
import {LoadingIcon, RemoveItemIcon} from "../icons/icons";
import {PanelNav} from "../panel-nav";
import {VenueInstanceDisplay} from "../ticket-order/subscription-item";
import './fulfillment.css';

interface FulfillmentMiniCartProps extends WrappedComponentProps{
	fulfillmentGroup: SubscriptionFulfillmentGroup;
	subFulfillmentSlots: SubscriptionFulfillmentSlot[];
	handleChangeSection?: () => void;
	handleConfirmSeats: () => void;
	handleRemoveSeat: (seatKey: string) => void;
	cartTimeRemaining: number;
}

export const FulfillmentMiniCart = (props: FulfillmentMiniCartProps) => {
	const {fulfillmentGroup, handleChangeSection, handleConfirmSeats, handleRemoveSeat, cartTimeRemaining, intl, subFulfillmentSlots} = props;

	const instances = useMemo(() => {
		return fulfillmentGroup.instances.map((instance: EventInstance) => new InstanceGroup(undefined, instance));
	},[fulfillmentGroup.instances]);
	
	// Disable the confirm seats button if not all selections have been made or seat requests are inflight
	const disableConfirm = useMemo(() => {
		return subFulfillmentSlots.some((slot: SubscriptionFulfillmentSlot) => {
			return slot.fulfillmentStatus === FulfillmentStatus.NEEDS_SELECTION || slot.fulfillmentStatus === FulfillmentStatus.PENDING;
		})
	},[subFulfillmentSlots]);
	
	return (
		<div className="w-100">
			<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
			<div id="MiniCartVenueInstances" className="mb-3">
				<VenueInstanceDisplay venueName={fulfillmentGroup.venueName} instances={instances} intl={intl} />
			</div>
			
			{subFulfillmentSlots.map((slot: SubscriptionFulfillmentSlot) => {
				return (
					slot.fulfillmentStatus === FulfillmentStatus.NEEDS_SELECTION 
						? (
							<Card body className="mb-3 p-2 small mini-cart-needs-selection" key={slot.subItem.id}>
								<div className="ml-3">
									<div>{slot.subItem.levelName}</div>
									<div className='font-weight-bold'>Select Seat</div>
								</div>
							</Card>
						) : (
							<Card body className="pts-ticket-item mb-3 p-2 small" key={slot.subItem.id}>
								<Row>
									<Col>
										<div className="ml-3">
											<div>{slot.subItem.levelName}</div>
											<div>{slot.seatAssignment}</div>
										</div>
									</Col>
									<Col xs="auto">
										{slot.fulfillmentStatus === FulfillmentStatus.PENDING ? (
											<Button color="link">
												<LoadingIcon />
											</Button>
										) : (
											<Button color="link" onClick={() => handleRemoveSeat(slot.seatKey as string)}>
												<RemoveItemIcon />
											</Button>
										)}
									</Col>
								</Row>
							</Card>
						)
				)
			})}

			<PanelNav
				back={!!handleChangeSection ? {handleClick: handleChangeSection, label: intl.formatMessage({id: "lbl_Back"})} : undefined}
				next={{handleClick: handleConfirmSeats, label: intl.formatMessage({id: "lbl_ConfirmSeats"}), isDisabled: disableConfirm}}
			/>
		</div>
	)
}