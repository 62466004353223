import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { EventInstanceSaleStatus } from "../../enums/event-instance-sale-status";
import {getInstanceRoute} from "../../helpers/routing";
import { TeEiPair } from "../../helpers/utilities";
import { BasicStringKeyedMap } from "../../models/basic-map";
import { DetailToggleButton } from "../detail-toggle-button";
import { HTMLContent } from "../html-content/html-content";
import {SimpleHTMLContent} from "../html-content/simple-html-content";
import {PasscodeDisplayWithInjections as PasscodeDisplay, PasscodeFormWithInjections as PasscodeForm} from "../passcode/wrapped-components";

interface EventListCondensedItemProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	clearAllMessages: () => void;
	teEiPair: TeEiPair;
	fetchEvents: () => void;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

interface EventListCondensedItemState {
	isOpen: boolean;
}

export class EventListCondensedItem extends React.Component<EventListCondensedItemProps, EventListCondensedItemState> {
	public readonly state: EventListCondensedItemState = {
		isOpen: false,
	};

	public toggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	public render() {
		const {
			blockingActions,
			clearAllMessages,
			teEiPair: {ei, te},
			fetchEvents,
			validatePasscode
		} = this.props;

		const { isOpen } = this.state;
		const hasDetail = !!ei.detail || !!te.description || !!te.detail;
		const soldOutMessage = (eiNoSaleMessage: string): JSX.Element => (
			<p className="text-info text-right">
				<SimpleHTMLContent rawHtml={eiNoSaleMessage}/>
			</p>
		);

		let cta;
		let passcodeDisplay;
		let passcodeCTA;
		
		const buyTicketsCTA = (eiId: string): JSX.Element => (
			<Link className="btn btn-primary btn-sm" to={getInstanceRoute(eiId)}>
				<FormattedMessage id="pmgr_lbl_Buy" />
			</Link>);

		if (ei.noSaleMessage) {
			cta = soldOutMessage(ei.noSaleMessage);
		}
		
		if (ei.saleStatus === EventInstanceSaleStatus.OS && !ei.soldOut) {
			cta = buyTicketsCTA(ei.id);
			if (!!ei.appliedPasscode) {
				passcodeDisplay = <PasscodeDisplay
					appliedPasscode={ei.appliedPasscode}
					eventInstanceId={ei.id}
					fetchEvents={fetchEvents}
				/>;
			}
		}
		
		if (ei.isPasscodeEligible){
			passcodeCTA =
				<PasscodeForm
					blockingActions={blockingActions}
					clearAllMessages={clearAllMessages}
					eventInstanceId={ei.id}
					fetchEvents={fetchEvents}
					validatePasscode={validatePasscode}
				/>;
		}
		
		return (
			<div className="pts--condensed-list-item pts-event-instance mb-5 p-2">
				<div>
					<p className="mb-1 small text-uppercase font-weight-bold">{te.name}</p>
				</div>
				<div className="d-flex justify-content-between">
					<div className="flex-grow-1">
						<div className="mb-2">
							<span className="pts--condensed-list-item-name">
								{ei.name}
							</span>
						</div>
						<div>
							{passcodeCTA}
							{passcodeDisplay}
						</div>
					</div>
					<div>
						{cta}
					</div>
				</div>
				<div>
					{hasDetail && (
						<div className={isOpen ? 'border-top mt-2' : ''}>
							<DetailToggleButton onClick={this.toggle} detailsVisible={isOpen} />
						</div>
					)}
					{isOpen &&
						<div className="pts--condensed-list-item-details">
							{!!ei.detail && <HTMLContent rawHtml={ei.detail} />}
							{!!te.description && <HTMLContent rawHtml={te.description} />}
							{!!te.detail && <HTMLContent rawHtml={te.detail} />}
						</div>
					}
				</div>
			</div>
		);
	}
}
