import * as moment from "moment/min/moment-with-locales";
import * as React from 'react';
import {FormattedMessage} from "react-intl";
import {TimerIcon} from "./icons/icons";

interface CountdownTimerProps {
	cartTimeRemaining?: number;
	elaborate: boolean;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
	const {cartTimeRemaining, elaborate} = props;
	if (!cartTimeRemaining) {
		return null;
	}

	let minutes = 0;
	let seconds = 0;
	if (cartTimeRemaining && cartTimeRemaining > 0) {
		const diffDuration = moment.duration(cartTimeRemaining);
		minutes = diffDuration.minutes();
		seconds = diffDuration.seconds();
	}

	const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

	if (elaborate) {
		return (
			<div className="bg-primary mb-3 p-2 text-white text-center">
				<span className="pr-2">
					<TimerIcon/>
				</span>
				<span className="pr-1">
					{formattedTime}
				</span>
				<FormattedMessage id="lbl_Remaining"/>
			</div>
		);
	}

	return (
		<span>
			<span className="pr-2">
				<TimerIcon/>
			</span>
			<span>
				{formattedTime}
			</span>
		</span>
	);
};

