import * as queryString from "query-string";
import {Component} from "react";
import {WrappedComponentProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Redirect, Route, Switch} from "react-router";
import {AnyAction} from "redux";
import {AnalyticsActions} from "../actions/analytics-actions";
import {ApiActions} from "../actions/api-actions";
import {PublicTicketAppActions} from "../actions/public-ticket-app-actions";
import {CountdownTimer} from "../components/countdown-timer";
import {ListSelector} from "../components/event-list/list-selector";
import {HTMLContent} from "../components/html-content/html-content";
import MembershipList from "../components/membership/membership-list";
import SubscriptionList from "../components/subscription/subscription-list";
import {Paths} from "../enums/paths";
import {membershipEventsOnly, singleTicketEventsOnly, subscriptionEventsOnly} from "../helpers/utilities";
import {Layout, layout} from "../hoc/layout";
import {BasicStringKeyedMap} from "../models/basic-map";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {TicketableEvent} from "../models/ticketable-events/ticketable-event";
import {RootState} from "../reducers";
import {RouteProps} from "../types/route-props";
import {ThunkDispatch} from "redux-thunk";

/**
 * All properties available within this component
 */
export interface EventsProps extends EventsPropsExcludingInjectedProps, EventsMappedStateProps, EventsMappedDispatchProps, WrappedComponentProps {}

/**
 * All properties that should be defined when using the component exported with injections.
 */
interface EventsPropsExcludingInjectedProps extends RouteProps {}

interface EventsMappedStateProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cartTimeRemaining?: number;
	config: PublicTicketAppConfig;
	defaultCalendarDate?: string;
	membershipEvents: TicketableEvent[] | null;
	singleTicketsEvents: TicketableEvent[] | null;
	subscriptionEvents: TicketableEvent[] | null;
}

interface EventsMappedDispatchProps {
	clearAllMessages: () => void;
	fetchEvents: () => void;
	pageView: (title: string, url: string) => void;
	setDefaultCalendarDate: (newDate: string) => void;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

export class Events extends Component<EventsProps> {
	private EventListComponent: Layout<typeof ListSelector, typeof HTMLContent>;
	private MembershipListComponent: Layout<typeof MembershipList, typeof HTMLContent>;
	private SubscriptionListComponent: Layout<typeof SubscriptionList, typeof HTMLContent>;
	
	constructor(props: EventsProps) {
		super(props);
		const {intl} = props;
		this.EventListComponent = layout({Main: ListSelector, Panel: HTMLContent});
		this.MembershipListComponent = layout({Main: MembershipList, Panel: HTMLContent}, null, null, {primary: intl.formatMessage({id: 'pmgr_term_Memberships'})});
		this.SubscriptionListComponent =  layout({Main: SubscriptionList, Panel: HTMLContent}, null, null, {primary: intl.formatMessage({id: 'pmgr_term_Subscriptions'})});
	}
	
	public componentDidMount() {
		this.props.fetchEvents();
		this.props.pageView(this.getTitle(), window.location.href);
	}
	
	public render() {
		const {
			blockingActions,
			cartTimeRemaining,
			clearAllMessages,
			config, 
			fetchEvents,
			history,
			intl,
			location, 
			match,
			membershipEvents,
			singleTicketsEvents, 
			subscriptionEvents,
			validatePasscode
		} = this.props;
		const {eventsContent, membershipsContent, portalLandingCopy, subscriptionsContent} = config;
		
		return (
			<>
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				<Switch>

					<Route path={Paths.MEMBERSHIPS} render={(routeProps) => {
						// Extract the "benefitId" param from the search string if present. This is the ID of a Benefit that is being renewed
						const params = queryString.parse(routeProps.location.search);
						const benefitId = (!!params && !!params.benefitId) ? params.benefitId as string : null;
						const rightPanelRawHtml: string = (
							`<div>
								${!!portalLandingCopy ? `<div class="mb-2">${portalLandingCopy}</div>` : ''}
								${!!membershipsContent ? membershipsContent : ''}
							</div>`
						);
						return <this.MembershipListComponent benefitId={benefitId} memberships={membershipEvents} rawHtml={rightPanelRawHtml}/>;
					}} />
	
					<Route path={Paths.SUBSCRIPTIONS} render={() => {
						const rightPanelRawHtml: string = (
							`<div>
								${!!portalLandingCopy ? `<div class="mb-2">${portalLandingCopy}</div>` : ''}
								${!!subscriptionsContent ? subscriptionsContent : ''}
							</div>`
						);
						return (
							<this.SubscriptionListComponent
								blockingActions={blockingActions}
								clearAllMessages={clearAllMessages}
								fetchEvents={fetchEvents}
								subscriptionEvents={subscriptionEvents} 
								rawHtml={rightPanelRawHtml}
								validatePasscode={validatePasscode}
								config={config}
							/>
						);
					}} />
	
					<Route path={Paths.EVENTS} exact={true} render={(routeProps) => {
						const rightPanelRawHtml: string = (
							`<div>
								${!!portalLandingCopy ? `<div class="mb-2">${portalLandingCopy}</div>` : ''}
								${!!eventsContent ? eventsContent : ''}
							</div>`
						);
						return (
							<this.EventListComponent
								blockingActions={blockingActions}
								clearAllMessages={clearAllMessages}
								fetchEvents={fetchEvents}
								singleTicketEvents={singleTicketsEvents}
								defaultEventView={config.defaultEventView}
								supportedEventViews={config.supportedEventViews}
								match={match}
								location={location}
								history={history}
								rawHtml={rightPanelRawHtml}
								defaultCalendarDate={this.props.defaultCalendarDate}
								onCalendarNavChange={this.setDefaultCalDate}
								timezone={config.timezone}
								validatePasscode={validatePasscode}
								config={config}
								intl={intl}
							/>
						);
					}} />
	
					<Redirect to={Paths.EVENTS} />
				</Switch>
			</>
		);
	}

	private getTitle = () => {
		// Set the title prop
		const {location: {pathname}, intl} = this.props;
		const organizationName = window.PublicTicketApp.orgName;
		let title: string;
		if (pathname.startsWith(Paths.SUBSCRIPTIONS)) {
			title = intl.formatMessage({id: 'lbl_title_subscriptions'}, {subscriptions: intl.formatMessage({id: "pmgr_term_Subscriptions"}), organizationName});
		} else if (pathname.startsWith(Paths.MEMBERSHIPS)) {
			title = intl.formatMessage({id: 'lbl_title_memberships'}, {memberships: intl.formatMessage({id: "pmgr_term_Memberships"}), organizationName});
		} else {
			title = intl.formatMessage({id: 'lbl_title_events'}, {events: intl.formatMessage({id: "pmgr_term_Events"}), organizationName});
		}
		return title;
	}
	
	private setDefaultCalDate = (newDate: string) => {
		if (newDate !== this.props.defaultCalendarDate) {
			this.props.setDefaultCalendarDate(newDate);
		}
	}
}

const mapStateToProps = (state: RootState): EventsMappedStateProps => {

	// default to null to indicate event list has not loaded yet.
	let singleTicketsEvents = null;
	let subscriptionEvents = null;
	let membershipEvents = null;

	if (state.ptApp.eventList != null) {
		singleTicketsEvents = state.ptApp.eventList.filter(singleTicketEventsOnly);
		subscriptionEvents = state.ptApp.eventList.filter(subscriptionEventsOnly);
		membershipEvents= state.ptApp.eventList.filter(membershipEventsOnly);
	}
	
	return {
		blockingActions: state.ptApp.blockingActions,
		cartTimeRemaining: state.ptApp.cartTimeRemaining,
		config: state.ptApp.config,
		defaultCalendarDate: state.ptApp.defaultCalendarDate,
		membershipEvents,
		singleTicketsEvents,
		subscriptionEvents
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, AnyAction>): EventsMappedDispatchProps => {
	return {
		clearAllMessages: () => {
			dispatch(PublicTicketAppActions.clearAllMessages());
		},
		fetchEvents: () => {
			dispatch(ApiActions.fetchEvents());
		},
		pageView: (title: string, url: string) => {
			dispatch(AnalyticsActions.pageView(title, url));
		},
		setDefaultCalendarDate: (newDate: string) => {
			dispatch(PublicTicketAppActions.setDefaultCalendarDate(newDate));
		},
		validatePasscode: (passcode: string, eventInstanceId: string) => {
			return dispatch(ApiActions.validatePasscode(passcode,eventInstanceId));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Events));
