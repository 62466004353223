import sanitizeHtml from 'sanitize-html';
import {IOptions} from "sanitize-html";

// see https://www.npmjs.com/package/sanitize-html/v/1.20.0 for options
// We're adding a few extras to the default options.
const {allowedAttributes, allowedTags, allowedSchemesByTag, allowedSchemes} = sanitizeHtml.defaults;
export const defaultOptions: IOptions = {
	allowedTags: allowedTags.concat(['h1', 'h2', 'img', 'iframe']),
	allowedAttributes: {
		...allowedAttributes,
		img: ['src', 'width', 'height', 'alt'],
		iframe: ['frameborder', 'src', 'width', 'height', 'style', 'allow']
	},
	allowedSchemesByTag: {
		...allowedSchemesByTag,
		a: [ ...allowedSchemes, 'tel']
	},
	allowedIframeHostnames: [
		'www.youtube.com',
		'player.vimeo.com',
		'open.spotify.com',
		'bandcamp.com',
		'w.soundcloud.com',
		'embed.music.apple.com'
	],
	transformTags: {
		'iframe': function(tagName, attribs) {
			// PMGR-11477 Only allowing spotify to use the "allow" iframe attribute
			let hostname;
			try{
				// try block is needed because URL constructor throws an error if the URL is not valid
				hostname = new URL(attribs['src'])?.hostname;
			} catch(e) {}
			if(hostname !== 'open.spotify.com') {
				delete attribs['allow'];
			}
			return {tagName, attribs};
		}
	}
};

interface SanitizeContentProps {
	rawHtml: string;
	options?: {};
}
export const sanitizeContent = (props: SanitizeContentProps): string => {
	const options = props.options || defaultOptions;
	const sanitized = sanitizeHtml(props.rawHtml, options);
	return sanitized.trim();
};