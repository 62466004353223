import * as React from "react";
import {FormattedMessage, IntlShape, WrappedComponentProps} from "react-intl";
import {RouteComponentProps} from "react-router";
import {AnyAction} from "redux";
import {Paths} from "../../enums/paths";
import {InventoryService} from "../../helpers/inventory-service";
import {getEventRoute, getInstanceRoute} from "../../helpers/routing";
import {shouldSkipSectionMap} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {CountdownTimer} from "../countdown-timer";
import {DetailToggleButton} from "../detail-toggle-button";
import {EmptyMiniCartMessage} from "../empty-mini-cart-message";
import {HTMLContent} from "../html-content/html-content";
import {MiniCartItemTotal} from "../mini-cart/mini-cart-item-total";
import {PanelNav} from "../panel-nav";
import {PasscodeDisplayWithInjections as PasscodeDisplay, PasscodeFormWithInjections as PasscodeForm} from "../passcode/wrapped-components";
import {Legend} from "../seat-map/legend";
import {PYOSCartItem} from "./pyos-cart-item";

interface PYOSCartProps extends RouteComponentProps<any>, WrappedComponentProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	deleteCartItems: (cartId: string, cartItems: CartItem[], nonBlocking: boolean) => Promise<any>;
	eventDescriptor: EventDescriptor;
	fetchEvents: () => void;
	insertStagedSeats: () => void;
	intl: IntlShape;
	pendingItemIds: BasicStringKeyedMap<string>;
	stagedSeatRequests: BasicStringKeyedMap<CartItem>;
	updateCartItems: (cartId: string, cartItems: CartItem[], nonBlocking: boolean) => Promise<any>;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
	deleteStagedSeat: (seatId: string) => void;
	updateStagedSeatPriceLevel: (seatId: string, priceLevelId: string, pwywPrice?: number) => void;
}

interface PYOSCartState {
	showPricingDetails: boolean;
}

export class PYOSCart extends React.Component<PYOSCartProps, PYOSCartState> {
	constructor(props: PYOSCartProps) {
		super(props);
		this.state = {showPricingDetails: true};
	}

	public togglePricingDetails = () => {
		this.setState({showPricingDetails: !this.state.showPricingDetails});
	}
	
	public render() {
		
		const {
			blockingActions,
			cart,
			cartTimeRemaining,
			clearAllMessages,
			config: {currencyCode, portalSeatSelectionMessage, includeFeesInPrice, currencySym},
			eventDescriptor,
			fetchEvents,
			intl,
			match,
			pendingItemIds,
			stagedSeatRequests,
			validatePasscode,
			...other
		} = this.props;
		
		const {appliedPasscode, isPasscodeEligible} = eventDescriptor;
		
		if (!cart.cartItems) {
			return null;
		}

		let allocationsInSectionOrGroup: AllocationDescriptor[] = [];

		if (match.path === Paths.INSTANCE__SECTION_GROUP) {
			allocationsInSectionOrGroup = InventoryService.getAllocationsByGroupId(eventDescriptor, match.params.sectionGroupId);
		} else if (match.path === Paths.INSTANCE__SECTION) {
			allocationsInSectionOrGroup = InventoryService.getAllocationsBySectionId(eventDescriptor, match.params.sectionId);
		}

		// Get the cart items for the currently selected EI
		const eiCartItems = cart.cartItems.reduce((prev: CartItem[], cartItem: CartItem) => {
			// Exclude CartItems that reference a Subscription TOI. They are fulfilling a PYOS Subscription that includes this EI
			if (eventDescriptor.id === cartItem.eiId && !cartItem.stoiId) {
				prev.push(cartItem);
			}
			return prev;
		}, []);

		let itemTotal: number = 0;
		let itemCount: number = 0;

		// Get the map of Price Levels, grouped by the Id of their parent Ticket Allocation
		const priceLevelMap: BasicStringKeyedMap<LevelDescriptor[]> = InventoryService.getLevelsMappedByAllocationId(eventDescriptor);
		const pyosCartItems:JSX.Element[] = [];

		// Combine the existing cart items with the currently staged seat requests into a single array
		const allCartItems = eiCartItems.concat(Object.values(stagedSeatRequests));
		
		let nonPWYWCartItemExists = false;

		allCartItems.forEach((cartItem: CartItem) => {
			itemTotal += Number(includeFeesInPrice ? cartItem.itemTotal : cartItem.subtotal);
			itemCount ++;
			const priceLevels = priceLevelMap[cartItem.allocId];
			if (!!priceLevels){
				if (!nonPWYWCartItemExists) {
					nonPWYWCartItemExists = priceLevels.find(pl => pl.id === cartItem.levelId)?.pwyw ? nonPWYWCartItemExists : true; 
				}
				
				pyosCartItems.push(
					<PYOSCartItem
						key={"item-" + cartItem.id}
						cart={cart}
						cartItem={cartItem}
						pending={cartItem.id in pendingItemIds}
						priceLevels={priceLevels}
						currencyCode={currencyCode}
						currencySym={currencySym}
						includeFeesInPrice={includeFeesInPrice}
						showPricingDetails={this.state.showPricingDetails}
						intl={intl}
						stagedSeat={!!cartItem.seatId && cartItem.seatId in stagedSeatRequests}
						{...other}
					/>
				);
			}
		});
		
		const miniCartHasItems = allCartItems.length > 0;

		

		return (
			<div className="pyos-cart">
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				{isPasscodeEligible && (
					<PasscodeForm
						blockingActions={blockingActions}
						clearAllMessages={clearAllMessages}
						eventInstanceId={eventDescriptor.id}
						fetchEvents={fetchEvents}
						validatePasscode={validatePasscode}
					/>
				)}
				{!!appliedPasscode && (
					<PasscodeDisplay
						appliedPasscode={appliedPasscode}
						eventInstanceId={eventDescriptor.id}
						fetchEvents={fetchEvents}
					/>
				)}
				
				<Legend
					eventDescriptor={eventDescriptor}
					currencyCode={currencyCode}
					intl={intl}
					allocationsInSectionOrGroup={allocationsInSectionOrGroup}
				/>

				{!!portalSeatSelectionMessage && (
					<div className="pt-2 mb-2">
						<HTMLContent rawHtml={portalSeatSelectionMessage} />
					</div>
				)}
				
				{miniCartHasItems
					? (
						<div>
							<div className="pts-empty-cart-message text-center mb-3 py-4">
								<FormattedMessage id="msg_item_count" values={{itemCount: allCartItems.length}}/>
							</div>
							{pyosCartItems}
							{(includeFeesInPrice && nonPWYWCartItemExists) &&
								<DetailToggleButton onClick={this.togglePricingDetails} detailsVisible={this.state.showPricingDetails} pricing={true}/>
							}
							<MiniCartItemTotal currencyCode={currencyCode} itemTotal={itemTotal} />
						</div>
					)
					: <EmptyMiniCartMessage />
				}
				
				<div className="mt-3">
					<PanelNav
						next={{handleClick: this.handleNext, label: intl.formatMessage({id: "lbl_Next"}), isDisabled: itemCount === 0 || Object.keys(pendingItemIds).length > 0}}
						back={{handleClick: this.back, label: intl.formatMessage({id: "lbl_Back"})}}
					/>
				</div>
			</div>
		);
	}

	private handleNext = () => {
		// if there are staged seats, then insert them, otherwise navigate straight to the cart
		Object.keys(this.props.stagedSeatRequests).length > 0 ? this.props.insertStagedSeats() : this.props.history.push(Paths.CART);
	};
	
	private back = () => {
		const {eventDescriptor, history} = this.props;
		// PMGR-8186 - If skipping section selection, then we can't go back to the instance route, because we'll just get
		// redirected back here. So, go to the event route instead.
		if (shouldSkipSectionMap(eventDescriptor)) {
			history.push(getEventRoute(eventDescriptor.teId));
		} else {
			history.push(getInstanceRoute(eventDescriptor.id));
		}
	}
}

