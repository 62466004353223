/**
 * Represents a key value pair. Where the keys are strings and
 * V is the Type of the Value
 *
 * @example
 * const mapOfNumbers: BasicStringKeyedMap<number> = { "A": 1, "B": 2, "C": 100};
 * const mapOfStrings: BasicStringKeyedMap<string> = { "A": "someValue", "B": "anotherValue"};
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class BasicStringKeyedMap<V> {
	[key: string]: V;
}