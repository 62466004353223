import {IntlShape} from "react-intl";
import {SeatingTypes} from "../../enums/seating-types";
import {BasicStringKeyedMap} from '../basic-map';
import {CartItem} from "../cart-item";
import {SubscriptionBuyerSelectionLinkDescriptor} from "../subscription-buyer-selection-link-descriptor";
import {EventInstance} from "../ticketable-events/event-instance";
import {SubscriptionFeeDisplay} from "./subscription-fee-display";

export const UNSPECIFIED_VENUE_ID: string = "unspecified";

class PriceLevelBreakdownMap {
	private priceLevelMap: BasicStringKeyedMap<PriceLevelItem> = {};
	public breakdown: string[] = [];
	public addPriceLevelItemAndReturnBreakdown = (ci: CartItem) => {
		if (ci.levelId in this.priceLevelMap) {
			this.priceLevelMap[ci.levelId].quantity += ci.qty;
		} else {
			this.priceLevelMap[ci.levelId] = new PriceLevelItem(ci.levelId, ci.levelName, ci.qty);
		}
		return Object.values(this.priceLevelMap).map((pli: PriceLevelItem) => {
			return pli.quantity + 'x ' + pli.name;
		});
	}
}

class PriceLevelItem {
	public id: string;
	public name: string;
	public quantity: number = 1;
	constructor(id: string, name: string, quantity: number) {
		this.id = id;
		this.name = name;
		this.quantity = quantity;
	}
}

export class SubscriptionTicketDisplay {

	private priceLevelBDMap: PriceLevelBreakdownMap = new PriceLevelBreakdownMap();

	public allocId: string = '';
	public allocName: string = '';
	public customCartText: string = '';
	public discountCodes: BasicStringKeyedMap<string> = {};
	public eiId: string = '';
	public eiName: string = '';
	public fees: BasicStringKeyedMap<SubscriptionFeeDisplay> = {};
	public quantity: number = 0;
	public quantityBreakdown: string[] = [];
	public subscriptionCartItems: BasicStringKeyedMap<CartItem> = {};
	public subscriptionType: string = '';
	public taName: string = '';
	public teName: string = '';
	public ticketAmount: number = 0;
	public ticketSubtotal: number = 0;
	public totalAmount: number = 0;
	public feeSubtotal: number = 0;
	public venueGroups: BasicStringKeyedMap<VenueGroup> = {}
	public instanceGroups: BasicStringKeyedMap<InstanceGroup> = {}

	constructor(subsItem: CartItem) {
		this.allocId = subsItem.allocId;
		this.allocName = subsItem.allocName;
		this.eiId = subsItem.eiId;
		this.eiName = subsItem.eiName;
		this.customCartText = subsItem.customCartText ? subsItem.customCartText : '';
		this.quantity = subsItem.qty;
		this.subscriptionCartItems[subsItem.id] = subsItem;
		this.subscriptionType = subsItem.subsType ? subsItem.subsType : '';
		this.teName = subsItem.teName;
		this.ticketAmount = subsItem.subtotal ? subsItem.subtotal : 0;
		this.ticketSubtotal = (subsItem.subtotal || 0) + (subsItem.disTotal || 0);
		this.totalAmount = subsItem.itemTotal;
		this.feeSubtotal = subsItem.itemFeeTotal || 0;
	}

	public addSubscriptionCartItem(subsItem: CartItem) {
		if (subsItem.allocId !== this.allocId) {
			return;
		}
		this.totalAmount += subsItem.itemTotal;
		this.ticketSubtotal += (subsItem.subtotal || 0) + (subsItem.disTotal || 0);
		this.ticketAmount += subsItem.subtotal ? subsItem.subtotal : 0;
		this.feeSubtotal += subsItem.itemFeeTotal || 0;
		this.quantity += subsItem.qty;
		this.subscriptionCartItems[subsItem.id] = subsItem;
	}

	/**
	 * This method adds the passed in fulfillment CartItem to the appropriate VenueGroup in the venueGroups structure
	 * @param fItem a fulfillment CartItem to be added.
	 */
	public addFulfillmentCartItemToVenueGroup(fItem: CartItem) {
		// Make sure the fulfillment CartItem being added fulfills one of the subscription CartItems in this object
		if (!fItem.stoiId || !(fItem.stoiId in this.subscriptionCartItems)) {
			return;
		}
		// Identify which VenueGroup it belongs in
		let venueGroup: VenueGroup | undefined;
		if (fItem.venueId) {
			venueGroup = this.venueGroups[fItem.venueId];
		} else {
			// It must be part of the "unspecified" VenueGroup
			venueGroup = this.venueGroups[UNSPECIFIED_VENUE_ID];
		}
		// Add the fulfillment item to the VenueGroup
		if (venueGroup) {
			venueGroup.addFulfillmentCartItem(fItem);
		}
	}

	/**
	 * This method adds the passed in fulfillment CartItem to the appropriate Instance Group in the instanceGroups structure
	 * @param fItem a fulfillment CartItem to be added.
	 */
	public addFulfillmentCartItemToInstanceGroup(fItem: CartItem) {
		// Make sure the fulfillment CartItem being added fulfills one of the subscription CartItems in this object
		if (!fItem.stoiId || !(fItem.stoiId in this.subscriptionCartItems)) {
			return;
		}
		// Identify which Instance Group it belongs in
		const instanceGroup = this.instanceGroups[fItem.eiId]
		// Add the fulfillment item to the Instance Group
		if (instanceGroup) {
			instanceGroup.addFulfillmentItem(fItem);
		}
	}

	public static getSubscriptionTicketDisplayStructure = (cartItems: CartItem[], intl: IntlShape): SubscriptionTicketDisplay[] => {
		const transformedCart: BasicStringKeyedMap<SubscriptionTicketDisplay> = {};
		
		// Process the "Subscription" CartItems
		cartItems.filter(ci => CartItem.isSubscriptionItem(ci)).forEach(subsItem => {
			SubscriptionTicketDisplay.addSubscriptionCartItems(subsItem, transformedCart, intl);
			if (!!subsItem.disName) {
				transformedCart[subsItem.allocId].discountCodes[subsItem.disName] = subsItem.disName;
			}
			SubscriptionFeeDisplay.tallyFees(subsItem, transformedCart[subsItem.allocId]);

			// Add the CartItem to the price-level breakdown structure and capture the current breakdown result
			transformedCart[subsItem.allocId].quantityBreakdown = transformedCart[subsItem.allocId].priceLevelBDMap.addPriceLevelItemAndReturnBreakdown(subsItem);

			// Process related fulfillment items ("Tickets" whose stoiId value matches the id of the "Subscription" CartItem being processed)
			cartItems.filter(ci => CartItem.isFulfillmentItem(ci) && ci.stoiId === subsItem.id).forEach((fulfillmentItem: CartItem) => {
				CartItem.isChooseYourOwnSubscription(subsItem)
					? transformedCart[subsItem.allocId].addFulfillmentCartItemToInstanceGroup(fulfillmentItem)
					: transformedCart[subsItem.allocId].addFulfillmentCartItemToVenueGroup(fulfillmentItem);
			});
		});
		
		return Object.values(transformedCart);
	}

	private static addSubscriptionCartItems = (subsItem: CartItem, stdStructure: BasicStringKeyedMap<SubscriptionTicketDisplay>, intl: IntlShape) => {
		if (!CartItem.isSubscriptionItem(subsItem)) {
			return;
		}

		if (subsItem.allocId in stdStructure) {
			// Increments and adds-up the amounts
			stdStructure[subsItem.allocId].addSubscriptionCartItem(subsItem);
		} else {
			// Adds the new item to the structure
			stdStructure[subsItem.allocId] = new SubscriptionTicketDisplay(subsItem);
		}

		subsItem.sbsls!.forEach((sbsl: SubscriptionBuyerSelectionLinkDescriptor) => {
			CartItem.isChooseYourOwnSubscription(subsItem)
				? InstanceGroup.addSBSLToInstanceGroup(sbsl, stdStructure[subsItem.allocId].instanceGroups)
				: VenueGroup.addSBSLToVenueGroup(sbsl, stdStructure[subsItem.allocId].venueGroups, intl);
		});
	}
}

export class VenueGroup {
	public venueId: string;
	public venueName: string;
	public pyosInstanceGroups: BasicStringKeyedMap<InstanceGroup> = {};
	public gaInstanceGroups: BasicStringKeyedMap<InstanceGroup> = {};

	public addFulfillmentCartItem(fItem: CartItem) {
		// If the fulfillment item specifies a different venue,
		// or it doesn't specify a venue and we're not adding 
		// it to the "unspecified" group, just return
		if ((fItem.venueId && fItem.venueId !== this.venueId) || 
			(!fItem.venueId && this.venueId !== UNSPECIFIED_VENUE_ID)) {
			return;
		}
		// Determine which InstanceGroup it belongs to
		let instanceGroup;
		if (fItem.seatingType === SeatingTypes.PYOS) {
			instanceGroup = this.pyosInstanceGroups[fItem.eiId];
		} else {
			instanceGroup = this.gaInstanceGroups[fItem.eiId];
		}
		// Add it to the InstanceGroup (assuming we found one)
		if (instanceGroup) {
			instanceGroup.addFulfillmentItem(fItem);
		}
	}

	/**
	 * Returns an array of seat assignment strings derived from the PYOS fulfillment items in the group.
	 * The returned array is sorted in alphabetical order.
	 */
	public getSeatAssignments(): string[] {
		const seatAssignments = new Set<string>();
		Object.values(this.pyosInstanceGroups).forEach(instanceGroup => {
			instanceGroup.seatAssignments.forEach(seatAssignment => seatAssignments.add(seatAssignment));
		});
		return Array.from(seatAssignments).sort();
	}

	/**
	 * Returns true if ALL of the PYOS InstanceGroup objects are fulfilled
	 */
	public isFulfilled(): boolean {
		return Object.values(this.pyosInstanceGroups).every(instanceGroup => instanceGroup.isFulfilled());
	}
	
	/**
	 * Adds SubscriptionEventDisplay object for the current SBSL, if, and only if,
	 * the SBSL is for the venue referenced by the SubscriptionVenueDisplay object
	 * @param sbsl a SubscriptionBuyerSelectionLinkDescriptor object
	 * @param venueGroups an Object, whose keys are venue IDs, and whose values are VenueDisplay objects
	 * @param intl the InjectedIntl instance used to localize strings
	 */
	public static addSBSLToVenueGroup = (sbsl: SubscriptionBuyerSelectionLinkDescriptor, venueGroups: BasicStringKeyedMap<VenueGroup>, intl: IntlShape) => {
		// Lump SBSLs that don't specify a Venue under the "Unspecified Venue" key
		const venueId = !!sbsl.venueId ? sbsl.venueId : UNSPECIFIED_VENUE_ID;
		const venueName = !!sbsl.venueName ? sbsl.venueName : intl.formatMessage({id: "lbl_UnspecifiedVenue"});
		let venueGroup = venueGroups[venueId];
		if (!venueGroup) {
			// It's the first time we've seen this venueId, so create a new VenueDisplay instance
			venueGroup = new VenueGroup();
			venueGroup.venueId = venueId;
			venueGroup.venueName = venueName;
			venueGroups[venueId] = venueGroup;
		}
		// Add the SBSL to the appropriate mapping object, by seating type
		let instanceGroup;
		if (sbsl.seatingType === SeatingTypes.PYOS) {
			instanceGroup = venueGroup.pyosInstanceGroups[sbsl.eiId];
			if (!instanceGroup) {
				instanceGroup = new InstanceGroup(sbsl);
				venueGroup.pyosInstanceGroups[sbsl.eiId] = instanceGroup;
			}
		} else {
			instanceGroup = venueGroup.gaInstanceGroups[sbsl.eiId];
			if (!instanceGroup) {
				instanceGroup = new InstanceGroup(sbsl);
				venueGroup.gaInstanceGroups[sbsl.eiId] = instanceGroup;
			}
		}
		instanceGroup.sbsls[sbsl.id] = sbsl;
	}
}

export class InstanceGroup{
	public eiId: string;
	public eiName: string;
	public teId: string;
	public teName: string;
	public seatingType: SeatingTypes;
	public sbsls: BasicStringKeyedMap<SubscriptionBuyerSelectionLinkDescriptor> = {};	// Map of SBSLs, keyed by their Id
	public fItems:  BasicStringKeyedMap<CartItem[]> = {};		// Map of fulfillment CartItems, keyed by the Id of the SBSL they fulfill
	public seatAssignments: Set<string> = new Set<string>();	// Set of seatAssignments from any of the PYOS fulfillment items
	
	constructor(sbsl?: SubscriptionBuyerSelectionLinkDescriptor, instance?: EventInstance) {
		if(!!sbsl) {
			this.eiId = sbsl.eiId;
			this.eiName = sbsl.eiName;
			this.seatingType = sbsl.seatingType;
			this.teId = sbsl.teId;
			this.teName = sbsl.teName;

		} else if(!!instance) {
			this.eiId = instance.id;
			this.eiName = instance.name;
			this.teName = instance.eventName;
		}
	}
	
	public static addSBSLToInstanceGroup(sbsl: SubscriptionBuyerSelectionLinkDescriptor, instanceGroups: BasicStringKeyedMap<InstanceGroup>) {
		let instanceGroup = instanceGroups[sbsl.eiId];
		if (!instanceGroup) {
			instanceGroup = new InstanceGroup(sbsl);
			instanceGroups[sbsl.eiId] = instanceGroup;
		}
		instanceGroup.sbsls[sbsl.id] = sbsl;
	}

	/**
	 * Add the fItem to the fItems object, mapping/grouping them by the Id of the SBSL they fulfill.
	 * The fulfillment CartItem is only added if its sbslId property is contained in the sbsls object. 
	 * This method also adds the seatAssignment value (if set) to the seatAssignments Set. 
	 * @fItem a fulfillment CartItem to be added to the fItems object.
 	 */
	public addFulfillmentItem(fItem: CartItem) {
		if (!fItem.sbslId || !(fItem.sbslId in this.sbsls)) {
			// It's not a fulfillment item if sbslId is not set
			return;
		}
		let fulfillmentItems = this.fItems[fItem.sbslId];
		if (!fulfillmentItems) {
			fulfillmentItems = new Array<CartItem>();
			this.fItems[fItem.sbslId] = fulfillmentItems;
		}
		fulfillmentItems.push(fItem);
		
		// Add the seatAssign value to the Set of seat assignments for this InstanceGroup
		if (fItem.seatAssign) {
			this.seatAssignments.add(fItem.seatAssign);
		}
	}

	/**
	 * Returns true if all of the SBSLs for PYOS events have the required number of associated fulfillment items.
	 */
	public isFulfilled(): boolean {
		return (
			Object.values(this.sbsls)
				.filter(sbsl => sbsl.seatingType === SeatingTypes.PYOS)
				.every(sbsl => !!this.fItems[sbsl.id] && this.fItems[sbsl.id].length === sbsl.qty)
		);
	}
	
	/**
	 * Returns an array of livestream URLs for any "virtual" fulfillment items in the InstanceGroup 
 	 */
	public getLivestreamUrls() {
		return Object.values(this.fItems).reduce((prev: string[], fItemArray: CartItem[]): string[] => {
			fItemArray.forEach(fItem => {
				if (fItem.liveStreamUrl) {
					prev.push(fItem.liveStreamUrl);
				}
			});
			return prev;
		}, []);
	}
}
