import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {Paths} from "../../enums/paths";

declare var window: any;

interface UserWelomeProps {
	userName: string;
	handleLogoutClick: () => void;
}
/**
 * A dropdown menu displayed when a user is logged in.
 * Displays a very brief welcome message and allows them to log out.
 * In the future, this could support other Portal functionality.
 */
export const UserWelcome = (props: UserWelomeProps) => {
	const {userName, handleLogoutClick} = props;
	return (
		<UncontrolledDropdown nav={true} inNavbar={true}>
			<DropdownToggle nav={true} caret={true}>
				<span className="mr-1">
					<FormattedMessage id="lbl_Hello" />
				</span>
				<span>{userName}</span>
			</DropdownToggle>
			<DropdownMenu right={true}>
				<DropdownItem tag={Link} to={Paths.PORTAL}>
					<FormattedMessage id="lbl_nav_MyProfile" />
				</DropdownItem>
				<DropdownItem tag="a" href={window.PublicTicketApp.sitePrefix + '/secur/logout.jsp'} onClick={handleLogoutClick}>
						<FormattedMessage id="lbl_LogOut" />
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};