import {Component} from "react";
import {IntlShape} from "react-intl";
import {CartItem} from "../../models/cart-item";
import {TwitterIcon} from "../icons/icons";
import {ShareButton} from "./share-button";
import {TwitterSDK} from "./twitter-sdk";

interface TwitterShareButtonProps {
	eventInstance: CartItem;
	intl: IntlShape;
	twitterHandle?: string;
	url: string; // the absolute HTTP/HTTPS URL to be shared on twitter
}

export class TwitterShareButton extends Component<TwitterShareButtonProps> {

	public componentDidMount() {
		new TwitterSDK().load();
	}

	public render() {

		const {url, intl, eventInstance, twitterHandle} = this.props;

		if (!url) {
			return;
		}

		const displayEventName = `${eventInstance.teName} - ${eventInstance.eiName}`;

		let messageToShare = '';

		if (twitterHandle) {
			messageToShare = intl.formatMessage({id: 'msg_default_twitter_share_message'}, {
					eventName: displayEventName,
					twitterHandle: `@${twitterHandle}`
				});
		} else {
			messageToShare = intl.formatMessage({id: 'msg_default_social_media_share_message'}, {eventName: displayEventName});
		}

		const twitterShareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(messageToShare)}&url=${encodeURIComponent(url)}`;

		// Twitter's SDK will automatically bind to <a> tags with whose href points to the twitter intent url.
		// as of this time 10/30/2018, it must be an <a> buttons and javascript window.open() will not go through the SDK
		return (
			<a href={twitterShareURL}>
				<ShareButton icon={TwitterIcon} context="Twitter"/>
			</a>
		);
	}
}
