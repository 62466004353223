import {CartItem} from "./cart-item";
import {SubscriptionBuyerSelectionLinkDescriptor} from "./subscription-buyer-selection-link-descriptor";

export const enum FulfillmentStatus {
	NEEDS_SELECTION = "needs selection",
	STAGED = "staged",
	PENDING = "pending",
	FULFILLED = "fulfilled"
}

export class SubscriptionFulfillmentSlot {
	public subItem: CartItem;
	public seatAssignment: string;
	public seatKey: string;
	public fulfillmentItems: CartItem[] = [];
	public sbsls: SubscriptionBuyerSelectionLinkDescriptor[] = [];
	public fulfillmentStatus: FulfillmentStatus = FulfillmentStatus.NEEDS_SELECTION;
	
	constructor(ci: CartItem, sbsls: SubscriptionBuyerSelectionLinkDescriptor[]) {
		this.subItem = ci;
		this.sbsls = sbsls;
	}
}