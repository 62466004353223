import * as React from 'react';
import {FormattedMessage} from "react-intl";
import {IntlShape} from "react-intl";
import {RouterProps} from "react-router";
import {Button, Col, Row} from "reactstrap";
import {AnyAction} from "redux";
import {Paths} from "../../enums/paths";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {FieldGroup, FieldGroupTypes} from "../field-group";
import {MainContentHeader} from "../main-content-header";
import {Message} from "../message";

interface ChangePasswordFormProps extends RouterProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	changePassword: (oldPassword: string, newPassword: string, verifyPassword: string) => void;
	changePasswordMessageId?: string;
	intl: IntlShape;
	oldPasswordRequired: boolean;
	pageView: (title: string, url: string) => void;
}

interface ChangePasswordFormState {
	formValues: BasicStringKeyedMap<string>;
	showPasswords: boolean;
	errors: BasicStringKeyedMap<string>;
}

export class ChangePasswordForm extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {
	public readonly state: ChangePasswordFormState = {
		formValues: {
			oldPassword: '',
			newPassword: '',
			verifyPassword: ''
		},
		showPasswords: false,
		errors: {},
	};
	
	public render() {
		const {errors} = this.state;
		const {formatMessage} = this.props.intl;
		const {oldPasswordRequired, changePasswordMessageId} = this.props;
		
		const isBusy: boolean = Object.keys(this.props.blockingActions).length > 0;
		
		return (
			<div>
				<MainContentHeader intlId="lbl_ChangePassword" />
				{changePasswordMessageId && <Message intlId={changePasswordMessageId} />}
				<Row>
					<Col>
						{oldPasswordRequired &&
							<FieldGroup 
								id="oldPassword" 
								name="oldPassword" 
								type={this.state.showPasswords ? FieldGroupTypes.TEXT : FieldGroupTypes.PASSWORD} 
								label={formatMessage({id:'lbl_OldPassword'})}
								value={this.state.formValues.oldPassword || ""} 
								onChange={this.handlePropertyChange}
								invalid={!!errors.oldPassword}
								feedbackMessage={errors.oldPassword}
							/>
						}
						<FieldGroup 
							id="newPassword" 
							name="newPassword" 
							type={this.state.showPasswords ? FieldGroupTypes.TEXT : FieldGroupTypes.PASSWORD} 
							label={formatMessage({id:'lbl_NewPassword'})}
							value={this.state.formValues.newPassword || ""} 
							onChange={this.handlePropertyChange}
							invalid={!!errors.newPassword}
							feedbackMessage={errors.newPassword}
						/>
						<FieldGroup 
							id="verifyPassword" 
							name="verifyPassword" 
							type={this.state.showPasswords ? FieldGroupTypes.TEXT : FieldGroupTypes.PASSWORD} 
							label={formatMessage({id:'lbl_VerifyYourPassword'})}
							value={this.state.formValues.verifyPassword || ""} 
							onChange={this.handlePropertyChange}
							invalid={!!errors.verifyPassword}
							feedbackMessage={errors.verifyPassword}
						/>
						<FieldGroup
							id="showPasswords"
							name="showPasswords"
							type={FieldGroupTypes.CHECKBOX}
							label={formatMessage({id: 'lbl_ShowPasswords'})}
							value={this.state.showPasswords || false}
							checked={this.state.showPasswords}
							onChange={this.handleShowPasswordsChanged}
							disabled={isBusy}
						/>
					</Col>
				</Row>
				<div className="text-right mt-3">
					{oldPasswordRequired &&
						<Button id="CancelChangePassword" size="sm" color="link" className="mr-2" onClick={this.handleCancel}>
							<span className="text-danger">
								<FormattedMessage id="lbl_button_Cancel" />
							</span>
						</Button>
					}
					<Button id="ChangePassword" color="primary" onClick={this.handleChangePassword} disabled={isBusy}>
						<FormattedMessage id="lbl_ChangePassword" />
					</Button>
				</div>
			</div>
		);
	}
	
	private handleCancel = () => {
		this.props.history.push(Paths.PORTAL);
	}
	
	private handleShowPasswordsChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({showPasswords: evt.target.checked});
	}
	
	private handlePropertyChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const {formValues} = this.state;
		formValues[evt.target.name] = evt.target.value;
		this.setState({formValues});
	}
	
	private handleChangePassword = () => {
		if (this.validateForm()) {
			const {formValues} = this.state;
			this.props.changePassword(formValues.oldPassword,formValues.newPassword,formValues.verifyPassword);
		}
	}
	
	private validateForm = () => {
		const {intl} = this.props;
		const {formValues} = this.state;
		
		const requiredFields = [
			"newPassword",
			"verifyPassword"
		];
		if (this.props.oldPasswordRequired){
			requiredFields.push("oldPassword");
		}
		
		const errors: BasicStringKeyedMap<string> = {};
		requiredFields.forEach((fieldName: string) => {
			if (!formValues[fieldName]) {
				errors[fieldName] = intl.formatMessage({id: "msg_required_field"});
			}
		});
		
		if (!!formValues.newPassword && !!formValues.verifyPassword && formValues.newPassword !== formValues.verifyPassword) {
			errors.verifyPassword = intl.formatMessage({id: "msg_password_mismatch"});
		}
		
		this.setState({errors});
		
		return (Object.keys(errors).length === 0);
	}
}
