import {History} from "history";
import * as React from "react";
import {IntlShape, WrappedComponentProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AnalyticsActions} from "../actions/analytics-actions";
import {ApiActions} from "../actions/api-actions";
import {CartActions} from "../actions/cart-actions";
import {PublicTicketAppActions} from "../actions/public-ticket-app-actions";
import {BuyerInfoForm} from "../components/buyer-info-form";
import {DeliveryForm} from "../components/delivery-form";
import {DiscountForm} from "../components/discount-form";
import {DonationForm} from "../components/donation-form";
import {DonationMessage} from "../components/donation-message";
import {EmptyCart} from "../components/empty-cart";
import {OrderSummary} from "../components/order-summary";
import {PaymentForm} from "../components/payment/payment-form";
import {TicketOrderPropsExcludingInjectedProps, TicketOrderWithIntl} from '../components/ticket-order/ticket-order';
import {Paths} from "../enums/paths";
import {Severities} from "../enums/severities";
import {getNextPath, getPreviousPath} from "../helpers/routing";
import {getCartPageTitle} from "../helpers/utilities";
import {Layout, layout} from "../hoc/layout";
import {Analytics} from "../models/analytics";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart as CartModel} from "../models/cart";
import {CartItem} from "../models/cart-item";
import {EventDescriptor} from "../models/event-descriptor/event-descriptor";
import {FeeDescriptor} from "../models/fee-descriptor";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {RootState} from "../reducers";

///
/// Interfaces
///

/**
 * All properties available within this component
 */
interface CartProps extends CartPropsExcludingInjectedProps, CartPropsStateToProps, CartDispatchToProps, WrappedComponentProps {}

/**
 * All properties that should be defined when using the component exported with injections.
 */
interface CartPropsExcludingInjectedProps extends RouteComponentProps<any> {}

interface CartDispatchToProps {
	applyDiscount: (cartId: string, discountCode: string, modstamp?: number | null) => Promise<any>;
	clearAllMessages: () => void;
	deleteCartItems: (cartId: string, cartItems: CartItem[], modstamp?: number | null) => Promise<any>;
	emptyCart: () => Promise<any>;
	ensureCart: () => Promise<any>;
	fetchGiftCardBalance: (gcNumber: string, captchaToken: string) => Promise<any>;
	fetchGiftCardBalanceByPaymentMethodId: (paymentMethodId: string) => Promise<any>;
	fetchItemFeeData: (cartId: string) => Promise<any>;
	fetchOrderFeeData: (cartId: string) => Promise<any>;
	onCustomFieldChange: (customFields: BasicStringKeyedMap<any>) => void;
	pageView: (title: string, url: string) => void;
	saveProps: (props: BasicStringKeyedMap<any>) => void;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
	showModalConfirmation: (body: React.ReactNode, confirmationCallback: () => any, intl: IntlShape) => void;
	submitCart: (history: History) => Promise<any>;
	updateCart: () => Promise<any>;
	updateCartItems: (cartId: string, cartItems: CartItem[], nonBlocking?: boolean) => Promise<any>;
}

interface CartPropsStateToProps {
	analytics: Analytics;
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: CartModel;
	cartTimeRemaining?: number;
	config: PublicTicketAppConfig;
	itemFeeData: FeeDescriptor[];
	orderFeeData: FeeDescriptor[];
	pyosEnabledSubscriptionEventDescriptors: BasicStringKeyedMap<string>;
	selectedEI?: EventDescriptor | null;
}

///
/// Component
///

export class Cart extends React.Component<CartProps> {
	private Delivery: Layout<typeof TicketOrderWithIntl, DeliveryForm>;
	private Donation: Layout<typeof DonationMessage, DonationForm>;
	private Discount: Layout<typeof TicketOrderWithIntl, DiscountForm>;
	private Contact: Layout<typeof TicketOrderWithIntl, BuyerInfoForm>;
	private Payment: Layout<typeof OrderSummary, typeof PaymentForm>;
	private EmptyCart: Layout<typeof EmptyCart>;

	public constructor(props: CartProps) {
		super(props);
		const {formatMessage} = props.intl;
		
		// Need to create the layout components in the constructor, and not in the render method, because of this problem:
		// https://labs.chiedo.com/blog/always-define-components-outside-react-render-method/
		const mainHeading = formatMessage({id: "lbl_YourOrder"});
		const screenPrimaryHeading = {primary: formatMessage({id: "lbl_Checkout"})};
		
		this.Delivery = layout(
			{Main: TicketOrderWithIntl, Panel: DeliveryForm},
			mainHeading,
			formatMessage({id: "lbl_Delivery"}),
			screenPrimaryHeading
		);
		this.Donation = layout(
			{Main: DonationMessage, Panel: DonationForm},
			props.config.donationCTAHeader,
			formatMessage({id: "lbl_Donation"}),
			screenPrimaryHeading
		);
		this.Discount = layout(
			{Main: TicketOrderWithIntl, Panel: DiscountForm},
			mainHeading,
			formatMessage({id: "lbl_Discounts"}),
			screenPrimaryHeading
		);
		this.Contact = layout(
			{Main: TicketOrderWithIntl, Panel: BuyerInfoForm},
			mainHeading,
			formatMessage({id: "lbl_Contact"}),
			screenPrimaryHeading,
			{largePanel: true}
		);
		this.Payment = layout(
			{Main: OrderSummary, Panel: PaymentForm},
			mainHeading,
			formatMessage({id: "lbl_Payment"}),
			screenPrimaryHeading
		);
		this.EmptyCart = layout({Main: EmptyCart});
	}
	
	public componentDidMount() {
		const {cart, fetchItemFeeData, fetchOrderFeeData, pageView} = this.props;

		if (!!cart.cartId) {
			fetchItemFeeData(cart.cartId);
			fetchOrderFeeData(cart.cartId);
		}
		
		pageView(this.getTitle(), window.location.href);
	}

	public componentDidUpdate(prevProps: CartProps) {
		const {analytics, cart, fetchItemFeeData, fetchOrderFeeData, pageView} = this.props;

		// Fetch the fee data once we know the Cart has been loaded into Redux state
		if (!!cart.cartId && cart.cartId !== prevProps.cart.cartId) {
			fetchItemFeeData(cart.cartId);
			fetchOrderFeeData(cart.cartId);
		}
		
		// Send a page view event anytime the URL changes (unless we're transitioning to the order complete page, 
		// in which case the page view will be sent from that component)
		if (analytics.url !== window.location.href && !window.location.href.endsWith('complete')) {
			pageView(this.getTitle(), window.location.href);
		}
	}
	
	public render() {
		const {
			applyDiscount,
			blockingActions,
			cart,
			cartTimeRemaining,
			clearAllMessages,
			config,
			emptyCart,
			history,
			itemFeeData,
			intl,
			orderFeeData,
			pyosEnabledSubscriptionEventDescriptors,
			saveProps,
			selectedEI,
			showAlert,
			showModalConfirmation,
			updateCart,
		} = this.props;
		
		// If still fetching the Cart, don't render anything
		if (!cart.cartId && Object.keys(blockingActions).length > 0) {
			return null;
		}
		
		const cartHasItems = cart.cartItems.length > 0;
		const thePath = this.props.location.pathname;

		const prevPath = getPreviousPath(thePath,config,'');
		const nextPath = getNextPath(thePath,config,'');

		// Properties required for the TicketOrder Component used within the Layout component.
		// Since all components below are using the same property values, lets define them once here
		const ticketOrderProperties: TicketOrderPropsExcludingInjectedProps = {
			allowEdit: true,
			blockingActions,
			cart,
			changeItemPriceLevel: this.handlePriceLevelChange,
			config,
			deleteCartItems: this.props.deleteCartItems,
			emptyCart,
			itemFeeData,
			orderFeeData,
			pyosEnabledSubscriptionEventDescriptors,
			removeItem: this.handleRemoveCartItem,
			selectedEI,
			showModalConfirmation
		};

		if (cartHasItems) {
			return (
				<Switch>
					<Route
						path={Paths.CART__DELIVERY}
						exact={true}
						render={() => {
							return <this.Delivery
								{...ticketOrderProperties}
								cartTimeRemaining={cartTimeRemaining}
								clearAllMessages={clearAllMessages}
								history={history}
								saveProps={saveProps}
								showAlert={showAlert}
								updateCart={updateCart}
								intl={intl}
								nextPagePath={nextPath}
							/>;
						}}
					/>
					<Route
						path={Paths.CART__DONATION}
						exact={true}
						render={() => {
							return <this.Donation
								blockingActions={blockingActions}
								cart={cart}
								cartTimeRemaining={cartTimeRemaining}
								clearAllMessages={clearAllMessages}
								config={config}
								history={history}
								intl={intl}
								saveProps={saveProps}
								showAlert={showAlert}
								updateCart={updateCart}
								nextPagePath={nextPath}
								prevPagePath={prevPath}
							/>;
						}}
					/>
					<Route
						path={Paths.CART__DISCOUNT}
						exact={true}
						render={() => {
							return <this.Discount
								{...ticketOrderProperties}
								applyDiscount={applyDiscount}
								cartTimeRemaining={cartTimeRemaining}
								clearAllMessages={clearAllMessages}
								history={history}
								intl={intl}
								showAlert={showAlert}
								nextPagePath={nextPath}
								prevPagePath={prevPath}
							/>;
						}}
					/>
					<Route
						path={Paths.CART__CONTACT}
						exact={true}
						render={() => {
							return <this.Contact
								{...ticketOrderProperties}
								applyDiscount={applyDiscount}
								cartTimeRemaining={cartTimeRemaining}
								clearAllMessages={clearAllMessages}
								history={history}
								intl={intl}
								onCustomFieldChange={this.props.onCustomFieldChange}
								updateCart={updateCart}
								saveProps={saveProps}
								showAlert={showAlert}
								nextPagePath={nextPath}
								prevPagePath={prevPath}
							/>;
						}}
					/>
					<Route
						path={Paths.CART__PAYMENT}
						exact={true}
						render={() => {
							return <this.Payment
								{...ticketOrderProperties}
								cartTimeRemaining={cartTimeRemaining}
								fetchGiftCardBalance={this.props.fetchGiftCardBalance}
								fetchGiftCardBalanceByPaymentMethodId={this.props.fetchGiftCardBalanceByPaymentMethodId}
								clearAllMessages={clearAllMessages}
								history={history}
								intl={intl}
								userProfile={config.userProfile}
								saveProps={saveProps}
								showAlert={showAlert}
								submitCart={this.props.submitCart}
								prevPagePath={prevPath}
							/>;
						}}
					/>

					{/*Redirect all non-matching routes*/}
					<Redirect to={Paths.CART__DELIVERY} />
				</Switch>
			);
		}

		// todo: need to adjust to be routable or it won't catch any redirects when the cart is empty
		return <this.EmptyCart intl={intl} />;
	}
	
	private getTitle = () => {
		return getCartPageTitle(window.location.href, this.props.intl);
	}
	
	private handleRemoveCartItem = (cartItemId: string): void => {
		const {cart, deleteCartItems} = this.props;
		const cartItem = cart.cartItems.find((ci) => ci.id === cartItemId);
		if (!!cartItem && !!cartItem.id) {
			deleteCartItems(cart.cartId, [cartItem], cart.modstamp);
		}
	}
	
	private handlePriceLevelChange = (cartItemId: string, newLevelId: string): void => {
		const {cart} = this.props;
		const cartItem = cart.cartItems.find((ci) => ci.id === cartItemId);
		const updatedCartItem = Object.assign({}, cartItem, {levelId: newLevelId});
		this.props.updateCartItems(cart.cartId, [updatedCartItem]);
	}
}

const mapStateToProps = (state: RootState): CartPropsStateToProps => {
	return {
		analytics: state.analytics,
		blockingActions: state.ptApp.blockingActions,
		cart: state.cart,
		cartTimeRemaining: state.ptApp.cartTimeRemaining,
		config: state.ptApp.config,
		itemFeeData: state.ptApp.itemFeeData,
		orderFeeData: state.ptApp.orderFeeData,
		pyosEnabledSubscriptionEventDescriptors: state.eventDescriptorCache.pyosEnabledSubscriptionEventDescriptors,
		selectedEI: state.ptApp.selectedEI
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, AnyAction>): CartDispatchToProps => {
	return {
		saveProps: (props: BasicStringKeyedMap<any>) => {
			dispatch(CartActions.saveProps(props));
		},
		onCustomFieldChange: (customFields: BasicStringKeyedMap<any>) => {
			dispatch(CartActions.saveProps({customFields}));
		},
		ensureCart: () => {
			return dispatch(CartActions.ensureCart(false));
		},
		updateCart: () => {
			return dispatch(ApiActions.updateCart(false));
		},
		submitCart: (history: History) => {
			return dispatch(CartActions.submitCart(false, history));
		},
		emptyCart: () => {
			return dispatch(ApiActions.emptyCart(false));
		},
		updateCartItems: (cartId: string, cartItems: CartItem[], nonBlocking?: boolean) => {
			return dispatch(ApiActions.updateCartItems(cartId, cartItems, null, nonBlocking));
		},
		deleteCartItems: (cartId: string, cartItems: CartItem[], modstamp?: number) => {
			return dispatch(ApiActions.deleteCartItems(cartId, cartItems, modstamp));
		},
		fetchItemFeeData: (cartId: string) => {
			return dispatch(ApiActions.fetchItemFeeData(cartId));
		},
		fetchOrderFeeData: (cartId: string) => {
			return dispatch(ApiActions.fetchOrderFeeData(cartId, true));
		},
		applyDiscount: (cartId: string, discountCode: string, modstamp?: number) => {
			return dispatch(ApiActions.applyDiscount(cartId, discountCode, modstamp));
		},
		showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => {
			dispatch(PublicTicketAppActions.showAlert({alertBody, alertSeverity}));
		},
		clearAllMessages: () => {
			dispatch(PublicTicketAppActions.clearAllMessages());
		},
		showModalConfirmation: (body: React.ReactNode, confirmationCallback: () => any, intl: IntlShape) => {
			dispatch(PublicTicketAppActions.showModalConfirmation(body, confirmationCallback, intl));
		},
		pageView: (title: string, url: string): void => {
			dispatch(AnalyticsActions.pageView(title, url));
		},
		fetchGiftCardBalance: (gcNumber: string, captchaToken: string) => {
			return dispatch(ApiActions.fetchGiftCardBalance(gcNumber, captchaToken));
		},
		fetchGiftCardBalanceByPaymentMethodId: (paymentMethodId: string) => {
			return dispatch(ApiActions.fetchGiftCardBalanceByPaymentMethodId(paymentMethodId));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart));
