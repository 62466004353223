import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";

export class AnalyticsActions {
	public static appMount(): AnyAction {
		return {type: ActionTypes.ANALYTICS_APP_MOUNT};
	}
	
	public static pageView(title: string, url: string): AnyAction {
		return {type: ActionTypes.ANALYTICS_PAGE_VIEW, title, url};
	}
}