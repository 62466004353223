import * as React from 'react';
import {IntlShape} from "react-intl";
import {RouterProps} from "react-router";
import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";
import {Severities} from "../enums/severities";
import {CartService} from "../helpers/cart-service";
import {formatValidationErrors, handleApplicationErrors} from "../helpers/checkout-form-utils";
import {canEditBuyerInfo} from "../helpers/utilities";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {CountdownTimer} from "./countdown-timer";
import {FieldGroup, FieldGroupTypes} from "./field-group";
import {HTMLContent} from "./html-content/html-content";
import {PanelNav} from './panel-nav';
import {WaitingMessage} from './waiting-message';


/**
 * All properties that should be defined
 */
interface DeliveryFormProps extends RouterProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	intl: IntlShape;
	nextPagePath: string;
	pyosEnabledSubscriptionEventDescriptors?: BasicStringKeyedMap<string>;
	saveProps: (props: any) => void;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
	updateCart: () => Promise<any>;
}

interface DeliveryFormState {
	errors: BasicStringKeyedMap<string>;
}

export class DeliveryForm extends React.Component<DeliveryFormProps, DeliveryFormState> {

	public readonly state: DeliveryFormState = {errors: {}};

	public render() {
		const {blockingActions, cart, cartTimeRemaining, config, intl, pyosEnabledSubscriptionEventDescriptors} = this.props;
		const {deliveryInformation} = config;
		const {errors} = this.state;
		const isBusy: boolean = Object.keys(blockingActions).length > 0;
		const hasErrors: boolean = Object.keys(errors).length > 0;

		const deliveryMethodOptions: JSX.Element[] = [];

		// Determine if there are any unfilled or partially filled PYOS Subs and disable the "Next" button if necessary
		let hasUnfilledSubs = false;
		if (!!pyosEnabledSubscriptionEventDescriptors) {
			hasUnfilledSubs = (new CartService(cart)).hasUnfulfilledPYOSSubscriptions(pyosEnabledSubscriptionEventDescriptors);
		}
		
		//only include the 'none' option if there is no default value
		if (!cart.delMethod) {
			deliveryMethodOptions.push(<option key="none" value="">{intl.formatMessage({id: "lbl_SelectNone"})} </option>);
		}

		cart.deliveryMethods.forEach((deliveryMethod: string) => {
			deliveryMethodOptions.push(<option key={deliveryMethod} value={deliveryMethod}>{deliveryMethod}</option>);
		});

		return (
			<div style={{minHeight: '300px'}} className="d-flex flex-column">
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				{!!deliveryInformation && <div className="mb-2"><HTMLContent rawHtml={deliveryInformation}/></div>}
				
				<FieldGroup
					id="deliveryMethod"
					name="deliveryMethod"
					type={FieldGroupTypes.SELECT}
					label={intl.formatMessage({id: "lbl_SelectDeliveryMethod"})}
					value={cart.delMethod || ""}
					selectionOptions={deliveryMethodOptions}
					onChange={this.handleChange}
					disabled={isBusy || deliveryMethodOptions.length === 1 || !canEditBuyerInfo(cart)}
					invalid={!!errors.delMethod}
					feedbackMessage={errors.delMethod}
				/>
				
				<div className="mt-auto">
					<WaitingMessage isOpen={isBusy} />
					<PanelNav
						next={{label: intl.formatMessage({id: "lbl_Next"}), handleClick: this.next, isDisabled: isBusy || hasErrors || hasUnfilledSubs}}
					/>
				</div>
			</div>);
	}

	private validate = (delMethod: string) => {
		const {intl} = this.props;
		const errors: BasicStringKeyedMap<string> = {};
		if (!delMethod) {
			errors.delMethod = intl.formatMessage({id: "msg_required_field"});
		}
		this.setState({errors});
		return (Object.keys(errors).length === 0);
	}

	private handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const {clearAllMessages, history, intl, saveProps, showAlert, updateCart} = this.props;
		
		// Clear error messages at the start of the submit operation
		clearAllMessages();
		
		const valid = this.validate(evt.target.value);
		saveProps({delMethod: evt.target.value});
		if (!valid) {
			return;
		}
		
		updateCart().then((result: any) => {
			if (result.type === ActionTypes.API_SUCCESS) {
				const updatedCart: Cart = result.data;
				const filteredValidationErrors = this.getFilteredValidationErrors(updatedCart.validationErrors || []);
				if (filteredValidationErrors) {
					const errors = handleApplicationErrors(this.getFilteredValidationErrors(filteredValidationErrors), {deliverymethod__c : "delMethod"}, intl);
					if (Object.keys(errors).length > 0) {
						showAlert(formatValidationErrors(errors), Severities.ERROR);
					}
					this.setState({errors});
				} else {
					// If successfully updated with no validation errors, navigate to the next page
					history.push(this.props.nextPagePath);
				}
			}
		});
	}
	
	/**
	 * Performs conditional filtering of the cart.validationErrors collection prior to passing it off to handleApplicationErrors.
	 * At this time, the only validation error we will show here are ones pertaining to the "deliverymethod__c" field.
	 */
	private getFilteredValidationErrors = (validationErrors: ApplicationMessage[]): ApplicationMessage[] => {
		return validationErrors.filter(validationError => {
			const fieldName: string = (!!validationError.msgArgs && !!validationError.msgArgs.fieldName) ? validationError.msgArgs.fieldName : "";
			return fieldName.toLowerCase() === "deliverymethod__c";
		});
	}
	
	
	private next = () => {
		const {cart, history, nextPagePath} = this.props;
		if (this.validate(cart.delMethod)) {
			history.push(nextPagePath);
		}
	}
}
