import {IntlShape} from "react-intl";
import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";
import {Severities} from "../enums/severities";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {ButtonColors, ButtonProps} from "../models/public-ticket-app/button-props";
import {ModalProps} from "../models/public-ticket-app/modal-props";
import {SubmitResult} from "../models/public-ticket-app/submit-result";
import {TicketableEvent} from "../models/ticketable-events/ticketable-event";

export interface AlertOptions {
	alertArgs?: BasicStringKeyedMap<any> | undefined;
	alertBody?: React.ReactNode | undefined;
	alertId?: string | undefined;
	alertSeverity?: Severities;
}

export class PublicTicketAppActions {
	public static showAlert(alertOptions: AlertOptions): AnyAction {
		const alertMessage = new ApplicationMessage(alertOptions.alertSeverity, alertOptions.alertId, alertOptions.alertArgs, alertOptions.alertBody);
		return {type: ActionTypes.PTS_SHOW_ALERT, alertMessage};
	}

	public static clearAllMessages(): AnyAction {
		return {type: ActionTypes.PTS_CLEAR_ALL_MESSAGES};
	}
	
	public static clearMessage(messageKey: string): AnyAction {
		return {type: ActionTypes.PTS_CLEAR_MESSAGE, messageKey};
	}
	
	public static showMessages(messages: any[] | any): AnyAction {
		return {type: ActionTypes.PTS_SHOW_MESSAGES, messages: Array.isArray(messages) ? messages: [messages]};
	}
	
	public static showModalAction(modalProps: ModalProps): AnyAction {
		return {type: ActionTypes.PTS_SHOW_MODAL_ACTION, modalProps};
	}
	
	/** A variant of the modal action dialog that displays two buttons; Confirm and Cancel,
	 * with the heading "Confirmation required" and the specified body.
	 * @param body the body of the confirmation dialog
	 * @param confirmationCallback the function that will be called if the "Confirm" button is clicked
	 * @param intl the internalization object (sadly, this is needed to format the button labels,
	 * and I can't figure out a good way to get this into the PublicTicketActions class without heavy lifting.
	 */
	public static showModalConfirmation(body: React.ReactNode, confirmationCallback: () => any, intl: IntlShape): AnyAction {
		const modalProps = new ModalProps(
			[
				new ButtonProps(ButtonColors.SECONDARY, intl.formatMessage({id: "lbl_button_Cancel"}), true),
				new ButtonProps(ButtonColors.PRIMARY, intl.formatMessage({id: "lbl_button_Confirm"}), false, confirmationCallback)
			],
			body,
			intl.formatMessage({id: "msg_confirmation_required"}),
			true
		);
		return {type: ActionTypes.PTS_SHOW_MODAL_ACTION, modalProps};
	}
	
	public static hideModalAction(): AnyAction {
		return {type: ActionTypes.PTS_HIDE_MODAL_ACTION};
	}
	
	public static setSelectedEvent(selectedEvent: TicketableEvent): AnyAction {
		return {type: ActionTypes.PTS_SET_SELECTED_EVENT, selectedEvent};
	}
	
	public static setDefaultCalendarDate(newDate: string): AnyAction {
		return {type: ActionTypes.PTS_SET_DEFAULT_CALENDAR_DATE, newDate};
	}
	
	public static setCompletedState(submitResult: SubmitResult): AnyAction {
		return {type: ActionTypes.PTS_SET_COMPLETED_STATE, submitResult};
	}
	public static clearCompletedOrder(): AnyAction {
		return {type: ActionTypes.PTS_CLEAR_COMPLETED_STATE};
	}
	
	public static showLoginForm(): AnyAction {
		return {type: ActionTypes.PTS_SHOW_LOGIN_FORM};
	}
	
	public static hideLoginForm(): AnyAction {
		return {type: ActionTypes.PTS_HIDE_LOGIN_FORM};
	}
	
	public static showResetPasswordForm(): AnyAction {
		return {type: ActionTypes.PTS_SHOW_RESET_PASSWORD_FORM};
	}
	
	public static hideResetPasswordForm(): AnyAction {
		return {type: ActionTypes.PTS_HIDE_RESET_PASSWORD_FORM};
	}
	public static updateCartTimeRemaining(cartExpiration?: number, ): AnyAction {
		return {type: ActionTypes.PTS_UPDATE_CART_TIME_REMAINING, cartExpiration};
	}
}
