declare var window: any;

export class FacebookSDK  {

	/**
	 * Loads facebook SDK if it has not been loaded already.
	 * Calling this after it has already been loaded, is a noop.
	 *
	 * @param facebookApplicationId the facebook application id of the organization whose context should be use
	 */
	public load(facebookApplicationId: string) {
		window.fbAsyncInit = () => {
			window.FB.init({
				appId            : facebookApplicationId,
				// autoLogAppEvents : true,
				xfbml            : true,
				version          : 'v3.2'
			});
		};

		((d, s, id) => {
			const fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			const js = d.createElement(s) as HTMLScriptElement;
			js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			js.async = true;
			if (fjs.parentNode) {
				fjs.parentNode.insertBefore(js, fjs);
			}
		})(document, 'script', 'facebook-jssdk');
	}
}