export const enum ActionTypes {
	ANALYTICS_APP_MOUNT = "analytics.APP_MOUNT",
	ANALYTICS_PAGE_VIEW = "analytics.PAGE_VIEW",
	
	// API actions (actions that get fired to either invoke an async operation, or get fired when the operation completes)
	API_REQUEST = "api.API_REQUEST",
	API_SUCCESS = "api.API_SUCCESS",
	API_FAILURE = "api.API_FAILURE",

	// Inventory Service actions
	ISVC_ED_ACCESSED = "inventoryService.ED_ACCESSED",
	ISVC_DELETE_ED = "inventoryService.DELETE_ED",

	CART_SAVE_PROPS = "cart.SAVE_PROPS",
	CART_CLEAR = "cart.CLEAR",

	PTS_SHOW_ALERT = "pts.SHOW_ALERT",
	PTS_SHOW_MESSAGES = "pts.SHOW_MESSAGES",
	PTS_CLEAR_ALL_MESSAGES = "pts.CLEAR_ALL_MESSAGES",
	PTS_CLEAR_MESSAGE = "pts.CLEAR_MESSAGE",
	PTS_SHOW_MODAL_ACTION = "pts.SHOW_MODAL_ACTION",
	PTS_HIDE_MODAL_ACTION = "pts.HIDE_MODAL_ACTION",
	PTS_SET_SELECTED_EVENT = "pts.SET_SELECTED_EVENT",
	PTS_SET_COMPLETED_STATE = "pts.SET_COMPLETED_STATE",
	PTS_SET_DEFAULT_CALENDAR_DATE = "pts.SET_DEFAULT_CALENDAR_DATE",
	PTS_CLEAR_COMPLETED_STATE = "pts.CLEAR_COMPLETED_STATE",
	PTS_SHOW_LOGIN_FORM = "pts.SHOW_LOGIN_FORM",
	PTS_HIDE_LOGIN_FORM = "pts.HIDE_LOGIN_FORM",
	PTS_SHOW_RESET_PASSWORD_FORM = "pts.SHOW_RESET_PASSWORD_FORM",
	PTS_HIDE_RESET_PASSWORD_FORM = "pts.HIDE_RESET_PASSWORD_FORM",
	PTS_UPDATE_CART_TIME_REMAINING = "pts.UPDATE_CART_TIME_REMAINING"
}
