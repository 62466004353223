import {ShareButton} from "./share-button";
import {TicketIcon} from "../icons/icons";
import {IntlShape} from "react-intl";
import {History} from "history";

interface MobileTicketButtonProps {
	path: string;
	intl: IntlShape;
	history: History;
}

export const MobileTicketButton = (props: MobileTicketButtonProps) => {
	const {path, intl, history} = props;

	const navigate = () => {

		if(!path) {
			return;
		}
		history.push(path);
	}
	
	return (
		<ShareButton
			icon={TicketIcon}
			context={intl.formatMessage({id: 'lbl_ViewYourTickets'})}
			onClick={navigate}
		/>
	);
}