import * as React from 'react';
import {WrappedComponentProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Redirect, RouterProps} from "react-router";
import {AnyAction, Dispatch} from "redux";
import {AnalyticsActions} from "../actions/analytics-actions";
import {PublicTicketAppActions} from "../actions/public-ticket-app-actions";
import {CompleteMain} from '../components/complete-order/complete-main';
import {CompletePanel} from "../components/complete-order/complete-panel";
import {TicketOrderStatus} from "../enums/ticket-order-status";
import {Layout, layout} from "../hoc/layout";
import {Analytics} from "../models/analytics";
import {BasicStringKeyedMap} from "../models/basic-map";
import {FeeDescriptor} from "../models/fee-descriptor";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {SubmitResult} from "../models/public-ticket-app/submit-result";
import {Paths} from "../enums/paths";
import {RootState} from "../reducers";
import { NormalizedEventsUrl, getNormalizedEventsUrl } from '../helpers/routing';

/**
 * All properties available within this component
 */
export interface CompleteProps extends CompletePropsExcludingInjectedProps, CompleteMappedStateProps, CompleteMappedDispatchProps, RouterProps, WrappedComponentProps {}

/**
 * All properties that should be defined when using the component exported with injections.
 */
interface CompletePropsExcludingInjectedProps {}

interface CompleteMappedStateProps {
	analytics: Analytics;
	blockingActions: BasicStringKeyedMap<AnyAction>;
	config: PublicTicketAppConfig;
	itemFeeData: FeeDescriptor[];
	orderFeeData: FeeDescriptor[];
	submitResult: SubmitResult | null;
}

interface CompleteMappedDispatchProps {
	clearCompletedOrder: () => AnyAction;
	pageView: (title: string, url: string) => void;
}

export class Complete extends React.Component<CompleteProps> {
	private readonly CompletedOrder: Layout<typeof CompleteMain, CompletePanel>;
	
	constructor(props: CompleteProps) {
		super(props);
		
		const {formatMessage} = this.props.intl;
		
		const screenHeading = formatMessage({id: "lbl_YourOrderIsComplete"});
		// TODO - PMGR-8595 Not sure about the layout of the headings here. Discuss with Emma
		this.CompletedOrder = layout(
			{Main: CompleteMain, Panel: CompletePanel},
			null,
			null,
			{primary: screenHeading}
		);
	}
	
	public componentDidMount() {
		const {pageView, submitResult} = this.props;
		if (!!submitResult) {
			pageView(this.getTitle(), window.location.href);
		}
	}
	
	public componentDidUpdate() {
		const {analytics, pageView, submitResult} = this.props;
		if (!!submitResult && (analytics.url !== window.location.href)) {
			pageView(this.getTitle(), window.location.href);
		}
	}
	
	public componentWillUnmount() {
		this.props.clearCompletedOrder();
	}

	private redirectToEventsUrl(config: PublicTicketAppConfig) {
		const normalizedEventsUrl: NormalizedEventsUrl | null = getNormalizedEventsUrl(config.eventsUrl);

		if (normalizedEventsUrl?.eventsUrl) {
			if(!normalizedEventsUrl.isInternal) {
				window.location.replace(normalizedEventsUrl.eventsUrl);
				return null;
			} else {
				return <Redirect to={normalizedEventsUrl.eventsUrl} />
			}
		} else {
			return <Redirect to={Paths.ROOT}/>;
		}
	}

	public render() {
		const {config, intl, history, submitResult} = this.props;
		
		if (!submitResult) {
			return this.redirectToEventsUrl(config);
		}
		
		const {cart: {orderStatus}} = submitResult;
		if (orderStatus === TicketOrderStatus.COMPLETE ||
			orderStatus === TicketOrderStatus.TO_BE_QUALIFIED ||
			orderStatus === TicketOrderStatus.CONFIRMATION_EXCEPTION) {
			return <this.CompletedOrder submitResult={submitResult} config={config} intl={intl} history={history}/>;
		} else {
			return this.redirectToEventsUrl(config);
		}
	}
	
	private getTitle() {
		return this.props.intl.formatMessage({id: "lbl_title_completed_order"});
	}
}

const mapStateToProps = (state: RootState): CompleteMappedStateProps => {
	return {
		analytics: state.analytics,
		blockingActions: state.ptApp.blockingActions,
		config: state.ptApp.config,
		itemFeeData: state.ptApp.itemFeeData,
		orderFeeData: state.ptApp.orderFeeData,
		submitResult: state.ptApp.submitResult
	};
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): CompleteMappedDispatchProps => {
	return {
		clearCompletedOrder: (): AnyAction => {
			return dispatch(PublicTicketAppActions.clearCompletedOrder());
		},
		pageView: (title: string, url: string): void => {
			dispatch(AnalyticsActions.pageView(title, url));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Complete));
