import {History} from "history";
import * as React from "react";
import {FormattedMessage, IntlShape} from "react-intl";
import {RouterProps} from "react-router";
import {AnyAction} from "redux";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {PanelNav} from "../panel-nav";

export interface NoPaymentRequiredProps extends RouterProps{
	blockingActions: BasicStringKeyedMap<AnyAction>;
	intl: IntlShape;
	submitCart: (history: History) => Promise<any>;
}

/**
 * Component to handle orders where no payment is required.
 */
export class NoPaymentRequired extends React.Component<NoPaymentRequiredProps>{

	public render() {
		const {intl, blockingActions} = this.props;

		const isBusy: boolean = Object.keys(blockingActions).length > 0;

		return (
			<div>
				<h3> <FormattedMessage id="lbl_NoPaymentRequired" /> </h3>

				<div className="mt-auto">
					<PanelNav
						next={{label: intl.formatMessage({id: "lbl_Submit"}), handleClick: this.handleSubmit, isDisabled: isBusy}}
						back={{label: intl.formatMessage({id: "lbl_Back"}), handleClick: this.back, isDisabled: isBusy}}
					/>
				</div>

			</div>
		);
	}

	private handleSubmit = () => {
		this.props.submitCart(this.props.history);
	}

	private back = () => {
		this.props.history.goBack();
	}
}