import {TicketableEventTypes} from "../../enums/ticketable-event-types";
import {BasicStringKeyedMap} from "../basic-map";
import {EventInstance} from "./event-instance";

// this is the one we are going to keep
export class TicketableEvent {
	public id: string;
	public name: string;
	public sortOrder: number;
	public type: TicketableEventTypes;
	public instances: EventInstance[];

	public category?: string;
	public custom?: BasicStringKeyedMap<any>;    // Custom fields
	public description?: string;
	public detail?: string;
	public largeImagePath?: string;
	public purchaseUrl?: string;
	public smallImagePath?: string;
	public smallImageAltText?: string;
	public largeImageAltText?: string;
}