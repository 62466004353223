import * as React from "react";
import {useContext} from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {ConfigContext} from "../containers/public-ticket-app";
import {getEventRoute} from "../helpers/routing";

interface TicketableEventDetailLinkProps
{
	teId: string;
}

/**
 * TicketableEventDetailLink - Links to the ticketable event "detail" screen.
 */
export const TicketableEventDetailLink: React.FunctionComponent<TicketableEventDetailLinkProps> = (props) => {

	const {teId} = props;

	const config = useContext(ConfigContext);

	const eventDetailLocation = getEventRoute(teId);

	if (config.hideEventInstanceLearnMoreLinks){
		return null;
	}

	return (
		<div className="mb-2">
			<Link to={eventDetailLocation} className="link__te-details ml-auto mb-1 mt-auto">
				<FormattedMessage id="pmgr_lbl_LearnMore" />
			</Link>
		</div>
	);
};