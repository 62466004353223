import * as React from 'react';
import {FormattedMessage, IntlShape} from "react-intl";
import {RouterProps} from "react-router";
import {Button} from "reactstrap";
import {AnyAction} from "redux";
import {AlertOptions} from "../../actions/public-ticket-app-actions";
import {FieldGroup, FieldGroupTypes} from "../../components/field-group";
import {ActionTypes} from "../../enums/action-types";
import {Severities} from "../../enums/severities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {UserProfile} from "../../models/public-ticket-app/user-profile";
import {MainContentHeader} from "../main-content-header";

interface ContactRequestFormProps extends RouterProps{
	intl: IntlShape;
	pageView: (title: string, url: string) => void;
	prevPagePath: string;
	request?: string;
	showAlert: (alertOptions: AlertOptions) => void;
	submitContactRequest: (whatId: string, request: string) => Promise<AnyAction>;
	userProfile: UserProfile;
	whatId: string;
}

interface ContactRequestFormState {
	formValues: BasicStringKeyedMap<string>;
	errors: BasicStringKeyedMap<string>;
}

export class ContactRequestForm extends React.Component<ContactRequestFormProps, ContactRequestFormState> {
	public readonly state: ContactRequestFormState = {
		formValues: {
			request: !!this.props.request ? this.props.request : ""
		},
		errors: {},
	};

	public componentDidMount() {
		const {pageView, intl} = this.props;
		pageView(intl.formatMessage({id: "lbl_title_portal_contact_request"}), window.location.href);
	}

	public render() {
		const {errors, formValues} = this.state;
		const {intl: {formatMessage}, userProfile: usr} = this.props;

		return (
			<div>
				<MainContentHeader intlId="lbl_ContactRequest" />
				<p>
					{formatMessage({id:'msg_change_request_use_this_form'})}
				</p>
				<div className="mb-4">
					<ContactItem label={formatMessage({id:'user.name'})} value={usr.name} />
					<ContactItem label={formatMessage({id:'user.phone'})} value={usr.phone} />
					<ContactItem label={formatMessage({id:'user.email'})} value={usr.email} />
				</div>
				<FieldGroup
					id="request"
					name="request"
					type={FieldGroupTypes.TEXTAREA}
					label={formatMessage({id:'lbl_CommentsSpecialInstructions'})}
					value={formValues.request || ""}
					maxLength={1000}
					onChange={this.handlePropertyChange}
					invalid={!!errors.request}
					feedbackMessage={errors.request}
				/>
				<div className="text-right">
					<Button size="sm" color="link" onClick={this.handleBack}>
						<span className="text-danger">
							<FormattedMessage id="lbl_button_Cancel" />
						</span>
					</Button>
					<Button color="primary" onClick={this.handleSubmit}>
						<FormattedMessage id="lbl_Submit" />
					</Button>
				</div>
			</div>
		);
	}

	private handleBack = () => {
		this.props.history.push(this.props.prevPagePath);
	}

	private handlePropertyChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const {formValues} = this.state;
		formValues[evt.target.name] = evt.target.value;
		this.setState({formValues});
	}
	
	private handleSubmit = () => {
		const {showAlert, submitContactRequest, whatId} = this.props;
		const {formValues: {request}} = this.state;
		if (this.validateForm()) {
			submitContactRequest(whatId, request)
				.then((result: any) => {
					if (result.type === ActionTypes.API_SUCCESS) {
						showAlert({alertId: "msg_change_request", alertSeverity: Severities.SUCCESS});
					}
				}
			);
		}
	}
	
	private validateForm() {
		const {intl} = this.props;
		const {formValues} = this.state;
		const errors: BasicStringKeyedMap<string> = {};
		
		if (!formValues.request){
			errors.request = intl.formatMessage({id: "msg_required_field"});
		}
		this.setState({errors});
		return (Object.keys(errors).length === 0);
	}
}

interface ItemProps {
	label: string;
	value: string;
}
const ContactItem: React.FunctionComponent<ItemProps> = ({label, value}) => (
	<p className="mb-1">
		<span className="font-weight-bold mr-1">{label}:</span>
		<span>{value}</span>
	</p>
);
