import * as React from "react";
import {Media} from "reactstrap";
import {HTMLContent} from "./html-content/html-content";

declare var window: any;

interface EventMainProps {
	eventDetail?: string;
	eventName: string;
	largeImagePath?: string;
	largeImageAltText?: string;
}

export const EventMain: React.FunctionComponent<EventMainProps> = (props) => {
	const {eventDetail, eventName, largeImagePath, largeImageAltText} = props;
	return (
		<div>
			{!!largeImagePath && (
				<Media
					object={true}
					src={window.PublicTicketApp.assetPath + largeImagePath}
					alt={largeImageAltText || eventName}
					className="w-100 mb-3"
				/>
			)}
			{!!eventDetail && <HTMLContent rawHtml={eventDetail}/>}
			
			{/* Fall back to just showing the event name if no other props are provided */}
			{!largeImagePath && !eventDetail && <span>{eventName}</span>}
		</div>
	);
};
