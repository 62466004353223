import {Component} from "react";
import {IntlShape} from "react-intl";
import {CartItem} from "../../models/cart-item";
import {FacebookIcon} from "../icons/icons";
import {FacebookSDK} from "./facebook-sdk";
import {ShareButton} from "./share-button";

interface FacebookShareButtonProps {
	eventInstance: CartItem;
	facebookAppId?: string;
	intl: IntlShape;
	url: string;
}

declare var window: any;

/**
 * Component for our custom facebook event share button
 */
export class FacebookShareButton extends Component<FacebookShareButtonProps> {

	public componentDidMount() {
		if (this.props.facebookAppId) {
			new FacebookSDK().load(this.props.facebookAppId);
		}
	}

	public share = () => {
		const {url, intl, eventInstance} = this.props;

		if (!url) {
			return;
		}

		const displayEventName = `${eventInstance.teName} - ${eventInstance.eiName}`;

		const messageToShare = intl.formatMessage({id: 'msg_default_social_media_share_message'}, {eventName: displayEventName});

		window.FB.ui({
			method: 'share',
			href: url,
			quote: messageToShare,
			mobile_iframe: true
		}, (response: any) => {
		});
	}

	public render() {
		const {facebookAppId, url} = this.props;

		if (!facebookAppId || !url) {
			return null;
		}

		return (
			<ShareButton
				icon={FacebookIcon}
				context='Facebook'
				onClick={this.share}/>
		);
	}

}
