import * as React from "react";
import {IntlShape} from "react-intl";
import {RouteComponentProps} from "react-router";
import {AnyAction} from "redux";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {PortalOrder} from "../../models/portal/portal-order";
import {PortalOrderDetails} from "../../models/portal/portal-order-details";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {MainContentHeader} from "../main-content-header";
import {Message} from "../message";
import {OrderList} from "./order-list";

interface PortalHistoryListProps extends RouteComponentProps<any>  {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	config: PublicTicketAppConfig;
	fetchPortalOrderDetails: (ticketOrderId: string) => Promise<any>;
	pageView: (title: string, url: string) => void;
	intl: IntlShape;
	orders: PortalOrder[];
	portalOrderCache: BasicStringKeyedMap<PortalOrderDetails>;
}

export class OrderHistoryList extends React.Component<PortalHistoryListProps> {

	public componentDidMount() {
		const {pageView, intl} = this.props;
		pageView(intl.formatMessage({id: "lbl_title_portal_orders"}), window.location.href);
	}

	public render() {

		const {
			orders,
			blockingActions,
			config,
			fetchPortalOrderDetails,
			history,
			location,
			match,
			portalOrderCache,
			staticContext,
			intl
		} = this.props;

		const hasOrders = orders.length > 0;

		return (
			<div>
				<MainContentHeader intlId="lbl_Orders"/>
				{
					hasOrders
						? <OrderList
							blockingActions={blockingActions}
							config={config}
							fetchPortalOrderDetails={fetchPortalOrderDetails}
							history={history}
							location={location}
							match={match}
							portalOrders={orders}
							portalOrderCache={portalOrderCache}
							intl={intl}
							staticContext={staticContext}
						/>
						: <Message intlId="msg_you_have_not_yet_placed_an_order"/>
				}
			</div>
		);
	}
}
