import * as React from "react";
import {ComponentClass} from "react";
import {Col, Row} from "reactstrap";

/**
 * Type that infers component properties
 *
 * todo: this should probably be type "never" instead of "any". But we need to do few more things before it can work, with optional generics.
 */
type InferComponentProps<T> = T extends React.ComponentType<infer P> | React.ComponentClass<infer P> | React.Component<infer P> | React.FunctionComponent<infer P>  ? P : any;

///
/// Interfaces
///

/**
 * Interface for Layout component
 * @param Components - An object that contains two props: Main and Panel, which specify the components to render in the left (Main) and right (Panel) panels. The Main property is required, the Panel is optional. If Panel is not provided, then Main is rendered as a full-width panel.
 * @param mainHeader - The string you want to have displayed as the heading in the main panel. This is an optional argument. If not provided, no heading will be rendered in the main panel.
 * @param panelHeader - The third argument is an object which contains two props: primary (optional) and secondary (optional). These props specify strings that will be used as the primary and / or secondary heading in the right-hand panel.
 */
export interface Layout<MainComponent, PanelComponent = {}> extends ComponentClass<InferComponentProps<MainComponent> & InferComponentProps<PanelComponent>> {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Components<MainComponent, PanelComponent> {
	Main: React.ComponentClass<any> | React.FunctionComponent<any>;
	Panel?: React.ComponentClass<any> | React.FunctionComponent<any>;
}

// The primary and secondary headings within the screen header.
interface ScreenHeader {
	primary?: string;
	secondary?: string;
}

interface LayoutOptions {
	largePanel: boolean; // When true, the "panel" is large and "main" is small.
}

/**
 * A Higher-Order Component that enforces a common layout across the application. If both a MainComponent and a PanelComponent
 * are specified, then the layout will generally be 2/3 main - 1/3 panel, unless the "largePanel" option is set to true, in
 * which case, the layout is 1/3 main - 2/3 panel. If the PanelComponent is omitted, the MainComponent will take up the full
 * width of the layout.
 *
 * @param components The React components that will comprise the main (left) and panel (right) sections of the layout
 * @param mainHeader A optional heading that will appear above the main component. (Rendered as a themed h2 element.)
 * @param panelHeader An optional heading that will appear above the panel component. (Rendered as a themed h2 element.)
 * @param screenHeader An optional heading that will appear across the top of the entire layout (both main and panel). It can specify a primary (h1) and secondary (h2) heading
 * @param options An options object. At this time, the only option is "largePanel". Setting largePanel to true cause the panel component to occupy 2/3 of the layout.
 */
export const layout = <MainComponent, PanelComponent = {}>(
		components: Components<MainComponent, PanelComponent>,
		mainHeader?: string | null,
		panelHeader?: string | null,
		screenHeader?: ScreenHeader | null,
		options?: LayoutOptions | null
	): ComponentClass<InferComponentProps<MainComponent> & InferComponentProps<PanelComponent>> => {
	const {Main, Panel} = components;

	class Component extends React.Component<InferComponentProps<MainComponent> & InferComponentProps<PanelComponent>>{
		public render() {
			const largePanel = !!options && options.largePanel;
			const panelColumnWidth = largePanel ? 8 : 4;
			const mainColumnWidth = 12 - panelColumnWidth;
			const panel = this.getPanel();
			if (panel) {
				return (
					<>
						{this.getScreenHeader()}
						<Row>
							<Col md={mainColumnWidth} className="mb-3">
								{this.getMain()}
							</Col>
							<Col md={panelColumnWidth} className="mb-3">
								{panel}
							</Col>
						</Row>
					</>
				);
			}
			return (
				<>
					{this.getScreenHeader()}
					<Row>
						<Col md={{size: 8, offset: 2}} className="mb-3">
							{this.getMain()}
						</Col>
					</Row>
				</>
			);
		}

		private getPanel(){
			if(!Panel){
				return null;
			}
			return (
				<>
					{panelHeader && (
						<div className="pts-panel-header mb-4">
							<h2>{panelHeader}</h2>
						</div>
					)}
					<Panel {...this.props} />
				</>
			);
		}

		private getMain() {
			return (
				<>
					{mainHeader && (
						<div className="pts-main-content-header mb-4">
							<h2>{mainHeader}</h2>
						</div>
					)}
					<Main {...this.props} />
				</>
			);
		}
		
		private getScreenHeader() {
			return !!screenHeader ? (
				<Row>
					<Col>
						<div className="pts-screen-header mb-4">
							<h1>{screenHeader.primary}</h1>
							<h2>{screenHeader.secondary}</h2>
						</div>
					</Col>
				</Row>
			) : null;
		}
	}
	return Component;
};
