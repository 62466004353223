import * as React from "react";
import {getInstanceRoute} from "../../../helpers/routing";
import {BackLink} from "../../back-link/back-link";
import {HTMLContent} from "../../html-content/html-content";
import {VenueProps} from "./venue-props";

/**
 * Venue details panel - Shows the user-defined venue "detail".
 * @param venue 
 * @param eventInstanceId - This gives us a way to link back to the event detail screen. 
 */
export const VenueDetail: React.FC<VenueProps> = ({venue: {detail}, eventInstanceId}) => (
	!!detail
		? (
			<div>
				<BackLink intlId="lbl_Back" toLocation={getInstanceRoute(eventInstanceId)} />
				<HTMLContent rawHtml={detail} />
			</div>
		)
		: null
);
