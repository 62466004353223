import * as React from "react";
import {AnyAction} from "redux";
import {Input} from "reactstrap";
import {Paths} from "../../enums/paths";
import {CartService} from "../../helpers/cart-service";
import {InventoryService} from "../../helpers/inventory-service";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {CountdownTimer} from "../countdown-timer";
import {DetailToggleButton} from "../detail-toggle-button";
import {EmptyMiniCartMessage} from "../empty-mini-cart-message";
import {CartItemQuantityPickerWithInjectedIntl as CartItemQuantityPicker, GAQuantityInput} from "../ga-selection/ga-item-quantity-selector";
import {getGAQuantityInputsTotalPrice, QuantityBasedCartFormProps} from "../ga-selection/ga-item-selection";
import {MiniCartItemTotal} from "../mini-cart/mini-cart-item-total";
import {PanelNav} from "../panel-nav";
import {WaitingMessage} from "../waiting-message";
import {SingleQuantitySelector} from "./single-quantity-selector";
import {getPriceString} from "../../helpers/localization";
import {getPriceWithFeesIncluded} from "../../helpers/utilities";
import { AddToCartFeeDisplay } from "../add-to-cart/add-to-cart-fee-display";

interface MembershipFormProps extends QuantityBasedCartFormProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
}

interface MembershipFormState {
	showPricingDetails: boolean;
	currentLevelId?: string;
}

export class MembershipForm extends React.Component<MembershipFormProps, MembershipFormState>  {
	constructor(props: MembershipFormProps) {
		super(props);
		this.state = {showPricingDetails: true};
	}

	public togglePricingDetails = () => {
		this.setState({showPricingDetails: !this.state.showPricingDetails});
	}

	public handleLevelChange = (priceLevelId: string) => {
		this.setState({currentLevelId: priceLevelId});
	}

	private handlePwywPriceChange = (evt: React.ChangeEvent<HTMLInputElement>, quantityInput?: GAQuantityInput) => {
		if (quantityInput && evt.target.value) {
			quantityInput.pwywPrice = Number(evt.target.value);
			this.props.handleQuantityChange(quantityInput);
		}
	}

	public render() {
		const {
			blockingActions,
			cart,
			cartTimeRemaining,
			config: {includeFeesInPrice, portalEnabled},
			currencyCode,
			deleteFromCart,
			eventDescriptor,
			handleQuantityChange,
			handleNext,
			intl,
			quantityInputs,
		} = this.props;

		const cartService = new CartService(cart);
		const itemTotal = cartService.getItemTotalPrice(eventDescriptor.id, includeFeesInPrice) + getGAQuantityInputsTotalPrice(Object.values(quantityInputs), includeFeesInPrice);
		const eiCartSize = cartService.getNumberOfItemsInCart(eventDescriptor.id) + Object.values(quantityInputs).reduce((prev, input) => prev + Number(input.inputQty), 0);

		if (eventDescriptor.allocList.length !== 1) {
			return null; // this component should only be used when the allocation list is 1
		}

		const isBusy: boolean = Object.keys(blockingActions).length > 0;

		// memberships only have one allocation
		const allocation = eventDescriptor.allocList[0];
		const priceLevels = InventoryService.getLevelsByAllocationId(eventDescriptor, allocation.id);
		const currentLevel = priceLevels.find((priceLevel) => priceLevel.id === this.state.currentLevelId);
		const currentQuantityInputId = Object.keys(quantityInputs).find((input) => input === this.state.currentLevelId);
		const currentQuantityInput = currentQuantityInputId ? quantityInputs[currentQuantityInputId] : undefined;

		const quantitySelectionInputs = priceLevels.map((priceLevel: LevelDescriptor) => {
			return (
				<div key={priceLevel.id} className="mr-3 mb-3">
					<CartItemQuantityPicker
						currencyCode={currencyCode}
						quantityInput={quantityInputs[priceLevel.id]}
						deleteFromCart={deleteFromCart}
						handleQuantityChange={handleQuantityChange}
						includeFeesInPrice={includeFeesInPrice}
					 	showPricingDetails={this.state.showPricingDetails}
					/>
				</div>
			);
		});

		return (

			<div>
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				{portalEnabled
					? <SingleQuantitySelector
						currencyCode={currencyCode}
						deleteFromCart={deleteFromCart}
						eventDescriptor={eventDescriptor}
						handleQuantityChange={handleQuantityChange}
						handleLevelChange={this.handleLevelChange}
						intl={intl}
						quantityInputs={quantityInputs}
						includeFeesInPrice={includeFeesInPrice}
						showPricingDetails={this.state.showPricingDetails}
					/>
					: quantitySelectionInputs
				}
				
				{includeFeesInPrice &&
					<DetailToggleButton onClick={this.togglePricingDetails} detailsVisible={this.state.showPricingDetails} pricing={true}/>
				}

				<div className="mt-1">
					<div className="d-flex mb-3">
						{currentLevel && <span>Price {currentLevel?.pwyw && '(' + getPriceString(intl, currencyCode, {minPrice: Number(currentLevel?.price)}) + ' ' + intl.formatMessage({id: "lbl_MinimumAmount"}) + ')'}</span>}

						{currentLevel?.pwyw ? (<Input
							type="number"
							style={{width: '33%'}}
							className="ml-auto"
							aria-label={currentLevel.name + intl.formatMessage({id: "lbl_PWYWPriceSuffix"})}
							min={currentLevel.price}
							id={currentLevel.id + '-pwyw-price'}
							name={currentLevel.id + '-pwyw-price'}
							onChange={(evt) => this.handlePwywPriceChange(evt, currentQuantityInput)}
						/>) : currentLevel && <span className="ml-auto">{getPriceString(intl, currencyCode, {minPrice: getPriceWithFeesIncluded(currentLevel)})}</span>}
					</div>
					{currentLevel?.pwyw && <AddToCartFeeDisplay isOpen={true} priceLevel={currentLevel} intl={intl} />}
				</div>
				
				{!portalEnabled && (
					eiCartSize > 0 ? <MiniCartItemTotal currencyCode={currencyCode} itemTotal={itemTotal}/> : <EmptyMiniCartMessage/>
				)}
				
				<div className="mt-3">
					<WaitingMessage isOpen={isBusy} />

					<PanelNav
						next={{handleClick: handleNext, label: intl.formatMessage({id: "lbl_Next"}), isDisabled: !eiCartSize || isBusy}}
						back={{handleClick: this.previous, label: intl.formatMessage({id: "lbl_Back"})}}
					/>
				</div>

			</div>


		);
	}

	private previous = () => {
		this.props.history.push(Paths.MEMBERSHIPS);
	}

}
