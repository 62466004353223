/**
 * Names of the @RemoteAction methods available to use
 */
/**
 * Names of the @RemoteAction methods available to use
 */
export enum VfRemoteActionMethods {
    APPLY_DISCOUNT = "applyDiscount",
    DELETE_CART_ITEMS = "deleteCartItems",
    FETCH_GLOBAL_CONFIG = "fetchGlobalConfig",
    FETCH_LOCALIZED_MESSAGES = "fetchLocalizedMessages",
    FETCH_EVENTS = "fetchEvents",
    FETCH_EVENT = "fetchEvent",
    FETCH_EVENT_DESCRIPTOR = "fetchEventDescriptor",
    FETCH_ITEM_FEE_DATA = "fetchItemFeeData",
    FETCH_ORDER_FEE_DATA = "fetchOrderFeeData",
    FETCH_GIFT_CARD_BALANCE = "fetchGiftCardBalance",
    FETCH_GIFT_CARD_BALANCE_BY_PAYMENT_METHOD_ID = "fetchGiftCardBalanceByPaymentMethodId",
    ENSURE_CART = "ensureCart",
    INSERT_CART_ITEMS = "insertCartItems",
    INSERT_PYOS_SUBSCRIPTION_CART_ITEMS = "insertPYOSSubscriptionCartItems",
    INSERT_SUBSCRIPTION_CART_ITEMS = "insertSubscriptionCartItems",
	INSERT_FULFILLMENT_ITEMS = "insertFulfillmentItems",
	FETCH_SUBSCRIPTION_FULFILLMENT_GROUPS = "fetchSubscriptionFulfillmentGroups",
	FETCH_FULFILLMENT_EVENT_DESCRIPTOR = "fetchFulfillmentEventDescriptor",
	FETCH_PREVIEW_FULFILLMENT_EVENT_DESCRIPTOR = "fetchPreviewFulfillmentEventDescriptor",
    FETCH_MOBILE_TICKET_CART = "fetchMobileTicketCart",
    FETCH_APPLE_PASS = 'fetchApplePass',
    FETCH_GOOGLE_PASS_URL = 'fetchGooglePassURL',
    SUBMIT_ORDER = "submitOrder",
    UPDATE_CART = "updateCart",
    UPDATE_CART_ITEMS = "updateCartItems",
    UPDATE_USER_PROFILE = "updateUserProfile",
    VALIDATE_PASSCODE = "validatePasscode",
	VALIDATE_CYO_SUBSCRIPTION_PRICE_LEVELS = 'validateCYOSubscriptionPriceLevels',
    
    //Portal specific
    CHANGE_PASSWORD = "changePassword",
    DO_NOT_RENEW = "doNotRenew",
    FORGOT_PASSWORD = "forgotPassword",
    FETCH_PORTAL_ORDERS = "fetchPortalOrders",
    FETCH_PORTAL_DONATIONS = "fetchPortalDonations",
    FETCH_RENEWABLE_BENEFITS = "fetchRenewableBenefits",
    FETCH_PORTAL_ORDER_DETAILS = "fetchPortalOrderDetails",
    FETCH_PENDING_RENEWAL = "fetchPendingRenewal",
    LOGIN = "login",
    SUBMIT_CONTACT_REQUEST = "submitContactRequest"
}